import { FC } from 'react';
import styled from 'styled-components';
import { Button, FormSwitcher, MediumText } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { TSystemSettings } from '@sim-admin-frontends/data-access-admin-be';

import { Wrapper } from '../common/Formstyles';

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spaces.spacing8} 0;
`;

type Props = {
  onSubmit: (arg: TSystemSettings) => Promise<void>;
  initialValues: TSystemSettings;
};

const SystemSettings: FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();

  const methods = useForm<TSystemSettings>({
    defaultValues: initialValues,
  });
  const { handleSubmit, formState, setValue } = methods;
  const { isSubmitting } = formState;

  const onNotificationsChange = (value: boolean) => {
    setValue('notificationsEnabled', value);
  };

  const onPostsChange = (value: boolean) => {
    setValue('postsEnabled', value);
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <Item>
            <MediumText>{t('systemSettings.notifications')}</MediumText>
            <FormSwitcher
              initialValue={initialValues.notificationsEnabled}
              onChange={onNotificationsChange}
              testId="SystemSettings#Notifications"
            />
          </Item>
          <Item>
            <MediumText>{t('systemSettings.posts')}</MediumText>
            <FormSwitcher
              initialValue={initialValues.postsEnabled}
              onChange={onPostsChange}
              testId="SystemSettings#Posts"
            />
          </Item>
        </FormProvider>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormWrapper>
    </Wrapper>
  );
};

export default SystemSettings;
