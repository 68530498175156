import {
  useInfiniteGraphQLQuery,
  SaLandmarksQueryVariables,
  SaLandmarksQuery,
  useSaLandmarksQuery,
} from '../..';

export const useInfiniteLandmarksQuery = (variables: SaLandmarksQueryVariables) => {
  const getNextPageParam = (lastPage: SaLandmarksQuery, allPages: SaLandmarksQuery[]) =>
    lastPage.landmarks.landmarks.length === variables.pageSize
      ? allPages.length * variables.pageSize
      : undefined;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => {
    const { query, ...restVariables } = variables;
    return {
      ...restVariables,
      pageSize: variables.pageSize,
      pageOffset: Number(pageParam ?? 0),
      ...(query ? { query } : {}),
    };
  };

  return useInfiniteGraphQLQuery(useSaLandmarksQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
