import { useMemo } from 'react';
import { TSAVisitorInterests, TVisitorInterestCategories } from '@sim-admin-frontends/data-access';
import { captureException } from '@sentry/react';
import {
  TSelectItems,
  TToastType,
  getErrorMessage,
  updateToast,
} from '@sim-admin-frontends/ui-shared';

import { INSTITUTION_TOAST_ID } from '../components/institutions/edit/InstitutionEdit.container';

const useParsedVisitorInterestsOptions = (visitorIterests?: TSAVisitorInterests) => {
  const parsedVisitorInterestsOptions = useMemo(() => {
    let options: TSelectItems = [];

    try {
      if (!visitorIterests) {
        return [];
      }

      const parsedData: TVisitorInterestCategories = JSON.parse(visitorIterests);

      options = Object.entries(parsedData).flatMap(([key, categoryData]) => {
        const subcategories = categoryData.subcategories;
        const categoryLabel = categoryData.category || '';
        const categoryOption = { value: key, label: categoryLabel };

        if (!subcategories) {
          return categoryOption;
        }

        return [
          categoryOption,
          ...subcategories.map((subcategory) => {
            const [subcategoryKey, subcategoryLabel] = Object.entries(subcategory)[0];
            return { value: subcategoryKey, label: subcategoryLabel };
          }),
        ];
      });
    } catch (e) {
      captureException(e);
      updateToast(INSTITUTION_TOAST_ID, getErrorMessage(e), TToastType.ERROR);
    }

    return options;
  }, [visitorIterests]);

  return parsedVisitorInterestsOptions;
};

export default useParsedVisitorInterestsOptions;
