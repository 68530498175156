export const HEADER_HEIGHT_PX = 64;
export const SIDER_WIDTH_PX = 250;
export const SIDER_COLLAPSED_WIDTH_PX = 70;
export const PINNED_CITIES = [
  'Miami',
  'New York', // testing, staging
  'New York City', // production
  'San Leandro',
  'Roseville',
  'Foster City',
];
export const WASTE_MANAGEMENT_URL = 'Garbage';
export const STREET_SWEEPING_URL = 'StreetSweeping';
export const MAX_PLACES_PAGE_SIZE = 9999;
export const MIN_NUMBER_OF_SEARCH_CHARS = 3;
export const SIDEPANEL_COLLAPSED_WIDTH_PX = 51;
export const SIDEPANEL_EXPANDED_WIDTH_PX = 340;
export const SIDEPANEL_DEFAULT_SIDE = 'right';
export const INFLUENCER_TOAST_ID = 'submitInfluencer';
export const START_DAY_TIME_12H = '12:00';
export const END_DAY_TIME_12H = '11:45';
export const AM = 'AM';
export const PM = 'PM';
export const START_DAY_TIME_24H = '00:01';
export const END_DAY_TIME_24H = '23:59';
export const STRIPE_PAYMENTS_DASHBOARD = 'https://dashboard.stripe.com/payments';
export const STRIPE_PAYMENTS_DASHBOARD_TESTING = 'https://dashboard.stripe.com/test/payments';
