import { useSaHidePlaceMutation } from '@sim-admin-frontends/data-access-admin-be';
import {
  AddInstitutionIcon,
  ConfirmModal,
  EditIcon,
  FeatureFlagIcon,
  LandmarkIcon,
  loadingToast,
  ShowInstitutionsIcon,
  ShowUsersIcon,
  TableIconProps,
  TrashCanIcon,
  ItineraryEventIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TPlaceModalActions } from '../../types/TPlace';

type Props = {
  placeId?: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGeneratePlaceActions = ({ refetch, placeId, isEditPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(TPlaceModalActions.DELETE);

  const { mutate: hidePlaceMutation } = useSaHidePlaceMutation({
    onMutate: () => {
      loadingToast(t('places.deleteToast.loading'), {
        toastId: TPlaceModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TPlaceModalActions.DELETE, t('places.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TPlaceModalActions.DELETE, t('places.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const hidePlace = async () => {
    await hidePlaceMutation({
      id: placeId || '',
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === TPlaceModalActions.DELETE) {
      hidePlace();
      setIsModalVisible(false);
      history.push(ROUTES.places.path);
      refetch();
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setModalDescription(t('places.deleteModal.description'));
    setModalClickAction(TPlaceModalActions.DELETE);
    setIsModalVisible(true);
  };

  const generateItems = (): TableIconProps[] => {
    if (!placeId) {
      return [];
    }
    const editPath = generatePath(ROUTES.placeEdit.path, { id: placeId });
    const addInstitutionPath = generatePath(ROUTES.institutionEditPlace.path, { placeId });
    const placeFeaturesPath = generatePath(ROUTES.placeFeatures.path, { id: placeId });
    const usersPath = generatePath(ROUTES.placeUsers.path, { placeId: placeId });
    const institutionsPath = generatePath(ROUTES.institutions.path, { placeId });
    const landmarksPath = generatePath(ROUTES.landmarks.path, { placeId });
    const placeItinerariesPath = generatePath(ROUTES.placeItineraries.path, { placeId });

    return [
      {
        label: t('table.dropdown.showItineraries'),
        path: placeItinerariesPath,
        icon: <ItineraryEventIcon />,
        testId: 'PlacesTableMenu#itineraries',
      },
      {
        label: t('places.features.title'),
        path: placeFeaturesPath,
        icon: <FeatureFlagIcon />,
        testId: 'PlacesTableMenu#features',
      },
      {
        label: t('table.dropdown.showInstitutions'),
        path: institutionsPath,
        icon: <ShowInstitutionsIcon />,
        testId: 'PlacesTableMenu#showInstitutions',
      },
      {
        label: t('table.dropdown.showPlaceUsers'),
        path: usersPath,
        icon: <ShowUsersIcon />,
        testId: 'PlacesTableMenu#showUsers',
      },
      {
        label: t('table.dropdown.addPlaceInstitution'),
        path: addInstitutionPath,
        icon: <AddInstitutionIcon />,
        testId: 'PlacesTableMenu#addPlaceInstitution',
      },
      {
        label: t('table.dropdown.showLandmarks'),
        path: landmarksPath,
        icon: <LandmarkIcon />,
        testId: 'PlacesTableMenu#showLandmarks',
      },
      {
        label: t('table.dropdown.edit'),
        path: editPath,
        hidden: isEditPage,
        testId: 'PlacesTableMenu#edit',
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'PlacesTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('places.deleteModal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('places.deleteModal.cancel')}
      confirmButtonText={t('places.deleteModal.confirm')}
      testId="Places#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
