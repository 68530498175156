import {
  CloseIcon,
  FormSectionHeader,
  FormSelect,
  FormTextarea,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { UseFormReturn, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TPlaceFeatureFlagValues, TReminderCategorySettings } from '../../types/TFeatureFlags';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled(CloseIcon)<{ $disabled: boolean }>`
  padding: ${({ theme }) => theme.spaces.spacing40};
  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  }
`;

type ReminderCategoryEditProps = {
  methods: UseFormReturn<TPlaceFeatureFlagValues>;
  reminderCategory: TReminderCategorySettings;
  categoryIndex: number;
  isDeleteDisabled?: boolean;
  onDeleteClick?: (index: number) => void;
  reminderCategoriesOptions: TSelectItems;
  isFetchingReminderCategories?: boolean;
  onReminderCategoriesScrollToBottom?: (e: Event) => void;
};

const ReminderCategorySection: FC<ReminderCategoryEditProps> = ({
  methods,
  reminderCategory,
  categoryIndex,
  isDeleteDisabled = false,
  onDeleteClick,
  reminderCategoriesOptions,
  isFetchingReminderCategories,
  onReminderCategoriesScrollToBottom,
}) => {
  const { t } = useTranslation();
  const { formState, control, register } = methods;
  const { errors } = formState;

  const handleCloseClick = () => {
    if (onDeleteClick && !isDeleteDisabled) {
      onDeleteClick(categoryIndex);
    }
  };

  return (
    <>
      <FormSectionHeader
        title={t('places.features.form.reminderCategory.header', { index: categoryIndex })}
      />
      <Wrapper>
        <LeftWrapper>
          <FormSelect
            clearable
            control={control}
            options={reminderCategoriesOptions}
            label={t('places.features.form.reminderCategory.reminderCategory')}
            name={`reminderSettings.categories.${categoryIndex}.code`}
            defaultValue={reminderCategory.code}
            testId={`FeatureFlagEdit#reminders-categories-${categoryIndex}-code`}
            onMenuScrollToBottom={onReminderCategoriesScrollToBottom}
            isLoading={isFetchingReminderCategories}
            error={get(errors, `reminderSettings.categories.${categoryIndex}.code`)}
          />
          <FormTextarea
            label={t('places.features.form.reminderCategory.jsonPath')}
            {...register(`reminderSettings.categories.${categoryIndex}.jsonPath`)}
            testId={`FeatureFlagEdit#reminders-categories-${categoryIndex}-jsonPath`}
            error={errors.reminderSettings?.categories?.[categoryIndex]?.jsonPath}
          />
        </LeftWrapper>
        <RightWrapper>
          {onDeleteClick && <CloseButton onClick={handleCloseClick} $disabled={isDeleteDisabled} />}
        </RightWrapper>
      </Wrapper>
    </>
  );
};

export default ReminderCategorySection;
