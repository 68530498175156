import {
  ImageInput,
  Institution,
  InstitutionPlaceRole,
  SaInstitutionQuery,
} from '@sim-admin-frontends/data-access';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TSAInstitutionPreferences = NonNullable<
  SaInstitutionQuery['institution']
>['preferences'];

export type TRoleSelectItem = {
  label: string;
  value: InstitutionPlaceRole;
};

export type TInstitutionFormVariables = {
  name: string;
  category?: TSelectItem;
  places: readonly TSelectItem[] | null;
  taskCodes: string[];
  description: string;
  website: string;
  contactEmail?: string;
  contactPhone?: string;
  location?: {
    address: string;
    geo: {
      lat: number;
      lon: number;
    };
  };
  branding?: {
    avatar?: (File | ImageInput)[] | null;
  } | null;
  role: TRoleSelectItem;
  isChatEnabled?: boolean | null;
  isRecommended?: boolean | null;
  lang?: TSelectItem;
  visitorMode?: boolean;
  preferences?: TSelectItem[];
  countryCode?: string;
};

export type TInstitution = Pick<Institution, 'id' | 'name'>;

export enum TInstitutionEnablingStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum TInstitutionModalActions {
  DELETE = 'DELETE',
}
