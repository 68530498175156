import { ChangeEvent, FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  SearchIcon,
  Table,
  TableActionHeader,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';
import { TSAPlaceListItem, TSAPlaceListItems } from '@sim-admin-frontends/data-access-admin-be';

import ROUTES from '../../../routing/routes';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';

const DEFAULT_PLACE_PAGE_SIZE = 100;

export interface PlacesViewProps {
  data: TSAPlaceListItems;
  columns: TExtendedColumn<TSAPlaceListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSearchTermChange: (searchTerm: string) => void;
}

const PlacesView: FC<PlacesViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSearchTermChange,
}) => {
  const { t } = useTranslation();
  const onSearchTermChangeInternal = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length < MIN_NUMBER_OF_SEARCH_CHARS) {
      return;
    }
    onSearchTermChange(e.target.value);
  };
  return (
    <>
      <TableActionHeader
        prependItem={
          <Input
            testId="PlacesView#Search"
            icon={<SearchIcon />}
            onChange={onSearchTermChangeInternal}
          />
        }
      >
        <Link to={generatePath(ROUTES.placeEdit.path)}>
          <Button testId="PlacesTabs#AddPlace">{t('places.addPlace')}</Button>
        </Link>
      </TableActionHeader>
      <Table
        loading={loading}
        itemsCount={itemsCount}
        tablePageSize={DEFAULT_PLACE_PAGE_SIZE}
        pageIndex={pageIndex}
        data={data}
        columns={columns}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        testId="PlacesView#Table"
        responsive
      />
    </>
  );
};

export { PlacesView };
