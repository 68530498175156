/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import {
  createAuthInterceptor,
  InterceptorId,
  unregisterInterceptor,
} from '@sim-admin-frontends/utils-shared';

import { TLoggedUser } from '../types/TUser';

const LOCAL_STORAGE_USER_KEY = 'user';

const defaultContext: TUserContext = {
  user: null,
  setUser: () => {},
  clearUser: () => {},
  logout: () => {},
  institutionUuid: undefined,
};

type TUserContext = {
  user: TLoggedUser | null;
  setUser: (user: TLoggedUser) => void;
  clearUser: () => void;
  logout: () => void;
  institutionUuid?: string;
};

const UserContext = createContext<TUserContext>(defaultContext);

const UserContextProvider: FC = ({ children }) => {
  const [user, setUserHook] = useState<TLoggedUser | null>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || 'null'),
  );

  const logout = useCallback(() => {
    Auth.signOut();
    unregisterInterceptor(InterceptorId.AUTH);
    clearUser();
  }, []);

  useEffect(() => {
    const token = user?.signInUserSession?.accessToken?.jwtToken;
    if (token) {
      createAuthInterceptor(logout);
    }
    return () => {
      unregisterInterceptor(InterceptorId.AUTH);
    };
  }, [logout, user]);

  const setUser = (newUser: TLoggedUser) => {
    setUserHook(newUser);
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(newUser));
  };

  const clearUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    setUserHook(null);
  };
  const value = { user, setUser, clearUser, logout };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useAuthInfo = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserContextProvider');
  }
  return context;
};

export { UserContextProvider, useAuthInfo };
