import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateUserActions } from '../../../hooks/actionButtons/useGenerateUserActions';

export interface PlacesViewTableMenuProps extends TBaseProps {
  username: string;
  refetch: () => void;
}

const UsersViewTableMenu: FC<PlacesViewTableMenuProps> = ({ username, refetch, testId }) => {
  const { listActions: items, renderModal } = useGenerateUserActions({ username, refetch });
  return (
    <TableIcons items={items} testId={testId}>
      {renderModal()}
    </TableIcons>
  );
};

export { UsersViewTableMenu };
