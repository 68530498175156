import { prepareFileObjects, useUploadImage } from '@sim-admin-frontends/utils-shared';
import {
  UploadType,
  useSaAllPreferenceCardsQuery,
  useSaUpdateUserPreferenceCardMutation,
} from '@sim-admin-frontends/data-access';
import {
  TSelectItem,
  TToastType,
  getErrorMessage,
  loadingToast,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';

import { TPreferenceCards } from '../../types/TPreferenceCards';
import PreferenceCards from './PreferenceCards';
import { SHARED_TOAST_ID } from '../notifications/view/NotificationsTabs';

const PreferenceCardsContainer = () => {
  const { t } = useTranslation();
  const { uploadFormImages } = useUploadImage();
  const cardIds = useSaAllPreferenceCardsQuery();
  const { mutateAsync: updateCards } = useSaUpdateUserPreferenceCardMutation();

  const cardIdsOptions = cardIds?.data?.getAllGenericCards.cards;

  const transformedCards: TSelectItem[] | undefined = cardIdsOptions?.map((card) => ({
    label: card.id,
    value: card.id,
  }));

  const onSubmit = async (values: TPreferenceCards) => {
    loadingToast(t('common.loading'), {
      toastId: SHARED_TOAST_ID,
    });
    const result = await uploadFormImages(UploadType.PreferenceCard, values.cardsImage, {});
    if (!result) {
      return;
    }
    const imageObjects = prepareFileObjects(result);
    const card = {
      title: values.title,
      type: values.type,
      content: values.content,
      placeUuid: 'GENERIC',
      imageObjects: imageObjects,
    };
    const id = values.card?.value;
    if (!id) {
      return;
    }
    try {
      await updateCards({ id, card: card });
      updateToast(SHARED_TOAST_ID, t('cards.success'), TToastType.SUCCESS);
    } catch (e) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(e), TToastType.ERROR);
      captureException(e);
    }
  };
  return <PreferenceCards onSubmit={onSubmit} cardIds={transformedCards} />;
};

export default PreferenceCardsContainer;
