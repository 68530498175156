import { useFormContext, useFieldArray, get } from 'react-hook-form';
import { SocialType } from '@sim-admin-frontends/data-access';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Button, FormInput, FormSelect, PlusSmallIcon } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { TInfluencerFormValues } from '../../../types/TInfluencer';
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';
import { SectionItemLeftWrapper, SectionItemRightWrapper, SectionItemWrapper } from './styles';

const SOCIALS_TYPE_OPTIONS = Object.values(SocialType).map((value) => ({ value, label: value }));

const CloseButton = styled(CloseIcon)`
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const InfluencerSocialsSection = () => {
  const { t } = useTranslation();
  const { control, register, formState } = useFormContext<TInfluencerFormValues>();
  const { errors } = formState;
  const name = 'socials';
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const onAddClick = () => {
    append({ url: '', type: { value: SocialType.Website, label: SocialType.Website } });
  };

  const onRemoveClick = (index: number) => () => {
    remove(index);
  };

  const renderFields = useCallback(
    () =>
      fields.map((field, index) => {
        const fieldNameType = `${name}.${index}.type`;
        const fieldNameUrl = `${name}.${index}.url`;

        const errorType = get(errors, fieldNameType);
        const errorUrl = get(errors, fieldNameUrl);

        return (
          <SectionItemWrapper key={field.id}>
            <SectionItemLeftWrapper>
              <FormSelect
                label={t('influencers.form.socialsType')}
                options={SOCIALS_TYPE_OPTIONS}
                control={control}
                error={errorType}
                name={`${name}.${index}.type`}
                defaultValue={field.type}
                testId={`InfluencerEdit#${fieldNameType}`}
              />
              <FormInput
                label={t('influencers.form.socialsUrl')}
                {...register(`${name}.${index}.url`)}
                error={errorUrl}
                testId={`InfluencerEdit#${fieldNameUrl}`}
              />
            </SectionItemLeftWrapper>
            <SectionItemRightWrapper>
              <CloseButton onClick={onRemoveClick(index)} />
            </SectionItemRightWrapper>
          </SectionItemWrapper>
        );
      }),
    [fields],
  );

  return (
    <div>
      {renderFields()}
      <Button
        variant="tertiary"
        size="smallest"
        onClick={onAddClick}
        prependIcon={<PlusSmallIcon />}
        testId="InfluencerEdit#addSocial"
      >
        {t('influencers.form.addSocial')}
      </Button>
    </div>
  );
};

export default InfluencerSocialsSection;
