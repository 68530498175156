import {
  useInfiniteGraphQLQuery,
  SaPlacesQueryVariables,
  SaPlacesQuery,
  useSaPlacesQuery,
} from '../..';

const DEFAULT_PLACE_PAGE_SIZE = 100;

export const useInfiniteSaPlacesQuery = (variables: SaPlacesQueryVariables, enabled?: boolean) => {
  const getNextPageParam = (lastPage: SaPlacesQuery, allPages: SaPlacesQuery[]) =>
    lastPage.places.places.length === (variables.pageSize ?? DEFAULT_PLACE_PAGE_SIZE)
      ? allPages.length * (variables.pageSize ?? DEFAULT_PLACE_PAGE_SIZE)
      : undefined;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    ...variables,
    pageSize: variables.pageSize ?? DEFAULT_PLACE_PAGE_SIZE,
    pageOffset: Number(pageParam ?? 0),
  });

  return useInfiniteGraphQLQuery(useSaPlacesQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled,
  });
};
