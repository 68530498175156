import { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { device, SimplicityIconSmall, Caption1 } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import ROUTES from '../../routing/routes';
import { HEADER_HEIGHT_PX } from '../../constants/Constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  padding: ${({ theme }) => theme.spaces.spacing8};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.menuBackground};
`;

const InnerWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spaces.spacing48};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spaces.spacing8};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  &:hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const Title = styled(Caption1)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 144px;
  height: ${({ theme }) => theme.spaces.spacing24};
  margin: 0 ${({ theme }) => theme.spaces.spacing8};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.large};
  display: none;
  ${device.sm} {
    display: flex;
    flex: 1;
  }
`;

const SimplicityLogo = styled(SimplicityIconSmall)`
  width: 20px;
  height: ${({ theme }) => theme.spaces.spacing24};
  padding: 3px 5px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
`;

const SidebarHeader: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const onLogoClick = () => {
    history.push(ROUTES.home.path);
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <SimplicityLogo onClick={onLogoClick} />
        <Title data-testid="HeaderSuperAdmin#title">{t('common.simplicity')}</Title>
      </InnerWrapper>
    </Wrapper>
  );
};

export { SidebarHeader };
