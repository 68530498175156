import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import InstitutionFeaturesContainer from '../../components/institutionFeatures/view/InstitutionFeatures.container';

type Props = {
  id: string;
};

const InstitutionFeaturesPage: FC<RouteComponentProps<Props>> = ({ match }) => {
  const { t } = useTranslation();
  const id = match.params.id;
  return (
    <PageWrapper>
      <PageHeader
        title={t('institutions.features.title')}
        caption={t('institutions.features.description')}
        testId="InstitutionFeatures#PageHeader"
      />
      <InstitutionFeaturesContainer institutionId={id} />
    </PageWrapper>
  );
};

export default InstitutionFeaturesPage;
