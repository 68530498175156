import { FC, useMemo } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { FormInput, FormSelect, TSelectItems } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { TicketmasterCountryCode } from '@sim-admin-frontends/data-access-admin-be';

import { TPlaceFeatureFlagValues } from '../../../types/TFeatureFlags';

type Props = {
  initialValues: TPlaceFeatureFlagValues;
  institutionOptions: TSelectItems;
  isFetchingInstitutions?: boolean;
  onInstitutionsSearch?: (text: string) => void;
  onInstitutionsScrollToBottom?: (e: Event) => void;
};

const PlaceFeatureEditTicketmasterSection: FC<Props> = ({
  initialValues,
  institutionOptions,
  isFetchingInstitutions,
  onInstitutionsSearch,
  onInstitutionsScrollToBottom,
}) => {
  const { control, register, formState } = useFormContext();
  const { errors } = formState;
  const { t } = useTranslation();
  const countryCodeOptions = useMemo(
    () =>
      Object.values(TicketmasterCountryCode).map((countryCode) => ({
        value: countryCode,
        label: countryCode,
      })),
    [TicketmasterCountryCode],
  );

  return (
    <>
      <FormInput
        testId="FeatureFlagEdit#ticketmasterPlaceName"
        label={t('places.features.form.ticketmasterPlaceName')}
        {...register('ticketmasterSettings.placeName')}
        defaultValue={initialValues.ticketmasterSettings?.placeName ?? ''}
        error={get(errors, 'ticketmasterSettings.placeName')}
      />
      <FormSelect
        control={control}
        clearable
        name="ticketmasterSettings.countryCode"
        label={t('places.features.form.ticketmasterCountryCode')}
        options={countryCodeOptions}
        defaultValue={initialValues.ticketmasterSettings?.countryCode}
        testId="FeatureFlagEdit#ticketmasterPlaceCountryCode"
        searchable
      />
      <FormInput
        testId="FeatureFlagEdit#ticketmasterStateCode"
        label={t('places.features.form.ticketmasterStateCode')}
        {...register('ticketmasterSettings.stateCode')}
        defaultValue={initialValues.ticketmasterSettings?.stateCode ?? ''}
        error={get(errors, 'ticketmasterSettings.stateCode')}
      />
      <FormSelect
        control={control}
        clearable
        searchable
        name="ticketmasterSettings.institution"
        label={t('places.features.form.institution')}
        options={institutionOptions}
        defaultValue={initialValues.ticketmasterSettings?.institution}
        testId="FeatureFlagEdit#ticketmasterInstitution"
        onMenuScrollToBottom={onInstitutionsScrollToBottom}
        onSearch={onInstitutionsSearch}
        isLoading={isFetchingInstitutions}
      />
    </>
  );
};

export default PlaceFeatureEditTicketmasterSection;
