import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  LandmarkInput,
  useCreateLandmarkMutation,
  useSaLandmarkQuery,
  useSaPlaceQuery,
  useUpdateLandmarkMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import { omitObjectProperty, useUploadImage } from '@sim-admin-frontends/utils-shared';
import { useQueryClient } from 'react-query';

import { TLandmarkEditFormValues } from '../../../types/TLandmark';
import { useGenerateLandmarkActions } from '../../../hooks/actionButtons/useGenerateLandmarkActions';
import ROUTES from '../../../routing/routes';
import LandmarkEdit from './LandmarkEdit';
import { prepareBackendData } from '../../../utils/landmarkUtils';

const SHARED_TOAST_ID = 'landmarksToast';

type LandmarksEditContainerProps = {
  placeId: string;
  id?: string;
};

const LandmarksEditContainer = ({ placeId, id }: LandmarksEditContainerProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { uploadFormImages } = useUploadImage();
  const pageTitle = id ? t('landmarks.editPageTitle') : t('landmarks.createPageTitle');
  const pageCaption = id ? t('landmarks.editPageCaption') : t('landmarks.createPageCaption');
  const queryClient = useQueryClient();
  const invalidate = async () => {
    await queryClient.invalidateQueries({ queryKey: ['SALandmarks'] });
  };

  const { data, isLoading, isError, refetch } = useSaLandmarkQuery(
    {
      id: id || '',
    },
    {
      enabled: !!id,
    },
  );

  const {
    data: placeData,
    isLoading: isLoadingPlace,
    isError: isErrorPlace,
  } = useSaPlaceQuery(
    {
      id: placeId || '',
    },
    {
      enabled: !!placeId,
    },
  );

  const { mutateAsync: addLandmark } = useCreateLandmarkMutation();
  const { mutateAsync: updateLandmark } = useUpdateLandmarkMutation();

  const landmark = data?.landmark;

  const place = placeData?.place;

  const onSubmit = async (values: TLandmarkEditFormValues) => {
    try {
      loadingToast(t('landmarks.toast.submit'), {
        toastId: SHARED_TOAST_ID,
      });
      let transformedData = await prepareBackendData(values, placeId, uploadFormImages);
      if (id) {
        transformedData = omitObjectProperty(transformedData, ['placeUuid']) as LandmarkInput;
        await updateLandmark({
          id,
          landmark: transformedData,
        });
      } else {
        await addLandmark({
          landmark: transformedData,
        });
      }

      updateToast(
        SHARED_TOAST_ID,
        id ? t('landmarks.toast.editSuccess') : t('landmarks.toast.createSuccess'),
        TToastType.SUCCESS,
      );
      await invalidate();
      history.push(generatePath(ROUTES.landmarks.path, { placeId }));
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGenerateLandmarkActions({
    refetch,
    placeId,
    id: id || '',
    isEditPage: true,
  });

  if (isLoading || isLoadingPlace)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError || isErrorPlace)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="LandmarksEdit#PageHeader" />
      <LandmarkEdit landmark={landmark} place={place} onSubmit={onSubmit} />
      {renderModal()}
    </>
  );
};

export default LandmarksEditContainer;
