import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import TaskEditGoogleContainer from '../../components/tasks/editGoogle/TaskEditGoogle.container';

type TaskEditPageProps = {
  code?: string;
};

const TaskEditGooglePage: FC<RouteComponentProps<TaskEditPageProps>> = ({ match }) => {
  const code = match.params.code;
  return (
    <PageWrapper>
      <TaskEditGoogleContainer code={code} />
    </PageWrapper>
  );
};

export default TaskEditGooglePage;
