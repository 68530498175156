import {
  SaAllSelectorsQuery,
  SaAllSelectorsQueryVariables,
  useInfiniteGraphQLQuery,
  useSaAllSelectorsQuery,
} from '../..';

export const useInfiniteSAAllSelectorsQuery = ({ affix }: SaAllSelectorsQueryVariables) => {
  const getNextPageParam = (lastPage: SaAllSelectorsQuery) =>
    lastPage?.allScrapeSelectors?.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    affix,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useSaAllSelectorsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
