import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import {
  TSASelectorListItem,
  TSASelectorListItems,
} from '@sim-admin-frontends/data-access-admin-be';

export interface SelectorsViewProps {
  data: TSASelectorListItems;
  columns: TExtendedColumn<TSASelectorListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
}

const SelectorsView: FC<SelectorsViewProps> = ({
  data,
  columns,
  onNextPress,
  hasNextPage,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
}) => {
  return (
    <Table
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      hasNextPage={hasNextPage}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      testId="SelectorsView#Table"
      responsive
    />
  );
};

export { SelectorsView };
