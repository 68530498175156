import { TCategories } from '@sim-admin-frontends/data-access';
import { TSelectItems } from '@sim-admin-frontends/ui-shared';

export const getCategoriesOptions = (categories: TCategories): TSelectItems => {
  return categories
    .filter((category) => category.name !== 'Events')
    .map((category) => ({
      label: category.name,
      value: category.id,
    }));
};
