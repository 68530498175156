import React, { FC } from 'react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormSelect,
  TSelectItems,
  FormWrapper,
  FormSectionHeader,
  TSearchItem,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TAdminUserDetail } from '@sim-admin-frontends/data-access';
import { generatePath, useHistory } from 'react-router-dom';

import { TAdminUserInfo, TUserEditFormValues } from '../../../types/TUser';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import ROUTES from '../../../routing/routes';

const schema = (t: TFunction) => {
  return Yup.object().shape({
    username: Yup.string().required(t('form.fieldRequired')),
    email: Yup.string().email(t('form.emailFormat')).required(t('form.fieldRequired')),
    institutions: Yup.mixed()
      .required(t('form.fieldRequired'))
      .test('required', t('form.fieldRequired'), (options) => !!options && options.length),
  });
};

type UserEditProps = {
  onSubmit: (values: TUserEditFormValues) => Promise<void>;
  institutions: TSelectItems;
  initialInstitutions: TSelectItems;
  details?: TAdminUserInfo | null;
  user?: TAdminUserDetail;
  places: TSelectItems;
  onAllInstitutionsMenuScrollToBottom?: (e: Event) => void;
  isFetchingInstitutions?: boolean;
  onPlacesMenuScrollToBottom?: (e: Event) => void;
  isLoadingPlaces?: boolean;
  onPlacesSearch?: (text: string) => void;
  onInstitutionsSearch?: (text: string) => void;
};

const UserEdit: FC<UserEditProps> = ({
  onSubmit,
  institutions,
  initialInstitutions,
  details,
  user,
  places,
  onAllInstitutionsMenuScrollToBottom,
  isFetchingInstitutions,
  onPlacesMenuScrollToBottom,
  isLoadingPlaces,
  onPlacesSearch,
  onInstitutionsSearch,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const initialValues: TUserEditFormValues = {
    username: user?.username || '',
    place: user?.place ? { label: user.place.name, value: user.place.id } : undefined,
    email: user?.email || '',
    institutions: initialInstitutions,
  };
  const submit = async (values: TUserEditFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TUserEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, control, register } = methods;
  const { errors, isSubmitting } = formState;

  const redirectToInstitutionEdit = (institution: TSearchItem) => {
    history.push(generatePath(ROUTES.institutionsEdit.path, { id: institution.data.value }));
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            searchable
            clearable
            isMulti
            label={t('users.form.institutions')}
            error={get(errors, 'institutions')}
            options={institutions}
            control={control}
            name={'institutions'}
            defaultValue={initialValues.institutions}
            testId="UserEdit#institutions"
            onMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
            onClickItemCallback={redirectToInstitutionEdit}
            onSearch={onInstitutionsSearch}
            isLoading={isFetchingInstitutions}
          />
          <FormSelect
            searchable
            clearable
            label={t('users.form.place')}
            error={get(errors, 'place')}
            options={places}
            control={control}
            name={'place'}
            defaultValue={initialValues.place}
            onMenuScrollToBottom={onPlacesMenuScrollToBottom}
            isLoading={isLoadingPlaces}
            onSearch={onPlacesSearch}
            testId="UserEdit#places"
          />
          <FormInput
            label={t('users.form.email')}
            {...register('email')}
            error={errors.email}
            testId="UserEdit#email"
          />
          <FormInput
            label={t('users.form.username')}
            {...register('username')}
            error={errors.username}
            disabled={!!user}
            testId="UserEdit#username"
          />
          {details && (
            <>
              <FormSectionHeader
                title={t('users.form.newUserCreated')}
                description={t('users.form.newUserDescription')}
              />
              <pre>{JSON.stringify(details, null, 4)}</pre>
            </>
          )}
          <ButtonsWrapper>
            <Button
              testId="UsersEdit#submit"
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {user ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default UserEdit;
