import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';

import { TInfluencerViewValues } from '../../../types/TInfluencer';

type Props = {
  data: TInfluencerViewValues[];
  columns: TExtendedColumn<TInfluencerViewValues>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
};

const InfluencersView = ({
  data,
  columns,
  onNextPress,
  onPreviousPress,
  pageIndex,
  itemsCount,
  loading,
  hasNextPage,
}: Props) => (
  <Table
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    hasNextPage={hasNextPage}
    onPreviousPress={onPreviousPress}
    testId="UsersView#Table"
  />
);

export default InfluencersView;
