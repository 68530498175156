import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import SelectorEditContainer from '../../components/selectors/edit/SelectorEdit.container';

type SelectorEditPageProps = {
  code?: string;
};

const SelectorEditPage: FC<RouteComponentProps<SelectorEditPageProps>> = ({ match }) => {
  const code = match.params.code;
  return (
    <PageWrapper>
      <SelectorEditContainer code={code} />
    </PageWrapper>
  );
};

export default SelectorEditPage;
