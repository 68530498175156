import { FC, useMemo } from 'react';
import { FieldPath, FormProvider, get, useForm } from 'react-hook-form';
import { getLangOptionByVal, getLangOptions, isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormWrapper,
  FormSelect,
  TSelectItem,
  FormSwitcherWithLabel,
  FormSectionHeader,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ScrapeContentType,
  ScrapeDataType,
  ScrapeType,
  TSATaskDetail,
} from '@sim-admin-frontends/data-access-admin-be';
import styled from 'styled-components';
import { TCategories } from '@sim-admin-frontends/data-access';

import { TTaskEditFormValues } from '../../../types/TTask';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { getCategoriesOptions } from '../../../utils/categoriesUtils';
import { getInitialCategoriesOptions } from '../../../utils/tasksUtils';

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const regexTask = /\w+_(\w+-)?\w+_(\w+-)?.+/;
const schema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .label(t('form.fieldRequired'))
      .matches(
        regexTask,
        t('tasks.form.codeMatch') +
          '{state}_{area}-{city or institution}_{identifier}-{meaningful suffix (news, events, uploader, etc.)}',
      )
      .trim(),
    enabled: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    enabledStaging: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    enabledTesting: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    url: Yup.string().required(t('form.fieldRequired')).trim(),
    JSRender: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    proxy: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    contentType: Yup.string()
      .oneOf(Object.values(ScrapeContentType))
      .required(t('form.fieldRequired')),
    dataType: Yup.string().oneOf(Object.values(ScrapeDataType)).required(t('form.fieldRequired')),
    scraperType: Yup.string().oneOf(Object.values(ScrapeType)).required(t('form.fieldRequired')),
    websiteTemplate: Yup.string().nullable().trim(),
    timeZone: Yup.string().required(t('form.fieldRequired')).trim(),
    checkCodes: Yup.array().of(Yup.string()),
    runSchedule: Yup.string().nullable(),
    streamingPlatform: Yup.string().nullable().trim(),
    subpageContentType: Yup.string()
      .oneOf(Object.values(ScrapeContentType))
      .required(t('form.fieldRequired')),
  });
};

type TaskEditProps = {
  onSubmit: (values: TTaskEditFormValues) => Promise<void>;
  task: TSATaskDetail;
  announcementCategories: TCategories;
  eventCategories: TCategories;
  announcementVisitorCategories: TCategories;
  eventVisitorCategories: TCategories;
  institutions: TSelectItems;
  initialInstitution?: TSelectItem;
  onAllInstitutionsMenuScrollToBottom?: (e: Event) => void;
  onInstitutionsSearch?: (text: string) => void;
  isFetchingInstitutions?: boolean;
  checkCodesOptions: TSelectItems;
  onAllTasksMenuScrollToBottom?: (e: Event) => void;
  onTasksSearch?: (text: string) => void;
  isFetchingTasks?: boolean;
};

const TaskEdit: FC<TaskEditProps> = ({
  onSubmit,
  task,
  announcementCategories,
  eventCategories,
  announcementVisitorCategories,
  eventVisitorCategories,
  institutions,
  initialInstitution,
  onAllInstitutionsMenuScrollToBottom,
  onInstitutionsSearch,
  isFetchingInstitutions,
  checkCodesOptions,
  onAllTasksMenuScrollToBottom,
  onTasksSearch,
  isFetchingTasks,
}) => {
  const { t } = useTranslation();
  const initCategoriesOptions = getCategoriesOptions(announcementCategories).concat(
    getCategoriesOptions(eventCategories)
      .concat(getCategoriesOptions(announcementVisitorCategories))
      .concat(getCategoriesOptions(eventVisitorCategories)),
  );
  const initialValues: TTaskEditFormValues = {
    code: task?.code || '',
    enabled: task?.enabled || false,
    enabledStaging: task?.enabledStaging || false,
    enabledTesting: task?.enabledTesting || false,
    url: task?.url || '',
    JSRender: task?.JSRender || false,
    proxy: task?.proxy || false,
    contentType: task?.contentType || ScrapeContentType.Html,
    timeZone: task?.timeZone || '',
    checkCodes: task?.checkCodes || [],
    lang: getLangOptionByVal(task?.lang),
    runSchedule: task?.runSchedule || null,
    dataType: task?.dataType || ScrapeDataType.News,
    scraperType: task?.scraperType || ScrapeType.Website,
    websiteTemplate: task?.websiteTemplate || null,
    subpageContentType: task?.subpageContentType || ScrapeContentType.Html,
    categories: getInitialCategoriesOptions(initCategoriesOptions, task?.categories),
    visitorScraper: task?.visitorScraper || false,
    institution: null,
    streamingPlatform: task?.streamingPlatform || null,
  };

  const methods = useForm<TTaskEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, formState, setValue, trigger, register, getValues, control } = methods;
  const { errors, isSubmitting } = formState;

  const onSwitcherChange = (field: FieldPath<TTaskEditFormValues>, value: boolean) => {
    setValue(field, value);
    trigger(field);
  };

  const dataTypeOptions = useMemo(
    () =>
      Object.values(ScrapeDataType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const initalDataType = useMemo(() => {
    const opt = dataTypeOptions.find((typeOption) => typeOption.value === initialValues?.dataType);
    if (opt) {
      return opt;
    }
    return {
      value: ScrapeDataType.News,
      label: ScrapeDataType.News,
    };
  }, []);

  const contentTypeOptions = useMemo(
    () =>
      Object.values(ScrapeContentType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const initalContentType = useMemo(() => {
    const opt = contentTypeOptions.find(
      (typeOption) => typeOption.value === initialValues?.contentType,
    );
    if (opt) {
      return opt;
    }
    return {
      value: ScrapeContentType.Html,
      label: ScrapeContentType.Html,
    };
  }, []);

  const scrapeTypeOptions = useMemo(
    () =>
      Object.values(ScrapeType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const initalScrapeType = useMemo(() => {
    const opt = scrapeTypeOptions.find(
      (typeOption) => typeOption.value === initialValues?.scraperType,
    );
    if (opt) {
      return opt;
    }
    return {
      value: ScrapeType.Website,
      label: ScrapeType.Website,
    };
  }, []);

  const categoriesOptions = useMemo(() => {
    const code = getValues('code');
    const visitorScraper = getValues('visitorScraper') ?? false;
    if (code?.endsWith('news') || code?.endsWith('twitter') || code?.endsWith('yelp')) {
      return visitorScraper
        ? getCategoriesOptions(announcementVisitorCategories)
        : getCategoriesOptions(announcementCategories);
    }
    if (code?.endsWith('events') || code?.endsWith('google')) {
      return visitorScraper
        ? getCategoriesOptions(eventVisitorCategories)
        : getCategoriesOptions(eventCategories);
    }
    return visitorScraper
      ? getCategoriesOptions(announcementVisitorCategories).concat(
          getCategoriesOptions(eventVisitorCategories),
        )
      : getCategoriesOptions(announcementCategories).concat(getCategoriesOptions(eventCategories));
  }, [getValues('code'), getValues('visitorScraper')]);

  const onDataTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as ScrapeDataType;
    setValue('dataType', value);
    trigger('dataType');
  };

  const onContentTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as ScrapeContentType;
    setValue('contentType', value);
    trigger('contentType');
  };

  const onSubpageContentTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as ScrapeContentType;
    setValue('subpageContentType', value);
    trigger('subpageContentType');
  };

  const onScrapeTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as ScrapeType;
    setValue('scraperType', value);
    trigger('scraperType');
  };

  const onCategoryChange = (arg: readonly TSelectItem[] | null) => {
    setValue('categories', arg ? [...arg] : undefined);
    trigger('subpageContentType');
  };

  const onInstitutionChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('institution', null);
      trigger('institution');
      return;
    }
    setValue('institution', { name: arg?.[0].label, id: arg?.[0].value });
    trigger('institution');
  };

  const onCheckCodesChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('checkCodes', []);
      trigger('checkCodes');
      return;
    }
    setValue(
      'checkCodes',
      arg.map((item) => item.value),
    );
    trigger('checkCodes');
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput label={t('tasks.form.code')} {...register('code')} error={errors.code} />
          <FlexWrapper>
            <FormSwitcherWithLabel
              isVertical
              testId="TaskEditTwitter#enabled"
              label={t('tasks.form.enabled')}
              initialValue={!!initialValues.enabled}
              onChange={(value: boolean) => onSwitcherChange('enabled', value)}
            />
            <FormSwitcherWithLabel
              isVertical
              testId="TaskEditTwitter#enabledStaging"
              label={t('tasks.form.enabledStaging')}
              initialValue={!!initialValues.enabledStaging}
              onChange={(value: boolean) => onSwitcherChange('enabledStaging', value)}
            />
            <FormSwitcherWithLabel
              isVertical
              testId="TaskEditTwitter#enabledTesting"
              label={t('tasks.form.enabledTesting')}
              initialValue={!!initialValues.enabledTesting}
              onChange={(value: boolean) => onSwitcherChange('enabledTesting', value)}
            />
          </FlexWrapper>
          <FormSelect
            searchable
            clearable
            label={t('tasks.form.institution')}
            error={get(errors, 'institution')}
            options={institutions}
            control={control}
            name={'institution'}
            defaultValue={initialInstitution}
            testId="TasksEdit#institutions"
            onMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
            onSearch={onInstitutionsSearch}
            isLoading={isFetchingInstitutions}
            onChange={onInstitutionChange}
          />
          <FormInput
            label={t('tasks.form.websiteTemplate')}
            {...register('websiteTemplate')}
            error={errors.websiteTemplate}
          />
          <FormSelect
            name="dataType"
            label={t('tasks.form.dataType')}
            options={dataTypeOptions}
            defaultValue={initalDataType}
            error={errors.dataType}
            onChange={onDataTypeChange}
          />
          <FormSelect
            name="scraperType"
            label={t('tasks.form.scrapeType')}
            options={scrapeTypeOptions}
            defaultValue={initalScrapeType}
            error={errors.scraperType}
            onChange={onScrapeTypeChange}
          />
          <FormInput label={t('tasks.form.url')} {...register('url')} error={errors.url} />
          <FlexWrapper>
            <FormSwitcherWithLabel
              isVertical
              testId="TaskEditTwitter#JSRender"
              label={t('tasks.form.JSRender')}
              initialValue={!!initialValues.JSRender}
              onChange={(value: boolean) => onSwitcherChange('JSRender', value)}
            />
            <FormSwitcherWithLabel
              isVertical
              testId="TaskEditTwitter#proxy"
              label={t('tasks.form.proxy')}
              initialValue={!!initialValues.proxy}
              onChange={(value: boolean) => onSwitcherChange('proxy', value)}
            />
          </FlexWrapper>
          <FormSelect
            name="contentType"
            label={t('tasks.form.contentType')}
            options={contentTypeOptions}
            defaultValue={initalContentType}
            error={errors.contentType}
            onChange={onContentTypeChange}
          />
          <FormSelect
            name="subpageContentType"
            label={t('tasks.form.subpageContentType')}
            options={contentTypeOptions}
            defaultValue={initalContentType}
            error={errors.subpageContentType}
            onChange={onSubpageContentTypeChange}
          />
          <FormInput
            label={t('tasks.form.timeZone')}
            {...register('timeZone')}
            error={errors.timeZone}
          />
          <FormSelect
            label={t('tasks.form.lang')}
            name="lang"
            defaultValue={initialValues.lang}
            options={getLangOptions()}
          />
          <FormSelect
            searchable
            isMulti
            clearable
            label={t('tasks.form.checkCodes')}
            error={get(errors, 'checkCodes')}
            options={checkCodesOptions}
            control={control}
            name={'checkCodes'}
            defaultValue={initialValues.checkCodes.map(
              (code: string) => ({ label: code, value: code } as TSelectItem),
            )}
            testId="TasksEdit#CheckCodes"
            onMenuScrollToBottom={onAllTasksMenuScrollToBottom}
            onSearch={onTasksSearch}
            isLoading={isFetchingTasks}
            onChange={onCheckCodesChange}
          />
          <FormInput
            label={t('tasks.form.cron')}
            {...register('runSchedule')}
            error={errors.runSchedule}
          />
          <FormInput
            label={t('tasks.form.streamingPlatform')}
            {...register('streamingPlatform')}
            error={errors.streamingPlatform}
          />
          <FormSectionHeader
            title={t('tasks.form.categories')}
            description={t('tasks.form.categoryDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="TaskEditTwitter#visitorScraper"
            label={t('tasks.form.visitorScraper')}
            initialValue={!!initialValues.visitorScraper}
            onChange={(value: boolean) => onSwitcherChange('visitorScraper', value)}
          />
          <FormSelect
            name={'categories'}
            error={get(errors, 'categories')}
            options={categoriesOptions}
            defaultValue={initialValues.categories}
            testId="TaskEdit#categories"
            onChange={onCategoryChange}
            clearable
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {task ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default TaskEdit;
