import { useSaHideInstitutionMutation } from '@sim-admin-frontends/data-access';
import {
  AddUsersIcon,
  ConfirmModal,
  EditIcon,
  FeatureFlagIcon,
  loadingToast,
  ShowUsersIcon,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TInstitutionModalActions } from '../../types/TInstitution';

type Props = {
  institutionId?: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateInstitutionActions = ({ refetch, institutionId, isEditPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(TInstitutionModalActions.DELETE);

  const { mutate: hideInstitutionMutation } = useSaHideInstitutionMutation({
    onMutate: () => {
      loadingToast(t('institutions.deleteToast.loading'), {
        toastId: TInstitutionModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(
        TInstitutionModalActions.DELETE,
        t('institutions.deleteToast.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        TInstitutionModalActions.DELETE,
        t('institutions.deleteToast.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const hideInstitution = async () => {
    await hideInstitutionMutation({
      id: institutionId || '',
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === TInstitutionModalActions.DELETE) {
      hideInstitution();
      setIsModalVisible(false);
      history.push(generatePath(ROUTES.institutions.path));
      refetch();
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setModalDescription(t('institutions.deleteModal.description'));
    setModalClickAction(TInstitutionModalActions.DELETE);
    setIsModalVisible(true);
  };

  const generateItems = (): TableIconProps[] => {
    if (!institutionId) {
      return [];
    }

    const editPath = generatePath(ROUTES.institutionsEdit.path, { id: institutionId });
    const featuresPath = generatePath(ROUTES.institutionFeatures.path, { id: institutionId });
    const addUserPath = generatePath(ROUTES.institutionUserEdit.path, { institutionId });
    const showUsersPath = generatePath(ROUTES.institutionsUsers.path, { institutionId });

    return [
      {
        label: t('table.dropdown.showUsers'),
        path: showUsersPath,
        testId: 'InstitutionsTableMenu#showUsers',
        icon: <ShowUsersIcon />,
      },
      {
        label: t('table.dropdown.addInstitutionUser'),
        path: addUserPath,
        testId: 'InstitutionsTableMenu#addInstitutionUser',
        icon: <AddUsersIcon />,
      },
      {
        label: t('institutions.features.title'),
        path: featuresPath,
        testId: 'InstitutionsTableMenu#features',
        icon: <FeatureFlagIcon />,
      },
      {
        label: t('table.dropdown.edit'),
        path: editPath,
        hidden: isEditPage,
        testId: 'InstitutionsTableMenu#edit',
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'InstitutionsTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('institutions.deleteModal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('institutions.deleteModal.cancel')}
      confirmButtonText={t('institutions.deleteModal.confirm')}
      testId="Institutions#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
