import {
  EditIcon,
  TableIconProps,
  TableIcons,
  TBaseProps,
  TrashCanIcon,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ROUTES from '../../../routing/routes';

interface Props extends TBaseProps {
  institutionId: string;
  onDeleteClick: (i: number) => void;
  featureIndex: number;
}

const InstitutionFeaturesTableMenu: FC<Props> = ({
  institutionId,
  onDeleteClick,
  featureIndex,
  testId,
}) => {
  const { t } = useTranslation();

  const editPath = generatePath(ROUTES.institutionFeaturesEdit.path, {
    institutionId,
    featureIndex,
  });

  const handleDeleteClick = () => {
    onDeleteClick(featureIndex);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'InstitutionFeaturesMenu#edit',
      icon: <EditIcon />,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'InstitutionFeaturesMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  const items = generateItems();

  return <TableIcons testId={testId} items={items} />;
};

export default InstitutionFeaturesTableMenu;
