import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PaymentEditContainer from '../../components/payments/edit/PaymentEdit.container';

type PaymentsEditPageProps = {
  id: string;
};

const PaymentsEditPage: FC<RouteComponentProps<PaymentsEditPageProps>> = ({ match }) => {
  const { id } = match.params;

  return <PaymentEditContainer id={id} />;
};

export default PaymentsEditPage;
