import {
  useInfiniteGraphQLQuery,
  useSaReminderCategoriesByQuery,
  SaReminderCategoriesByQuery,
} from '../..';

export const useInfiniteSAReminderCategoriesByQuery = (
  placeUuid: string,
  pageSize?: number,
  disabled?: boolean,
) => {
  const getNextPageParam = (lastPage: SaReminderCategoriesByQuery) =>
    lastPage.reminderCategoriesBy.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter: { placeUuid },
    nextToken: pageParam,
    pageSize,
  });

  return useInfiniteGraphQLQuery(useSaReminderCategoriesByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: !disabled,
  });
};
