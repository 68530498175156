import { useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import {
  Button,
  FormInput,
  FormLocationInput,
  FormSelect,
  FormSwitcherWithLabel,
  FormTextarea,
  FormUpload,
  FormWrapper,
  LocationInputValue,
  SUPPORTED_WEB_IMAGE_FORMATS,
  SwitchableFormSection,
  TSelectItem,
  TSelectItems,
  TSelectRef,
} from '@sim-admin-frontends/ui-shared';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { Currency, TInfluencerSpotMarketItem } from '@sim-admin-frontends/data-access';

import { TInfluencerSpotDetail, TInfluencerSpotFormValues } from '../../../types/TInfluencer';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { getInitialValuesFromMarketItem, parse24hTime } from '../../../utils/influencerUtils';
import { AM, PM } from '../../../constants/Constants';
import InfluencerSpotStartEndSection from './InfluencerSpotStartEndSection';

const CURRENCY_OPTIONS = Object.values(Currency).map((value) => ({
  label: value,
  value: value,
}));

const schema = (t: TFunction) => {
  return Yup.object().shape({
    place: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .test('required', t('form.fieldRequired'), (value?: TSelectItem) => !!value?.value),
    title: Yup.string().required(t('form.fieldRequired')),
  });
};

type Props = {
  influencerSpot?: TInfluencerSpotDetail;
  onSubmit: (formValues: TInfluencerSpotFormValues) => Promise<void>;
  initialPlace?: TSelectItem;
  places: TSelectItems;
  onPlacesSearch?: (text: string) => void;
  isLoadingPlaces?: boolean;
  onPlacesMenuScrollToBottom?: (e: Event) => void;
  categories: TSelectItems;
  setPlaceUuid: (placeUuid: string) => void;
  marketItems: TInfluencerSpotMarketItem[];
  isLoadingMarketItems?: boolean;
  onMarketItemsSearch?: (text: string) => void;
  onMarketItemsMenuScrollToBottom?: (e: Event) => void;
};

const InfluencerSpotEdit = ({
  influencerSpot,
  onSubmit,
  initialPlace,
  places,
  onPlacesSearch,
  isLoadingPlaces,
  onPlacesMenuScrollToBottom,
  categories,
  setPlaceUuid,
  marketItems,
  isLoadingMarketItems,
  onMarketItemsSearch,
  onMarketItemsMenuScrollToBottom,
}: Props) => {
  const { t } = useTranslation();
  const currencyRef = useRef<TSelectRef>();
  const marketItemRef = useRef<TSelectRef>();

  const marketItemOptions = useMemo(() => {
    if (!marketItems) {
      return [];
    }

    return marketItems
      .map((marketItem) => ({ value: marketItem.id, label: marketItem.title }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [marketItems]);

  const initialValues: TInfluencerSpotFormValues = {
    place: initialPlace,
    title: influencerSpot?.title || '',
    content: influencerSpot?.content || '',
    image: influencerSpot?.image ? [influencerSpot.image] : null,
    location: influencerSpot?.location,

    startTime: influencerSpot?.startTime
      ? parse24hTime(influencerSpot?.startTime, 'hh:mm')
      : undefined,
    endTime: influencerSpot?.endTime ? parse24hTime(influencerSpot?.endTime, 'hh:mm') : undefined,
    startTimePeriod: influencerSpot?.startTime ? parse24hTime(influencerSpot?.startTime, 'a') : AM,
    endTimePeriod: influencerSpot?.endTime ? parse24hTime(influencerSpot?.endTime, 'a') : PM,

    duration: influencerSpot?.duration,
    marketItem: influencerSpot?.marketItem
      ? { value: influencerSpot.marketItem.id, label: influencerSpot.marketItem.title }
      : undefined,
    price: influencerSpot?.price?.amount,
    currency: influencerSpot?.price
      ? { value: influencerSpot.price.currency, label: influencerSpot.price.currency }
      : undefined,
    category: influencerSpot?.category
      ? { value: influencerSpot.category.id, label: influencerSpot.category.name }
      : undefined,
    favourite: !!influencerSpot?.favourite,
  };

  const defaultLocationValue = initialValues.location?.address
    ? {
        label: initialValues.location?.address,
        value: initialValues.location?.address,
      }
    : undefined;

  const startEndInitiallyOpen = !!initialValues.startTime || !!initialValues.endTime;

  const submit = async (values: TInfluencerSpotFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TInfluencerSpotFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, control, register, setValue, trigger } = methods;
  const { errors, isSubmitting } = formState;

  const setFormValues = (values: TInfluencerSpotFormValues) => {
    setValue('title', values.title);
    setValue('content', values.content);
    setValue('image', values.image);
    setValue('location', values.location);
    setValue('duration', values.duration);
    setValue('price', values.price);
    setValue('currency', values.currency);
    setValue('favourite', values.favourite);
  };

  const onLocationValueChange = (value: LocationInputValue) => {
    setValue(
      'location',
      value.gps
        ? {
            geo: value.gps,
            address: value.name,
          }
        : undefined,
    );
    trigger('location');
  };

  const setTimeValueAndTriggerOthers =
    (name: 'startTime' | 'endTime' | 'startTimePeriod' | 'endTimePeriod') => (value?: string) => {
      setValue(name, value);
      trigger(['startTime', 'endTime', 'startTimePeriod', 'endTimePeriod']);
    };

  const onStartEndVisibilityChange = (open: boolean) => {
    if (open) {
      return;
    }
    setTimeValueAndTriggerOthers('startTime')(undefined);
    setTimeValueAndTriggerOthers('endTime')(undefined);
    setTimeValueAndTriggerOthers('startTimePeriod')(undefined);
    setTimeValueAndTriggerOthers('endTimePeriod')(undefined);
  };

  const onFavouriteChange = (value: boolean) => {
    setValue('favourite', value);
  };

  const [previewPlace, previewMarketItem, previewLocation, previewFavourite] = useWatch({
    name: ['place', 'marketItem', 'location', 'favourite'],
    control,
  });

  const onPlaceChange = (selectedPlace: readonly TSelectItem[] | null) => {
    const placeId = selectedPlace?.[0].value || '';
    setPlaceUuid(placeId);
  };

  const [marketItemChanged, setMarketItemChanged] = useState(false);

  useEffect(() => {
    (async () => {
      const marketItem = marketItems.find((i) => i.id === previewMarketItem?.value);

      if (!marketItem || previewMarketItem?.value === initialValues.marketItem?.value) {
        if (marketItemChanged) {
          marketItemRef?.current?.setValue(undefined);
          setFormValues(initialValues);
          setMarketItemChanged(false);
        }
        return;
      }

      const newInitialValues = await getInitialValuesFromMarketItem(marketItem);
      setFormValues(newInitialValues);
      currencyRef?.current?.setValue(newInitialValues.currency);
      setMarketItemChanged(true);
    })();
  }, [previewMarketItem, marketItems]);

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            searchable
            clearable
            label={t('users.form.place')}
            error={get(errors, 'place')}
            options={places}
            control={control}
            name={'place'}
            defaultValue={initialValues.place}
            onMenuScrollToBottom={onPlacesMenuScrollToBottom}
            isLoading={isLoadingPlaces}
            onSearch={onPlacesSearch}
            onChange={onPlaceChange}
            testId="InfluencerSpotEdit#place"
          />

          <FormSelect
            searchable
            clearable
            label={t('influencerSpots.form.marketItem')}
            error={get(errors, 'marketItem')}
            options={marketItemOptions}
            control={control}
            name={'marketItem'}
            defaultValue={initialValues.marketItem}
            onMenuScrollToBottom={onMarketItemsMenuScrollToBottom}
            isLoading={isLoadingMarketItems}
            onSearch={onMarketItemsSearch}
            testId="InfluencerSpotEdit#marketItem"
            ref={marketItemRef}
            disabled={!previewPlace}
          />

          <FormInput
            label={t('notifications.title')}
            {...register('title')}
            error={errors.title}
            testId="InfluencerSpotEdit#title"
          />
          <FormTextarea
            label={t('reminderCategories.edit.content')}
            {...register('content')}
            error={errors.content}
            testId="InfluencerSpotEdit#content"
          />
          <FormUpload
            control={control}
            name="image"
            t={t}
            dropzoneLabel={t('influencerSpos.edit.dropImage')}
            testId="InfluencerSpotEdit#image"
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />
          <FormLocationInput
            control={control}
            name="location"
            error={get(errors, 'location.address')}
            label={t('influencerSpos.edit.location')}
            placeholder={
              previewLocation ? previewLocation.address : t('influencerSpos.edit.location')
            }
            testId="InfluencerSpotEdit#location"
            onValueChange={onLocationValueChange}
            defaultValue={defaultLocationValue}
          />
          <SwitchableFormSection
            title={t('influencerSpos.form.startEndSection')}
            description={t('updates.form.scheduleSectionDescription')}
            onVisibilityChanged={onStartEndVisibilityChange}
            initiallyOpened={startEndInitiallyOpen}
          >
            <InfluencerSpotStartEndSection
              initialValues={initialValues}
              setTimeValueAndTriggerOthers={setTimeValueAndTriggerOthers}
            />
          </SwitchableFormSection>
          <FormInput
            type="number"
            label={t('influencerSpos.edit.duration')}
            {...register('duration')}
            error={errors.duration}
            testId="InfluencerSpotEdit#duration"
          />

          <FormInput
            type="number"
            label={t('influencerSpos.edit.price')}
            {...register('price')}
            error={errors.price}
            testId="InfluencerSpotEdit#price"
          />
          <FormSelect
            searchable
            control={control}
            name="currency"
            label={t('influencerSpos.edit.currency')}
            error={get(errors, 'currency')}
            options={CURRENCY_OPTIONS || []}
            defaultValue={initialValues.currency}
            ref={currencyRef}
            testId="InfluencerSpotEdit#currency"
          />
          <FormSelect
            control={control}
            name="category"
            label={t('influencerSpos.edit.category')}
            error={get(errors, 'category')}
            options={categories}
            defaultValue={initialValues.category}
            testId="InfluencerSpotEdit#category"
          />
          <FormSwitcherWithLabel
            isVertical
            testId="InfluencerSpotEdit#favourite"
            label={t('influencerSpos.edit.favourite')}
            initialValue={previewFavourite}
            onChange={onFavouriteChange}
            shouldSyncInitalValue
          />
          <ButtonsWrapper>
            <Button
              testId="InfluencerSpotEdit#submit"
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {influencerSpot ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default InfluencerSpotEdit;
