import { TReminderCategories, TReminderCategory } from '@sim-admin-frontends/data-access';
import { Button, Table, TableActionHeader, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import ROUTES from '../../../routing/routes';

type ReminderCategoriesViewProps = {
  placeId: string;
  data: TReminderCategories;
  columns: TExtendedColumn<TReminderCategory>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
};

const ReminderCategoriesView: FC<ReminderCategoriesViewProps> = ({
  placeId,
  data,
  columns,
  onNextPress,
  onPreviousPress,
  pageIndex,
  itemsCount,
  loading,
  hasNextPage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TableActionHeader>
        <Link to={generatePath(ROUTES.reminderCategoriesEdit.path, { placeId })}>
          <Button testId="ReminderCategoriesView#AddCategory">{t('reminderCategories.add')}</Button>
        </Link>
      </TableActionHeader>
      <Table
        loading={loading}
        itemsCount={itemsCount}
        pageIndex={pageIndex}
        data={data}
        columns={columns}
        hasPagination={true}
        onNextPress={onNextPress}
        hasNextPage={hasNextPage}
        onPreviousPress={onPreviousPress}
        testId="ReminderCategoriesView#Table"
        responsive
      />
    </>
  );
};

export default ReminderCategoriesView;
