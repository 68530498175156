import {
  BusinessActiveUsersCounts,
  BusinessMetricsNotificationCounts,
  Channel,
  PushNotificationPriority,
  PushNotificationType,
} from '@sim-admin-frontends/data-access';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TMappedNotificationItem = {
  name: string;
  placeUuid: string;
  counts: {
    notifications: BusinessMetricsNotificationCounts[];
    users: BusinessActiveUsersCounts | undefined;
  };
};

export type TMappedNotifications = TMappedNotificationItem[];

export type TNotificationsEditValues = {
  city: TSelectItem[];
  institution: TSelectItem;
  title: string;
  body: string;
  channel: { label: string; value: Channel };
  deeplink?: string;
  postId?: string;
};

export type TVisitorNotificationsEditValues = {
  city: TSelectItem[];
  title: string;
  body: string;
};

export type TItineraryNotificationsEditValues = {
  city: TSelectItem[];
  title: string;
  body?: string;
  deeplink?: string;
};

export type TSelectType = { label: string; value: PushNotificationType };
export type TSelectPriority = { label: string; value: PushNotificationPriority };
export type TChatName = 'Charlie';
export type TChatBot = { label: TChatName; value: TChatName };
export type TChatBotNotificationsEditValues = {
  title: string;
  subtitle?: string;
  body: string;
  email: string;
  type: TSelectType;
  priority?: TSelectPriority;
  chatBot?: TChatBot;
};

export enum TNotificationCategory {
  Announcement = 'ANNOUNCEMENT',
  Event = 'EVENT',
  Coupon = 'COUPON',
  DeepLink = 'DEEP_LINK',
}

export enum TNotificationsTabTypes {
  CHAT_BOT = 'chatBot',
  DEFAULT = 'default',
  VISITOR = 'visitor',
  ITINERARY = 'itinerary',
}
