import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import NotificationsTabs from '../../components/notifications/view/NotificationsTabs';

const NotificationsEditPage: FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageHeader
        title={t('notifications.editPageTitle')}
        caption={t('notifications.editPageDescription')}
        testId="Notifications#PageHeader"
      />
      <NotificationsTabs />
    </PageWrapper>
  );
};

export default NotificationsEditPage;
