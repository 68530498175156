import { get, useFieldArray, useFormContext } from 'react-hook-form';
import { PropertyType } from '@sim-admin-frontends/data-access';
import { Button, FormInput, FormSelect, PlusSmallIcon } from '@sim-admin-frontends/ui-shared';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TInfluencerFormValues } from '../../../types/TInfluencer';
import { SectionItemLeftWrapper, SectionItemRightWrapper, SectionItemWrapper } from './styles';
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

const PROPERTIES_TYPE_OPTIONS = Object.values(PropertyType).map((value) => ({
  value,
  label: value,
}));

const CloseButton = styled(CloseIcon)`
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const InfluencerPropertiesSection = () => {
  const { t } = useTranslation();
  const { control, register, formState, trigger } = useFormContext<TInfluencerFormValues>();
  const { errors } = formState;
  const name = 'properties';
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const onAddClick = () => {
    append({ value: '', name: { value: PropertyType.Art, label: PropertyType.Art } });
  };

  const onRemoveClick = (index: number) => () => {
    remove(index);
    trigger('properties');
  };

  const onPropertiesNameChange = () => {
    trigger('properties');
  };

  const renderFields = useCallback(
    () =>
      fields.map((field, index) => {
        const fieldNameName = `${name}.${index}.name`;
        const fieldNameValue = `${name}.${index}.value`;

        const errorName = get(errors, fieldNameName);
        const errorValue = get(errors, fieldNameValue);

        return (
          <SectionItemWrapper key={field.id}>
            <SectionItemLeftWrapper>
              <FormSelect
                label={t('influencers.form.propertiesName')}
                options={PROPERTIES_TYPE_OPTIONS}
                control={control}
                error={errorName}
                name={`${name}.${index}.name`}
                defaultValue={field.name}
                onChange={onPropertiesNameChange}
                testId={`InfluencerEdit#${fieldNameName}`}
              />
              <FormInput
                label={t('influencers.form.propertiesValue')}
                {...register(`${name}.${index}.value`)}
                error={errorValue}
                testId={`InfluencerEdit#${fieldNameValue}`}
              />
            </SectionItemLeftWrapper>
            <SectionItemRightWrapper>
              <CloseButton onClick={onRemoveClick(index)} />
            </SectionItemRightWrapper>
          </SectionItemWrapper>
        );
      }),
    [fields, errors],
  );

  return (
    <div>
      {renderFields()}
      <Button
        variant="tertiary"
        size="smallest"
        onClick={onAddClick}
        prependIcon={<PlusSmallIcon />}
        testId="InfluencerEdit#addProperty"
      >
        {t('influencers.form.addProperty')}
      </Button>
    </div>
  );
};

export default InfluencerPropertiesSection;
