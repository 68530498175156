import { FC } from 'react';
import {
  PaymentStatus,
  PaymentType,
  ProcessingStatus,
  TPaymentTransaction,
} from '@sim-admin-frontends/data-access';
import styled from 'styled-components';
import {
  Button,
  CollapsiblePanel,
  FormInput,
  PageHeader,
  FormWrapper as OriginalFormWrapper,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { formatDateObject, formatTableDateTime } from '@sim-admin-frontends/utils-shared';

import PaymentEditDonationSection from './PaymentEditDonationSection';
import PaymentEditTicketSection from './PaymentEditTicketSection';
import {
  SIDEPANEL_COLLAPSED_WIDTH_PX,
  SIDEPANEL_DEFAULT_SIDE,
  SIDEPANEL_EXPANDED_WIDTH_PX,
} from '../../../constants/Constants';
import PaymentEditTuiSection from './PaymentEditTuiSection';
import PaymentEditRefundSection from './PaymentEditRefundSection';

const FormWrapper = styled(OriginalFormWrapper)`
  align-items: center;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacing48} ${({ theme }) => theme.spaces.spacing32};
  overflow: auto;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 540px;
`;

const ButtonsWrapper = styled(ItemWrapper)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  width: 100%;
`;

const PreviewContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: auto;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.spacing24};
`;

const ImagePreview: FC<{ image: string }> = ({ image }) => (
  <PreviewContainer>
    <StyledImage src={image} data-testid="PaymentEdit#previewImage" />
  </PreviewContainer>
);

type Props = {
  paymentTransaction: TPaymentTransaction;
  changeProcessingStatus: (status: ProcessingStatus) => () => void;
};

const PaymentEdit = ({ paymentTransaction, changeProcessingStatus }: Props) => {
  const { t } = useTranslation();
  const createdAt = formatTableDateTime(paymentTransaction.createdAt);
  const paidAt = paymentTransaction.paidAt
    ? formatTableDateTime(paymentTransaction.paidAt)
    : t('payments.unpaid');
  const issueDate = formatDateObject(new Date(paymentTransaction.issueDate));

  const isTicket = paymentTransaction.paymentType === PaymentType.Ticket;
  const isDonation = paymentTransaction.paymentType === PaymentType.Donation;
  const isTui = paymentTransaction.paymentType === PaymentType.Tui;
  const isRefunded = paymentTransaction.paymentStatus === PaymentStatus.RequestedRefund;

  return (
    <Wrapper>
      <PageWrapper>
        <FormWrapper>
          <HeaderWrapper>
            <PageHeader
              title={t('payments.editPageTitle')}
              caption={t('payments.editPageDescription')}
              testId="PaymentEdit#PageHeader"
            />
          </HeaderWrapper>
          <FormInput
            label={t('payments.installationUuid')}
            value={paymentTransaction.installationUuid}
            readOnly
            disabled
            testId="PaymentEdit#installationUuid"
          />
          <FormInput
            label={t('payments.createdAt')}
            value={createdAt}
            readOnly
            disabled
            testId="PaymentEdit#createdAt"
          />
          <FormInput
            label={t('payments.paidAt')}
            value={paidAt}
            readOnly
            disabled
            testId="PaymentEdit#paidAt"
          />
          <FormInput
            label={t('payments.amount')}
            value={`${paymentTransaction.amount} ${paymentTransaction.currency}`}
            readOnly
            disabled
            testId="PaymentEdit#amount"
          />
          <FormInput
            label={t('payments.issueDate')}
            value={issueDate}
            readOnly
            disabled
            testId="PaymentEdit#issueDate"
          />
          <FormInput
            label={t('payments.paymentType')}
            value={paymentTransaction.paymentType}
            readOnly
            disabled
            testId="PaymentEdit#paymentType"
          />
          <FormInput
            label={t('payments.receiver')}
            value={paymentTransaction.receiver}
            readOnly
            disabled
            testId="PaymentEdit#receiver"
          />
          <FormInput
            label={t('payments.receiver')}
            value={paymentTransaction.receiver}
            readOnly
            disabled
            testId="PaymentEdit#receiver"
          />
          <FormInput
            label={t('payments.simplicityPlus')}
            value={paymentTransaction.simplicityPlus.toString().toUpperCase()}
            readOnly
            disabled
            testId="PaymentEdit#simplicityPlus"
          />
          {isTui && <PaymentEditTuiSection paymentTransaction={paymentTransaction} />}
          {isDonation && <PaymentEditDonationSection paymentTransaction={paymentTransaction} />}
          {isTicket && <PaymentEditTicketSection paymentTransaction={paymentTransaction} />}
          <FormInput
            label={t('payments.paymentStatus')}
            value={paymentTransaction.paymentStatus}
            readOnly
            disabled
            testId="PaymentEdit#paymentStatus"
          />
          <FormInput
            label={t('payments.processingStatus')}
            value={paymentTransaction.processingStatus}
            readOnly
            disabled
            testId="PaymentEdit#processingStatus"
          />
          {isRefunded && <PaymentEditRefundSection paymentTransaction={paymentTransaction} />}
          {paymentTransaction.processingStatus === ProcessingStatus.New && (
            <ButtonsWrapper>
              <Button onClick={changeProcessingStatus(ProcessingStatus.Done)} testId="Payment#Done">
                {t('payments.markAsDone')}
              </Button>
              <Button
                onClick={changeProcessingStatus(ProcessingStatus.Failure)}
                testId="Payment#Failure"
              >
                {t('payments.markAsFailure')}
              </Button>
            </ButtonsWrapper>
          )}
        </FormWrapper>
      </PageWrapper>
      {!!paymentTransaction.image && (
        <CollapsiblePanel
          title={t('payments.previewImage')}
          side={SIDEPANEL_DEFAULT_SIDE}
          collapsedWidth={SIDEPANEL_COLLAPSED_WIDTH_PX}
          expandedWidth={SIDEPANEL_EXPANDED_WIDTH_PX}
        >
          <ImagePreview image={paymentTransaction.image} />
        </CollapsiblePanel>
      )}
    </Wrapper>
  );
};

export default PaymentEdit;
