import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div<{ justifyContent?: string }>`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

export const SwitchableSectionContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.coolGray10};
  padding: ${({ theme }) => theme.spaces.spacing24};
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;
