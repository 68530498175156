import { useInfiniteSAReminderCategoriesByQuery } from '@sim-admin-frontends/data-access';
import { Button, SpinnerWrapper, TableActionHeader, Error } from '@sim-admin-frontends/ui-shared';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import ReminderCategoriesView from './ReminderCategoriesView';

type ReminderCategoriesViewContainerProps = {
  placeId: string;
};

const ReminderCategoriesViewContainer: FC<ReminderCategoriesViewContainerProps> = ({ placeId }) => {
  const { t } = useTranslation();

  const { data, isError, isLoading, refetch, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteSAReminderCategoriesByQuery(placeId);

  const columns = createTableColumns(t, refetch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const itemsCount = data?.pages?.[0].reminderCategoriesBy.reminderCategories.length ?? 0;
  const reminderCategories =
    data?.pages?.[currentPageIndex].reminderCategoriesBy.reminderCategories;

  const sortedReminderCategories = useMemo(
    () =>
      reminderCategories?.sort((firstCategory, secondCategory) =>
        firstCategory.title.localeCompare(secondCategory.title),
      ),
    [reminderCategories],
  );

  if (isError) {
    return (
      <>
        <TableActionHeader>
          <Link to={generatePath(ROUTES.reminderCategoriesEdit.path, { placeId })}>
            <Button>{t('reminderCategories.add')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }

  return (
    <ReminderCategoriesView
      placeId={placeId}
      loading={isLoading || isFetchingNextPage}
      itemsCount={itemsCount}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      hasNextPage={hasNextPage || false}
      data={sortedReminderCategories || []}
    />
  );
};

export default ReminderCategoriesViewContainer;
