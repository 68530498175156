import { FC, useMemo } from 'react';
import { captureException } from '@sentry/react';
import {
  PlaceFeatureFlag,
  useSaPlaceQuery,
  useSaUpdatePlaceMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Error,
  getErrorMessage,
  loadingToast,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import PlaceFeatures from './PlaceFeatures';
import { compareFlagOrder, omitPlace } from '../../../utils/placeFeatureFlagsUtils';

const SHARED_TOAST_ID = 'featuresFlagsToast';

type Props = {
  placeId: string;
};

const PlaceFeaturesContainer: FC<Props> = ({ placeId }) => {
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useSaPlaceQuery({ id: placeId });
  const { mutateAsync: updatePlace } = useSaUpdatePlaceMutation();
  const place = data?.place;

  const initialValues = useMemo(
    () => (place?.featureFlags ? place?.featureFlags.sort(compareFlagOrder) : []),
    [place],
  );

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const onSubmit = async (featureFlags: Array<PlaceFeatureFlag>) => {
    if (!place) {
      return;
    }
    try {
      loadingToast(t('common.loading'), {
        toastId: SHARED_TOAST_ID,
      });

      const modifiedFeatureFlags = featureFlags.map((flag) => {
        const newFlag = cloneDeep(flag);
        delete newFlag.icon?.links;
        return newFlag;
      });

      const newPlace = omitPlace(place);

      await updatePlace({
        id: placeId,
        place: {
          ...newPlace,
          featureFlags: modifiedFeatureFlags,
        },
      });

      updateToast(SHARED_TOAST_ID, t('places.features.success'), TToastType.SUCCESS);
    } catch (e) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(e), TToastType.ERROR);
      captureException(e);
    }
  };

  return (
    <PlaceFeatures
      onSubmit={onSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      placeId={placeId}
    />
  );
};

export default PlaceFeaturesContainer;
