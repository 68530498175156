import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import PaymentsViewContainer from '../../components/payments/view/PaymentsView.container';

const PaymentsListPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader
        title={t('payments.pageTitle')}
        caption={t('payments.pageCaption')}
        testId="Payments#PageHeader"
      />
      <PaymentsViewContainer />
    </PageWrapper>
  );
};

export default PaymentsListPage;
