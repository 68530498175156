import { Branding, TSAInstitutionListItem } from '@sim-admin-frontends/data-access';
import { TSAPlaceListItem } from '@sim-admin-frontends/data-access-admin-be';
import { ErrorIcon, TExtendedColumn, ClickableCopyText } from '@sim-admin-frontends/ui-shared';
import { TFunction } from 'i18next';
import { CellProps } from 'react-table';

import { InstitutionsViewTableMenu } from './InstitutionsViewTableMenu';

const renderBrandingLinks = (branding: Branding, t: TFunction) => (
  <a target="_blank" rel="noreferrer" href={branding.avatar.links?.self}>
    {t('places.edit.avatar')}
  </a>
);

export const createTableColumns = (t: TFunction, refetch: () => void) => {
  const columns: TExtendedColumn<TSAInstitutionListItem>[] = [
    {
      Header: `${t('institutions.columns.name')}`,
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.category')}`,
      accessor: 'category',
      Cell: ({ value }) => (value ? value.name : null),
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.places')}`,
      accessor: 'places',
      Cell: ({ value }) => {
        if (value) {
          return value.map(
            (place, index) => `${place.name}${index !== value.length - 1 ? ',' : ''} `,
          );
        }
        return null;
      },
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.uuid')}`,
      accessor: 'id',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.hasBranding')}`,
      accessor: 'branding',
      Cell: ({ value }) => (value ? renderBrandingLinks(value, t) : <ErrorIcon />),
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.form.role')}`,
      accessor: 'inPlaces',
      Cell: ({ value }) => (value?.[0] ? value[0].role : null),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      minWidth: 15,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TSAPlaceListItem>) => {
        return (
          <InstitutionsViewTableMenu
            refetch={refetch}
            institutionId={row.original.id}
            testId={`InstitutionsView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];
  return columns;
};
