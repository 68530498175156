import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';

import { PageWrapper } from '../../components/layout/PageLayout';
import { InstitutionsViewContainer } from '../../components/institutions/view/InstitutionsView.container';

type InstitutionsListPageProps = {
  placeId?: string;
};

const InstitutionsListPage: FC<RouteComponentProps<InstitutionsListPageProps>> = ({ match }) => {
  const { t } = useTranslation();
  const placeId = match.params.placeId;
  const { data } = usePlaceQuery({ id: placeId || '' }, { enabled: !!placeId });
  const placeName = data?.place?.name ?? '';
  return (
    <PageWrapper>
      <PageHeader
        title={`${t('institutions.pageTitle')} ${placeId ? `${t('common.in')} ${placeName}` : ''}`}
        caption={t('institutions.pageCaption')}
        testId="InstitutionsTabs#PageHeader"
      />
      <InstitutionsViewContainer placeId={placeId} />
    </PageWrapper>
  );
};

export default InstitutionsListPage;
