import { useSaPlaceQuery } from '@sim-admin-frontends/data-access-admin-be';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { PageWrapper } from '../../components/layout/PageLayout';
import ReminderCategoriesViewContainer from '../../components/reminderCategories/view/ReminderCategoriesView.container';

const WrappedB = styled.b`
  white-space: pre-wrap;
`;

type ReminderCategoriesListPage = {
  placeId: string;
};

const ReminderCategoriesListPage: FC<RouteComponentProps<ReminderCategoriesListPage>> = ({
  match,
}) => {
  const { placeId } = match.params;
  const { t } = useTranslation();
  const { data } = useSaPlaceQuery({ id: placeId });

  return (
    <PageWrapper>
      <PageHeader
        title={t('reminderCategories.pageTitle')}
        caption={
          <Trans
            t={t}
            i18nKey={'reminderCategories.pageCaption'}
            values={{ cityName: data?.place?.name || '' }}
            components={{ b: <WrappedB /> }}
          />
        }
        testId="ReminderCategoriesTabs#PageHeader"
      />
      <ReminderCategoriesViewContainer placeId={placeId} />
    </PageWrapper>
  );
};

export default ReminderCategoriesListPage;
