import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import {
  Button,
  Error,
  SpinnerWrapper,
  TableActionHeader,
  useSearchFilter,
} from '@sim-admin-frontends/ui-shared';
import {
  InstitutionsBySearchFilterInput,
  useInfiniteSAAllInstitutionsQuery,
  useInfiniteSAInstitutionsBySearchQuery,
} from '@sim-admin-frontends/data-access';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import { TInstitutionEnablingStatus } from '../../../types/TInstitution';
import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { InstitutionsView } from './InstitutionsView';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';

const TABLE_PAGE_SIZE = 100;

type InstitutionsViewContainerProps = {
  placeId?: string;
};

const InstitutionsViewContainer: FC<InstitutionsViewContainerProps> = ({ placeId }) => {
  const { t } = useTranslation();
  const { filter: searchFilter, renderFilterComponent } = useSearchFilter({
    testId: 'InstitutionsView#Search',
    minNumOfChars: MIN_NUMBER_OF_SEARCH_CHARS,
  });
  const filter: InstitutionsBySearchFilterInput = {
    placeUuid: placeId,
    text: searchFilter,
  };

  const shouldUseFilterQuery = placeId || searchFilter;

  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteSAAllInstitutionsQuery(TABLE_PAGE_SIZE, { enabled: !placeId });

  const {
    data: dataFiltered,
    isLoading: isLoadingFiltered,
    isError: isErrorFiltered,
    fetchNextPage: fetchNextPageFiltered,
    hasNextPage: hasNextPageFiltered,
    refetch: refetchFiltered,
    isFetchingNextPage: isFetchingNextPageFiltered,
  } = useInfiniteSAInstitutionsBySearchQuery(filter, !!placeId || !!searchFilter, TABLE_PAGE_SIZE);

  const columns = createTableColumns(t, refetch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    shouldUseFilterQuery ? fetchNextPageFiltered : fetchNextPage,
    shouldUseFilterQuery ? hasNextPageFiltered : hasNextPage,
  );

  const itemsCount = shouldUseFilterQuery
    ? dataFiltered?.pages?.[0]?.institutionsBySearch.institutions.length
    : data?.pages?.[0]?.allInstitutions?.institutions.length;

  const institutions = shouldUseFilterQuery
    ? dataFiltered?.pages?.[currentPageIndex]?.institutionsBySearch.institutions
    : data?.pages?.[currentPageIndex]?.allInstitutions?.institutions;

  const filteredInstitutions =
    dataFiltered?.pages?.[currentPageIndex]?.institutionsBySearch?.institutions;

  const loading = shouldUseFilterQuery
    ? isLoadingFiltered || isFetchingNextPageFiltered
    : isLoading || isFetchingNextPage || isLoadingFiltered;

  const enabledInstitutions = useMemo(() => {
    const currentInstitutions = searchFilter ? filteredInstitutions : institutions;
    return currentInstitutions
      ? currentInstitutions?.filter((i) => i.enabled !== TInstitutionEnablingStatus.DISABLED)
      : [];
  }, [institutions, searchFilter, filteredInstitutions]);

  if (isError || isErrorFiltered) {
    return (
      <>
        <TableActionHeader>
          <Link to={generatePath(ROUTES.institutionsEdit.path)}>
            <Button>{t('institutions.addInstitution')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error
            caption={t('error.fetchingDataError')}
            onClick={placeId ? refetchFiltered : refetch}
          />
        </SpinnerWrapper>
      </>
    );
  }

  return (
    <>
      <TableActionHeader prependItem={renderFilterComponent()}>
        <Link
          to={
            placeId
              ? generatePath(ROUTES.institutionEditPlace.path, { placeId })
              : generatePath(ROUTES.institutionsEdit.path)
          }
        >
          <Button testId="InstitutionsTabs#AddInstitution">
            {placeId ? t('institutions.addInstitutionToPlace') : t('institutions.addInstitution')}
          </Button>
        </Link>
      </TableActionHeader>
      <InstitutionsView
        tablePageSize={TABLE_PAGE_SIZE}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        hasNextPage={hasNextPage || false}
        data={enabledInstitutions}
      />
    </>
  );
};

export { InstitutionsViewContainer };
