import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import LoginContainer from '../../components/login/Login.container';

const LoginPage: FC<RouteComponentProps> = () => {
  return <LoginContainer />;
};

export default LoginPage;
