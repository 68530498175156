import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import PlaceFeatureEditContainer from '../../components/placeFeatures/edit/PlaceFeatureEdit.container';

type PlaceFeaturesEditPageProps = {
  placeId: string;
  featureIndex: string;
};

const PlaceFeaturesEditPage: FC<RouteComponentProps<PlaceFeaturesEditPageProps>> = ({ match }) => {
  const { t } = useTranslation();
  const { placeId, featureIndex } = match.params;
  return (
    <PageWrapper>
      <PageHeader
        title={
          featureIndex
            ? t('places.features.updateFeatureFlagTitle')
            : t('places.features.createFeatureFlagTitle')
        }
        caption={
          featureIndex
            ? t('places.features.updateFeatureFlagCaption')
            : t('places.features.createFeatureFlagCaption')
        }
        testId="FeatureFlagEdit#PageHeader"
      />
      <PlaceFeatureEditContainer placeId={placeId} featureIndex={featureIndex} />
    </PageWrapper>
  );
};

export default PlaceFeaturesEditPage;
