import { UseInfiniteQueryOptions } from 'react-query';

import {
  MarketItemsByFilterInput,
  SaMarketItemsByQuery,
  useInfiniteGraphQLQuery,
  useSaMarketItemsByQuery,
} from '../..';

export const useInfiniteMarketItemsQuery = (
  filter: MarketItemsByFilterInput,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaMarketItemsByQuery) => lastPage.marketItemsBy.nextToken;
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useSaMarketItemsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled ?? true,
  });
};
