import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { captureException } from '@sentry/react';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  useSaAddSelectorMutation,
  useSaSelectorByCodeQuery,
  ScrapeSelectorInput,
  useSaDeleteSelectorMutation,
} from '@sim-admin-frontends/data-access-admin-be';

import { useGenerateSelectorActions } from '../../../hooks/actionButtons/useGenerateSelectorActions';
import SelectorEdit from './SelectorEdit';
import { TSelectorEditFormValues } from '../../../types/TSelector';
import ROUTES from '../../../routing/routes';
import NotFound from '../../../routing/NotFound';

const SHARED_TOAST_ID = 'selectorsToast';

type SelectorEditContainerProps = {
  code?: string;
};

const SelectorEditContainer: FC<SelectorEditContainerProps> = ({ code }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const pageTitle = code ? t('selectors.editPageTitle') : t('selectors.createPageTitle');
  const pageCaption = code ? t('selectors.editPageCaption') : t('selectors.createPageCaption');

  const { mutateAsync: addSelector } = useSaAddSelectorMutation();
  const { mutateAsync: deleteSelectorMutation } = useSaDeleteSelectorMutation();
  const { data, isLoading, isError, refetch } = useSaSelectorByCodeQuery(
    {
      code: code || '',
    },
    {
      enabled: !!code,
    },
  );

  const prepareBackendData = (formValues: TSelectorEditFormValues): ScrapeSelectorInput => {
    const nodeSelectors = formValues.nodeSelectors.filter(
      (field) => field !== null && field !== undefined,
    );
    nodeSelectors.forEach((field) =>
      field.selector.filter((selector) => !!selector.collect.length),
    );
    const subpageSelectors = formValues.subpageSelectors.filter(
      (field) => field !== null && field !== undefined,
    );
    subpageSelectors.forEach((field) =>
      field.selector.filter((selector) => !!selector.collect.length),
    );
    const transformedData: ScrapeSelectorInput = {
      code: formValues.code,
      nodeSelector: formValues.nodeSelector,
      subpageSelector: formValues.subpageSelector.filter((selector) => !!selector.collect.length),
      subpageUrlFormat: formValues?.subpageUrlFormat?.trim() || null,
      imports: formValues.imports,
      variables: formValues.variables.filter((variable) => !!variable.key && !!variable.value),
      nodeSelectors: nodeSelectors,
      subpageSelectors: subpageSelectors,
      removePageTrash: formValues.removePageTrash,
      nextPageSelector: formValues.nextPageSelector.filter((selector) => !!selector.collect.length),
      maxPages: formValues.maxPages,
      originalSourceOverride: formValues?.originalSourceOverride?.trim() || null,
      paginationType: formValues.paginationType,
    };
    return transformedData;
  };

  const onSubmit = async (values: TSelectorEditFormValues) => {
    try {
      loadingToast(t('selectors.createToast.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const transformedData = prepareBackendData(values);
      if (code) {
        await deleteSelectorMutation({
          code: code,
        });
      }
      await addSelector({
        selector: transformedData,
      });

      updateToast(
        SHARED_TOAST_ID,
        code ? t('selectors.editToast.success') : t('selectors.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(ROUTES.selectors.path);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGenerateSelectorActions({
    code: code || '',
    refetch,
    isEditPage: true,
  });

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (code && !data?.scrapeSelector) {
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} />
      <SelectorEdit onSubmit={onSubmit} selector={data?.scrapeSelector} />
      {renderModal()}
    </>
  );
};

export default SelectorEditContainer;
