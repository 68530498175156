import {
  BusinessActiveUsersCounts,
  BusinessMetricsNotificationCounts,
  BusinessNotificationCounts,
} from '@sim-admin-frontends/data-access';
import { formatDateObject } from '@sim-admin-frontends/utils-shared';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import unionBy from 'lodash.unionby';

import { PINNED_CITIES } from '../constants/Constants';
import { TMappedNotifications } from '../types/TNotifications';

export const mapDashboardData = (
  notifications: BusinessNotificationCounts[],
  users: BusinessActiveUsersCounts[],
): TMappedNotifications => {
  const uniquePlaceUuids = unionBy(notifications, 'placeUuid');
  const items = uniquePlaceUuids.map((item) => {
    const notification = notifications.find((notif) => notif.placeUuid === item.placeUuid);
    const userCounts = users.find((usr) => usr.placeUuid === item.placeUuid);

    return {
      name: notification?.name ?? '',
      placeUuid: item.placeUuid,
      counts: {
        notifications: notification?.counts ?? [],
        users: userCounts,
      },
    };
  });

  const sortedItems = items.sort((a, b) => {
    const aPinned = PINNED_CITIES.includes(a.name);
    const bPinned = PINNED_CITIES.includes(b.name);
    if (aPinned !== bPinned) {
      return aPinned ? -1 : 1;
    }
    if (aPinned) {
      return PINNED_CITIES.indexOf(a.name) - PINNED_CITIES.indexOf(b.name);
    }
    return a.name.localeCompare(b.name);
  });

  return sortedItems;
};

export const yesterday = (dateFormat: string) =>
  formatDateObject(endOfDay(subDays(new Date(), 1)), dateFormat);

export const threeDaysAgo = (dateFormat: string) =>
  formatDateObject(startOfDay(subDays(new Date(), 3)), dateFormat);

export const getNotificationCellColor = (
  todayValue: number,
  allData: BusinessMetricsNotificationCounts[],
  colors: { error: string; warning: string; white: string },
) => {
  const hasZeroNotifications = !allData?.reduce((acc, curr) => acc + curr?.count, 0);

  const hasErrorValues =
    allData?.slice(1).filter((item) => todayValue <= item.count * 0.5).length > 0;
  const hasWarningValues =
    allData?.slice(1).filter((item) => todayValue <= item.count * 0.8).length > 0;

  if (hasZeroNotifications) {
    return colors.white;
  }
  if (hasErrorValues) {
    return colors.error;
  }
  if (hasWarningValues) {
    return colors.warning;
  }
  return colors.white;
};
