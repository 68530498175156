import { TFunction, useTranslation } from 'react-i18next';
import {
  Button,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormUpload,
  FormWrapper,
  SUPPORTED_WEB_IMAGE_FORMATS,
  TSelectItems,
  FormTextarea,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import * as Yup from 'yup';
import { FormProvider, get, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TYupTestContextExtended, isEmpty } from '@sim-admin-frontends/utils-shared';

import {
  TInfluencerDetail,
  TInfluencerFormValues,
  TPropertySelectItem,
} from '../../../types/TInfluencer';
import {
  getInitialInfluencerProperties,
  getInitialInfluencerSocials,
} from '../../../utils/influencerUtils';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import InfluencerSocialsSection from './InfluencerSocialsSection';
import InfluencerPropertiesSection from './InfluencerPropertiesSection';

const schema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().required(t('form.fieldRequired')),
    description: Yup.string().required(t('form.fieldRequired')),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
    properties: Yup.array().of(
      Yup.object({
        name: Yup.object()
          .shape({
            value: Yup.string().defined(),
            label: Yup.string().defined(),
          })
          .defined()
          .test(
            'unique',
            t('common.validation.unique'),
            (propertyName: TPropertySelectItem, context: TYupTestContextExtended) => {
              const parent = context.from?.[2];
              const properties: TInfluencerFormValues['properties'] | undefined =
                parent?.value?.properties;
              if (!properties) {
                return true;
              }

              return (
                properties.reduce((acc, curr) => {
                  if (curr.name.value === propertyName.value) {
                    return acc + 1;
                  }
                  return acc;
                }, 0) === 1
              );
            },
          ),
      }),
    ),
  });
};

type Props = {
  onSubmit: (values: TInfluencerFormValues) => Promise<void>;
  influencer?: TInfluencerDetail;
  places: TSelectItems;
  onPlacesSearch?: (text: string) => void;
  isLoadingPlaces?: boolean;
  onPlacesMenuScrollToBottom?: (e: Event) => void;
};

const InfluencerEdit = ({
  onSubmit,
  influencer,
  places,
  onPlacesSearch,
  isLoadingPlaces,
  onPlacesMenuScrollToBottom,
}: Props) => {
  const { t } = useTranslation();

  const initialValues: TInfluencerFormValues = {
    name: influencer?.name || '',
    description: influencer?.description || '',
    socials: getInitialInfluencerSocials(influencer?.socials),
    properties: getInitialInfluencerProperties(influencer?.properties),
    places: influencer?.places?.map((place) => ({ label: place.name, value: place.id })) || [],
    avatar: influencer?.avatar ? [influencer.avatar] : null,
    background: influencer?.background ? [influencer.background] : null,
  };

  const submit = async (values: TInfluencerFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TInfluencerFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, control, register } = methods;
  const { errors, isSubmitting } = formState;

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('influencers.form.name')}
            {...register('name')}
            error={errors.name}
            testId="InfluencerEdit#name"
          />
          <FormTextarea
            label={t('influencers.form.description')}
            {...register('description')}
            error={errors.description}
            testId="InfluencerEdit#description"
          />
          <FormSelect
            isMulti
            searchable
            clearable
            label={t('influencers.form.places')}
            error={get(errors, 'places')}
            options={places}
            control={control}
            name={'places'}
            defaultValue={initialValues.places}
            onMenuScrollToBottom={onPlacesMenuScrollToBottom}
            isLoading={isLoadingPlaces}
            onSearch={onPlacesSearch}
            testId="InfluencerEdit#places"
          />
          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={`${t('places.edit.avatar')}\n${t('places.edit.avatarDescription')}`}
          />
          <FormUpload
            control={control}
            name="avatar"
            t={t}
            dropzoneLabel={t('places.edit.dropAvatar')}
            testId="InfluencerEdit#imageAvatar"
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />
          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={t('places.edit.background')}
          />
          <FormUpload
            control={control}
            name="background"
            t={t}
            dropzoneLabel={t('influencers.edit.dropBackground')}
            testId="InfluencerEdit#imageBackground"
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />
          <FormSectionHeader
            title={t('influencers.form.socialsLabel')}
            description={t('influencers.form.socialsSublabel')}
          />
          <InfluencerSocialsSection />
          <FormSectionHeader
            title={t('influencers.form.propertiesLabel')}
            description={t('influencers.form.propertiesSublabel')}
          />
          <InfluencerPropertiesSection />
          <ButtonsWrapper>
            <Button
              testId="InfluencerEdit#submit"
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {influencer ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default InfluencerEdit;
