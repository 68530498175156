import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import UserEditContainer from '../../components/users/edit/UserEdit.container';

type UserEditPageProps = {
  username?: string;
  institutionId?: string;
};

const UserEditPage: FC<RouteComponentProps<UserEditPageProps>> = ({ match }) => {
  const username = match.params.username;
  const institutionId = match.params.institutionId;
  return (
    <PageWrapper>
      <UserEditContainer username={username} institutionId={institutionId} />
    </PageWrapper>
  );
};

export default UserEditPage;
