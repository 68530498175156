import { InfoIcon, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TFunction } from 'react-i18next';
import { CellProps } from 'react-table';
import { formatTableDateTime, formatTableDate } from '@sim-admin-frontends/utils-shared';

import { TItinerariesListItem } from '../../types/TPlaceItineraries';
import { HeaderItem, HeaderWrapper, StyledTippy } from '../common/TableStyles';
import PlaceItinerariesTableMenu from './PlaceItinerariesTableMenu';

export const createTableColumns = (t: TFunction) => {
  const columns: TExtendedColumn<TItinerariesListItem>[] = [
    {
      Header: `${t('payments.createdAt')}`,
      accessor: 'createdAt',
      Cell: ({ value }) => (value ? formatTableDateTime(value) : null),
    },
    {
      Header: `${t('places.itineraries.dayOfWeek')}`,
      accessor: 'dayOfWeek',
      disableSortBy: true,
    },
    {
      Header: () => (
        <HeaderWrapper>
          <HeaderItem>{t('places.itineraries.preferences')}</HeaderItem>
          <HeaderItem>
            <StyledTippy
              arrow={false}
              placement="right-start"
              content={`${t('places.itineraries.company')}, ${t(
                'places.itineraries.priceLevel',
              )}, ${t('places.itineraries.mode')}`}
            >
              <InfoIcon />
            </StyledTippy>
          </HeaderItem>
        </HeaderWrapper>
      ),
      id: 'itineraryPreferences',
      Cell: ({ row }: CellProps<TItinerariesListItem>) => {
        const { company, priceLevel, mode } = row.original;
        const text = `${company}, ${priceLevel}, ${mode}`;
        return <div>{text}</div>;
      },
      disableSortBy: true,
      minWidth: 50,
    },
    {
      Header: `${t('places.itineraries.creationStatus')}`,
      accessor: 'creationStatus',
      disableSortBy: true,
    },
    {
      Header: `${t('places.itineraries.failReason')}`,
      accessor: 'failReason',
      disableSortBy: true,
    },
    {
      Header: `${t('places.itineraries.date')}`,
      accessor: 'date',
      disableSortBy: true,
      Cell: ({ value }) => (value ? formatTableDate(value) : null),
    },
    {
      Header: `${t('places.itineraries.isRetriable')}`,
      accessor: 'isRetriable',
      disableSortBy: true,
    },
    {
      Header: ``,
      id: 'actions',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TItinerariesListItem>) => (
        <PlaceItinerariesTableMenu cloudWatchLogUrl={row.original.cloudWatchLogUrl} />
      ),
    },
  ];

  return columns;
};
