import { TFunction } from 'i18next';
import { BrandingPlace, TSAPlaceListItem } from '@sim-admin-frontends/data-access-admin-be';
import { TExtendedColumn, ErrorIcon, ClickableCopyText } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';

import { PlacesViewTableMenu } from './PlacesViewTableMenu';

const renderPlaceBrandingLinks = (branding: BrandingPlace | undefined | null, t: TFunction) => {
  if (!branding?.logo && !branding?.avatar) {
    return <ErrorIcon />;
  }
  return (
    <>
      {branding.logo && (
        <a target="_blank" rel="noreferrer" href={branding?.logo.links?.self}>
          {t('places.edit.logo')}
        </a>
      )}
      <br />
      {branding.avatar && (
        <a target="_blank" rel="noreferrer" href={branding?.avatar.links?.self}>
          {t('places.edit.avatar')}
        </a>
      )}
    </>
  );
};

export const createTableColumns = (t: TFunction, refetch: () => void) => {
  const columns: TExtendedColumn<TSAPlaceListItem>[] = [
    {
      Header: `${t('places.columns.name')}`,
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: `${t('places.columns.country')}`,
      accessor: 'countryName',
      disableSortBy: true,
    },
    {
      Header: `${t('places.columns.uuid')}`,
      accessor: 'id',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('places.columns.timezone')}`,
      accessor: 'timezoneCode',
      disableSortBy: true,
    },
    {
      Header: `${t('places.columns.hasBranding')}`,
      accessor: 'branding',
      Cell: ({ value }) => renderPlaceBrandingLinks(value, t),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      minWidth: 60,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TSAPlaceListItem>) => {
        return (
          <PlacesViewTableMenu
            refetch={refetch}
            placeId={row.original.id}
            testId={`PlacesView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];
  return columns;
};
