import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PlacesViewContainer } from '../../components/places/view/PlacesView.container';
import { PageWrapper } from '../../components/layout/PageLayout';

const PlacesListPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader
        title={t('places.pageTitle')}
        caption={t('places.pageCaption')}
        testId="PlacesTabs#PageHeader"
      />
      <PlacesViewContainer />
    </PageWrapper>
  );
};

export default PlacesListPage;
