import { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from '../pages/home/HomePage';
import InstitutionsEditPage from '../pages/institutions/InstitutionsEditPage';
import InstitutionsListPage from '../pages/institutions/InstitutionsListPage';
import LoginPage from '../pages/login/LoginPage';
import PlaceFeaturesEditPage from '../pages/placeFeatures/PlaceFeaturesEditPage';
import PlaceFeaturesPage from '../pages/placeFeatures/PlaceFeaturesPage';
import PlaceEditPage from '../pages/places/PlaceEditPage';
import PlacesListPage from '../pages/places/PlacesListPage';
import SelectorEditPage from '../pages/selectors/SelectorEditPage';
import SelectorListPage from '../pages/selectors/SelectorListPage';
import SystemSettingsPage from '../pages/systemSettings/SystemSettingsPage';
import TaskEditPage from '../pages/tasks/TaskEditPage';
import TaskEditTwitterPage from '../pages/tasks/TaskEditTwitterPage';
import InstitutionFeaturesEditPage from '../pages/institutionFeatures/InstitutionFeaturesEditPage';
import InstitutionFeaturesPage from '../pages/institutionFeatures/InstitutionFeaturesPage';
import TaskEditGooglePage from '../pages/tasks/TaskEditGooglePage';
import PaymentsEditPage from '../pages/payments/PaymentsEditPage';
import PaymentsListPage from '../pages/payments/PaymentsListPage';
import TaskListPage from '../pages/tasks/TaskListPage';
import UserEditPage from '../pages/users/UserEditPage';
import UserListPage from '../pages/users/UserListPage';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';
import ROUTES from './routes';
import ReminderCategoriesListPage from '../pages/reminderCategories/ReminderCategoriesListPage';
import ReminderCategoriesEditPage from '../pages/reminderCategories/ReminderCategoriesEditPage';
import NotificationsEditPage from '../pages/notifications/NotificationsEditPage';
import TaskEditYelpPage from '../pages/tasks/TaskEditYelpPage';
import InfluencersListPage from '../pages/influencers/InfluencersListPage';
import InfluencersEditPage from '../pages/influencers/InfluencersEditPage';
import InfluencerSpotsEditPage from '../pages/influencerSpots/InfluencerSpotsEditPage';
import InfluencerSpotsListPage from '../pages/influencerSpots/InfluencerSpotsListPage';
import LandmarksListPage from '../pages/landmarks/LandmarksListPage';
import LandmarksEditPage from '../pages/landmarks/LandmarksEditPage';
import PlaceItinerariesPage from '../pages/placeItineraries/PlaceItinerariesPage';
import PreferenceCardsPage from '../pages/preferenceCards/PreferenceCards';

const Routing: FC = () => (
  <Router>
    <Switch>
      <Route exact path={ROUTES.login.path} component={LoginPage} />
      <PrivateRoute exact path={ROUTES.home.path} component={HomePage} />
      <PrivateRoute exact path={ROUTES.places.path} component={PlacesListPage} />
      <PrivateRoute exact path={ROUTES.placeEdit.path} component={PlaceEditPage} />
      <PrivateRoute exact path={ROUTES.placeFeatures.path} component={PlaceFeaturesPage} />
      <PrivateRoute exact path={ROUTES.placeFeaturesEdit.path} component={PlaceFeaturesEditPage} />
      <PrivateRoute
        exact
        path={ROUTES.reminderCategories.path}
        component={ReminderCategoriesListPage}
      />
      <PrivateRoute
        exact
        path={ROUTES.reminderCategoriesEdit.path}
        component={ReminderCategoriesEditPage}
      />
      <PrivateRoute exact path={ROUTES.institutionsEdit.path} component={InstitutionsEditPage} />
      <PrivateRoute
        exact
        path={ROUTES.institutionEditPlace.path}
        component={InstitutionsEditPage}
      />
      <PrivateRoute exact path={ROUTES.institutions.path} component={InstitutionsListPage} />
      <PrivateRoute
        exact
        path={ROUTES.institutionFeatures.path}
        component={InstitutionFeaturesPage}
      />
      <PrivateRoute
        exact
        path={ROUTES.institutionFeaturesEdit.path}
        component={InstitutionFeaturesEditPage}
      />
      <PrivateRoute exact path={ROUTES.userEdit.path} component={UserEditPage} />
      <PrivateRoute exact path={ROUTES.institutionUserEdit.path} component={UserEditPage} />
      <PrivateRoute exact path={ROUTES.users.path} component={UserListPage} />
      <PrivateRoute exact path={ROUTES.placeUsers.path} component={UserListPage} />
      <PrivateRoute exact path={ROUTES.institutionsUsers.path} component={UserListPage} />
      <PrivateRoute exact path={ROUTES.tasks.path} component={TaskListPage} />
      <PrivateRoute exact path={ROUTES.taskEdit.path} component={TaskEditPage} />
      <PrivateRoute exact path={ROUTES.taskEditTwitter.path} component={TaskEditTwitterPage} />
      <PrivateRoute exact path={ROUTES.taskEditGoogle.path} component={TaskEditGooglePage} />
      <PrivateRoute exact path={ROUTES.taskEditYelp.path} component={TaskEditYelpPage} />
      <PrivateRoute exact path={ROUTES.selectors.path} component={SelectorListPage} />
      <PrivateRoute exact path={ROUTES.selectorEdit.path} component={SelectorEditPage} />
      <PrivateRoute exact path={ROUTES.systemSettings.path} component={SystemSettingsPage} />
      <PrivateRoute exact path={ROUTES.preferenceCards.path} component={PreferenceCardsPage} />
      <PrivateRoute exact path={ROUTES.paymentsEdit.path} component={PaymentsEditPage} />
      <PrivateRoute exact path={ROUTES.payments.path} component={PaymentsListPage} />
      <PrivateRoute exact path={ROUTES.notificationsEdit.path} component={NotificationsEditPage} />
      <PrivateRoute exact path={ROUTES.influencers.path} component={InfluencersListPage} />
      <PrivateRoute exact path={ROUTES.influencersEdit.path} component={InfluencersEditPage} />
      <PrivateRoute exact path={ROUTES.influencerSpots.path} component={InfluencerSpotsListPage} />
      <PrivateRoute exact path={ROUTES.landmarks.path} component={LandmarksListPage} />
      <PrivateRoute exact path={ROUTES.landmarkEdit.path} component={LandmarksEditPage} />
      <PrivateRoute
        exact
        path={ROUTES.influencerSpotsEdit.path}
        component={InfluencerSpotsEditPage}
      />
      <PrivateRoute exact path={ROUTES.placeItineraries.path} component={PlaceItinerariesPage} />
      <PrivateRoute path={'*'} component={NotFound} />
    </Switch>
  </Router>
);

export default Routing;
