import { FC } from 'react';
import {
  Error,
  SpinnerWrapper,
  TableActionHeader,
  loadingToast,
  updateToast,
  TToastType,
  getErrorMessage,
  useSearchFilter,
  DropdownMenuItemProps,
  DropdownMenu,
  ArrowDown,
  Button,
} from '@sim-admin-frontends/ui-shared';
import {
  useSaAddTaskMutation,
  ScrapeTaskInput,
  useInfiniteSAAllTasksQuery,
} from '@sim-admin-frontends/data-access-admin-be';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { TasksView } from './TasksView';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';

const SHARED_TOAST_ID = 'tasksToast';

const TasksViewContainer: FC = () => {
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'TasksView#Search',
    minNumOfChars: MIN_NUMBER_OF_SEARCH_CHARS,
  });
  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteSAAllTasksQuery({ affix: filter });

  const { mutateAsync: addTask } = useSaAddTaskMutation();

  const onSwitch = async (values: ScrapeTaskInput, changedValue: boolean) => {
    try {
      loadingToast(t('tasks.enable.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      await addTask({
        task: values,
      });

      updateToast(
        SHARED_TOAST_ID,
        changedValue ? t('tasks.enable.success') : t('tasks.disable.success'),
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };
  const columns = createTableColumns(t, refetch, onSwitch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const dropdownItems: DropdownMenuItemProps[] = [
    {
      label: t('tasks.addWebsiteTask'),
      type: 'normal',
      path: generatePath(ROUTES.taskEdit.path),
    },
    {
      label: t('tasks.addTwitterTask'),
      type: 'normal',
      path: generatePath(ROUTES.taskEditTwitter.path),
    },
    {
      label: t('tasks.addGoogleTask'),
      path: generatePath(ROUTES.taskEditGoogle.path),
      type: 'normal',
    },
    {
      label: t('tasks.addYelpTask'),
      type: 'normal',
      path: generatePath(ROUTES.taskEditYelp.path),
    },
  ];

  const renderTableActionHeader = (hideFilter?: boolean) => (
    <TableActionHeader prependItem={hideFilter ? undefined : renderFilterComponent()}>
      <DropdownMenu items={dropdownItems}>
        <Button size="smaller">
          <ArrowDown height={32} width={24} />
          {t('tasks.addTask')}
        </Button>
      </DropdownMenu>
    </TableActionHeader>
  );

  if (isError) {
    return (
      <>
        {renderTableActionHeader(true)}
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }
  const itemsCount = data?.pages[0].allScrapeTasks.tasks.length;
  const tasks = data?.pages[currentPageIndex].allScrapeTasks.tasks;
  const hasNextToken = !!data?.pages[currentPageIndex].allScrapeTasks.nextToken;

  return (
    <>
      {renderTableActionHeader()}
      <TasksView
        loading={isLoading || isFetchingNextPage}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        hasNextPage={hasNextToken}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={tasks || []}
      />
    </>
  );
};

export { TasksViewContainer };
