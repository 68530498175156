import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ArrowDown,
  DropdownMenu,
  DropdownMenuItemProps,
  LogoutIcon,
  MediumText,
} from '@sim-admin-frontends/ui-shared';

import { useAuthInfo } from '../../contexts/userContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const UserPrefsHeader = () => {
  const { t } = useTranslation();
  const { logout, user } = useAuthInfo();
  const theme = useTheme();

  const menuItems: DropdownMenuItemProps[] = [
    {
      icon: <LogoutIcon fill={theme.colors.black} />,
      label: t('login.logout'),
      action: logout,
      type: 'normal',
      testId: 'UserHeaderMenu#Logout',
    },
  ];

  const userName = user?.username;

  return (
    <Wrapper>
      <DropdownMenu items={menuItems} testId="UserHeader#Dropdown">
        <ArrowDown height={32} width={24} />
        {userName && <MediumText>{userName}</MediumText>}
      </DropdownMenu>
    </Wrapper>
  );
};

export default UserPrefsHeader;
