import { Button, Caption1, Heading1 } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ROUTES from './routes';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Heading = styled(Heading1)`
  padding-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Caption = styled(Caption1)`
  padding-bottom: ${({ theme }) => theme.spaces.spacing32};
`;

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Heading data-testid="SomethingWrong#title">{t('notFound.somethingWentWrong')}</Heading>
      <Caption>{t('notFound.pageNotFound')}</Caption>
      <Link to={ROUTES.home.path}>
        <Button testId="BackToHome#button" variant="primary">
          {t('notFound.backToHomePage')}
        </Button>
      </Link>
    </Wrapper>
  );
};

export default NotFound;
