import styled from 'styled-components';

export const SectionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

export const SectionItemLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SectionItemRightWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
  display: flex;
  flex-grow: 0;
`;
