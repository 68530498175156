import { TFunction } from 'react-i18next';
import { DashboardIcon, TMenuEntries } from '@sim-admin-frontends/ui-shared';

import ROUTES from '../../routing/routes';

export const createMainMenuItems: (t: TFunction) => TMenuEntries = (t: TFunction) => {
  return [
    {
      label: t('mainMenu.dashboard'),
      icon: <DashboardIcon />,
      path: ROUTES.home.path,
      testId: 'mainMenu#dashboard',
    },
    {
      label: t('mainMenu.places'),
      icon: <DashboardIcon />,
      path: ROUTES.places.path,
      testId: 'mainMenu#places',
    },
    {
      label: t('mainMenu.institutions'),
      icon: <DashboardIcon />,
      path: ROUTES.institutions.path,
      testId: 'mainMenu#institutions',
    },
    {
      label: t('mainMenu.payments'),
      icon: <DashboardIcon />,
      path: ROUTES.payments.path,
      testId: 'mainMenu#payments',
    },
    {
      label: t('mainMenu.users'),
      icon: <DashboardIcon />,
      path: ROUTES.users.path,
      testId: 'mainMenu#users',
    },
    {
      label: t('mainMenu.tasks'),
      icon: <DashboardIcon />,
      path: ROUTES.tasks.path,
      testId: 'mainMenu#tasks',
    },
    {
      label: t('mainMenu.selectors'),
      icon: <DashboardIcon />,
      path: ROUTES.selectors.path,
      testId: 'mainMenu#selectors',
    },
    {
      label: t('mainMenu.influencers'),
      icon: <DashboardIcon />,
      path: ROUTES.influencers.path,
      testId: 'mainMenu#influencers',
    },
    {
      label: t('mainMenu.notifications'),
      icon: <DashboardIcon />,
      path: ROUTES.notificationsEdit.path,
      testId: 'mainMenu#notifications',
    },
    {
      label: t('mainMenu.systemSettings'),
      icon: <DashboardIcon />,
      path: ROUTES.systemSettings.path,
      testId: 'mainMenu#systemSettings',
    },
    {
      label: t('mainMenu.preferenceCards'),
      icon: <DashboardIcon />,
      path: ROUTES.preferenceCards.path,
      testId: 'mainMenu#preferenceCards',
    },
  ];
};
