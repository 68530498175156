import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  TSelectItem,
  TSelectItems,
  Wrapper,
} from '@sim-admin-frontends/ui-shared';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Channel } from '@sim-admin-frontends/data-access';

import { TNotificationCategory, TNotificationsEditValues } from '../../../types/TNotifications';

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
  align-self: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const schema = (t: TFunction) => {
  return Yup.object().shape({
    channel: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TNotificationsEditValues['channel'] | null) => !!value,
    ),
    title: Yup.string().required(t('common.validation.required')),
    city: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
    institution: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
    deeplink: Yup.string().when('channel', {
      is: (channel: TNotificationsEditValues['channel'] | null) =>
        channel?.value === Channel.DeepLink,
      then: Yup.string().required(t('form.fieldRequired')),
    }),
    postId: Yup.string().when('channel', {
      is: (channel: TNotificationsEditValues['channel'] | null) =>
        channel?.value !== Channel.DeepLink,
      then: Yup.string().required(t('form.fieldRequired')),
    }),
  });
};

type Props = {
  onPlacesSearch: (arg: string) => void;
  onPlacesScrollToBottom: () => void;
  isFetchingPlaces: boolean;
  onPlaceSelect: (arg: readonly TSelectItem[] | null) => void;
  places: TSelectItems;
  onInstitutionsSearch: (arg: string) => void;
  onInstitutionsScrollToBottom: () => void;
  isFetchingInstitutions: boolean;
  institutions: TSelectItems;
  onSubmit: (values: TNotificationsEditValues) => void;
};

const NotificationsEdit: FC<Props> = ({
  places,
  institutions,
  isFetchingPlaces,
  onPlacesSearch,
  onPlacesScrollToBottom,
  onPlaceSelect,
  onInstitutionsSearch,
  onInstitutionsScrollToBottom,
  isFetchingInstitutions,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const methods = useForm<TNotificationsEditValues>({
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });
  const { handleSubmit, formState, control, register, setValue } = methods;
  const { errors, isSubmitting } = formState;
  const [shouldDisableSendButton, setShouldDisableSendButton] = useState(false);

  const [postId, channel] = useWatch({
    name: ['postId', 'channel'],
    control,
  });

  useEffect(() => {
    setShouldDisableSendButton(false);
  }, [postId]);

  const submit = async (values: TNotificationsEditValues) => {
    await onSubmit(values);
    setShouldDisableSendButton(true);
  };

  const getTranslatedCategoryName = (value: TNotificationCategory) => {
    const map = {
      [TNotificationCategory.Announcement]: t('notifications.category.announcement'),
      [TNotificationCategory.Event]: t('notifications.category.event'),
      [TNotificationCategory.Coupon]: t('notifications.category.benefit'),
      [TNotificationCategory.DeepLink]: t('notifications.category.deeplink'),
    };
    return map[value];
  };

  const categories = useMemo(
    () =>
      Object.values(TNotificationCategory).map((value) => ({
        label: getTranslatedCategoryName(value),
        value: value,
      })),
    [],
  );

  useEffect(() => {
    if (channel?.value === Channel.DeepLink) {
      setValue('postId', undefined);
    } else {
      setValue('deeplink', undefined);
    }
  }, [channel]);

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            searchable
            clearable
            label={t('notifications.city')}
            error={get(errors, 'city')}
            options={places}
            control={control}
            name={'city'}
            onMenuScrollToBottom={onPlacesScrollToBottom}
            isLoading={isFetchingPlaces}
            onSearch={onPlacesSearch}
            onChange={onPlaceSelect}
            testId="NotificationEdit#city"
          />
          <FormSelect
            searchable
            clearable
            label={t('notifications.institution')}
            error={get(errors, 'institution')}
            options={institutions}
            control={control}
            name={'institution'}
            onMenuScrollToBottom={onInstitutionsScrollToBottom}
            isLoading={isFetchingInstitutions}
            onSearch={onInstitutionsSearch}
            testId="NotificationEdit#institution"
          />
          <FormInput
            label={t('notifications.title')}
            {...register('title')}
            error={errors.title}
            testId="NotificationEdit#title"
          />
          <FormTextarea
            label={t('notifications.body')}
            {...register('body')}
            error={errors.body}
            testId="NotificationEdit#body"
          />
          <FormSelect
            searchable
            clearable
            label={t('notifications.channel')}
            error={get(errors, 'channel')}
            options={categories}
            control={control}
            name={'channel'}
            onMenuScrollToBottom={onInstitutionsScrollToBottom}
            isLoading={isFetchingInstitutions}
            onSearch={onInstitutionsSearch}
            testId="NotificationEdit#category"
          />
          {channel?.value === Channel.DeepLink && (
            <FormInput
              label={t('notifications.deeplink')}
              {...register('deeplink')}
              error={errors.deeplink}
              testId="NotificationEdit#deeplink"
            />
          )}
          {channel?.value !== Channel.DeepLink && (
            <FormInput
              label={t('notifications.reference')}
              {...register('postId')}
              error={errors.postId}
              testId="NotificationEdit#postId"
            />
          )}
          <ButtonsWrapper>
            <Button
              testId="NotificationEdit#submit"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting && !shouldDisableSendButton}
              disabled={isSubmitting || !isEmpty(errors) || shouldDisableSendButton}
            >
              {t('common.send')}
            </Button>
            {shouldDisableSendButton && (
              <Button
                type="submit"
                onClick={handleSubmit(submit)}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                {t('common.sendAgain')}
              </Button>
            )}
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default NotificationsEdit;
