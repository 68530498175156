import { FC, useMemo, useState } from 'react';
import {
  useInfiniteSaPlacesQuery,
  useSendVisitorPromoNotificationMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';
import {
  getErrorMessage,
  loadingToast,
  TSelectItem,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { TVisitorNotificationsEditValues } from '../../../types/TNotifications';
import { transformPlaces } from '../../../utils/placeUtils';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';
import VisitorNotificationsEdit from './VisitorNotificationsEdit';
import { SHARED_TOAST_ID } from '../view/NotificationsTabs';

const VisitorNotificationsEditContainer: FC = () => {
  const { t } = useTranslation();
  const [selectedPlaceId, setSelectedPlaceId] = useState('');
  const { onSearch: onPlacesSearch, searchTerm: searchTermPlaces } = useSearchTerm(
    MIN_NUMBER_OF_SEARCH_CHARS,
  );

  const {
    data: placesData,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
    isLoading: isLoadingPlaces,
  } = useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTermPlaces });

  const { mutateAsync: sendNotification } = useSendVisitorPromoNotificationMutation();

  const places = useMemo(
    () => placesData?.pages.flatMap((page) => transformPlaces(page.places.places)) ?? [],
    [placesData],
  );

  const onPlacesScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const onPlaceSelect = (selectedItem: readonly TSelectItem[] | null) => {
    if (!selectedItem) {
      return;
    }
    setSelectedPlaceId(selectedItem[0].value);
  };

  const onSubmit = async (values: TVisitorNotificationsEditValues) => {
    loadingToast(t('notifications.toastSubmitting'), {
      toastId: SHARED_TOAST_ID,
    });
    try {
      await sendNotification({
        data: {
          notificationTitle: values.title,
          notificationBody: values.body,
        },
        placeId: selectedPlaceId,
      });
      updateToast(SHARED_TOAST_ID, t('notifications.toastSuccess'), TToastType.SUCCESS);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  return (
    <VisitorNotificationsEdit
      onPlacesSearch={onPlacesSearch}
      onPlacesScrollToBottom={onPlacesScrollToBottom}
      isFetchingPlaces={isLoadingPlaces}
      onPlaceSelect={onPlaceSelect}
      places={places}
      onSubmit={onSubmit}
    />
  );
};

export default VisitorNotificationsEditContainer;
