import { useInfinitePlaceItinerariesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';
import { useTranslation } from 'react-i18next';
import { SpinnerWrapper, Error } from '@sim-admin-frontends/ui-shared';

import PlaceItineraries from './PlaceItineraries';
import { createTableColumns } from './helper';

type Props = {
  placeId?: string;
};

const PlaceItinerariesContainer = ({ placeId }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfinitePlaceItinerariesQuery(placeId || '');

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const columns = createTableColumns(t);
  const itineraries = data?.pages?.[currentPageIndex]?.placeItineraries.itineraries;

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  return (
    <PlaceItineraries
      data={itineraries || []}
      columns={columns}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      pageIndex={currentPageIndex}
      loading={isLoading || isFetchingNextPage}
    />
  );
};

export default PlaceItinerariesContainer;
