import { FC, useMemo, useRef } from 'react';
import { FieldPath, FormProvider, get, useForm } from 'react-hook-form';
import { getLangOptionByVal, getLangOptions, isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormWrapper,
  FormSectionHeader,
  FormSelect,
  TSelectRef,
  FormSwitcherWithLabel,
  TSelectItems,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ScrapeContentType,
  ScrapeDataType,
  ScrapeType,
  TSATaskDetail,
} from '@sim-admin-frontends/data-access-admin-be';
import { TCategories } from '@sim-admin-frontends/data-access';

import { getCategoriesOptions } from '../../../utils/categoriesUtils';
import { getInitialCategoriesOptions } from '../../../utils/tasksUtils';
import { TTaskEditFormValues } from '../../../types/TTask';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';

const cronExpression = '* 7 * * *';

const regexTask = /\w+_(\w+-)?\w+_(\w+-)?(google)/;
const regexQuery = /^Events\b.*\bin$/;
const schema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .label(t('form.fieldRequired'))
      .matches(
        regexTask,
        t('tasks.form.codeMatch') + '{state}_{area}-{city or institution}_{identifier}-{google}',
      )
      .trim(),
    enabled: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    url: Yup.string().required(t('form.fieldRequired')).trim(),
    JSRender: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    proxy: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    contentType: Yup.string()
      .oneOf(Object.values(ScrapeContentType))
      .required(t('form.fieldRequired')),
    dataType: Yup.string().oneOf(Object.values(ScrapeDataType)).required(t('form.fieldRequired')),
    scraperType: Yup.string().oneOf(Object.values(ScrapeType)).required(t('form.fieldRequired')),
    websiteTemplate: Yup.string().nullable().trim(),
    timeZone: Yup.string().required(t('form.fieldRequired')).trim(),
    checkCodes: Yup.array().of(Yup.string().trim()),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .compact(),
    query: Yup.string()
      .label(t('form.fieldRequired'))
      .matches(
        regexQuery,
        t('tasks.form.codeMatch') + 'Must start with "Events" and end with "in"',
      ),
  });
};

type TaskEditProps = {
  onSubmit: (values: TTaskEditFormValues) => Promise<void>;
  task: TSATaskDetail;
  categories: TCategories;
  visitorCategories: TCategories;
  institutions: TSelectItems;
  initialInstitution?: TSelectItem;
  onAllInstitutionsMenuScrollToBottom?: (e: Event) => void;
  onInstitutionsSearch?: (text: string) => void;
  isFetchingInstitutions?: boolean;
  checkCodesOptions: TSelectItems;
  onAllTasksMenuScrollToBottom?: (e: Event) => void;
  onTasksSearch?: (text: string) => void;
  isFetchingTasks?: boolean;
};

const TaskEditGoogle: FC<TaskEditProps> = ({
  onSubmit,
  task,
  categories,
  visitorCategories,
  institutions,
  initialInstitution,
  onAllInstitutionsMenuScrollToBottom,
  onInstitutionsSearch,
  isFetchingInstitutions,
  checkCodesOptions,
  onAllTasksMenuScrollToBottom,
  onTasksSearch,
  isFetchingTasks,
}) => {
  const { t } = useTranslation();

  const getUrlValue = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }
    const googleUrlRegex = /Events.*\+in\+(.*)&/;
    const extractedValue = value.match(googleUrlRegex);
    if (extractedValue === null) {
      return value;
    }
    return extractedValue[1].split('+').join(' ');
  };

  const getUrlQueryValue = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }
    const googleUrlRegex = /(Events.*\+in)\+.*&/;
    const extractedValue = value.match(googleUrlRegex);
    if (extractedValue === null) {
      return '';
    }
    return extractedValue[1].split('+').join(' ');
  };

  const initialCategoriesOptions = getCategoriesOptions(categories).concat(
    getCategoriesOptions(visitorCategories),
  );

  const initialValues: TTaskEditFormValues = {
    code: task?.code || '',
    enabled: task?.enabled || false,
    enabledStaging: task?.enabledStaging || false,
    enabledTesting: task?.enabledTesting || false,
    url: getUrlValue(task?.url),
    JSRender: task?.JSRender || true,
    proxy: task?.proxy || false,
    contentType: task?.contentType || ScrapeContentType.Html,
    timeZone: task?.timeZone || '',
    checkCodes: [],
    lang: getLangOptionByVal(task?.lang),
    runSchedule: task?.runSchedule || cronExpression,
    categories: getInitialCategoriesOptions(initialCategoriesOptions, task?.categories),
    dataType: task?.dataType || ScrapeDataType.Events,
    scraperType: task?.scraperType || ScrapeType.Google,
    websiteTemplate: task?.websiteTemplate || null,
    subpageContentType: task?.subpageContentType || ScrapeContentType.Html,
    visitorScraper: task?.visitorScraper || false,
    query: getUrlQueryValue(task?.url) || 'Events in',
    institution: null,
  };

  const methods = useForm<TTaskEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, setValue, register, trigger, control, getValues } = methods;

  const { errors, isSubmitting } = formState;

  const onSwitcherChange = (field: FieldPath<TTaskEditFormValues>, value: boolean) => {
    setValue(field, value);
    trigger(field);
  };

  const onEnabledChange = (value: boolean) => onSwitcherChange('enabled', value);

  const onVisitorScraperChange = (value: boolean) => {
    setValue('query', `Events ${value ? 'for visitors' : ''} in`);
    onSwitcherChange('visitorScraper', value);
  };

  const categoriesRef = useRef<TSelectRef>();

  const categoriesOptions = useMemo(() => {
    const visitorScraper = getValues('visitorScraper') ?? false;

    return visitorScraper
      ? getCategoriesOptions(visitorCategories)
      : getCategoriesOptions(categories);
  }, [getValues('visitorScraper')]);

  const onInstitutionChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('institution', null);
      trigger('institution');
      return;
    }
    setValue('institution', { name: arg?.[0].label, id: arg?.[0].value });
    trigger('institution');
  };

  const onCheckCodesChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('checkCodes', []);
      trigger('checkCodes');
      return;
    }
    setValue(
      'checkCodes',
      arg.map((item) => item.value),
    );
    trigger('checkCodes');
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput label={t('tasks.form.code')} {...register('code')} error={errors.code} />
          <FormSwitcherWithLabel
            isVertical
            testId="TaskEditGoogle#enabled"
            label={t('tasks.form.enabled')}
            initialValue={!!initialValues.enabled}
            onChange={onEnabledChange}
          />
          <FormSelect
            searchable
            clearable
            label={t('tasks.form.institution')}
            error={get(errors, 'institution')}
            options={institutions}
            control={control}
            name={'institution'}
            defaultValue={initialInstitution}
            testId="TasksEdit#institutions"
            onMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
            onSearch={onInstitutionsSearch}
            isLoading={isFetchingInstitutions}
            onChange={onInstitutionChange}
          />
          <FormInput label={t('tasks.form.location')} {...register('url')} error={errors.url} />
          <FormInput label={t('tasks.form.query')} {...register('query')} error={errors.query} />
          <FormInput
            label={t('tasks.form.timeZone')}
            {...register('timeZone')}
            error={errors.timeZone}
          />
          <FormSelect
            label={t('tasks.form.lang')}
            name="lang"
            defaultValue={initialValues.lang}
            options={getLangOptions()}
          />
          <FormSelect
            searchable
            isMulti
            clearable
            label={t('tasks.form.checkCodes')}
            error={get(errors, 'checkCodes')}
            options={checkCodesOptions}
            control={control}
            name={'checkCodes'}
            defaultValue={initialValues.checkCodes.map(
              (code: string) => ({ label: code, value: code } as TSelectItem),
            )}
            testId="TasksEdit#CheckCodes"
            onMenuScrollToBottom={onAllTasksMenuScrollToBottom}
            onSearch={onTasksSearch}
            isLoading={isFetchingTasks}
            onChange={onCheckCodesChange}
          />
          <FormInput
            label={t('tasks.form.cron')}
            {...register('runSchedule')}
            error={errors.runSchedule}
          />
          <FormSectionHeader
            title={t('tasks.form.categories')}
            description={t('tasks.form.categoryDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="TaskEditTwitter#visitorScraper"
            label={t('tasks.form.visitorScraper')}
            initialValue={!!initialValues.visitorScraper}
            onChange={onVisitorScraperChange}
          />
          <FormSelect
            control={control}
            name={'categories'}
            error={get(errors, 'categories')}
            options={categoriesOptions}
            defaultValue={initialValues.categories}
            ref={categoriesRef}
            testId="TaskEdit#categories"
            isMulti
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {task ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default TaskEditGoogle;
