import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import { TasksViewContainer } from '../../components/tasks/view/TasksView.container';

const TaskListPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader title={t('tasks.pageTitle')} caption={t('tasks.pageCaption')} />
      <TasksViewContainer />
    </PageWrapper>
  );
};

export default TaskListPage;
