import { ScrapeItemSelector } from '@sim-admin-frontends/data-access';
import { Button, FormTextarea, Select } from '@sim-admin-frontends/ui-shared';
import { BaseSyntheticEvent, FC, useState } from 'react';
import { FieldPath, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TSelectorEditFormValues } from '../../../types/TSelector';
import { ButtonsWrapper } from '../../common/Formstyles';

const ButtonsNodeWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 20%;
  display: inline-flex;
  justify: flex-end;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

const SelectWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  margin-right: ${({ theme }) => theme.spaces.spacing24};
  width: 75%;
  display: inline-flex;
  justify: flex-start;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

type SingleSelectorProps = {
  selectors: ScrapeItemSelector[];
  setSelectors: React.Dispatch<React.SetStateAction<ScrapeItemSelector[]>>;
  selectorKey: 'nodeSelectors' | 'subpageSelectors';
  onJsonChange: (event: BaseSyntheticEvent, field: FieldPath<TSelectorEditFormValues>) => void;
  setValue: UseFormSetValue<TSelectorEditFormValues>;
  trigger: UseFormTrigger<TSelectorEditFormValues>;
  fieldOptions: string[];
  updateFieldOptions: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any; //Yup errors as any due to the dynamic nature of the form
};

const SingelSelectorEdit: FC<SingleSelectorProps> = ({
  selectors,
  setSelectors,
  selectorKey,
  onJsonChange,
  setValue,
  trigger,
  fieldOptions,
  updateFieldOptions,
  errors,
}) => {
  const { t } = useTranslation();
  const [newField, setNewField] = useState('');
  const addSelectorField = () => {
    selectors.push({
      field: newField,
      selector: [
        {
          collect: [],
          remove: [],
          regex: null,
          replace: [],
        },
      ],
    });
    setSelectors(selectors);
    updateFieldOptions();
    setValue(selectorKey, selectors);
    trigger(selectorKey);
  };

  const removeSelectorField = (index: number) => {
    delete selectors[index];
    setSelectors(selectors);
    updateFieldOptions();
    setValue(selectorKey, selectors);
    trigger(selectorKey);
  };
  //Yup errors as any due to the dynamic nature of the form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const findErrorMessage = (error: any) => {
    if (!error) {
      return undefined;
    }
    if (error?.selector) {
      const selectorErr = error.selector[0];
      if (selectorErr.collect) {
        return selectorErr.collect;
      }
      if (selectorErr.remove) {
        return selectorErr.remove;
      }
      if (selectorErr.regex) {
        return selectorErr.regex;
      }
      if (selectorErr.replace) {
        return selectorErr.replace;
      }
      return selectorErr;
    }
    return error;
  };

  const onFieldChanged = (value: string) => {
    setNewField(value);
  };

  return (
    <div>
      {selectors.map((item, index) => {
        return (
          <div key={index}>
            <FormTextarea
              label={item.field === '' ? t('selectors.form.newNode') : item.field}
              name={item.field}
              onChange={(e) => onJsonChange(e, `${selectorKey}.${index}`)}
              defaultValue={JSON.stringify(item, null, 2)}
              error={findErrorMessage(errors?.[selectorKey]?.[index])}
            />
            <ButtonsWrapper>
              <Button type="button" onClick={() => removeSelectorField(index)} variant="danger">
                {t('selectors.form.removeNode')}
              </Button>
            </ButtonsWrapper>
          </div>
        );
      })}
      <SelectWrapper>
        <Select
          options={fieldOptions.map((field) => {
            return { label: field, value: field };
          })}
          onChange={(value) => onFieldChanged(value?.[0].value || fieldOptions[0])}
          defaultValue={{ value: '', label: '' }}
          searchable={true}
          placeholder={t('selectors.form.selectPlaceholder')}
        />
      </SelectWrapper>
      <ButtonsNodeWrapper>
        <Button type="button" onClick={addSelectorField} variant="secondary">
          {t('selectors.form.addNode')}
        </Button>
      </ButtonsNodeWrapper>
    </div>
  );
};

export default SingelSelectorEdit;
