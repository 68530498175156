import { TExtendedColumn, ClickableCopyText } from '@sim-admin-frontends/ui-shared';
import { TFunction } from 'i18next';
import { DefaultTheme } from 'styled-components';

import { TMappedNotificationItem } from '../../types/TNotifications';
import { getNotificationCellColor } from '../../utils/dashboardUtils';

export const createTableColumns = (t: TFunction, theme: DefaultTheme) => {
  const colors = {
    error: theme.colors.red40,
    warning: theme.colors.yellow50,
    white: theme.colors.white,
  };

  const notificationColumns: TExtendedColumn<TMappedNotificationItem>[] = [
    {
      Header: `${t('dashboard.yesterday')}`,
      id: 'yesterday-notifications',
      accessor: (row) => row.counts?.notifications?.[0]?.count,
      testId: 'DashboardTable#yesterday-notifications',
      disableSortBy: true,
      background: (row, cell) =>
        getNotificationCellColor(cell.value, row.original.counts?.notifications, colors),
    },
    {
      Header: `${t('dashboard.twoDaysAgo')}`,
      id: 'twoDaysAgo-notifications',
      accessor: (row) => row.counts?.notifications?.[1]?.count,
      testId: 'DashboardTable#twoDaysAgo-notifications',
      disableSortBy: true,
    },
    {
      Header: `${t('dashboard.threeDaysAgo')}`,
      id: 'threeDaysAgo-notifications',
      accessor: (row) => row.counts?.notifications?.[2]?.count,
      testId: 'DashboardTable#threeDaysAgo-notifications',
      disableSortBy: true,
    },
  ];

  const userColumns: TExtendedColumn<TMappedNotificationItem>[] = [
    {
      Header: `${t('dashboard.today')}`,
      id: 'today-users',
      accessor: (row) => row.counts?.users?.today,
      testId: 'DashboardTable#today-users',
      disableSortBy: true,
    },
    {
      Header: `${t('dashboard.twoDaysAgo')}`,
      id: 'yesterday-users',
      accessor: (row) => row.counts?.users?.yesterday,
      testId: 'DashboardTable#yesterday-users',
      disableSortBy: true,
    },
    {
      Header: `${t('dashboard.thirtyDays')}`,
      id: 'thirtyDays-users',
      accessor: (row) => row.counts?.users?.thirtyDays,
      testId: 'DashboardTable#thirtyDays-users',
      disableSortBy: true,
    },
  ];

  const columns: TExtendedColumn<TMappedNotificationItem>[] = [
    {
      Header: `${t('dashboard.cityName')}`,
      accessor: 'name',
      testId: 'DashboardTable#name',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.uuid')}`,
      accessor: 'placeUuid',
      testId: 'DashboardTable#placeUuid',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('systemSettings.notifications')}`,
      columns: notificationColumns,
    },
    {
      Header: `${t('users.pageTitle')}`,
      columns: userColumns,
    },
  ];

  return columns;
};
