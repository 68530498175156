import { TTabPanelTabs, TabPanel } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES from '../../routing/routes';
import { TPlaceItinerariesTabTypes } from '../../types/TPlaceItineraries';
import PlaceItinerariesContainer from './PlaceItineraries.container';
import FailedPlaceItinerariesContainer from './FailedPlaceItineraries.container';

const placeItinerariesTabs = [TPlaceItinerariesTabTypes.ALL, TPlaceItinerariesTabTypes.FAILED];

type Props = {
  placeId?: string;
};

const PlaceItinerariesTabs = ({ placeId }: Props) => {
  const { t } = useTranslation();
  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.placeItineraries.path,
    placeItinerariesTabs,
    undefined,
    { placeId },
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: <PlaceItinerariesContainer placeId={placeId} />,
        label: t('places.itineraries.all'),
        testId: 'PlacesItinerariesTabs#all',
      },
      {
        content: <FailedPlaceItinerariesContainer placeId={placeId} />,
        label: t('places.itineraries.failed'),
        testId: 'PlacesItinerariesTabs#failed',
      },
    ],
    [placeId],
  );

  return <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex} />;
};

export default PlaceItinerariesTabs;
