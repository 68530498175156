import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, ToastContainer } from '@sim-admin-frontends/ui-shared';
import { useInitAmplify } from '@sim-admin-frontends/utils-shared';

import Routing from '../routing/Router';
import { UserContextProvider } from '../contexts/userContext';
import { GlobalError } from '../components/global-error/GlobalError';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
    },
  },
});

const App = () => {
  useInitAmplify();
  return (
    <ErrorBoundary fallback={<GlobalError />}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <UserContextProvider>
            <Routing />
            <ToastContainer />
          </UserContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
