import { TInstitutionByTaskCode } from '@sim-admin-frontends/data-access';
import {
  ScrapeContentType,
  ScrapeDataType,
  ScrapeType,
} from '@sim-admin-frontends/data-access-admin-be';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TScrapeCategory = {
  id: string;
  name: string;
};

export type TTaskEditFormValues = {
  code: string;
  enabled: boolean;
  enabledStaging?: boolean | null;
  enabledTesting?: boolean | null;
  url: string;
  JSRender: boolean;
  contentType: ScrapeContentType;
  checkCodes: string[];
  timeZone: string;
  proxy: boolean;
  lang?: TSelectItem;
  runSchedule?: string | null;
  categories?: TSelectItem[];
  dataType: ScrapeDataType;
  scraperType: ScrapeType;
  websiteTemplate?: string | null;
  subpageContentType: ScrapeContentType;
  visitorScraper?: boolean | null;
  query?: string;
  institution?: TInstitutionByTaskCode | null;
  streamingPlatform?: string | null;
};

export const TTaskModalActions = {
  DELETE: 'DELETE',
  RUN: 'RUN',
};
