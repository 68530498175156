import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import LandmarksEditContainer from '../../components/landmarks/edit/LandmarksEdit.container';

type LandmarksEditPageProps = {
  placeId: string;
  id?: string;
};

const LandmarksEditPage = ({ match }: RouteComponentProps<LandmarksEditPageProps>) => {
  const placeId = match.params.placeId;
  const id = match.params.id;
  return (
    <PageWrapper>
      <LandmarksEditContainer placeId={placeId} id={id} />
    </PageWrapper>
  );
};

export default LandmarksEditPage;
