import { Heading1, Modal, Button, FormTextarea } from '@sim-admin-frontends/ui-shared';
import { BaseSyntheticEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MODAL_WIDTH_PX = 1056;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacing24} ${({ theme }) => theme.spaces.spacing16};
`;

const BackButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  width: 33%;
  align-self: center;
`;

const ConfirmButton = styled(Button).attrs({
  variant: 'danger',
})`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  width: 33%;
  align-self: center;
`;

export interface UpdateSelectorProps {
  isOpen: boolean;
  isValid: boolean;
  onBackClick: () => void;
  onConfirmClick: () => void;
  onChange: (e: BaseSyntheticEvent) => void;
}

const UpdateSelectorConfig: FC<UpdateSelectorProps> = ({
  isOpen,
  isValid,
  onBackClick,
  onConfirmClick,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} shouldResize={true} maxWidth={MODAL_WIDTH_PX}>
      <Wrapper>
        <Heading1>{t('selectors.configModal.heading')}</Heading1>
        <FormTextarea name={'imports'} onChange={onChange} />
        <BackButton onClick={onBackClick}>{t('selectors.configModal.cancel')}</BackButton>
        <ConfirmButton onClick={onConfirmClick} disabled={!isValid}>
          {t('selectors.configModal.confirm')}
        </ConfirmButton>
      </Wrapper>
    </Modal>
  );
};

export { UpdateSelectorConfig };
