import { useSaRemoveReminderCategoriesMutation } from '@sim-admin-frontends/data-access';
import {
  ConfirmModal,
  EditIcon,
  loadingToast,
  TableIconProps,
  TableIcons,
  TBaseProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import ROUTES from '../../../routing/routes';

const REMINDER_CATEGORY_TOAST_ID = 'reminderCategory';

interface ReminderCategoriesViewTableMenuProps extends TBaseProps {
  placeId: string;
  refetch: () => void;
  code: string;
}

const ReminderCategoriesViewTableMenu: FC<ReminderCategoriesViewTableMenuProps> = ({
  placeId,
  refetch,
  code,
  testId,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutateAsync: removeReminderCategories } = useSaRemoveReminderCategoriesMutation({
    onMutate: () => {
      loadingToast(t('reminderCategories.deleteToast.loading'), {
        toastId: REMINDER_CATEGORY_TOAST_ID,
      });
    },
    onError: () => {
      updateToast(
        REMINDER_CATEGORY_TOAST_ID,
        t('reminderCategories.deleteToast.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        REMINDER_CATEGORY_TOAST_ID,
        t('reminderCategories.deleteToast.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleConfirmClick = () => {
    removeReminderCategories({ codes: [code] });
    setIsModalVisible(false);
  };

  const editPath = generatePath(ROUTES.reminderCategoriesEdit.path, { placeId, code });

  const tableIcons: TableIconProps[] = [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'PlacesTableMenu#edit',
      icon: <EditIcon />,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'PlacesTableMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  return (
    <TableIcons testId={testId} items={tableIcons}>
      <ConfirmModal
        title={t('reminderCategories.deleteModal.title')}
        content={t('reminderCategories.deleteModal.content')}
        isOpen={isModalVisible}
        onBackClick={handleCancelClick}
        onConfirmClick={handleConfirmClick}
        backButtonText={t('places.deleteModal.cancel')}
        confirmButtonText={t('places.deleteModal.confirm')}
        testId="ReminderCategories#ConfirmModal"
      />
    </TableIcons>
  );
};

export default ReminderCategoriesViewTableMenu;
