import { TableIcons } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateSelectorActions } from '../../../hooks/actionButtons/useGenerateSelectorActions';

export interface SelectorsViewTableMenuProps {
  code: string;
  refetch: () => void;
}

const SelectorsViewTableMenu: FC<SelectorsViewTableMenuProps> = ({ code, refetch }) => {
  const { listActions: items, renderModal } = useGenerateSelectorActions({ code, refetch });

  return <TableIcons items={items}>{renderModal()}</TableIcons>;
};

export { SelectorsViewTableMenu };
