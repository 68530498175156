import { FC } from 'react';
import {
  DateRangePicker,
  Select,
  Table,
  TableActionHeader,
  TDatesRange,
  TExtendedColumn,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { ProcessingStatus, TPaymentTransactions } from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';

export interface PaymentsViewProps {
  data: TPaymentTransactions;
  columns: TExtendedColumn<TPaymentTransactions[number]>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
  tablePageSize: number;
  onDateFilterChange: (filter: TDatesRange) => void;
  initialDateFilterValues: TDatesRange;
  onStatusFilterChange: (option: readonly TSelectItem[] | null) => void;
}

const PaymentsView: FC<PaymentsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  hasNextPage,
  tablePageSize,
  onDateFilterChange,
  initialDateFilterValues,
  onStatusFilterChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableActionHeader
        prependItem={
          <DateRangePicker
            dateFromLabel={t('payments.dateFrom')}
            dateToLabel={t('payments.dateTo')}
            onDatesChanged={onDateFilterChange}
            initialValues={initialDateFilterValues}
            testId="PaymentsView#DatePicker"
          />
        }
      >
        <Select
          options={Object.values(ProcessingStatus).map((value) => ({ label: value, value: value }))}
          onChange={onStatusFilterChange}
          defaultValue={{ label: ProcessingStatus.New, value: ProcessingStatus.New }}
          clearable={false}
          testId="PaymentsView#Select-status"
        />
      </TableActionHeader>
      <Table
        tablePageSize={tablePageSize}
        loading={loading}
        itemsCount={itemsCount}
        pageIndex={pageIndex}
        data={data}
        columns={columns}
        hasPagination={true}
        onNextPress={onNextPress}
        hasNextPage={hasNextPage}
        onPreviousPress={onPreviousPress}
        testId="PaymentsView#Table"
      />
    </>
  );
};

export default PaymentsView;
