import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';

import { TInfluencerSpottViewValues } from '../../../types/TInfluencer';

type Props = {
  data: TInfluencerSpottViewValues[];
  columns: TExtendedColumn<TInfluencerSpottViewValues>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
};

const InfluencerSpotsView = ({
  data,
  columns,
  onNextPress,
  onPreviousPress,
  pageIndex,
  itemsCount,
  loading,
  hasNextPage,
}: Props) => (
  <Table
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    hasNextPage={hasNextPage}
    onPreviousPress={onPreviousPress}
    testId="InfluencerSpotsView#Table"
  />
);

export default InfluencerSpotsView;
