import { useCallback, useState, useMemo } from 'react';
import { useInfiniteSAAllTasksQuery } from '@sim-admin-frontends/data-access-admin-be';

import { MIN_NUMBER_OF_SEARCH_CHARS } from '../constants/Constants';

export const useSearchTaskQuery = () => {
  const [searchTermCode, setSearchTermCode] = useState('');

  const onTaskSearch = useCallback(
    (text: string) => {
      if (text.length < MIN_NUMBER_OF_SEARCH_CHARS) {
        return;
      }
      setSearchTermCode(text);
    },
    [searchTermCode],
  );

  const {
    data: allTasksData,
    isLoading: isLoadingAllTasks,
    isError: isErrorAllTasks,
    fetchNextPage: fetchNextPageAllTasks,
    hasNextPage: hasNextPageAllTasks,
    refetch: refetchAllTasks,
    isFetchingNextPage: isFetchingNextPageAllTasks,
  } = useInfiniteSAAllTasksQuery({ affix: '' });

  const {
    data: searchData,
    isError: isErrorSearch,
    isFetching: isSearchFetching,
    fetchNextPage: fetchNextPageSearchTasks,
    hasNextPage: hasNextPageSearchTasks,
  } = useInfiniteSAAllTasksQuery({ affix: searchTermCode });

  const tasksBySearch = useMemo(
    () => searchData?.pages?.flatMap((page) => page.allScrapeTasks.tasks),
    [searchData, searchTermCode],
  );

  const allTasks = useMemo(
    () => allTasksData?.pages?.flatMap((page) => page.allScrapeTasks.tasks),
    [allTasksData],
  );

  const tasks = searchTermCode ? tasksBySearch : allTasks;
  const fetchNextPageTasks = searchTermCode ? fetchNextPageSearchTasks : fetchNextPageAllTasks;
  const hasNextPageTasks = searchTermCode ? hasNextPageSearchTasks : hasNextPageAllTasks;
  const isFetchingTasks = isLoadingAllTasks || isFetchingNextPageAllTasks || isSearchFetching;
  const isErrorTasks = isErrorAllTasks || isErrorSearch;

  return {
    tasks,
    isLoadingTasks: isLoadingAllTasks,
    isFetchingTasks,
    isErrorTasks,
    refetchTasks: refetchAllTasks,
    fetchNextPageTasks,
    hasNextPageTasks,
    onTaskSearch,
  };
};
