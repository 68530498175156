import { ImageInput, InstitutionPlaceRole, PlaceContent } from '@sim-admin-frontends/data-access';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TAddPlaceFormValues = {
  name: string;
  timezoneCode: string;
  stateName: string;
  stateCode: string;
  countryName: string;
  countryCode: string;
  isDistrict?: boolean;
  hasDistricts?: boolean;
  borough?: string;
  parentPlaceUuid?: TSelectItem;
  gps: {
    lat: number;
    lon: number;
  };
  branding?: {
    logo?: (File | ImageInput)[] | null;
    avatar?: (File | ImageInput)[] | null;
    background?: (File | ImageInput)[] | null;
    topTenBackground?: (File | ImageInput)[] | null;
  } | null;
  aboutHeader?: string | null;
  description?: string | null;
  about?: string | null;
  reportsEmail?: string | null;
  additionalGroupsOfInstitutions?: InstitutionPlaceRole[];
  announcementRange?: number | null;
  eventRange?: number | null;
  centerMapMinDistance?: number | null;
  visitorWelcomeVideoUrl?: string | null;
  visitorMode?: boolean;
  disabledContent?: PlaceContent[];
  localCuisines?: TSelectItem[];
  badgesImage?: (File | ImageInput)[] | null;
  sectionImages?: {
    attractions?: (File | ImageInput)[] | null;
    museums?: (File | ImageInput)[] | null;
    theatre?: (File | ImageInput)[] | null;
    concerts?: (File | ImageInput)[] | null;
    sports?: (File | ImageInput)[] | null;
    cell?: (File | ImageInput)[] | null;
  };
  welcomeScreenImage?: (File | ImageInput)[] | null;
  itineraryMode?: boolean;
  popularDestination?: boolean;
  residentMode?: boolean;
  trafficAvgSpeed?: number;
  isOgCity?: boolean;
};

export enum TPlaceModalActions {
  DELETE = 'DELETE',
}

export enum TPlaceEnablingStatus {
  ENABLED = 'true',
  DISABLED = 'false',
}
