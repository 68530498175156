import { TPaymentTransaction } from '@sim-admin-frontends/data-access';
import { Button, FormInput, FormSectionHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getStripeUrl } from '../../../utils/paymentsUtils';

const Wrapper = styled.div`
  width: 100%;
`;

const RefundPolicy = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const VerticalDivider = styled.div`
  width: ${({ theme }) => theme.spaces.spacing32};
`;

type Props = {
  paymentTransaction: TPaymentTransaction;
};

const PaymentEditRefundSection = ({ paymentTransaction }: Props) => {
  const { t } = useTranslation();
  const { refundDetails, stripePaymentId } = paymentTransaction;

  const onRefundPress = () => {
    if (!stripePaymentId) {
      return;
    }
    window.open(getStripeUrl(stripePaymentId), '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <FormSectionHeader title={t('paymentEdit.refundSection')} />
      {refundDetails?.map((refund, index) => {
        const isPercentage = refund.refundPolicy.type.toUpperCase() === 'PERCENTAGE';
        const valueString = isPercentage
          ? `${refund.refundPolicy.value} %`
          : `${refund.refundPolicy.value} ${refund.currency}`;

        const actualValueString = `${refund.amount} ${refund.currency}`;

        return (
          <Wrapper key={refund.createdAt}>
            <FormInput
              label={t('payments.refundValue')}
              value={actualValueString}
              readOnly
              disabled
              testId={`PaymentEdit#refundValue${index}`}
            />
            <RefundPolicy>
              <FormInput
                label={t('payments.refundPolicyValue')}
                value={valueString}
                readOnly
                disabled
                testId={`PaymentEdit#refundPolicyValue${index}`}
              />
              <VerticalDivider />
              <FormInput
                label={t('payments.refundPolicyUuid')}
                value={refund.refundPolicy.uuid}
                readOnly
                disabled
                testId={`PaymentEdit#refundPolicyUuid${index}`}
              />
            </RefundPolicy>
          </Wrapper>
        );
      })}
      <Wrapper>
        <Button onClick={onRefundPress} testId="PaymentEdit#Refund">
          {t('payments.refund')}
        </Button>
      </Wrapper>
    </>
  );
};

export default PaymentEditRefundSection;
