import { GeoPoint, LandmarkInitials } from '@sim-admin-frontends/data-access';
import {
  ImageInput,
  LandmarkAdditionalType,
  TLandmarkListItem,
} from '@sim-admin-frontends/data-access-admin-be';

export type TLandmarkViewValues = TLandmarkListItem;

export enum TLandmarkModalActions {
  DELETE = 'DELETE',
}

export type TLandmarkInfo = {
  id: string;
  title: string;
  additionalType?: LandmarkAdditionalType[] | null;
  rating?: number | null;
  userRatingCount?: number | null;
  googlePlaceId?: string;
  gpsLocation?: GeoPoint | null;
  imageObjects?: (File | ImageInput)[] | null;
  initials: LandmarkInitials;
  isHiddenGem?: boolean | null;
  landmarkName?: string | null;
  primaryType?: string | null;
  types?: string[] | null;
  placeUuid?: string | null;
  description?: string | null;
};

export type TLandmarkEditFormValues = {
  id: string;
  title: string;
  additionalType?: LandmarkAdditionalType[];
  rating?: number;
  userRatingCount?: number;
  googlePlacesId?: string;
  gpsLocation?: GeoPoint;
  imageObjects?: (File | ImageInput)[] | null;
  initials: LandmarkInitials;
  isHiddenGem?: boolean;
  landmarkName?: string;
  primaryType?: string;
  types?: string[];
  description?: string;
};
