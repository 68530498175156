import React, { BaseSyntheticEvent, FC, useMemo, useState } from 'react';
import { FieldPath, FormProvider, get, useForm } from 'react-hook-form';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormWrapper,
  FormTextarea,
  MediumText,
  TToastType,
  toast,
  FormSelect,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { TSASelectorDetail } from '@sim-admin-frontends/data-access-admin-be';
import { PaginationType } from '@sim-admin-frontends/data-access';

import { TSelectorEditFormValues } from '../../../types/TSelector';
import SingelSelectorEdit from './SingleSelectorEdit';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { UpdateSelectorConfig } from './UpdateSelectorConfig';
import parseExtensionInput from './parser';
import { schema } from './helper';

const eventFields = [
  'title',
  'image',
  'content_html',
  'perex',
  'date_from',
  'time_from',
  'date_to',
  'time_to',
  'location',
  'price',
  'currency',
  'market_url',
];
const newsFields = ['title', 'image', 'content_html', 'perex', 'date_published', 'time_published'];

type SelectorEditProps = {
  onSubmit: (values: TSelectorEditFormValues) => Promise<void>;
  selector: TSASelectorDetail;
};

const singleSelector = [
  {
    collect: [],
    remove: [],
    regex: null,
    replace: [],
  },
];
const SelectorEdit: FC<SelectorEditProps> = ({ onSubmit, selector }) => {
  const { t } = useTranslation();

  const initialValues: TSelectorEditFormValues = {
    code: selector?.code || '',
    imports: selector?.imports || [],
    variables:
      selector?.variables === undefined || selector.variables.length === 0
        ? [
            {
              key: '',
              value: '',
            },
          ]
        : selector.variables,
    nextPageSelector:
      selector?.nextPageSelector === undefined || selector.nextPageSelector.length === 0
        ? singleSelector
        : selector.nextPageSelector,
    maxPages: selector?.maxPages || 0,
    removePageTrash: selector?.removePageTrash || [],
    nodeSelector: selector?.nodeSelector || [],
    nodeSelectors: selector?.nodeSelectors || [],
    subpageSelector:
      selector?.subpageSelector === undefined || selector.subpageSelector.length === 0
        ? singleSelector
        : selector.subpageSelector,
    subpageUrlFormat: selector?.subpageUrlFormat || null,
    subpageSelectors: selector?.subpageSelectors || [],
    originalSourceOverride: selector?.originalSourceOverride || null,
    paginationType: selector?.paginationType || PaginationType.Pages,
  };

  const methods = useForm<TSelectorEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, newsFields.concat(eventFields))),
  });

  const { handleSubmit, formState, setValue, trigger, register, getValues } = methods;
  const { errors, isSubmitting } = formState;

  const onListChange = (event: BaseSyntheticEvent, field: FieldPath<TSelectorEditFormValues>) => {
    const text = event.target.value;
    const parsedList = text.split('\n');
    const transformedParsedList = parsedList.filter((val: string) => !!val.length);
    setValue(field, transformedParsedList);
    trigger(field);
  };

  const onJsonChange = (event: BaseSyntheticEvent, field: FieldPath<TSelectorEditFormValues>) => {
    const text = event.target.value;
    try {
      const parsedJson = JSON.parse(text);
      setValue(field, parsedJson);
      trigger(field);
    } catch (e) {
      setValue(field, text);
      trigger(field);
    }
  };

  const getSelectorFields = () => {
    const code = getValues('code');
    if (code?.endsWith('news')) {
      return newsFields;
    } else if (code?.endsWith('events')) {
      return eventFields;
    } else {
      return [...new Set(eventFields.concat(newsFields))];
    }
  };
  const [subpageSelectors, setSubpageSelectors] = useState(initialValues.subpageSelectors);
  const [nodeSelectors, setNodeSelectors] = useState(initialValues.nodeSelectors);
  const [fieldOptions, setFieldOptions] = useState(
    getSelectorFields().filter(
      (field) =>
        !subpageSelectors
          .concat(nodeSelectors)
          .map((sel) => sel.field)
          .includes(field),
    ),
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configSelector, setConfigSelector] = useState(Object);
  const [isValidJson, setIsValidJson] = useState(true);
  const [nodeSelectorInput, setNodeSelectorInput] = useState(initialValues.nodeSelector.join('\n'));
  const [nextPageSelectorInput, setNextPageSelectorInput] = useState(
    JSON.stringify(initialValues.nextPageSelector, null, 2),
  );
  const [subpageSelectorInput, setSubpageSelectorInput] = useState(
    JSON.stringify(initialValues.subpageSelector, null, 2),
  );

  const updateFieldOptions = () => {
    setFieldOptions(
      getSelectorFields().filter(
        (field) =>
          !subpageSelectors
            .concat(nodeSelectors)
            .map((sel) => sel.field)
            .includes(field),
      ),
    );
  };

  const onConfigSelectorChanged = (event: BaseSyntheticEvent) => {
    const text = event.target.value;
    try {
      const parsedJson = JSON.parse(text);
      setIsValidJson(true);
      setConfigSelector(parsedJson);
    } catch (e) {
      setIsValidJson(false);
    }
  };

  const onConfirmClick = () => {
    try {
      if (!configSelector) {
        setIsModalVisible(false);
        return;
      }
      const parsedConfig = parseExtensionInput(configSelector);
      setValue('code', parsedConfig.code);
      setValue('maxPages', parsedConfig.maxPages || 0);

      setValue('nodeSelector', parsedConfig.nodeSelector || []);
      setNodeSelectorInput(getValues('nodeSelector').join('\n'));

      setValue('nextPageSelector', parsedConfig.nextPageSelector || singleSelector);
      setNextPageSelectorInput(JSON.stringify(getValues('nextPageSelector'), null, 2));

      setValue('nodeSelectors', parsedConfig.nodeSelectors || []);
      setNodeSelectors(getValues('nodeSelectors'));

      setValue('subpageSelector', parsedConfig.subpageSelector || singleSelector);
      setSubpageSelectorInput(JSON.stringify(getValues('subpageSelector'), null, 2));

      setValue('subpageSelectors', parsedConfig.subpageSelectors || []);
      setSubpageSelectors(getValues('subpageSelectors'));
      updateFieldOptions();
    } catch (e) {
      const error = e as Error;
      toast(error.message, TToastType.ERROR);
    }
    setIsModalVisible(false);
  };

  const onBackClick = () => {
    setIsModalVisible(false);
  };

  const modalClick = () => {
    setIsModalVisible(true);
  };

  const paginationTypeOptions = useMemo(
    () =>
      Object.values(PaginationType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const onPaginationTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as PaginationType;
    setValue('paginationType', value);
    trigger('paginationType');
  };

  const initalPaginationType = useMemo(() => {
    const opt = paginationTypeOptions.find(
      (typeOption) => typeOption.value === initialValues?.paginationType,
    );
    if (opt) {
      return opt;
    }
    return {
      value: PaginationType.Pages,
      label: PaginationType.Pages,
    };
  }, []);

  const onChangeSet = (
    event: BaseSyntheticEvent,
    field: FieldPath<TSelectorEditFormValues>,
    func: (event: BaseSyntheticEvent, field: FieldPath<TSelectorEditFormValues>) => void,
    setFunc: (value: React.SetStateAction<string>) => void,
  ) => {
    func(event, field);
    setFunc(event.target.value);
  };

  return (
    <Wrapper>
      <ButtonsWrapper>
        <Button type="button" onClick={modalClick} variant="primary">
          {t('selectors.modal.configuration')}
        </Button>
      </ButtonsWrapper>
      <UpdateSelectorConfig
        isOpen={isModalVisible}
        isValid={isValidJson}
        onConfirmClick={onConfirmClick}
        onBackClick={onBackClick}
        onChange={onConfigSelectorChanged}
      />
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput label={t('selectors.form.code')} {...register('code')} error={errors.code} />
          <FormTextarea
            label={t('selectors.form.imports')}
            name={'imports'}
            onChange={(e) => onListChange(e, 'imports')}
            defaultValue={initialValues.imports.join('\n')}
          />
          <FormTextarea
            label={t('selectors.form.variables')}
            name={'variables'}
            onChange={(e) => onJsonChange(e, 'variables')}
            defaultValue={JSON.stringify(initialValues.variables, null, 2)}
            error={get(errors, 'variables')}
          />
          <FormInput
            label={t('selectors.form.maxPages')}
            {...register('maxPages')}
            error={errors.maxPages}
          />
          <FormTextarea
            label={t('selectors.form.removePageTrash')}
            name={'removePageTrash'}
            onChange={(e) => onListChange(e, 'removePageTrash')}
            defaultValue={initialValues.imports.join('\n')}
            error={get(errors, 'removePageTrash')}
          />
          <FormTextarea
            label={t('selectors.form.nodeSelector')}
            name={'nodeSelector'}
            onChange={(e) => {
              onChangeSet(e, 'nodeSelector', onListChange, setNodeSelectorInput);
            }}
            value={nodeSelectorInput}
            error={get(errors, 'nodeSelector')}
          />
          <MediumText>{t('selectors.form.nodeSelectors')}</MediumText>
          <SingelSelectorEdit
            onJsonChange={onJsonChange}
            selectorKey="nodeSelectors"
            selectors={nodeSelectors}
            setSelectors={setNodeSelectors}
            setValue={setValue}
            trigger={trigger}
            fieldOptions={fieldOptions}
            updateFieldOptions={updateFieldOptions}
            errors={errors}
          />
          <FormSelect
            name="paginationType"
            label={t('selectors.form.paginationType')}
            options={paginationTypeOptions}
            defaultValue={initalPaginationType}
            error={errors.paginationType}
            onChange={onPaginationTypeChange}
          />
          <FormTextarea
            label={t('selectors.form.nextPageSelector')}
            name={'nextPageSelector'}
            onChange={(e) => {
              onChangeSet(e, 'nextPageSelector', onJsonChange, setNextPageSelectorInput);
            }}
            value={nextPageSelectorInput}
            error={get(errors, 'nextPageSelector')}
          />
          <FormTextarea
            label={t('selectors.form.subpageSelector')}
            name={'subpageSelector'}
            onChange={(e) => {
              onChangeSet(e, 'subpageSelector', onJsonChange, setSubpageSelectorInput);
            }}
            value={subpageSelectorInput}
            error={get(errors, 'subpageSelector')}
          />
          <FormInput
            label={t('selectors.form.subpageUrlFormat')}
            {...register('subpageUrlFormat')}
            error={errors.subpageUrlFormat}
          />
          <FormInput
            label={t('selectors.form.originalSourceOverride')}
            {...register('originalSourceOverride')}
            error={errors.originalSourceOverride}
          />
          <MediumText>{t('selectors.form.subpageSelectors')}</MediumText>
          <SingelSelectorEdit
            onJsonChange={onJsonChange}
            selectorKey="subpageSelectors"
            selectors={subpageSelectors}
            setSelectors={setSubpageSelectors}
            setValue={setValue}
            trigger={trigger}
            fieldOptions={fieldOptions}
            updateFieldOptions={updateFieldOptions}
            errors={errors}
          />

          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {selector ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default SelectorEdit;
