import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Error,
  Spinner,
  SpinnerWrapper,
  TableActionHeader,
} from '@sim-admin-frontends/ui-shared';
import {
  useInfiniteAllUsersQuery,
  useSaAllInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import { useTablePaging, getInstitutionInitValues } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { UsersView } from './UsersView';
import { TUserViewValues } from '../../../types/TUser';

const INSTITUTION_PAGE_SIZE = 100;
const USERS_PAGE_SIZE = 60;

type UsersViewContainerProps = {
  institutionId?: string;
  placeId?: string;
};

const UsersViewContainer: FC<UsersViewContainerProps> = ({ institutionId, placeId }) => {
  const { t } = useTranslation();

  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteAllUsersQuery({
      group: 'Admin',
      limit: USERS_PAGE_SIZE,
      institutionUuid: institutionId,
      placeUuid: placeId,
    });

  const {
    data: allInstitutions,
    isLoading: isLoadingInstitutions,
    isError: isErrorInstitutions,
  } = useSaAllInstitutionsQuery({
    pageSize: INSTITUTION_PAGE_SIZE,
  });

  const columns = createTableColumns(t, refetch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  if (isLoading || isLoadingInstitutions) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError || isErrorInstitutions) {
    return (
      <>
        <TableActionHeader>
          <Link to={generatePath(ROUTES.userEdit.path)}>
            <Button>{t('users.addUser')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }
  const itemsCount = data?.pages?.[0].adminUsers?.users?.length || 0;
  const users = data?.pages?.[currentPageIndex]?.adminUsers?.users || [];
  const hasNextToken = !!data?.pages[currentPageIndex].adminUsers.nextToken;
  const institutions = allInstitutions?.allInstitutions?.institutions;

  const userData: TUserViewValues[] = users.map((user) => ({
    ...user,
    institutions:
      getInstitutionInitValues(user.institutionUuids, user.institution, institutions) || [],
  }));

  return (
    <>
      <TableActionHeader>
        <Link
          to={
            institutionId
              ? generatePath(ROUTES.institutionUserEdit.path, {
                  institutionId: institutionId,
                })
              : generatePath(ROUTES.userEdit.path)
          }
        >
          <Button testId="UsersTabs#AddUser">
            {institutionId ? t('users.addUserToInstitution') : t('users.addUser')}
          </Button>
        </Link>
      </TableActionHeader>
      <UsersView
        tablePageSize={USERS_PAGE_SIZE}
        loading={isLoading || isFetchingNextPage}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        hasNextPage={hasNextToken ?? (hasNextPage || false)}
        columns={columns}
        data={userData || []}
      />
    </>
  );
};

export { UsersViewContainer };
