import { FC, useMemo, useState } from 'react';
import {
  useInfiniteSAInstitutionsBySearchQuery,
  useSendSaSendNotificationMutation,
} from '@sim-admin-frontends/data-access';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';
import {
  getErrorMessage,
  loadingToast,
  TSelectItem,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { TNotificationsEditValues } from '../../../types/TNotifications';
import NotificationsEdit from './NotificationsEdit';
import { transformPlaces } from '../../../utils/placeUtils';
import { transformInstitutions } from '../../../utils/institutionUtils';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';
import { SHARED_TOAST_ID } from '../view/NotificationsTabs';

const NotificationsEditContainer: FC = () => {
  const { t } = useTranslation();
  const [selectedPlaceId, setSelectedPlaceId] = useState('');
  const { onSearch: onPlacesSearch, searchTerm: searchTermPlaces } = useSearchTerm(
    MIN_NUMBER_OF_SEARCH_CHARS,
  );
  const { onSearch: onInstitutionsSearch, searchTerm: searchTermInstitutions } = useSearchTerm(
    MIN_NUMBER_OF_SEARCH_CHARS,
  );

  const {
    data: placesData,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
    isLoading: isLoadingPlaces,
  } = useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTermPlaces });

  const {
    data: institutionsData,
    fetchNextPage: fetchNextPageInstitutions,
    hasNextPage: hasNextPageInstitutions,
    isLoading: isLoadingInstitutions,
  } = useInfiniteSAInstitutionsBySearchQuery(
    { placeUuid: selectedPlaceId, text: searchTermInstitutions },
    !!selectedPlaceId,
  );

  const { mutateAsync: sendNotification } = useSendSaSendNotificationMutation();

  const places = useMemo(
    () => placesData?.pages.flatMap((page) => transformPlaces(page.places.places)) ?? [],
    [placesData],
  );

  const institutions = useMemo(
    () =>
      institutionsData?.pages.flatMap((page) =>
        transformInstitutions(page.institutionsBySearch.institutions),
      ) ?? [],
    [institutionsData],
  );

  const onPlacesScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const onInstitutionsScrollToBottom = () => {
    if (hasNextPageInstitutions) {
      fetchNextPageInstitutions();
    }
  };

  const onPlaceSelect = (selectedItem: readonly TSelectItem[] | null) => {
    if (!selectedItem) {
      return;
    }
    setSelectedPlaceId(selectedItem[0].value);
  };

  const onSubmit = async (values: TNotificationsEditValues) => {
    loadingToast(t('notifications.toastSubmitting'), {
      toastId: SHARED_TOAST_ID,
    });
    try {
      await sendNotification({
        message: {
          title: values.title,
          body: values.body,
          notifyAlsoNonCategoried: true,
          channel: values.channel.value,
          deeplink: values.deeplink || undefined,
          postUuid: values.postId || undefined,
        },
        filter: { institutionUuid: values.institution.value },
      });
      updateToast(SHARED_TOAST_ID, t('notifications.toastSuccess'), TToastType.SUCCESS);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  return (
    <NotificationsEdit
      onPlacesSearch={onPlacesSearch}
      onPlacesScrollToBottom={onPlacesScrollToBottom}
      isFetchingPlaces={isLoadingPlaces}
      onPlaceSelect={onPlaceSelect}
      places={places}
      onInstitutionsSearch={onInstitutionsSearch}
      onInstitutionsScrollToBottom={onInstitutionsScrollToBottom}
      isFetchingInstitutions={isLoadingInstitutions}
      institutions={institutions}
      onSubmit={onSubmit}
    />
  );
};

export default NotificationsEditContainer;
