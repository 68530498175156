import {
  InstitutionFeatureFlag,
  InstitutionFeatureFlagEnum,
  PlaceFeatureFlag,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';

import { TInstitutionFeatureFlagValues } from '../types/TFeatureFlags';
import { cloneFlagWithoutIcon, transformTitleLocalizationsForBE } from './featureFlagsUtils';

export const appendValuesFields = (
  values: TInstitutionFeatureFlagValues,
  iconImage?: TUploadedFile[],
) => {
  const type = values.type.label;
  return {
    ...values,
    icon: prepareFileObjects(iconImage)[0],
    type: type as InstitutionFeatureFlagEnum,
    titleLocalizations: values.titleLocalizations
      ? transformTitleLocalizationsForBE(values.titleLocalizations)
      : undefined,
  };
};

export const transformFeatureFlagsValues = (
  featureFlags: InstitutionFeatureFlag[],
  values: TInstitutionFeatureFlagValues,
  iconImage?: TUploadedFile[],
  featureIndex?: string,
) => {
  if (featureIndex) {
    const prevFlags: InstitutionFeatureFlag[] = featureFlags.map((flag, index) => {
      if (index === Number(featureIndex)) {
        return appendValuesFields(values, iconImage);
      }
      return { ...cloneFlagWithoutIcon(flag) };
    });

    return { newFlag: null, prevFlags };
  }
  const newFlag: InstitutionFeatureFlag = appendValuesFields(values, iconImage);
  const prevFlags: InstitutionFeatureFlag[] = featureFlags.map((flag) =>
    cloneFlagWithoutIcon(flag),
  );
  return { newFlag, prevFlags };
};

export const compareFlagOrder = (a: PlaceFeatureFlag, b: PlaceFeatureFlag) => {
  const aOrder = a.order;
  const bOrder = b.order;
  if ((aOrder ?? 0) < (bOrder ?? 0)) {
    return -1;
  }
  if ((aOrder ?? 0) > (bOrder ?? 0)) {
    return 1;
  }
  return 0;
};
