import { TFunction } from 'i18next';
import { InfoIcon, TDatesRange, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import {
  PaymentTransactionsByProcessingStatusFilterInput,
  PaymentType,
  TPaymentData,
  TPaymentsData,
  TPaymentTransaction,
  TPaymentTransactions,
} from '@sim-admin-frontends/data-access';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';

import PaymentsTableMenu from '../components/payments/view/PaymentsTableMenu';
import {
  STRIPE_PAYMENTS_DASHBOARD,
  STRIPE_PAYMENTS_DASHBOARD_TESTING,
} from '../constants/Constants';
import { HeaderItem, HeaderWrapper, StyledTippy } from '../components/common/TableStyles';

export const createTableColumns = (t: TFunction) => {
  const columns: TExtendedColumn<TPaymentTransactions[number]>[] = [
    {
      Header: `${t('payments.createdAt')}`,
      accessor: 'createdAt',
      Cell: ({ value }) => formatTableDateTime(value),
      disableSortBy: true,
    },
    {
      Header: `${t('payments.receiver')}`,
      accessor: 'receiver',
      disableSortBy: true,
      Cell: ({ row, value }) => value || row.original.paymentType,
    },
    {
      Header: () => (
        <HeaderWrapper>
          <HeaderItem>{t('payments.id')}</HeaderItem>
          <HeaderItem>
            <StyledTippy
              arrow={false}
              placement="right-start"
              content={`${t('payments.citationNumber')} / ${t('payments.postId')} / ${t(
                'payments.activityId',
              )}`}
            >
              <InfoIcon />
            </StyledTippy>
          </HeaderItem>
        </HeaderWrapper>
      ),
      id: 'citationNumber',
      Cell: ({ row }: CellProps<TPaymentTransaction>) =>
        row.original.marketPaymentDetails?.activityUuid ??
        row.original.citationNumber ??
        row.original.postId,
      disableSortBy: true,
    },
    {
      Header: `${t('payments.amount')}`,
      Cell: ({ row }: CellProps<TPaymentTransaction>) =>
        `${row.original.amount} ${row.original.currency}`,
      disableSortBy: true,
    },
    {
      Header: `${t('payments.paymentStatus')}`,
      accessor: 'paymentStatus',
      disableSortBy: true,
    },
    {
      Header: `${t('payments.paidAt')}`,
      accessor: 'paidAt',
      Cell: ({ value }) => (value ? formatTableDateTime(value) : ''),
      disableSortBy: true,
    },
    {
      Header: `${t('payments.processingStatus')}`,
      accessor: 'processingStatus',
      disableSortBy: true,
    },
    {
      Header: `${t('payments.paymentType')}`,
      accessor: 'paymentType',
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TPaymentTransaction>) => {
        return <PaymentsTableMenu id={row.original.id} />;
      },
    },
  ];
  return columns;
};

export const getPaymentsData = (paymentsData: TPaymentsData): TPaymentTransactions =>
  paymentsData.map((payment) => {
    const {
      id,
      stripePaymentId,
      paymentStatus,
      paymentDetails,
      currency,
      installation,
      createdAt,
      processingStatus,
      paidAt,
      amount,
      paymentType,
      receiver,
      marketItem,
      metadata,
    } = payment;
    return {
      id: id,
      stripePaymentId: stripePaymentId ?? undefined,
      paymentStatus: paymentStatus,
      licenseNumber: paymentDetails?.licenseNumber ?? '',
      stateCode: paymentDetails?.stateCode ?? undefined,
      citationNumber: paymentDetails?.citationNumber ?? '',
      issueDate: paymentDetails?.issueDate ?? '',
      createdAt: createdAt,
      installationUuid: installation?.id ?? '',
      processingStatus: processingStatus,
      paidAt: paidAt ?? '',
      currency: currency,
      amount: amount,
      paymentType: paymentType ?? PaymentType.Ticket,
      postId: paymentDetails?.postId ?? '',
      receiver: receiver ?? '',
      marketItem,
      metadata: metadata ?? undefined,
      simplicityPlus: payment.simplicityPlus ?? false,
      marketPaymentDetails: payment.marketPaymentDetails ?? undefined,
    };
  });

export const getPaymentData = (paymentData: TPaymentData): TPaymentTransaction | null => {
  if (!paymentData) {
    return null;
  }
  const {
    stripePaymentId,
    paymentDetails,
    installation,
    paidAt,
    document,
    paymentType,
    receiver,
    marketItem,
    metadata,
  } = paymentData;

  return {
    ...paymentData,
    stripePaymentId: stripePaymentId ?? undefined,
    paidAt: paidAt ?? '',
    installationUuid: installation?.id ?? '',
    licenseNumber: paymentDetails?.licenseNumber ?? '',
    stateCode: paymentDetails?.stateCode ?? undefined,
    citationNumber: paymentDetails?.citationNumber ?? '',
    issueDate: paymentDetails?.issueDate ?? '',
    image: document?.links?.self,
    paymentType: paymentType ?? PaymentType.Ticket,
    postId: paymentDetails?.postId ?? '',
    receiver: receiver ?? '',
    marketItem,
    metadata: metadata ?? undefined,
    orderUuid: paymentDetails?.orderUuid ?? undefined,
    simplicityPlus: paymentData.simplicityPlus ?? false,
    marketPaymentDetails: paymentData.marketPaymentDetails ?? undefined,
    refundDetails: paymentData.refundDetails ?? undefined,
  };
};

export const transformDateRangeValues = (
  values: PaymentTransactionsByProcessingStatusFilterInput,
): TDatesRange => ({
  dateFrom: values.datetimeFrom ? new Date(values.datetimeFrom) : new Date(),
  dateTo: values.datetimeTo ? new Date(values.datetimeTo) : new Date(),
});

export const getStripeUrl = (stripePaymentId: string) => {
  if (process.env.NX_ENVIRONMENT === 'production') {
    return `${STRIPE_PAYMENTS_DASHBOARD}/${stripePaymentId}`;
  }

  return `${STRIPE_PAYMENTS_DASHBOARD_TESTING}/${stripePaymentId}`;
};
