import React, { FC } from 'react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { getLangOptionByVal, getLangOptions, isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormWrapper,
  FormSwitcherWithLabel,
  FormSelect,
  TSelectItems,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ScrapeContentType,
  ScrapeDataType,
  ScrapeType,
  TSATaskDetail,
} from '@sim-admin-frontends/data-access-admin-be';

import { TTaskEditFormValues } from '../../../types/TTask';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';

const cronExpression = '* 0 * * 0';

const getUrlValue = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }
  const yelpUrlRegex = /location=(.*)/;
  const extractedValue = value.match(yelpUrlRegex);
  if (extractedValue === null) {
    return value;
  }
  return extractedValue[1].split('%20').join(' ');
};

const regexTask = /\w+_(\w+-)?\w+_(\w+-)?(yelp)/;
const schema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .label(t('form.fieldRequired'))
      .matches(
        regexTask,
        t('tasks.form.codeMatch') + '{state}_{area}-{city or institution}_{identifier}-{yelp}',
      )
      .trim(),
    enabled: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    url: Yup.string().required(t('form.fieldRequired')).trim(),
    JSRender: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    proxy: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    contentType: Yup.string()
      .oneOf(Object.values(ScrapeContentType))
      .required(t('form.fieldRequired')),
    dataType: Yup.string().oneOf(Object.values(ScrapeDataType)).required(t('form.fieldRequired')),
    scraperType: Yup.string().oneOf(Object.values(ScrapeType)).required(t('form.fieldRequired')),
    websiteTemplate: Yup.string().nullable().trim(),
    timeZone: Yup.string().required(t('form.fieldRequired')).trim(),
    checkCodes: Yup.array().of(Yup.string().trim()),
  });
};

type TaskEditProps = {
  onSubmit: (values: TTaskEditFormValues) => Promise<void>;
  task: TSATaskDetail;
  institutions: TSelectItems;
  initialInstitution?: TSelectItem;
  onAllInstitutionsMenuScrollToBottom?: (e: Event) => void;
  onInstitutionsSearch?: (text: string) => void;
  isFetchingInstitutions?: boolean;
};

const TaskEditYelp: FC<TaskEditProps> = ({
  onSubmit,
  task,
  institutions,
  initialInstitution,
  onAllInstitutionsMenuScrollToBottom,
  onInstitutionsSearch,
  isFetchingInstitutions,
}) => {
  const { t } = useTranslation();

  const initialValues: TTaskEditFormValues = {
    code: task?.code || '',
    enabled: task?.enabled || false,
    enabledStaging: task?.enabledStaging || false,
    enabledTesting: task?.enabledTesting || false,
    url: getUrlValue(task?.url),
    JSRender: task?.JSRender || false,
    proxy: task?.proxy || false,
    contentType: task?.contentType || ScrapeContentType.Json,
    timeZone: task?.timeZone || 'UTC',
    checkCodes: [],
    lang: getLangOptionByVal(task?.lang),
    dataType: task?.dataType || ScrapeDataType.News,
    scraperType: task?.scraperType || ScrapeType.Yelp,
    websiteTemplate: task?.websiteTemplate || null,
    runSchedule: task?.runSchedule || cronExpression,
    subpageContentType: task?.subpageContentType || ScrapeContentType.Json,
    institution: null,
  };

  const methods = useForm<TTaskEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, setValue, register, control, trigger } = methods;
  const { errors, isSubmitting } = formState;

  const onSwitcherChange = (value: boolean) => {
    setValue('enabled', value);
  };

  const onInstitutionChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('institution', null);
      trigger('institution');
      return;
    }
    setValue('institution', { name: arg?.[0].label, id: arg?.[0].value });
    trigger('institution');
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput label={t('tasks.form.code')} {...register('code')} error={errors.code} />
          <FormSwitcherWithLabel
            isVertical
            testId="TaskEditYelp#enabled"
            label={t('tasks.form.enabled')}
            initialValue={!!initialValues.enabled}
            onChange={onSwitcherChange}
          />
          <FormSelect
            searchable
            clearable
            label={t('tasks.form.institution')}
            error={get(errors, 'institution')}
            options={institutions}
            control={control}
            name={'institution'}
            defaultValue={initialInstitution}
            testId="TasksEdit#institutions"
            onMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
            onSearch={onInstitutionsSearch}
            isLoading={isFetchingInstitutions}
            onChange={onInstitutionChange}
          />
          <FormInput label={t('tasks.form.location')} {...register('url')} error={errors.url} />
          <FormSelect
            label={t('tasks.form.lang')}
            name="lang"
            defaultValue={initialValues.lang}
            options={getLangOptions()}
          />
          <FormInput
            label={t('tasks.form.cron')}
            {...register('runSchedule')}
            error={errors.runSchedule}
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {task ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default TaskEditYelp;
