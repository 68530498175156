import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateInfluencerActions } from '../../../hooks/actionButtons/useGenerateInfluencerActions';

export interface Props extends TBaseProps {
  id: string;
  editPath: string;
  listPath: string;
  refetch: () => void;
  influencerId?: string;
  deleteMutation: ({ id }: { id: string }) => void;
}

const InfluencersViewTableMenu: FC<Props> = ({
  id,
  editPath,
  listPath,
  refetch,
  influencerId,
  deleteMutation,
  testId,
}) => {
  const { listActions: items, renderModal } = useGenerateInfluencerActions({
    id,
    refetch,
    editPath,
    listPath,
    influencerId,
    deleteMutation,
  });
  return (
    <TableIcons items={items} testId={testId}>
      {renderModal()}
    </TableIcons>
  );
};

export default InfluencersViewTableMenu;
