import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGeneratePaymentActions } from '../../../hooks/actionButtons/useGeneratePaymentActions';

interface PaymentsTableMenuProps extends TBaseProps {
  id: string;
}

const PaymentsTableMenu: FC<PaymentsTableMenuProps> = ({ testId, id }) => {
  const { listActions: items } = useGeneratePaymentActions({ id });
  return <TableIcons items={items} testId={testId} />;
};

export default PaymentsTableMenu;
