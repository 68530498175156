import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import ReminderCategoriesEditContainer from '../../components/reminderCategories/edit/ReminderCategoriesEdit.container';

type ReminderCategoriesEditPageProps = {
  placeId: string;
  code: string;
};

const ReminderCategoriesEditPage: FC<RouteComponentProps<ReminderCategoriesEditPageProps>> = ({
  match,
}) => {
  const { t } = useTranslation();
  const { placeId, code } = match.params;
  return (
    <PageWrapper>
      <PageHeader
        title={code ? t('reminderCategories.updateTitle') : t('reminderCategories.createTitle')}
        caption={
          code ? t('reminderCategories.updateCaption') : t('reminderCategories.createCaption')
        }
        testId="FeatureFlagEdit#PageHeader"
      />
      <ReminderCategoriesEditContainer placeId={placeId} code={code} />
    </PageWrapper>
  );
};

export default ReminderCategoriesEditPage;
