import { TFunction } from 'i18next';
import { ScrapeTaskInput, TSATaskListItem } from '@sim-admin-frontends/data-access-admin-be';
import { Switcher, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps, Row } from 'react-table';

import { TasksViewTableMenu } from './TasksViewTableMenu';

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  onSwitch: (value: ScrapeTaskInput, changedValue: boolean) => void,
) => {
  const onEnabledChange = (value: boolean, row: Row<TSATaskListItem>) => {
    row.original.enabled = value;
    onSwitch({ ...row.original }, value);
  };
  const onEnabledStagingChange = (value: boolean, row: Row<TSATaskListItem>) => {
    row.original.enabledStaging = value;
    onSwitch({ ...row.original }, value);
  };
  const onEnabledTestingChange = (value: boolean, row: Row<TSATaskListItem>) => {
    row.original.enabledTesting = value;
    onSwitch({ ...row.original }, value);
  };

  const columns: TExtendedColumn<TSATaskListItem>[] = [
    {
      Header: `${t('tasks.columns.code')}`,
      accessor: 'code',
      disableSortBy: true,
    },
    {
      Header: `${t('tasks.columns.enabled')}`,
      id: '1',
      Cell: ({ row }: CellProps<TSATaskListItem>) => {
        return (
          <Switcher
            value={row.original.enabled}
            onChange={(value: boolean) => onEnabledChange(value, row)}
          />
        );
      },
      disableSortBy: true,
    },
    {
      Header: `${t('tasks.columns.enabledStaging')}`,
      id: '2',
      Cell: ({ row }: CellProps<TSATaskListItem>) => {
        return (
          <Switcher
            value={row.original.enabledStaging || false}
            onChange={(value: boolean) => onEnabledStagingChange(value, row)}
          />
        );
      },
      disableSortBy: true,
    },
    {
      Header: `${t('tasks.columns.enabledTesting')}`,
      id: '3',
      Cell: ({ row }: CellProps<TSATaskListItem>) => {
        return (
          <Switcher
            value={row.original.enabledTesting || false}
            onChange={(value: boolean) => onEnabledTestingChange(value, row)}
          />
        );
      },
      disableSortBy: true,
    },
    {
      Header: `${t('tasks.columns.url')}`,
      accessor: 'url',
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TSATaskListItem>) => {
        return <TasksViewTableMenu refetch={refetch} code={row.original.code} />;
      },
    },
  ];
  return columns;
};
