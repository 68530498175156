import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import TaskEditContainer from '../../components/tasks/edit/TaskEdit.container';

type TaskEditPageProps = {
  code?: string;
};

const TaskEditPage: FC<RouteComponentProps<TaskEditPageProps>> = ({ match }) => {
  const code = match.params.code;
  return (
    <PageWrapper>
      <TaskEditContainer code={code} />
    </PageWrapper>
  );
};

export default TaskEditPage;
