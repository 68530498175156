import { TPaymentMarketPaymentDetails } from '@sim-admin-frontends/data-access';
import { FormInput } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;

type Props = { product: NonNullable<TPaymentMarketPaymentDetails['products'][number]> };

const PaymentEditTuiProduct = ({ product }: Props) => {
  const { t } = useTranslation();
  const { id, title, ageRange, quantity, retailPrice, merchantPrice } = product;

  return (
    <Wrapper>
      {!!title && (
        <FormInput
          label={t('payments.productTitle')}
          value={title}
          readOnly
          disabled
          testId="PaymentEdit#productTitle"
        />
      )}
      {!!ageRange && (
        <FormInput
          label={t('payments.productAgeRange')}
          value={ageRange}
          readOnly
          disabled
          testId="PaymentEdit#productAgeRange"
        />
      )}
      {!!quantity && (
        <FormInput
          label={t('payments.productQuantity')}
          value={quantity}
          readOnly
          disabled
          testId="PaymentEdit#productQuantity"
        />
      )}
      {!!retailPrice?.formattedValue && (
        <FormInput
          label={t('payments.retailPrice')}
          value={retailPrice.formattedValue}
          readOnly
          disabled
          testId="PaymentEdit#retailPrice"
        />
      )}
      {!!merchantPrice?.formattedValue && (
        <FormInput
          label={t('payments.merchantPrice')}
          value={merchantPrice.formattedValue}
          readOnly
          disabled
          testId="PaymentEdit#merchantPrice"
        />
      )}
      {!!id && (
        <FormInput
          label={t('payments.productId')}
          value={id}
          readOnly
          disabled
          testId="PaymentEdit#productId"
        />
      )}
    </Wrapper>
  );
};

export default PaymentEditTuiProduct;
