import { TFunction } from 'i18next';
import {
  ClickableCopyText,
  TExtendedColumn,
  TableHeaderTitle,
} from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import { TInfluencerListItem } from '@sim-admin-frontends/data-access';

import InfluencersViewTableMenu from './InfluencersViewTableMenu';
import { TInfluencerViewValues } from '../../../types/TInfluencer';
import ROUTES from '../../../routing/routes';
import InfluencerAvatar from './InfluencerAvatar';

export const createTableColumns = ({
  t,
  refetch,
  deleteMutation,
}: {
  t: TFunction;
  refetch: () => void;
  deleteMutation: ({ id }: { id: string }) => void;
}) => {
  const columns: TExtendedColumn<TInfluencerViewValues>[] = [
    {
      Header: `${t('influencers.form.avatar')}`,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TInfluencerViewValues>) => {
        const src = row.original.avatar?.links?.self;
        const name = row.original.name;
        if (!src) {
          return null;
        }

        return <InfluencerAvatar imageSrc={src} name={name} isCircular />;
      },
    },
    {
      Header: `${t('influencers.form.name')}`,
      accessor: 'name',
      Cell: ({ value }) =>
        value ? <TableHeaderTitle item={value || ''} maxTextLength={100} /> : null,
      disableSortBy: true,
    },
    {
      Header: `${t('influencers.form.description')}`,
      accessor: 'description',
      Cell: ({ value }) =>
        value ? <TableHeaderTitle item={value || ''} maxTextLength={100} /> : null,
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.uuid')}`,
      accessor: 'id',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TInfluencerListItem>) => {
        return (
          <InfluencersViewTableMenu
            testId={`InfluencersView#TableMenu${row.index}`}
            refetch={refetch}
            id={row.original.id}
            editPath={ROUTES.influencersEdit.path}
            listPath={ROUTES.influencers.path}
            deleteMutation={deleteMutation}
          />
        );
      },
    },
  ];
  return columns;
};
