import styled from 'styled-components';
import { FC, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker, Label, TBaseProps } from '../..';
import { TDatesRange } from '../../types/TDateRangePicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

const StyledLabel = styled(Label)`
  margin-bottom: ${({ theme }) => theme.spaces.spacing4};
`;

export interface DateRangePickerProps extends TBaseProps {
  dateFromLabel?: string;
  dateToLabel?: string;
  initialValues?: TDatesRange;
  onDatesChanged: (values: TDatesRange) => void;
  testId?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  initialValues,
  dateFromLabel,
  dateToLabel,
  onDatesChanged,
  testId,
}) => {
  const [dateFromValue, setDateFromValue] = useState<Date | null>(
    initialValues?.dateFrom ?? new Date(),
  );
  const [dateToValue, setDateToValue] = useState<Date | null>(initialValues?.dateTo ?? new Date());

  useEffect(() => {
    onDatesChanged({ dateFrom: dateFromValue, dateTo: dateToValue });
  }, [dateFromValue, dateToValue]);

  return (
    <Wrapper>
      <div>
        {dateFromLabel && <StyledLabel>{dateFromLabel}</StyledLabel>}
        <DatePicker
          initialValue={dateFromValue}
          onDateChanged={setDateFromValue}
          testId={`${testId}-from`}
        />
      </div>
      <div>
        {dateToLabel && <StyledLabel>{dateToLabel}</StyledLabel>}
        <DatePicker
          initialValue={dateToValue}
          onDateChanged={setDateToValue}
          testId={`${testId}-to`}
        />
      </div>
    </Wrapper>
  );
};

export { DateRangePicker };
