import {
  FailedPlaceItinerariesQuery,
  PlaceItinerariesQuery,
  useFailedPlaceItinerariesQuery,
  usePlaceItinerariesQuery,
} from '../../graphql/graphql';
import { useInfiniteGraphQLQuery } from '../../hooks/useInfiniteGraphQLQuery';

export const ITINERARIES_PAGE_SIZE = 100;

export const useInfinitePlaceItinerariesQuery = (placeUuid: string) => {
  const getNextPageParam = (lastPage: PlaceItinerariesQuery) => lastPage.placeItineraries.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    placeUuid,
    nextToken: pageParam,
    pageSize: ITINERARIES_PAGE_SIZE,
  });

  return useInfiniteGraphQLQuery(usePlaceItinerariesQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};

export const useInfiniteFailedPlaceItinerariesQuery = (placeUuid: string) => {
  const getNextPageParam = (lastPage: FailedPlaceItinerariesQuery) =>
    lastPage.failedPlaceItineraries.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    placeUuid,
    nextToken: pageParam,
    pageSize: ITINERARIES_PAGE_SIZE,
  });

  return useInfiniteGraphQLQuery(useFailedPlaceItinerariesQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
