import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import TaskEditTwitterContainer from '../../components/tasks/editTwitter/TaskEditTwitter.container';

type TaskEditPageProps = {
  code?: string;
};

const TaskEditTwitterPage: FC<RouteComponentProps<TaskEditPageProps>> = ({ match }) => {
  const code = match.params.code;
  return (
    <PageWrapper>
      <TaskEditTwitterContainer code={code} />
    </PageWrapper>
  );
};

export default TaskEditTwitterPage;
