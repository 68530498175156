import {
  ReminderCategoryInput,
  UploadType,
  useInfiniteSAReminderCategoriesByQuery,
  useSaPutReminderCategoriesMutation,
} from '@sim-admin-frontends/data-access';
import {
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
  Error,
} from '@sim-admin-frontends/ui-shared';
import { prepareFileObjects, useUploadImage } from '@sim-admin-frontends/utils-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { TReminderCategoryValues } from '../../../types/TReminders';
import ReminderCategoriesEdit from './ReminderCategoriesEdit';
import NotFound from '../../../routing/NotFound';

const SHARED_TOAST_ID = 'reminderCategoriesEditToast';
const PAGE_SIZE = 100;

type ReminderCategoriesEditContainerProps = {
  placeId: string;
  code?: string;
};

const ReminderCategoriesEditContainer: FC<ReminderCategoriesEditContainerProps> = ({
  placeId,
  code,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data, isLoading, isError, refetch } = useInfiniteSAReminderCategoriesByQuery(
    placeId,
    PAGE_SIZE,
    !code,
  );

  const reminderCategory = code
    ? data?.pages?.[0]?.reminderCategoriesBy?.reminderCategories.find(
        (category) => category.code === code,
      )
    : undefined;

  const { mutateAsync: putReminderCategory } = useSaPutReminderCategoriesMutation();
  const { uploadFormImages } = useUploadImage();

  const onSubmit = async (values: TReminderCategoryValues) => {
    loadingToast(t('reminderCategories.createToast.loading'), {
      toastId: SHARED_TOAST_ID,
    });
    const iconImage = await uploadFormImages(UploadType.ReminderCategoryIcons, values?.icon, {
      placeId,
      reminderCategoryCode: values.code,
    });

    const reminderCategoryForBE: ReminderCategoryInput = {
      title: values.title,
      shortTitle: values.shortTitle,
      subtitle: values.subtitle,
      code: values.code,
      icon: prepareFileObjects(iconImage)[0],
      color: values.color,
      placeUuid: placeId,
      reminderType: values.reminderTypeOption.value,
      content: values.content,
    };

    await putReminderCategory({ reminderCategories: [reminderCategoryForBE] });

    updateToast(
      SHARED_TOAST_ID,
      code
        ? t('reminderCategories.createToast.success')
        : t('reminderCategories.createToast.success'),
      TToastType.SUCCESS,
    );
    history.push(generatePath(ROUTES.reminderCategories.path, { placeId }));
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (code && !reminderCategory) {
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  }

  return <ReminderCategoriesEdit onSubmit={onSubmit} reminderCategory={reminderCategory} />;
};

export default ReminderCategoriesEditContainer;
