import { FC } from 'react';
import { useSystemStatusQuery } from '@sim-admin-frontends/data-access-admin-be';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getInitialSystemStatusValues } from '../../utils/systemSettingsUtils';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.spacing16};
`;

const WarningText = styled.div`
  color: ${({ theme }) => theme.colors.red60};
  text-align: center;
`;

const GlobalWarning: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useSystemStatusQuery();

  const initialValues = getInitialSystemStatusValues(data);

  if (isLoading || (initialValues.notificationsEnabled && initialValues.postsEnabled)) {
    return null;
  }

  const getWarningText = () => {
    if (!initialValues.notificationsEnabled && initialValues.postsEnabled) {
      return t('systemSettings.notificationsWarning');
    }
    if (initialValues.notificationsEnabled && !initialValues.postsEnabled) {
      return t('systemSettings.postsWarning');
    }
    return t('systemSettings.globalWarning');
  };

  return (
    <Wrapper>
      <WarningText>{getWarningText()}</WarningText>
    </Wrapper>
  );
};

export default GlobalWarning;
