import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import PlaceEditContainer from '../../components/places/edit/PlaceEdit.container';

type PlaceEditPageProps = {
  id?: string;
};

const PlaceEditPage: FC<RouteComponentProps<PlaceEditPageProps>> = ({ match }) => {
  const id = match.params.id;
  return (
    <PageWrapper>
      <PlaceEditContainer id={id} />
    </PageWrapper>
  );
};

export default PlaceEditPage;
