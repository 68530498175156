import { RouteComponentProps } from 'react-router-dom';

import { DashboardViewContainer } from '../../components/dashboard/DashboardView.container';
import { PageWrapper } from '../../components/layout/PageLayout';

const HomePage: React.FC<RouteComponentProps> = () => {
  return (
    <PageWrapper>
      <DashboardViewContainer />
    </PageWrapper>
  );
};

export default HomePage;
