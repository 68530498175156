import { useEffect, useMemo, useState } from 'react';
import { useInfiniteMarketItemsQuery } from '@sim-admin-frontends/data-access';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';

const useSearchableMarketItemsOptions = (initialPlaceUuid: string) => {
  const { onSearch: onMarketItemsSearch, searchTerm } = useSearchTerm();

  const [placeUuid, setPlaceUuid] = useState(initialPlaceUuid);

  useEffect(() => {
    setPlaceUuid(initialPlaceUuid);
  }, [initialPlaceUuid]);

  const {
    data: marketItemsData,
    isError: isErrorMarketItems,
    refetch: refetchMarketItems,
    isLoading: isLoadingMarketItems,
    fetchNextPage: fetchNextPageMarketItems,
    hasNextPage: hasNextPageMarketItems,
  } = useInfiniteMarketItemsQuery({ text: searchTerm, placeUuid });

  const marketItems = useMemo(
    () => marketItemsData?.pages?.flatMap((page) => page.marketItemsBy.marketItems) || [],
    [marketItemsData?.pages],
  );

  const onMarketItemsMenuScrollToBottom = () => {
    if (hasNextPageMarketItems) {
      fetchNextPageMarketItems();
    }
  };

  return {
    setPlaceUuid,
    marketItems,
    onMarketItemsMenuScrollToBottom,
    onMarketItemsSearch,
    isErrorMarketItems,
    isLoadingMarketItems,
    refetchMarketItems,
  };
};

export default useSearchableMarketItemsOptions;
