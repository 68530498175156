import {
  InfluencerInput,
  InfluencerSpotInput,
  LocationInput,
  TInfluencerSpotMarketItem,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';
import { format, parse } from 'date-fns';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { serializeToString } from '@simplicity-tech/sim-slate-types';

import {
  TInfluencerDetail,
  TInfluencerFormValues,
  TInfluencerSpotFormValues,
} from '../types/TInfluencer';
import { isNumberSet } from './formUtils';

export const getInitialInfluencerSocials = (
  socials?: TInfluencerDetail['socials'],
): TInfluencerFormValues['socials'] => {
  if (!socials) {
    return [];
  }

  return socials.map(({ type, url }) => ({ url, type: { value: type, label: type } }));
};

export const getInitialInfluencerProperties = (
  properties?: TInfluencerDetail['properties'],
): TInfluencerFormValues['properties'] => {
  if (!properties) {
    return [];
  }

  return properties.map(({ name, value }) => ({ value, name: { value: name, label: name } }));
};

export const transformFormValues = (
  formValues: TInfluencerFormValues,
  avatarImages: TUploadedFile[] | undefined,
  backgroundImages: TUploadedFile[] | undefined,
): InfluencerInput => {
  const avatar = prepareFileObjects(avatarImages)[0];
  const background = prepareFileObjects(backgroundImages)[0];

  return {
    avatar,
    background,
    name: formValues.name,
    description: formValues.description,
    socials: formValues.socials.map((social) => ({ type: social.type.value, url: social.url })),
    properties: formValues.properties.map((property) => ({
      name: property.name.value,
      value: property.value,
    })),
    placeUuids: formValues.places.map((place) => place.value),
  };
};

export const parse24hTime = (time: string, formatString: string) =>
  format(parse(time, 'HH:mm:ss', new Date()), formatString);

export const parse12hTime = (time: string, formatString: string) =>
  format(parse(time, 'hh:mm a', new Date()), formatString);

export const transformInfluencerSpotFormValues = (
  formValues: TInfluencerSpotFormValues,
  images: TUploadedFile[] | undefined,
  influencerUuid: string,
): InfluencerSpotInput => {
  const image = prepareFileObjects(images)[0];

  return {
    influencerUuid,
    image,
    title: formValues.title,
    content: formValues.content,
    placeUuid: formValues.place?.value || '',
    location: formValues.location,
    startTime: formValues.startTime
      ? parse12hTime(`${formValues.startTime} ${formValues.startTimePeriod}`, 'HH:mm')
      : undefined,
    endTime: formValues.endTime
      ? parse12hTime(`${formValues.endTime} ${formValues.endTimePeriod}`, 'HH:mm')
      : undefined,
    duration: isNumberSet(formValues.duration) ? formValues.duration : undefined,
    marketItemUuid: formValues.marketItem?.value,
    price:
      formValues.price && formValues.currency
        ? {
            amount: formValues.price,
            currency: formValues.currency.value,
          }
        : undefined,
    categoryUuid: formValues.category?.value,
    favourite: formValues.favourite,
  };
};

export const getInitialValuesFromMarketItem = async (
  marketItem: TInfluencerSpotMarketItem,
): Promise<TInfluencerSpotFormValues> => {
  let location: LocationInput | undefined = undefined;

  if (marketItem.gpsLocation) {
    const geoResult = await geocodeByLatLng({
      lat: marketItem.gpsLocation?.lat,
      lng: marketItem.gpsLocation?.lon,
    });

    location = {
      address: geoResult[0].formatted_address,
      geo: {
        lat: geoResult[0].geometry.location?.lat(),
        lon: geoResult[0].geometry.location?.lng(),
      },
    };
  }

  return {
    title: marketItem.title,
    content: serializeToString(marketItem.content || ' '),
    image: marketItem.imageObjects,
    location,
    duration: marketItem.duration,
    price: marketItem.price?.retailPrice,
    currency: marketItem.price?.currency
      ? { value: marketItem.price.currency, label: marketItem.price.currency }
      : undefined,
    favourite: false,
  };
};
