import { TSelectItems } from '@sim-admin-frontends/ui-shared';
import { ScrapeCategoryInput, ScrapeTaskInput } from '@sim-admin-frontends/data-access-admin-be';
import { SuppportedLanguages } from '@sim-admin-frontends/utils-shared';

import { TScrapeCategory, TTaskEditFormValues } from '../types/TTask';

export const transformFormValues = (formData: TTaskEditFormValues): ScrapeTaskInput => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { institution: _, ...rest } = formData;
  const categories: ScrapeCategoryInput[] | undefined = formData.categories?.map((category) => ({
    id: category.value,
    name: category.label,
  }));
  const checkCodes = formData.checkCodes.reduce<string[]>(
    (acc, code) => {
      if (code !== formData.code) {
        return [...acc, code];
      }
      return acc;
    },
    [formData.code],
  );
  return {
    ...rest,
    checkCodes,
    categories: categories,
    runSchedule: formData.runSchedule?.trim() ? formData.runSchedule.trim() : null,
    websiteTemplate: formData.websiteTemplate?.trim() ? formData.websiteTemplate.trim() : null,
    lang: formData.lang?.value || SuppportedLanguages.EN,
    visitorScraper: formData.visitorScraper ?? false,
  };
};

export const getCategoryOptionFromIds = (options: TSelectItems, ids: string[] | undefined) =>
  options.reduce((prev, option) => {
    if (ids?.includes(option.value)) {
      return [...prev, option];
    }
    return prev;
  }, [] as TSelectItems);

export const getGoogleUrl = (location: string, query = 'Events in') => {
  const escapedQuery = query.split(' ').join('+');
  const googleUrlPrefix = `https://www.google.com/search?q=${escapedQuery}+`;
  const googleUrlPostfix = '&ibp=htl;events#';
  return `${googleUrlPrefix}${location.split(' ').join('+')}${googleUrlPostfix}`;
};

export const getYelpUrl = (location: string) => {
  const googleUrlPrefix = 'https://api.yelp.com/v3/businesses/search?location=';
  return `${googleUrlPrefix}${location.split(' ').join('%20')}`;
};

export const getInitialCategoriesOptions = (
  options?: TSelectItems | null,
  announcementCategories?: TScrapeCategory[] | null,
) => {
  const resultOptions = getCategoryOptionFromIds(
    options || [],
    announcementCategories?.map((category) => category.id),
  );
  return resultOptions.length ? resultOptions : [];
};
