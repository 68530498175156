import { TableIcons } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateTaskActions } from '../../../hooks/actionButtons/useGenerateTaskActions';

export interface TasksViewTableMenuProps {
  code: string;
  scraperType?: string;
  websiteTemplate?: string;
  dataType?: string;
  refetch: () => void;
}

const TasksViewTableMenu: FC<TasksViewTableMenuProps> = ({
  code,
  scraperType,
  websiteTemplate,
  dataType,
  refetch,
}) => {
  const { listActions: items, renderModal } = useGenerateTaskActions({
    code,
    refetch,
    scraperType,
    websiteTemplate,
    dataType,
  });
  return (
    <TableIcons items={items} testId={'TasksView#TableMenu'}>
      {renderModal()}
    </TableIcons>
  );
};

export { TasksViewTableMenu };
