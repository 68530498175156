import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import SystemSettingsContainer from '../../components/systemSettings/SystemSettings.container';
import { PageWrapper } from '../../components/layout/PageLayout';
import { WarningText } from '../../components/common/Texts/Texts';

const SystemSettingsPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader
        title={t('systemSettings.title')}
        caption={t('systemSettings.description')}
        testId="SystemSettings#PageHeader"
      />
      <WarningText>{t('systemSettings.warningText')}</WarningText>
      <SystemSettingsContainer />
    </PageWrapper>
  );
};

export default SystemSettingsPage;
