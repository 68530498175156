import { TFunction } from 'i18next';
import { ClickableCopyText, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import { TLandmarkListItem } from '@sim-admin-frontends/data-access-admin-be';

import { LandmarksViewTableMenu } from './LandmarksViewTableMenu';

export const createTableColumns = (t: TFunction, refetch: () => void, placeId: string) => {
  const columns: TExtendedColumn<TLandmarkListItem>[] = [
    {
      Header: `${t('landmarks.id')}`,
      accessor: 'id',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('landmarks.title')}`,
      accessor: 'title',
      disableSortBy: false,
    },
    {
      Header: `${t('landmarks.additionalType')}`,
      accessor: 'additionalType',
      Cell: ({ value }: CellProps<TLandmarkListItem>) => {
        return value ? `[${value.join(', ')}]` : '[]';
      },
      disableSortBy: true,
    },
    {
      Header: `${t('landmarks.rating')}`,
      accessor: 'rating',
      disableSortBy: true,
    },
    {
      Header: `${t('landmarks.userRatingCount')}`,
      accessor: 'userRatingCount',
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TLandmarkListItem>) => (
        <LandmarksViewTableMenu
          testId={`LandmarkssView#TableMenu${row.index}`}
          refetch={refetch}
          id={row.original.id}
          placeId={placeId}
        />
      ),
    },
  ];
  return columns;
};
