import { FormInput } from '@sim-admin-frontends/ui-shared';
import { getLangLabel, SuppportedLanguages } from '@sim-admin-frontends/utils-shared';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { TDefaultLanguageSettings, TTitleLocalizations } from '../../../types/TFeatureFlags';

type Props = {
  defaultLanguageSettings: TDefaultLanguageSettings;
  initialValues?: TTitleLocalizations;
};

const FeatureEditLanguagesSection: FC<Props> = ({ defaultLanguageSettings, initialValues }) => {
  const { register, formState, control, setValue } = useFormContext();
  const { errors } = formState;
  const languages = Object.values(SuppportedLanguages);
  const countryCode = defaultLanguageSettings.countryCode || '';

  let defaultLanguage: string;

  switch (countryCode) {
    case 'SK':
      defaultLanguage = SuppportedLanguages.SK;
      break;
    case 'US':
      defaultLanguage = SuppportedLanguages.EN;
      break;
    case 'UA':
      defaultLanguage = SuppportedLanguages.UK;
      break;
    default:
      defaultLanguage = defaultLanguageSettings.language || SuppportedLanguages.EN;
      break;
  }

  const [title] = useWatch({
    name: ['title'],
    control,
  });

  useEffect(() => {
    if (!defaultLanguage) {
      return;
    }
    setValue(`titleLocalizations.${defaultLanguage}`, title);
  }, [title]);

  const getDefaultValue = (lang: string) => {
    if (lang === defaultLanguage) {
      return title;
    }

    return initialValues?.[lang as keyof TTitleLocalizations] ?? '';
  };

  return (
    <>
      {languages.map((lang) => (
        <FormInput
          key={lang}
          testId="FeatureFlagEdit#Title"
          label={getLangLabel(lang)}
          {...register(`titleLocalizations.${lang}`)}
          disabled={lang === defaultLanguage}
          defaultValue={getDefaultValue(lang)}
          error={errors[`languages.${lang}`]}
        />
      ))}
    </>
  );
};

export default FeatureEditLanguagesSection;
