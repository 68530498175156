import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import styled from 'styled-components';

import { useGenerateInstitutionActions } from '../../../hooks/actionButtons/useGenerateInstitutionActions';

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface InstitutionsViewTableMenuProps extends TBaseProps {
  institutionId: string;
  refetch: () => void;
}

const InstitutionsViewTableMenu: FC<InstitutionsViewTableMenuProps> = ({
  institutionId,
  refetch,
  testId,
}) => {
  const { listActions: items, renderModal } = useGenerateInstitutionActions({
    refetch,
    institutionId,
  });

  return (
    <MenuWrapper>
      <TableIcons items={items} testId={testId}>
        {renderModal()}
      </TableIcons>
    </MenuWrapper>
  );
};

export { InstitutionsViewTableMenu };
