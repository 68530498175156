import { FormSelect, TSelectItems } from '@sim-admin-frontends/ui-shared';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { TPayParkingSettings } from '../../../types/TFeatureFlags';

type Props = {
  initialValues?: TPayParkingSettings;
};

const PlaceFeatureEditPayParkingSection: FC<Props> = ({ initialValues }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const { t } = useTranslation();
  // TODO: provide real select items base on BE
  const connectorTypesOptions: TSelectItems = [];

  return (
    <FormSelect
      control={control}
      name="payParkingSettings.connectorTypes"
      label={t('places.features.form.connector')}
      options={connectorTypesOptions}
      defaultValue={initialValues?.connectorTypes}
      error={get(errors, 'plateCheckerSettings.connector')}
      testId="FeatureFlagEdit#payParkingConnector"
      isMulti
      clearable
      allSelectable
    />
  );
};

export default PlaceFeatureEditPayParkingSection;
