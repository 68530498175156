import {
  InstitutionFeIconEnum,
  InstitutionFeatureFlag,
  InstitutionFeatureFlagEnum,
  InstitutionFeatureFlagInput,
  InstitutionInput,
  TSAInstitutionListItems,
  UploadType,
} from '@sim-admin-frontends/data-access';
import {
  prepareFileObjects,
  SuppportedLanguages,
  TUploadImagesFunction,
} from '@sim-admin-frontends/utils-shared';
import i18n from 'i18next';
import { TSelectItems } from '@sim-admin-frontends/ui-shared';

import {
  TInstitutionEnablingStatus,
  TInstitutionFormVariables,
  TSAInstitutionPreferences,
} from '../types/TInstitution';

export const prepareCreationData = (
  formValues: TInstitutionFormVariables,
  instituionFeatureFlags?: InstitutionFeatureFlag[],
): InstitutionInput => {
  const transformedData: InstitutionInput = {
    name: formValues.name,
    description: formValues.description,
    webSite: formValues.website,
    isChatEnabled: formValues.isChatEnabled,
    isRecommended: formValues.isRecommended,
    taskCodes: formValues.taskCodes,
    info: {
      contactEmail: formValues.contactEmail,
      contactPhone: formValues.contactPhone,
      description: formValues.description,
      website: formValues.website,
      location: formValues.location,
      featureFlags: instituionFeatureFlags,
    },
    places: formValues.places?.map((place) => ({
      placeId: place.value,
      role: formValues.role.value,
    })),
    categoryUuid: formValues.category?.value || '',
    branding: null,
    lang: formValues.lang?.value,
    visitorMode: formValues.visitorMode,
    preferences: formValues.preferences?.map((preference) => preference.value),
    countryCode: formValues.countryCode,
  };
  return transformedData;
};

export const prepareUpdateData = async (
  createdData: InstitutionInput,
  formValues: TInstitutionFormVariables,
  institutionId: string,
  uploadFormImages: TUploadImagesFunction,
): Promise<InstitutionInput> => {
  const avatarImage = await uploadFormImages(
    UploadType.InstitutionAvatar,
    formValues.branding?.avatar,
    { institutionId },
  );
  const avatar = prepareFileObjects(avatarImage);
  const transformedData: InstitutionInput = {
    ...createdData,
    branding: !formValues.branding?.avatar
      ? null
      : {
          avatar: avatar[0],
          logo: avatar[0],
        },
  };
  return transformedData;
};

export const transformInstitutions = (places: TSAInstitutionListItems) =>
  places.map((place) => ({
    label: place.name,
    value: place.id,
    disabled: place.enabled === TInstitutionEnablingStatus.DISABLED,
  }));

export const appendFeatureFlagsToInstitution = (
  createdData: InstitutionInput,
  formValues: TInstitutionFormVariables,
): InstitutionInput => ({
  ...createdData,
  info: {
    ...createdData.info,
    featureFlags: prepareInsitutionFeatureFlags(formValues),
  },
});

export const prepareInsitutionFeatureFlags = (
  formValues: TInstitutionFormVariables,
): InstitutionFeatureFlag[] => {
  const location = formValues.location
    ? `${formValues.location.geo.lat},${formValues.location.geo.lon}`
    : undefined;

  const featureFlags: InstitutionFeatureFlagInput[] = [
    ...prepareWebsiteInstitutionFeatureFlag(formValues.website, formValues.lang?.value),
    ...preparePhoneInstitutionFeatureFlag(formValues.contactPhone, formValues.lang?.value),
    ...prepareAddressInstitutionFeatureFlag(location, formValues.lang?.value),
    ...prepareEmailInstitutionFeatureFlag(formValues.contactEmail, formValues.lang?.value),
  ];

  return featureFlags;
};

const getTitleTranslation = (key: string, lng: string) => ({
  language: lng,
  title: i18n.t(key, { lng }),
});

export const prepareWebsiteInstitutionFeatureFlag = (
  website: string,
  institutionLanguage?: string,
): InstitutionFeatureFlagInput[] => {
  if (!website) {
    return [];
  }
  return [
    {
      type: InstitutionFeatureFlagEnum.Website,
      title: i18n.t('insitution.edit.featureFlagWebsite', {
        lng: institutionLanguage || SuppportedLanguages.EN,
      }),
      value: website,
      feIcon: InstitutionFeIconEnum.Earth,
      titleLocalizations: Object.values(SuppportedLanguages).map((lng) =>
        getTitleTranslation('insitution.edit.featureFlagWebsite', lng),
      ),
    },
  ];
};

export const preparePhoneInstitutionFeatureFlag = (
  contactPhone?: string,
  institutionLanguage?: string,
): InstitutionFeatureFlagInput[] => {
  if (!contactPhone) {
    return [];
  }
  return [
    {
      type: InstitutionFeatureFlagEnum.Phone,
      title: i18n.t('insitution.edit.featureFlagPhone', {
        lng: institutionLanguage || SuppportedLanguages.EN,
      }),
      value: contactPhone,
      feIcon: InstitutionFeIconEnum.Phone,
      titleLocalizations: Object.values(SuppportedLanguages).map((lng) =>
        getTitleTranslation('insitution.edit.featureFlagPhone', lng),
      ),
    },
  ];
};

export const prepareAddressInstitutionFeatureFlag = (
  address?: string,
  institutionLanguage?: string,
): InstitutionFeatureFlagInput[] => {
  if (!address) {
    return [];
  }
  return [
    {
      type: InstitutionFeatureFlagEnum.Address,
      title: i18n.t('insitution.edit.featureFlagAddress', {
        lng: institutionLanguage || SuppportedLanguages.EN,
      }),
      value: address,
      feIcon: InstitutionFeIconEnum.Gps,
      titleLocalizations: Object.values(SuppportedLanguages).map((lng) =>
        getTitleTranslation('insitution.edit.featureFlagAddress', lng),
      ),
    },
  ];
};

export const prepareEmailInstitutionFeatureFlag = (
  contactEmail?: string,
  institutionLanguage?: string,
): InstitutionFeatureFlagInput[] => {
  if (!contactEmail) {
    return [];
  }
  return [
    {
      type: InstitutionFeatureFlagEnum.Email,
      title: i18n.t('insitution.edit.featureFlagEmail', {
        lng: institutionLanguage || SuppportedLanguages.EN,
      }),
      value: contactEmail,
      feIcon: InstitutionFeIconEnum.PaperPlane,
      titleLocalizations: Object.values(SuppportedLanguages).map((lng) =>
        getTitleTranslation('insitution.edit.featureFlagEmail', lng),
      ),
    },
  ];
};

export const getInitialPreferences = (
  institutionPreferences: TSAInstitutionPreferences,
  visitorInterests?: TSelectItems,
) =>
  institutionPreferences?.map((institutionPreference) => ({
    label:
      visitorInterests?.find((preference) => preference.value === institutionPreference)?.label ||
      institutionPreference,
    value:
      visitorInterests?.find((preference) => preference.value === institutionPreference)?.value ||
      institutionPreference,
  }));
