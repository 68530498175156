import { useSaDeleteSelectorMutation } from '@sim-admin-frontends/data-access-admin-be';
import {
  ConfirmModal,
  EditIcon,
  loadingToast,
  SearchIcon,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';

type Props = {
  code?: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateSelectorActions = ({ code, refetch, isEditPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate: deleteSelectorMutation } = useSaDeleteSelectorMutation({
    onMutate: () => {
      loadingToast(t('selectors.deleteToast.loading'), {
        toastId: 'DELETE',
      });
    },
    onError: () => {
      updateToast('DELETE', t('selectors.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast('DELETE', t('selectors.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const deleteSelector = async () => {
    await deleteSelectorMutation({
      code: code || '',
    });
  };

  const handleConfirmClick = () => {
    deleteSelector();
    setIsModalVisible(false);
    history.push(generatePath(ROUTES.selectors.path));
    refetch();
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = () => {
    history.push(generatePath(ROUTES.selectorEdit.path, { code }));
  };

  const goToTasks = () => {
    history.push(generatePath(ROUTES.taskEdit.path, { code }));
  };

  const generateItems = (): TableIconProps[] => {
    if (!code) {
      return [];
    }
    const actions = [
      {
        label: t('table.dropdown.edit'),
        action: handleEditClick,
        testId: 'SelectorsTableMenu#edit',
        hidden: isEditPage,
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'SelectorsTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];
    if (code.toLocaleLowerCase() === code) {
      actions.push({
        label: t('table.dropdown.tasks'),
        action: goToTasks,
        testId: 'SelectorsTableMenu#tasks',
        icon: <SearchIcon />,
      });
    }

    return actions;
  };

  const renderModal = () => (
    <ConfirmModal
      title={t('selectors.deleteModal.title')}
      content={t('selectors.deleteModal.description')}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('selectors.deleteModal.cancel')}
      confirmButtonText={t('selectors.deleteModal.confirm')}
    />
  );

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);
  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
