import {
  useInfiniteSAInstitutionsBySearchQuery,
  useSAsendChatBotNotificationMutation,
} from '@sim-admin-frontends/data-access';
import { useState } from 'react';
import {
  Error,
  getErrorMessage,
  loadingToast,
  SpinnerWrapper,
  TSelectItem,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { TChatBotNotificationsEditValues, TChatName } from '../../../types/TNotifications';
import ChatBotNotificationsEdit from './ChatBotNotificationsEdit';
import { SHARED_TOAST_ID } from '../view/NotificationsTabs';

export const DEFAULT_CHAT_BOT = 'Charlie';

const ChatBotNotificationsEditContainer = () => {
  const { t } = useTranslation();
  const { mutateAsync: sendNotification } = useSAsendChatBotNotificationMutation();

  const [chatBot, setChatBot] = useState<TChatName | undefined>(DEFAULT_CHAT_BOT);

  const {
    data: institutionsData,
    isLoading: isLoadingInstitutions,
    isFetching: isFetchingInstitutions,
    refetch,
  } = useInfiniteSAInstitutionsBySearchQuery(
    {
      text: chatBot,
    },
    !!chatBot,
  );

  const institution = institutionsData?.pages?.[0]?.institutionsBySearch?.institutions?.[0];

  if (
    !(isLoadingInstitutions || isFetchingInstitutions) &&
    !!chatBot &&
    institution?.name !== chatBot
  ) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const onChatBotChange = (newChatBot: readonly TSelectItem[] | null) => {
    const value = newChatBot?.[0].value;
    if (!value) {
      setChatBot(undefined);
      return;
    }

    setChatBot(newChatBot?.[0].value as TChatName);
  };

  const onSubmit = async (values: TChatBotNotificationsEditValues) => {
    loadingToast(t('notifications.toastSubmitting'), {
      toastId: SHARED_TOAST_ID,
    });
    const isData = !!institution;
    try {
      await sendNotification({
        target: {
          email: values.email,
        },
        notification: {
          type: values.type.value,
          title: values.title,
          subtitle: values.subtitle,
          body: values.body,
          priority: values.priority?.value,
          data: isData ? { institutionUuid: institution?.id } : undefined,
        },
      });
      updateToast(SHARED_TOAST_ID, t('notifications.toastSuccess'), TToastType.SUCCESS);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  return (
    <ChatBotNotificationsEdit
      onSubmit={onSubmit}
      onChatBotChange={onChatBotChange}
      isFetchingInstitutions={isLoadingInstitutions || isFetchingInstitutions}
    />
  );
};

export default ChatBotNotificationsEditContainer;
