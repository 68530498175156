import {
  SaAllTasksQuery,
  useInfiniteGraphQLQuery,
  useSaAllTasksQuery,
  SaAllTasksQueryVariables,
} from '../..';

export const useInfiniteSAAllTasksQuery = ({ affix }: SaAllTasksQueryVariables) => {
  const getNextPageParam = (lastPage: SaAllTasksQuery) => lastPage?.allScrapeTasks?.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    affix,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useSaAllTasksQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
