import { useMemo } from 'react';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';

import { transformPlaces } from '../utils/placeUtils';

const useSearchablePlaceOptions = (visitorMode?: boolean) => {
  const { onSearch: onPlacesSearch, searchTerm: searchTermPlaces } = useSearchTerm();

  const {
    data: allPlaces,
    isError: isErrorPlaces,
    refetch: refetchPlaces,
    isLoading: isLoadingPlaces,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
  } = useInfiniteSaPlacesQuery({
    includeDistricts: false,
    visitorMode: visitorMode,
    prefix: searchTermPlaces,
  });

  const placesOptions = useMemo(() => {
    const places = allPlaces?.pages?.flatMap((page) => page.places.places) || [];

    if (!places) {
      return [];
    }

    return transformPlaces(places).sort((a, b) => a.label.localeCompare(b.label));
  }, [allPlaces?.pages]);

  const onPlacesMenuScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  return {
    placesOptions,
    onPlacesMenuScrollToBottom,
    onPlacesSearch,
    isErrorPlaces,
    isLoadingPlaces,
    refetchPlaces,
  };
};

export default useSearchablePlaceOptions;
