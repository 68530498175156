import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import InfluencerEditContainer from '../../components/influencers/edit/InfluencerEdit.container';

type Props = {
  id?: string;
};

const InfluencersEditPage = ({ match }: RouteComponentProps<Props>) => {
  const id = match.params.id;

  return (
    <PageWrapper>
      <InfluencerEditContainer id={id} />
    </PageWrapper>
  );
};

export default InfluencersEditPage;
