import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TSelectItems,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  AdminUserInput,
  useSaAddAdminUserMutation,
  useSaAdminUserQuery,
  useSaUpdateAdminUserMutation,
} from '@sim-admin-frontends/data-access';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';

import { useGenerateUserActions } from '../../../hooks/actionButtons/useGenerateUserActions';
import { transformInstitutions } from '../../../utils/institutionUtils';
import UserEdit from './UserEdit';
import { TAdminUserInfo, TUserEditFormValues } from '../../../types/TUser';
import ROUTES from '../../../routing/routes';
import { transformPlaces } from '../../../utils/placeUtils';
import { useSearchCombinedInstitutions } from '../../../hooks/useSearchCombinedInstitutions';

const SHARED_TOAST_ID = 'usersToast';

type UserEditContainerProps = {
  username?: string;
  institutionId?: string;
};

const UserEditContainer: FC<UserEditContainerProps> = ({ username, institutionId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState<TAdminUserInfo | null>(null);
  const { onSearch: onPlacesSearch, searchTerm: searchTermPlaces } = useSearchTerm();

  const pageTitle = username ? t('users.editPageTitle') : t('users.createPageTitle');
  const pageCaption = username ? t('users.editPageCaption') : t('users.createPageCaption');

  const {
    data: allPlaces,
    isError: isErrorPlaces,
    refetch: refetchPlaces,
    isLoading: isLoadingPlaces,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
  } = useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTermPlaces });

  const {
    institutions,
    isLoadingInstitutions,
    isFetchingInstitutions,
    isErrorInsitutions,
    refetchInstitutions,
    fetchNextPageInstitutions,
    hasNextPageInstitutions,
    onInstitutionsSearch,
  } = useSearchCombinedInstitutions();

  const onAllInstitutionsMenuScrollToBottom = () => {
    if (hasNextPageInstitutions) {
      fetchNextPageInstitutions();
    }
  };

  const places = useMemo(
    () => allPlaces?.pages?.flatMap((page) => page.places.places) || [],
    [allPlaces?.pages],
  );

  const onPlacesMenuScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const {
    data: admin,
    isLoading: isLoadingAdmin,
    isError: isErrorAdmin,
    refetch: refetchAdmin,
  } = useSaAdminUserQuery(
    {
      username: username || '',
    },
    {
      enabled: !!username,
    },
  );

  const { mutateAsync: addUser } = useSaAddAdminUserMutation();
  const { mutateAsync: updateUser } = useSaUpdateAdminUserMutation();

  const refetch = () => {
    refetchAdmin();
    refetchPlaces();
    refetchInstitutions();
  };

  const user = admin?.adminUser;

  const initialInstitutions = useMemo(
    () =>
      institutionId
        ? transformInstitutions(
            institutions?.filter((institution) => institution.id === institutionId) || [],
          )
        : admin?.adminUser?.institutions?.map((institution) => ({
            label: institution.name,
            value: institution.id,
          })),
    [institutions, institutionId, admin],
  );

  const placeOptions = useMemo(() => {
    if (!places) {
      return [];
    }
    return transformPlaces(places).sort((a, b) => a.label.localeCompare(b.label));
  }, [places]);

  const institutionOptions: TSelectItems = transformInstitutions(institutions ?? []);

  const prepareBackendData = (formValues: TUserEditFormValues): AdminUserInput => {
    const transformedData: AdminUserInput = {
      institutionUuid: formValues?.institutions?.[0]?.value || '',
      institutionUuids: formValues.institutions?.map((institution) => institution.value),
      placeUuid: formValues.place?.value || null,
      username: formValues.username,
      email: formValues.email,
    };
    return transformedData;
  };

  const onSubmit = async (values: TUserEditFormValues) => {
    try {
      loadingToast(t('users.toast.submit'), {
        toastId: SHARED_TOAST_ID,
      });
      const transformedData = prepareBackendData(values);
      if (username) {
        await updateUser({
          user: transformedData,
        });
      } else {
        const response = await addUser({
          user: transformedData,
        });
        setUserDetails(response?.addAdminUser);
      }

      updateToast(
        SHARED_TOAST_ID,
        username ? t('users.toast.editSuccess') : t('users.toast.createSuccess'),
        TToastType.SUCCESS,
      );
      username && history.push(generatePath(ROUTES.users.path));
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGenerateUserActions({
    refetch,
    username: username || '',
    isEditPage: true,
  });

  const isError = isErrorAdmin || isErrorPlaces || isErrorInsitutions;

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  const isLoading = isLoadingAdmin || isLoadingInstitutions;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="UsersEdit#PageHeader" />
      <UserEdit
        user={user}
        institutions={institutionOptions}
        initialInstitutions={initialInstitutions || []}
        details={userDetails}
        onSubmit={onSubmit}
        places={placeOptions}
        onAllInstitutionsMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
        isFetchingInstitutions={isFetchingInstitutions}
        onPlacesMenuScrollToBottom={onPlacesMenuScrollToBottom}
        isLoadingPlaces={isLoadingPlaces}
        onPlacesSearch={onPlacesSearch}
        onInstitutionsSearch={onInstitutionsSearch}
      />
      {renderModal()}
    </>
  );
};

export default UserEditContainer;
