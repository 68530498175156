import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import {
  Button,
  Error,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TableActionHeader,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  useInfiniteInfluencersQuery,
  useSaDeleteInfluencerMutation,
} from '@sim-admin-frontends/data-access';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import InfluencersView from './InfluencersView';
import { INFLUENCER_TOAST_ID } from '../../../constants/Constants';

const InfluencersViewContainer = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteInfluencersQuery({});

  const { mutate: deleteMutation } = useSaDeleteInfluencerMutation({
    onMutate: () => {
      loadingToast(t('institutions.deleteToast.loading'), {
        toastId: INFLUENCER_TOAST_ID,
      });
    },
    onError: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const columns = createTableColumns({ t, refetch, deleteMutation });

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <>
        <TableActionHeader>
          <Link to={generatePath(ROUTES.influencersEdit.path)}>
            <Button>{t('influencers.addInfluencer')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }

  const itemsCount = data?.pages?.[0].influencersBy?.influencers?.length || 0;
  const influencersData = data?.pages?.[currentPageIndex]?.influencersBy?.influencers || [];
  const hasNextToken = !!data?.pages[currentPageIndex].influencersBy.nextToken;

  return (
    <>
      <TableActionHeader>
        <Link to={generatePath(ROUTES.influencersEdit.path)}>
          <Button testId="InfluencersTabs#AddInfluencer">{t('influencers.addInfluencer')}</Button>
        </Link>
      </TableActionHeader>
      <InfluencersView
        loading={isLoading || isFetchingNextPage}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        hasNextPage={hasNextToken ?? (hasNextPage || false)}
        columns={columns}
        data={influencersData || []}
      />
    </>
  );
};

export default InfluencersViewContainer;
