import {
  InstitutionFeatureFlag,
  InstitutionFeatureFlagEnum,
} from '@sim-admin-frontends/data-access';
import { FormProvider, useForm, get } from 'react-hook-form';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormInput,
  FormSelect,
  FormUpload,
  SUPPORTED_WEB_IMAGE_FORMATS,
  SwitchableFormSection,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { ImageFile, isEmpty } from '@sim-admin-frontends/utils-shared';
import styled from 'styled-components';

import { iconValidation } from '../../../utils/formUtils';
import { ButtonsWrapper, SwitchableSectionContentWrapper, Wrapper } from '../../common/Formstyles';
import {
  TDefaultLanguageSettings,
  TInstitutionFeatureFlagValues,
} from '../../../types/TFeatureFlags';
import { StyledFormWrapper } from '../../common/featureFlags/FeatureFlagFormStyles';
import { URL_REGEX } from '../../placeFeatures/edit/PlaceFeatureEdit';
import IconPicker from '../../iconPicker/IconPicker';
import { getInitialTitleLocalizations } from '../../../utils/featureFlagsUtils';
import FeatureEditLanguagesSection from '../../common/featureFlags/FeatureEditLanguagesSection';

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const schema = (t: TFunction, validateIcon: boolean) => {
  return Yup.object().shape({
    title: Yup.string().required(t('form.fieldRequired')),
    type: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value?: TSelectItem) => !!value,
    ),
    value: Yup.string()
      .required(t('form.fieldRequired'))
      .when('type', (type: TSelectItem) => {
        if (type.value === InstitutionFeatureFlagEnum.Website) {
          return Yup.string()
            .matches(URL_REGEX, t('form.urlFormat'))
            .required(t('form.fieldRequired'));
        } else if (type.value === InstitutionFeatureFlagEnum.Email) {
          return Yup.string().required().email(t('common.validation.email'));
        } else {
          return Yup.string().required(t('form.fieldRequired'));
        }
      }),
    icon: validateIcon ? iconValidation(t, false) : Yup.mixed().test(() => true),
  });
};

type Props = {
  featureFlag?: InstitutionFeatureFlag;
  onSubmit: (values: TInstitutionFeatureFlagValues) => Promise<void>;
  defaultLanguageSettings: TDefaultLanguageSettings;
};

const InstitutionFeatureEdit: FC<Props> = ({ featureFlag, onSubmit, defaultLanguageSettings }) => {
  const { t } = useTranslation();

  const initialValues: TInstitutionFeatureFlagValues = {
    icon: featureFlag?.icon ? [featureFlag?.icon] : null,
    title: featureFlag?.title || '',
    value: featureFlag?.value || '',
    type: featureFlag?.type
      ? { value: featureFlag?.type, label: featureFlag?.type }
      : {
          value: InstitutionFeatureFlagEnum.FeatureFlag,
          label: InstitutionFeatureFlagEnum.FeatureFlag,
        },
    feIcon: featureFlag?.feIcon,
    titleLocalizations: getInitialTitleLocalizations(featureFlag?.titleLocalizations || undefined),
  };
  const [areLanguages, setAreLanguages] = useState(!!initialValues.titleLocalizations);

  const [pickedIcon, setPickedIcon] = useState<TInstitutionFeatureFlagValues['feIcon']>(
    initialValues.feIcon,
  );

  const typeOptions = useMemo(
    () =>
      Object.values(InstitutionFeatureFlagEnum).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, !pickedIcon)),
  });

  const submit = async (values: TInstitutionFeatureFlagValues) => {
    return onSubmit(values);
  };

  const { handleSubmit, formState, control, register, setValue } = methods;
  const { errors, isSubmitting } = formState;

  const onIconClick = (value: TInstitutionFeatureFlagValues['feIcon']) => {
    setValue('icon', null);
    setValue('feIcon', value);
    setPickedIcon(value);
  };

  const onIconChange = (files?: (File | ImageFile)[]) => {
    if (files) {
      setValue('feIcon', null);
      setPickedIcon(null);
    }
  };

  const onLanguagesSectionVisibilityChanged = (open: boolean) => {
    setAreLanguages(open);
    if (!open) {
      setValue('titleLocalizations', undefined);
    }
  };

  return (
    <Wrapper>
      <StyledFormWrapper>
        <FormProvider {...methods}>
          <IconsWrapper>
            <IconPicker
              pickedIcon={pickedIcon}
              onIconClick={onIconClick}
              testId={'InstitutionFeatureEdit#IconPicker'}
              label={t('institutions.features.form.pick')}
            />
            <FormUpload
              control={control}
              name="icon"
              dropzoneLabel={t('places.features.form.icon')}
              t={t}
              testId="InstitutionFeatureEdit#Icon"
              onChange={onIconChange}
              label={t('institutions.features.form.customIcon')}
              accept={SUPPORTED_WEB_IMAGE_FORMATS}
              fileTypeErrorLabel={t('common.validation.png')}
            />
          </IconsWrapper>
          <FormInput
            {...register('title')}
            label={t('places.features.form.title')}
            error={errors.title}
            defaultValue={initialValues.title ?? ''}
            testId="InstitutionFeatureEdit#Title"
          />
          <FormSelect
            control={control}
            name="type"
            label={t('institutions.features.form.type')}
            error={get(errors, 'type')}
            options={typeOptions}
            defaultValue={initialValues.type ?? ''}
            testId="FeatureFlagEdit#Type"
          />
          <FormInput
            {...register('value')}
            label={t('institutions.features.form.value')}
            error={errors.value}
            testId="FeatureFlagEdit#Value"
          />

          <SwitchableFormSection
            title={t('places.features.form.titleLanguages')}
            description={t('places.features.form.titleLanguagesDescription')}
            onVisibilityChanged={onLanguagesSectionVisibilityChanged}
            initiallyOpened={areLanguages}
            testId={'FeatureFlagEdit#languagesSwitch'}
          >
            <SwitchableSectionContentWrapper>
              <FeatureEditLanguagesSection
                defaultLanguageSettings={defaultLanguageSettings}
                initialValues={initialValues.titleLocalizations}
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>

          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
              testId="InstitutionFeatureEdit#CreateFlag"
            >
              {featureFlag
                ? t('places.features.form.updateFlag')
                : t('places.features.form.createFlag')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </StyledFormWrapper>
    </Wrapper>
  );
};

export default InstitutionFeatureEdit;
