import { captureException } from '@sentry/react';
import {
  CategoryType,
  InstitutionPlaceRole,
  useCategoriesQuery,
  useSaAddInstitutionMutation,
  useSaAddInstitutionRoleMutation,
  useSaInstitutionQuery,
  useSaInstitutionRoleQuery,
  useSaUpdateInstitutionMutation,
  useSaVisitorInterestsQuery,
} from '@sim-admin-frontends/data-access';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  filterCategories,
  useUploadImage,
  useSearchTerm,
  SuppportedLanguages,
} from '@sim-admin-frontends/utils-shared';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useGenerateInstitutionActions } from '../../../hooks/actionButtons/useGenerateInstitutionActions';
import NotFound from '../../../routing/NotFound';
import ROUTES from '../../../routing/routes';
import { TInstitutionFormVariables } from '../../../types/TInstitution';
import { getCategoriesOptions } from '../../../utils/categoriesUtils';
import InstitutionEdit from './InstitutionEdit';
import {
  appendFeatureFlagsToInstitution,
  prepareCreationData,
  prepareUpdateData,
} from '../../../utils/institutionUtils';
import { transformPlaces } from '../../../utils/placeUtils';
import { WarningText } from '../../common/Texts/Texts';
import useParsedVisitorInterestsOptions from '../../../hooks/useParsedVisitorInterests';

export const INSTITUTION_TOAST_ID = 'institutionsToast';

const useInstitutionVisitorMode = (initialVisitorMode?: boolean) => {
  const [visitorMode, setVisitorMode] = useState<boolean | undefined>(initialVisitorMode);

  useEffect(() => {
    setVisitorMode(initialVisitorMode);
  }, [initialVisitorMode]);

  return { visitorMode, setVisitorMode };
};

const getRole = (role: string) => {
  switch (role) {
    case InstitutionPlaceRole.City:
      return InstitutionPlaceRole.City;
    case InstitutionPlaceRole.ElectedOfficials:
      return InstitutionPlaceRole.ElectedOfficials;
    case InstitutionPlaceRole.Official:
      return InstitutionPlaceRole.Official;
    case InstitutionPlaceRole.Religional:
      return InstitutionPlaceRole.Religional;
    case InstitutionPlaceRole.Unions:
      return InstitutionPlaceRole.Unions;
    case InstitutionPlaceRole.LocalBusiness:
      return InstitutionPlaceRole.LocalBusiness;
    case InstitutionPlaceRole.Unofficial:
    default:
      return InstitutionPlaceRole.Unofficial;
  }
};

type InstitutionEditContainerProps = {
  id?: string;
  placeId?: string;
};

const InstitutionEditContainer: FC<InstitutionEditContainerProps> = ({ id, placeId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { searchTerm, onSearch } = useSearchTerm();
  const pageTitle = id ? t('institutions.editPageTitle') : t('institutions.createPageTitle');
  const pageCaption = id ? t('institutions.editPageCaption') : t('institutions.createPageCaption');

  const { uploadFormImages } = useUploadImage();
  const { mutateAsync: addInstitution } = useSaAddInstitutionMutation();
  const { mutateAsync: updateInstitution } = useSaUpdateInstitutionMutation();
  const { mutateAsync: addRole } = useSaAddInstitutionRoleMutation();

  const { data, isLoading, isError, refetch } = useSaInstitutionQuery(
    {
      id: id || '',
    },
    {
      enabled: !!id,
    },
  );

  const institution = data?.institution;
  const instituionFeatureFlags = institution?.info?.featureFlags;
  const institutionPlaceId = data?.institution?.places?.[0]?.id;
  const institutionVisitorMode = data?.institution?.visitorMode || undefined;

  const { visitorMode, setVisitorMode } = useInstitutionVisitorMode(institutionVisitorMode);

  const {
    data: visitorInterestsData,
    isLoading: isLoadingVisitorInterests,
    isError: isErrorVisitorInterests,
  } = useSaVisitorInterestsQuery({
    lang: SuppportedLanguages.EN,
  });

  const visitorInterestsOptions = useParsedVisitorInterestsOptions(
    visitorInterestsData?.visitorInterests.visitorInterests,
  );

  const {
    data: allPlaces,
    isError: isErrorPlaces,
    isLoading: isLoadingPlaces,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
  } = useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTerm });

  const {
    data: allCategories,
    isError: isErrorCategories,
    isLoading: isLoadingCategories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.AppInterests,
      visitorMode: visitorMode,
    },
  });

  const categoryOptions = useMemo(() => {
    // TODO: remove when backend sends categories properly
    const filteredCategories = filterCategories(allCategories?.categoriesBy.categories, {
      filterLegacy: true,
    });

    return getCategoriesOptions(filteredCategories || []);
  }, [allCategories?.categoriesBy.categories]);

  const places = useMemo(
    () => allPlaces?.pages?.flatMap((page) => page.places.places) || [],
    [allPlaces?.pages],
  );

  const {
    data: institutionRoleData,
    isLoading: isLoadingRole,
    isError: isErrorRole,
  } = useSaInstitutionRoleQuery(
    {
      id: id || '',
    },
    {
      enabled: !!id && !!institutionPlaceId,
    },
  );

  const placeOptions = useMemo(() => {
    if (places) {
      return transformPlaces(places).sort((a, b) => a.label.localeCompare(b.label));
    }
    return places;
  }, [places]);

  const initialPlaceValues = useMemo(() => {
    if (!placeId) {
      return undefined;
    }

    return places
      ?.filter((place) => place.id === placeId)
      .map((place) => ({ label: place.name, value: place.id }));
  }, [placeId, places]);

  const { detailActions, renderModal } = useGenerateInstitutionActions({
    institutionId: id || placeId || '',
    refetch,
    isEditPage: true,
  });

  const onPlacesMenuScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const onSubmit = async (values: TInstitutionFormVariables) => {
    try {
      loadingToast(t('institutions.createToast.loading'), {
        toastId: INSTITUTION_TOAST_ID,
      });
      const creationData = prepareCreationData(values, instituionFeatureFlags || undefined);
      const roleToSet = getRole(values.role.value);

      const inPlaces =
        creationData.places?.map((place) => ({
          placeId: place.placeId,
          role: roleToSet,
        })) || [];
      if (id) {
        const updateData = await prepareUpdateData(creationData, values, id, uploadFormImages);
        await updateInstitution({ id: id || '', institution: updateData });

        await addRole({ id, inPlaces });
      } else {
        // create feature flags from WEBSITE, PHONE, ADDRESS, EMAIL
        const appendedFFCreationData = appendFeatureFlagsToInstitution(creationData, values);
        // add institution without images
        const result = await addInstitution({
          institution: appendedFFCreationData,
        });
        const newId = result.addInstitution.id;

        await addRole({ id: newId, inPlaces });
        // once we acquired institutionUuid upload images with id and update institution with branding links
        const updateData = await prepareUpdateData(
          appendedFFCreationData,
          values,
          newId,
          uploadFormImages,
        );
        await updateInstitution({ id: newId, institution: updateData });
      }

      updateToast(
        INSTITUTION_TOAST_ID,
        id ? t('institutions.editToast.success') : t('institutions.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(generatePath(ROUTES.institutions.path));
    } catch (err) {
      updateToast(INSTITUTION_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  if (isLoading || isLoadingRole)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const isErrorFinal =
    isError || isErrorPlaces || isErrorCategories || isErrorRole || isErrorVisitorInterests;

  if (isErrorFinal)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !institution)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="InstitutionsEdit#PageHeader" />
      {!!id && <WarningText>{t('common.warningText')}</WarningText>}
      <InstitutionEdit
        initialPlaceValues={initialPlaceValues}
        institution={institution}
        places={placeOptions}
        categories={categoryOptions}
        onSubmit={onSubmit}
        role={institutionRoleData?.institution?.inPlaces?.[0]?.role ?? undefined}
        onPlacesMenuScrollToBottom={onPlacesMenuScrollToBottom}
        isLoadingPlaces={isLoadingPlaces}
        onPlacesSearch={onSearch}
        onVisitorModeChange={setVisitorMode}
        isLoadingCategories={isLoadingCategories}
        visitorInterestsOptions={visitorInterestsOptions}
        isLoadingVisitorInterests={isLoadingVisitorInterests}
      />
      {renderModal()}
    </>
  );
};

export default InstitutionEditContainer;
