import { FormSwitcher, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { PlaceFeatureFlag } from '@sim-admin-frontends/data-access-admin-be';
import styled from 'styled-components';
import { CellProps } from 'react-table';

import PlaceFeaturesTableMenu from './PlaceFeaturesTableMenu';
import { getFeatureFlagPlaceholderImage } from '../../../utils/featureFlagsUtils';

const Image = styled.img`
  aspect-ratio: 1;
  width: 25px;
`;

export const createFeatureFlagsTableColumns = (
  onClick: (i: number) => void,
  onDeleteClick: (i: number) => void,
  placeId: string,
) => {
  const columns: TExtendedColumn<PlaceFeatureFlag>[] = [
    {
      Header: 'Icon',
      accessor: 'icon',
      disableSortBy: true,
      Cell: ({ cell, row }: CellProps<PlaceFeatureFlag>) =>
        cell?.value?.links?.self ? (
          <Image src={cell?.value?.links?.self} alt={'icon'} />
        ) : (
          getFeatureFlagPlaceholderImage(row.original)
        ),
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
    },
    {
      Header: 'Desc',
      accessor: 'description',
      disableSortBy: true,
    },
    {
      Header: 'Url',
      accessor: 'url',
      disableSortBy: true,
    },
    {
      Header: 'Toggle',
      accessor: 'enabled',
      Cell: (all: CellProps<PlaceFeatureFlag>) => (
        <FormSwitcher
          initialValue={all.value}
          onChange={() => {
            onClick(all.row.index);
          }}
          testId={`PlacesFeature#Flag${all.row.index}`}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Visitor mode',
      accessor: 'visitorMode',
      disableSortBy: true,
      Cell: ({ cell }: CellProps<PlaceFeatureFlag>) => (
        <div>{(cell.value ?? false).toString().toUpperCase()}</div>
      ),
    },
    {
      Header: '',
      id: '0',
      testId: 'PlaceFeaturesTable#dots',
      disableSortBy: true,
      Cell: ({ row }: CellProps<PlaceFeatureFlag>) => {
        return (
          <PlaceFeaturesTableMenu
            placeId={placeId}
            onDeleteClick={onDeleteClick}
            featureIndex={row.index}
            testId={`PlaceFeatures#TableMenu${row.index}-${row.original.url}`}
          />
        );
      },
    },
  ];
  return columns;
};
