import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Error,
  SpinnerWrapper,
  TableActionHeader,
  useSearchFilter,
} from '@sim-admin-frontends/ui-shared';
import { useInfiniteLandmarksQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { LandmarksView } from './LandmarksView';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';

const LANDMARKS_PAGE_SIZE = 60;

type LandmarksViewContainerProps = {
  placeId: string;
};

const LandmarksViewContainer = ({ placeId }: LandmarksViewContainerProps) => {
  const { t } = useTranslation();
  const placeUuid = placeId ?? '';
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'LandmarksView#Search',
    minNumOfChars: MIN_NUMBER_OF_SEARCH_CHARS,
  });
  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteLandmarksQuery({
      pageSize: LANDMARKS_PAGE_SIZE,
      placeUuid,
      query: filter,
    });

  const columns = createTableColumns(t, refetch, placeUuid);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  if (isError) {
    return (
      <>
        <TableActionHeader prependItem={renderFilterComponent()}>
          <Link
            to={generatePath(ROUTES.landmarkEdit.path, {
              placeId: placeId,
            })}
          >
            <Button testId="LandmarksTabs#AddLandmark">
              {placeId ? t('landmarks.addLandmarkToPlace') : t('landmarks.addLandmark')}
            </Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }
  const landmarks = data?.pages?.[currentPageIndex]?.landmarks?.landmarks || [];

  return (
    <>
      <TableActionHeader prependItem={renderFilterComponent()}>
        <Link
          to={generatePath(ROUTES.landmarkEdit.path, {
            placeId: placeId,
          })}
        >
          <Button testId="LandmarksTabs#AddLandmark">
            {placeId ? t('landmarks.addLandmarkToPlace') : t('landmarks.addLandmark')}
          </Button>
        </Link>
      </TableActionHeader>
      <LandmarksView
        tablePageSize={LANDMARKS_PAGE_SIZE}
        loading={isLoading || isFetchingNextPage}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        hasNextPage={hasNextPage ?? false}
        columns={columns}
        data={landmarks}
      />
    </>
  );
};

export { LandmarksViewContainer };
