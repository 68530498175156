import styled from 'styled-components';
import { FormTimePicker } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  TInfluencerSpotFormValues,
  TInfluencerStartEndFormValues,
} from '../../../types/TInfluencer';

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacing16};
  margin-top: ${({ theme }) => theme.spaces.spacing32};
`;

type Props = {
  initialValues: TInfluencerStartEndFormValues;
  setTimeValueAndTriggerOthers: (
    name: 'startTime' | 'endTime' | 'startTimePeriod' | 'endTimePeriod',
  ) => (value?: string) => void;
};

const InfluencerSpotStartEndSection = ({ initialValues, setTimeValueAndTriggerOthers }: Props) => {
  const { control } = useFormContext<TInfluencerSpotFormValues>();
  const { t } = useTranslation();

  return (
    <TimeWrapper>
      <FormTimePicker
        initialTime={initialValues.startTime}
        initialDayPeriod={initialValues.startTimePeriod}
        label={t('influencerSpos.startTime')}
        control={control}
        name="startTime"
        onChange={setTimeValueAndTriggerOthers('startTime')}
        onDayPeriodChanged={setTimeValueAndTriggerOthers('startTimePeriod')}
        testId="InfluencerSpotEdit#startTime"
        isBold
      />
      <FormTimePicker
        initialTime={initialValues.endTime}
        initialDayPeriod={initialValues.endTimePeriod}
        label={t('influencerSpos.endTime')}
        control={control}
        name="endTime"
        onChange={setTimeValueAndTriggerOthers('endTime')}
        onDayPeriodChanged={setTimeValueAndTriggerOthers('endTimePeriod')}
        testId="InfluencerSpotEdit#endTime"
        isBold
      />
    </TimeWrapper>
  );
};

export default InfluencerSpotStartEndSection;
