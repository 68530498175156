import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGeneratePlaceActions } from '../../../hooks/actionButtons/useGeneratePlaceActions';

export interface PlacesViewTableMenuProps extends TBaseProps {
  placeId: string;
  refetch: () => void;
}

const PlacesViewTableMenu: FC<PlacesViewTableMenuProps> = ({ placeId, refetch, testId }) => {
  const { listActions: items, renderModal } = useGeneratePlaceActions({ placeId, refetch });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { PlacesViewTableMenu };
