import {
  FormSelect,
  FormSwitcherWithLabel,
  SwitchableFormSection,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MessagingProblemConnectorType } from '@sim-admin-frontends/data-access-admin-be';

import { TPlaceFeatureFlagValues } from '../../../types/TFeatureFlags';

type Props = {
  initialValues: TPlaceFeatureFlagValues;
  reportProblemCategoriesOptions: TSelectItems;
  reportProblemCategoriesIsLoading: boolean;
  onReportProblemCategoriesScrollBottom: () => void;
};

const PlaceFeatureEditReportProblemSection: FC<Props> = ({
  initialValues,
  reportProblemCategoriesOptions,
  reportProblemCategoriesIsLoading,
  onReportProblemCategoriesScrollBottom,
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const [isPothole, setIsPothole] = useState(
    !!initialValues.messagingProblemSettings?.potholeCategory?.value,
  );

  const messagingCategoriesOptions = useMemo(() => {
    return Object.values(MessagingProblemConnectorType).map((v) => ({
      value: v,
      label: v,
    }));
  }, []);

  const onisLaneAvailalableChange = (value: boolean) => {
    setValue('messagingProblemSettings.isLaneAvailable', value);
  };
  const onIsFullnameRequiredChange = (value: boolean) => {
    setValue('isFullnameRequired', value);
  };
  const onDisableProblemsOnMapChange = (value: boolean) => {
    setValue('disableProblemsOnMap', value);
  };
  const onShowCategoriesChange = (value: boolean) => {
    setValue('messagingProblemSettings.showCategories', value);
  };
  const onIsMediaRequiredChange = (value: boolean) => {
    setValue('messagingProblemSettings.isMediaRequired', value);
  };
  const onIsPotholeVisibilityChange = (value: boolean) => {
    setIsPothole(value);
    if (value) {
      return;
    }

    setValue('messagingProblemSettings.potholeCategory', undefined);
  };

  return (
    <>
      <FormSelect
        control={control}
        clearable
        isMulti
        name="messagingProblemSettings.connectorTypes"
        label={t('places.features.form.connector')}
        options={messagingCategoriesOptions}
        defaultValue={initialValues.messagingProblemSettings?.connectorTypes}
        testId="FeatureFlagEdit#messagingProblemCategoryUuid"
      />

      <SwitchableFormSection
        controlsPadding
        title={t('places.features.form.isPothole')}
        description={t('places.features.form.potholeDescription')}
        onVisibilityChanged={onIsPotholeVisibilityChange}
        initiallyOpened={isPothole}
        testId={'FeatureFlagEdit#potholeSwitch'}
      >
        <FormSelect
          control={control}
          clearable
          name="messagingProblemSettings.potholeCategory"
          label={t('places.features.form.potholeCategory')}
          options={reportProblemCategoriesOptions}
          defaultValue={initialValues.messagingProblemSettings?.potholeCategory}
          testId="FeatureFlagEdit#potholeCategory"
          onMenuScrollToBottom={onReportProblemCategoriesScrollBottom}
          isLoading={reportProblemCategoriesIsLoading}
        />
      </SwitchableFormSection>
      <FormSwitcherWithLabel
        isVertical
        testId="FeatureFlagEdit#isLaneAvailalable"
        label={t('places.features.isLaneAvailable')}
        initialValue={!!initialValues.messagingProblemSettings?.isLaneAvailable}
        onChange={onisLaneAvailalableChange}
      />
      <FormSwitcherWithLabel
        isVertical
        testId="FeatureFlagEdit#disableProblemsOnMap"
        label={t('places.features.disableProblemsOnMap')}
        initialValue={!!initialValues.disableProblemsOnMap}
        onChange={onDisableProblemsOnMapChange}
      />
      <FormSwitcherWithLabel
        isVertical
        testId="FeatureFlagEdit#isFullnameRequired"
        label={t('places.features.isFullnameRequired')}
        initialValue={!!initialValues.isFullnameRequired}
        onChange={onIsFullnameRequiredChange}
      />
      <FormSwitcherWithLabel
        isVertical
        testId="FeatureFlagEdit#showCategories"
        label={t('places.features.showCategories')}
        initialValue={!!initialValues.messagingProblemSettings?.showCategories}
        onChange={onShowCategoriesChange}
        disabled={isPothole}
        invokeOnDisabled
      />
      <FormSwitcherWithLabel
        isVertical
        testId="FeatureFlagEdit#isMediaRequired"
        label={t('places.features.isMediaRequired')}
        initialValue={!!initialValues.messagingProblemSettings?.isMediaRequired}
        onChange={onIsMediaRequiredChange}
      />
    </>
  );
};

export default PlaceFeatureEditReportProblemSection;
