import { UseInfiniteQueryOptions } from 'react-query';

import {
  InstitutionsByFilterInput,
  InstitutionsBySearchFilterInput,
  SaAllInstitutionsQuery,
  SaInstitutionsByQuery,
  SaInstitutionsBySearchQuery,
  useInfiniteGraphQLQuery,
  useSaAllInstitutionsQuery,
  useSaInstitutionsByQuery,
  useSaInstitutionsBySearchQuery,
} from '../..';

const DEFAULT_INSTITUTIONS_PAGE_SIZE = 10;

export const useInfiniteSAAllInstitutionsQuery = (
  pageSize?: number,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaAllInstitutionsQuery) => lastPage.allInstitutions.nextToken;
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    nextToken: pageParam,
    pageSize,
  });

  return useInfiniteGraphQLQuery(useSaAllInstitutionsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled ?? true,
  });
};

export const useInfiniteSAInstitutionsByQuery = (
  filter: InstitutionsByFilterInput,
  pageSize?: number,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaInstitutionsByQuery) => lastPage.institutionsBy.nextToken;
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
    pageSize,
  });
  return useInfiniteGraphQLQuery(useSaInstitutionsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled ?? true,
  });
};

export const useInfiniteSAInstitutionsBySearchQuery = (
  filter: InstitutionsBySearchFilterInput,
  enabled?: boolean,
  pageSize?: number,
) => {
  const getNextPageParam = (
    lastPage: SaInstitutionsBySearchQuery,
    allPages: SaInstitutionsBySearchQuery[],
  ) =>
    lastPage.institutionsBySearch.institutions.length ===
    (pageSize ?? DEFAULT_INSTITUTIONS_PAGE_SIZE)
      ? allPages.length * (pageSize ?? DEFAULT_INSTITUTIONS_PAGE_SIZE)
      : undefined;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    pageOffset: Number(pageParam ?? 0),
    pageSize: pageSize ?? DEFAULT_INSTITUTIONS_PAGE_SIZE,
  });

  return useInfiniteGraphQLQuery(useSaInstitutionsBySearchQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: enabled ?? true,
  });
};
