import {
  UploadType,
  useSaInstitutionQuery,
  useUpdateInstitutionInfoMutation,
} from '@sim-admin-frontends/data-access';
import {
  SpinnerWrapper,
  Spinner,
  Error,
  loadingToast,
  updateToast,
  TToastType,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { useUploadImage } from '@sim-admin-frontends/utils-shared';

import NotFound from '../../../routing/NotFound';
import InstitutionFeatureEdit from './InstitutionFeatureEdit';
import ROUTES from '../../../routing/routes';
import { transformFeatureFlagsValues } from '../../../utils/institutionFeatureFlagsUtils';
import { TInstitutionFeatureFlagValues } from '../../../types/TFeatureFlags';

const SHARED_TOAST_ID = 'institutionFeaturesEditToast';

type Props = {
  institutionId: string;
  featureIndex: string;
};

const InstitutionFeatureEditContainer: FC<Props> = ({ institutionId, featureIndex }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { uploadFormImages } = useUploadImage();
  const { data, isLoading, isError, refetch } = useSaInstitutionQuery({
    id: institutionId,
  });
  const { mutateAsync: updateInstitutionInfo } = useUpdateInstitutionInfoMutation();
  const institutionInfo = data?.institution?.info;
  const featureFlags = institutionInfo?.featureFlags || [];

  const featureFlag = featureIndex ? featureFlags[Number(featureIndex)] : undefined;

  const defaultLanguageSettings = useMemo(
    () => ({ language: data?.institution?.lang || undefined }),
    [data],
  );

  const onSubmit = async (values: TInstitutionFeatureFlagValues) => {
    if (!institutionInfo) {
      return;
    }
    try {
      loadingToast(t('institutions.createToast.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const iconImage = await uploadFormImages(UploadType.FeatureFlagIcons, values?.icon, {
        institutionId,
      });
      const { newFlag, prevFlags } = transformFeatureFlagsValues(
        featureFlags,
        values,
        iconImage,
        featureIndex,
      );

      await updateInstitutionInfo({
        id: institutionId,
        info: {
          ...institutionInfo,
          featureFlags: newFlag ? [...prevFlags, newFlag] : [...prevFlags],
        },
      });
      updateToast(
        SHARED_TOAST_ID,
        institutionId ? t('institutions.editToast.success') : t('institutions.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(generatePath(ROUTES.institutionFeatures.path, { id: institutionId }));
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const isNotFound =
    (institutionId && !data) || (featureIndex && !featureFlag) || (featureIndex && !featureFlags);

  if (isNotFound) {
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  }

  return (
    <InstitutionFeatureEdit
      featureFlag={featureFlag}
      onSubmit={onSubmit}
      defaultLanguageSettings={defaultLanguageSettings}
    />
  );
};

export default InstitutionFeatureEditContainer;
