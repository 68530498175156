import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

const singleSelectorSchema = (t: TFunction, fields: string[]) => {
  return Yup.object()
    .strict()
    .shape({
      field: Yup.string().strict().oneOf(fields),
      selector: Yup.array()
        .strict()
        .of(
          Yup.object()
            .strict()
            .shape({
              collect: Yup.array()
                .strict()
                .of(Yup.string().strict().required())
                .required(`${t('form.fieldRequired')}: collect`)
                .min(1, `${t('form.collectNoEmpty')}`),
              regex: Yup.string().strict().nullable(),
              remove: Yup.array().strict().of(Yup.string().strict()),
              replace: Yup.array().strict().of(Yup.array().strict().of(Yup.string().strict())),
            }),
        ),
    });
};

const navigantionSelectorSchema = (fields: string[]) => {
  return Yup.object()
    .strict()
    .shape({
      field: Yup.string().strict().oneOf(fields),
      selector: Yup.array()
        .strict()
        .of(
          Yup.object()
            .strict()
            .shape({
              collect: Yup.array().strict().of(Yup.string().strict().required()),
              regex: Yup.string().strict().nullable(),
              remove: Yup.array().strict().of(Yup.string().strict()),
              replace: Yup.array().strict().of(Yup.array().strict().of(Yup.string().strict())),
            }),
        ),
    });
};

export const schema = (t: TFunction, fields: string[]) => {
  return Yup.object().shape({
    code: Yup.string().required(t('form.fieldRequired')),
    imports: Yup.array().strict().of(Yup.string()),
    maxPages: Yup.number().required(t('form.fieldRequired')),
    nextPageSelector: Yup.array().strict().of(navigantionSelectorSchema(fields)),
    nodeSelector: Yup.array().strict().of(Yup.string().strict()),
    nodeSelectors: Yup.array().strict().of(singleSelectorSchema(t, fields)),
    removePageTrash: Yup.array().strict().of(Yup.string().strict()),
    subpageSelector: Yup.array().strict().of(navigantionSelectorSchema(fields)),
    subpageSelectors: Yup.array().of(singleSelectorSchema(t, fields)),
    subpageUrlFormat: Yup.string().nullable(),
    variables: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().strict(),
        value: Yup.string().strict(),
      }),
    ),
  });
};
