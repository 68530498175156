import { NotificationStatus } from '@sim-admin-frontends/data-access';
import {
  PageHeader,
  Select,
  Table,
  TableActionHeader,
  TExtendedColumn,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

import { TMappedNotificationItem, TMappedNotifications } from '../../types/TNotifications';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
`;

interface DashboardViewProps {
  columns: TExtendedColumn<TMappedNotificationItem>[];
  itemsCount: number;
  data: TMappedNotifications;
  period: string;
  onStatusFilterChange: (value: readonly TSelectItem[] | null) => void;
  isLoading: boolean;
}

const DashboardView: FC<DashboardViewProps> = ({
  columns,
  itemsCount,
  data,
  period,
  onStatusFilterChange,
  isLoading,
}) => {
  const { t } = useTranslation();

  const notificationOptions = Object.values(NotificationStatus).map((value) => ({
    label: value,
    value: value,
  }));

  const defaultNotificationValue = {
    label: NotificationStatus.Sent,
    value: NotificationStatus.Sent,
  };

  return (
    <>
      <TableActionHeader
        prependItem={
          <PageHeader
            title={t('mainMenu.dashboard')}
            caption={t('dashboard.caption')}
            info={period}
            testId="DashboardTabs#PageHeader"
          />
        }
      >
        <Select
          options={notificationOptions}
          onChange={onStatusFilterChange}
          defaultValue={defaultNotificationValue}
          clearable={false}
        />
      </TableActionHeader>
      <Wrapper>
        <ResponsiveContainer width="100%">
          <Table
            data={data}
            hasPagination={false}
            columns={columns}
            itemsCount={itemsCount}
            loading={isLoading}
          />
        </ResponsiveContainer>
      </Wrapper>
    </>
  );
};

export { DashboardView };
