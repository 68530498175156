import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormError,
  FormInput,
  FormSelect,
  PlusSmallIcon,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { get, UseFormReturn, useWatch } from 'react-hook-form';

import {
  TPlaceFeatureFlagValues,
  TReminderCategorySettings,
  TReminderSettingsValues,
} from '../../types/TFeatureFlags';
import ReminderCategorySection from './ReminderCategorySection';
import { getReminderTypeOptions, getReminderVisualizationOptions } from '../../utils/reminderUtils';

type RemiderEditProps = {
  methods: UseFormReturn<TPlaceFeatureFlagValues>;
  initialValues?: TReminderSettingsValues;
  reminderCategoriesOptions: TSelectItems;
  isFetchingReminderCategories?: boolean;
  onReminderCategoriesScrollToBottom?: (e: Event) => void;
  institutionOptions: TSelectItems;
  isFetchingInstitutions?: boolean;
  onInstitutionsSearch?: (text: string) => void;
  onInstitutionsScrollToBottom?: (e: Event) => void;
};

const ReminderEdit: FC<RemiderEditProps> = ({
  methods,
  initialValues,
  reminderCategoriesOptions,
  isFetchingReminderCategories,
  onReminderCategoriesScrollToBottom,
  institutionOptions,
  isFetchingInstitutions,
  onInstitutionsSearch,
  onInstitutionsScrollToBottom,
}) => {
  const { t } = useTranslation();
  const { formState, setValue, control, register, trigger } = methods;
  const { errors } = formState;

  const [previewReminderCategories] = useWatch({
    name: ['reminderSettings.categories'],
    control,
  });

  const reminderTypeOptions = getReminderTypeOptions(t);
  const visualizationOptions = getReminderVisualizationOptions(t);

  const onDeleteCategoryClick = (index: number) => {
    if (index < 0 || !previewReminderCategories || index > previewReminderCategories?.length) {
      return;
    }

    const splicedReminderCategories = [...previewReminderCategories];
    splicedReminderCategories.splice(index, 1);
    setValue('reminderSettings.categories', splicedReminderCategories);
  };

  const onAddCategoryClick = () => {
    const defaultReminderCategory: TReminderCategorySettings = {
      code: undefined,
      jsonPath: undefined,
    };
    setValue(
      'reminderSettings.categories',
      previewReminderCategories
        ? [...previewReminderCategories, defaultReminderCategory]
        : [defaultReminderCategory],
    );
    trigger('reminderSettings.categories');
  };

  const categoriesError = get(errors, 'reminderSettings.categories');

  return (
    <>
      <FormSelect
        label={t('places.features.form.reminderType')}
        options={reminderTypeOptions}
        control={control}
        error={get(errors, 'reminderSettings.type')}
        name={'reminderSettings.type'}
        defaultValue={initialValues?.type ?? reminderTypeOptions[0]}
        testId="FeatureFlagEdit#reminders-reminderType"
      />
      <FormSelect
        searchable
        clearable
        label={t('places.features.form.institution')}
        error={get(errors, 'reminderSettings.institution')}
        options={institutionOptions}
        defaultValue={initialValues?.institution}
        control={control}
        name={'reminderSettings.institution'}
        testId="FeatureFlagEdit#reminders-institution"
        onMenuScrollToBottom={onInstitutionsScrollToBottom}
        onSearch={onInstitutionsSearch}
        isLoading={isFetchingInstitutions}
      />
      <FormSelect
        label={t('places.features.form.visualization.title')}
        options={visualizationOptions}
        control={control}
        error={get(errors, 'reminderSettings.visualization')}
        name={'reminderSettings.visualization'}
        defaultValue={initialValues?.visualization}
        testId="FeatureFlagEdit#reminders-visualization"
      />

      <FormInput
        testId="FeatureFlagEdit#reminders-parserSettings-parser"
        label={t('places.features.form.parser')}
        {...register('reminderSettings.parserSettings.parser')}
        defaultValue={initialValues?.parserSettings.parser ?? ''}
        error={errors.reminderSettings?.parserSettings?.parser}
      />
      <FormInput
        testId="FeatureFlagEdit#reminders-parserSettings-apiUrl"
        label={t('places.features.form.parserApiUrl')}
        {...register('reminderSettings.parserSettings.apiUrl')}
        defaultValue={initialValues?.parserSettings.apiUrl ?? ''}
        error={errors.reminderSettings?.parserSettings?.apiUrl}
      />

      <div>
        {previewReminderCategories?.map((category, index) => (
          <ReminderCategorySection
            methods={methods}
            reminderCategory={category}
            categoryIndex={index}
            onDeleteClick={onDeleteCategoryClick}
            key={index}
            reminderCategoriesOptions={reminderCategoriesOptions}
            isFetchingReminderCategories={isFetchingReminderCategories}
            onReminderCategoriesScrollToBottom={onReminderCategoriesScrollToBottom}
          />
        ))}
        <Button
          variant="tertiary"
          size="smallest"
          onClick={onAddCategoryClick}
          prependIcon={<PlusSmallIcon />}
          testId="FeatureFlagEdit#reminders-addCategory"
        >
          {t('places.features.form.addCategory')}
        </Button>
        {categoriesError && (
          <FormError error={categoriesError} testId="FeatureFlagEdit#reminders-categories-error" />
        )}
      </div>
    </>
  );
};

export default ReminderEdit;
