import { FC } from 'react';
import {
  InstitutionFeatureFlag,
  useSaInstitutionQuery,
  useUpdateInstitutionInfoMutation,
} from '@sim-admin-frontends/data-access';
import {
  SpinnerWrapper,
  Error,
  loadingToast,
  updateToast,
  TToastType,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { captureException } from '@sentry/react';

import InstitutionFeatures from './InstitutionFeatures';

const SHARED_TOAST_ID = 'institutionFeaturesToast';

type Props = {
  institutionId: string;
};

const InstitutionFeaturesContainer: FC<Props> = ({ institutionId }) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useSaInstitutionQuery({
    id: institutionId,
  });
  const { mutateAsync: updateInstitutionInfo } = useUpdateInstitutionInfoMutation();
  const institutionInfo = data?.institution?.info;

  const initialValues = institutionInfo?.featureFlags || [];

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const onSubmit = async (featureFlags: Array<InstitutionFeatureFlag>) => {
    if (!institutionInfo) {
      return;
    }
    try {
      loadingToast(t('common.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const modifiedFeatureFlags = featureFlags.map((flag) => {
        const newFlag = cloneDeep(flag);
        delete newFlag.icon?.links;
        return newFlag;
      });
      await updateInstitutionInfo({
        id: institutionId,
        info: {
          ...institutionInfo,
          featureFlags: modifiedFeatureFlags,
        },
      });
      updateToast(SHARED_TOAST_ID, t('places.features.success'), TToastType.SUCCESS);
    } catch (e) {
      updateToast(SHARED_TOAST_ID, e.message, TToastType.ERROR);
      captureException(e);
    }
  };

  return (
    <InstitutionFeatures
      onSubmit={onSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      institutionId={institutionId}
    />
  );
};

export default InstitutionFeaturesContainer;
