import { Link, generatePath } from 'react-router-dom';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TTabPanelTabs, TabPanel } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { TNotificationsTabTypes } from '../../../types/TNotifications';
import NotificationsEditContainer from '../edit/NotificationsEdit.container';
import VisitorNotificationsEditContainer from '../edit/VisitorNotificationsEdit.container';
import ItineraryNotificationsEditContainer from '../edit/ItineraryNotificationsEdit.container';
import ChatBotNotificationsEditContainer from '../edit/ChatBotNotificationsEdit.container';

export const notificationTabs = [
  TNotificationsTabTypes.CHAT_BOT,
  TNotificationsTabTypes.DEFAULT,
  TNotificationsTabTypes.VISITOR,
  TNotificationsTabTypes.ITINERARY,
];

export const SHARED_TOAST_ID = 'notificationToast';

const NotificationsTabs: FC = () => {
  const { t } = useTranslation();

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.notificationsEdit.path,
    notificationTabs,
  );

  const tabs: TTabPanelTabs = [
    {
      content: <ChatBotNotificationsEditContainer />,
      label: t('notifications.tab.chatBot'),
      testId: 'NotificationTabs#chatBot',
    },
    {
      content: <NotificationsEditContainer />,
      label: t('notifications.tab.default'),
      testId: 'NotificationTabs#default',
    },
    {
      content: <VisitorNotificationsEditContainer />,
      label: t('notifications.tab.visitor'),
      testId: 'NotificationTabs#visitor',
    },
    {
      content: <ItineraryNotificationsEditContainer />,
      label: t('notifications.tab.itinerary'),
      testId: 'NotificationTabs#itinerary',
    },
  ];

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <Link to={generatePath(ROUTES.notificationsEdit.path)} />
    </TabPanel>
  );
};
export default NotificationsTabs;
