import { UseInfiniteQueryOptions } from 'react-query';

import {
  useInfiniteGraphQLQuery,
  SaAllUsersQuery,
  useSaAllUsersQuery,
  AdminUsersFilterInput,
} from '../..';

export const useInfiniteAllUsersQuery = (
  filter: AdminUsersFilterInput,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaAllUsersQuery) => lastPage.adminUsers.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    nextToken: pageParam,
    filter,
  });

  return useInfiniteGraphQLQuery(useSaAllUsersQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled,
  });
};
