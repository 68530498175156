import styled from 'styled-components';
import { BaseSyntheticEvent, FC, useCallback, useMemo, useRef } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import {
  getLangOptionByVal,
  getLangOptions,
  isEmpty,
  isFile,
} from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormTextarea,
  FormUpload,
  FormWrapper,
  TSelectItem,
  TSelectItems,
  MAX_BRANDING_UPLOAD_SIZE,
  SUPPORTED_WEB_IMAGE_FORMATS,
  FormSwitcherWithLabel,
  TSearchItem,
  TSelectRef,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ImageInput,
  InstitutionPlaceRole,
  TSAInstitutionDetail,
} from '@sim-admin-frontends/data-access';
import { generatePath, useHistory } from 'react-router-dom';

import { TInstitutionFormVariables, TRoleSelectItem } from '../../../types/TInstitution';
import { LocationInputValue, PlaceSearchInput } from '../../common/PlaceSearchInput';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { getInitialBrandingValues } from '../../../utils/formUtils';
import ROUTES from '../../../routing/routes';
import { URL_REGEX } from '../../placeFeatures/edit/PlaceFeatureEdit';
import { getInitialPreferences } from '../../../utils/institutionUtils';

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const schema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().required(t('form.fieldRequired')),
    branding: Yup.object().shape({
      avatar: Yup.mixed()
        .test('fileSize', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_BRANDING_UPLOAD_SIZE : true,
        )
        .test('fileType', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
        ),
    }),
    category: Yup.mixed().when('role', (role) =>
      role.value !== InstitutionPlaceRole.LocalBusiness
        ? Yup.mixed().required(t('form.fieldRequired'))
        : Yup.mixed(),
    ),
    website: Yup.string().matches(URL_REGEX, t('form.urlFormat')).required(t('form.fieldRequired')),
    description: Yup.string().required(t('form.fieldRequired')),
    places: Yup.mixed().required(t('form.fieldRequired')),
    preferences: Yup.mixed().optional(),
    contactEmail: Yup.string().email(t('form.emailFormat')),
    location: Yup.object().shape({
      address: Yup.string().required(t('common.validation.required')),
    }),
  });
};

type InstitutionEditProps = {
  onSubmit: (values: TInstitutionFormVariables) => Promise<void>;
  places?: TSelectItems;
  categories?: TSelectItems;
  institution?: TSAInstitutionDetail;
  role?: InstitutionPlaceRole;
  initialPlaceValues?: TSelectItems;
  onPlacesMenuScrollToBottom?: (e: Event) => void;
  isLoadingPlaces?: boolean;
  onPlacesSearch: (text: string) => void;
  onVisitorModeChange: (visitorMode: boolean) => void;
  isLoadingCategories: boolean;
  visitorInterestsOptions?: TSelectItems;
  isLoadingVisitorInterests: boolean;
};

const InstitutionEdit: FC<InstitutionEditProps> = ({
  onSubmit,
  categories,
  places,
  institution,
  role,
  initialPlaceValues,
  onPlacesMenuScrollToBottom,
  isLoadingPlaces,
  onPlacesSearch,
  onVisitorModeChange,
  isLoadingCategories,
  visitorInterestsOptions,
  isLoadingVisitorInterests,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const categoriesRef = useRef<TSelectRef>();
  const roleOptions: TRoleSelectItem[] = [
    { label: t('institutions.form.city'), value: InstitutionPlaceRole.City },
    {
      label: t('institutions.form.electedOfficials'),
      value: InstitutionPlaceRole.ElectedOfficials,
    },
    { label: t('institutions.form.official'), value: InstitutionPlaceRole.Official },
    { label: t('institutions.form.unofficial'), value: InstitutionPlaceRole.Unofficial },
    { label: t('institutions.form.religional'), value: InstitutionPlaceRole.Religional },
    { label: t('institutions.form.union'), value: InstitutionPlaceRole.Unions },
    { label: t('institutions.form.localbusiness'), value: InstitutionPlaceRole.LocalBusiness },
  ];

  const initialValues: TInstitutionFormVariables = useMemo(
    () => ({
      name: institution?.name || '',
      website: institution?.info?.website || '',
      description: institution?.info?.description || '',
      taskCodes: institution?.taskCodes || [],
      category: institution?.category
        ? { label: institution.category.name, value: institution.category.id }
        : undefined,
      places:
        initialPlaceValues ??
        (institution?.places?.map((place) => ({ label: place.name, value: place.id })) || null),
      contactEmail: institution?.info?.contactEmail || '',
      contactPhone: institution?.info?.contactPhone || '',
      branding: getInitialBrandingValues(institution?.branding),
      location: institution?.info?.location || undefined,
      role: roleOptions.find((o) => o.value === role) || roleOptions[0],
      isChatEnabled: institution?.isChatEnabled,
      isRecommended: institution?.isRecommended,
      lang: getLangOptionByVal(institution?.lang || ''),
      visitorMode: institution?.visitorMode || false,
      preferences: getInitialPreferences(institution?.preferences, visitorInterestsOptions),
      countryCode: institution?.countryCode || '',
    }),
    [roleOptions, institution, initialPlaceValues],
  );

  const onIsChatEnabledChange = (value: boolean) => {
    setValue('isChatEnabled', value);
  };

  const onIsRecommendedChange = (value: boolean) => {
    setValue('isRecommended', value);
  };

  const onVisitorModeSwitchChange = (value: boolean) => {
    onVisitorModeChange(value);
    setValue('visitorMode', value);

    if (value !== initialValues.visitorMode) {
      setValue('category', undefined);
      categoriesRef?.current?.setValue(undefined);
      return;
    }

    setValue('category', initialValues.category);
    categoriesRef?.current?.setValue(initialValues.category);
  };

  const submit = async (values: TInstitutionFormVariables) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TInstitutionFormVariables>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, setValue, control, trigger, register } = methods;
  const { errors, isSubmitting } = formState;

  const [isVisitorMode] = useWatch({
    name: ['visitorMode'],
    control,
  });

  const onPlacesInputChange = (placesInput: readonly TSelectItem[] | null) => {
    if (!placesInput) {
      setValue('places', null);
    } else {
      setValue('places', placesInput);
    }
  };

  const onTaskCodesChange = (event: BaseSyntheticEvent) => {
    const text = event.target.value;
    const taskCodes = text.split('\n');
    const transformedCodes = taskCodes.filter((code: string) => code.length > 0);
    setValue('taskCodes', transformedCodes);
    trigger('taskCodes');
  };

  const onLocationValueChange = useCallback(
    (value?: LocationInputValue | null) => {
      setValue(
        'location',
        value?.gps
          ? {
              address: value.name,
              geo: value.gps,
            }
          : undefined,
      );
      trigger('location');
    },
    [setValue, trigger],
  );

  const redirectToPlaceEdit = (place: TSearchItem) => {
    history.push(generatePath(ROUTES.placeEdit.path, { id: place.data.value }));
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('institutions.form.name')}
            {...register('name')}
            error={errors.name}
            testId="InstitutionEdit#name"
          />
          <FormSelect
            label={t('institutions.form.role')}
            error={get(errors, 'role')}
            options={roleOptions}
            control={control}
            name={'role'}
            defaultValue={initialValues.role}
            testId="InstitutionEdit#role"
          />
          <FormSelect
            label={t('institutions.form.category')}
            error={get(errors, 'category')}
            options={categories || []}
            control={control}
            name={'category'}
            defaultValue={initialValues.category}
            testId="InstitutionEdit#category"
            isLoading={isLoadingCategories}
            ref={categoriesRef}
          />
          <FormSectionHeader
            title={t('places.edit.visitorModeSection')}
            description={t('institutions.edit.visitorModeSectionDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="InstitutionEdit#visitorMode"
            label={t('institutions.edit.visitorMode')}
            initialValue={initialValues.visitorMode || false}
            onChange={onVisitorModeSwitchChange}
          />
          {isVisitorMode && (
            <FormSelect
              clearable
              isMulti
              label={t('institutions.form.preferences')}
              error={get(errors, 'preferences')}
              options={visitorInterestsOptions || []}
              control={control}
              name={'preferences'}
              defaultValue={initialValues.preferences || []}
              testId="InstitutionEdit#preferences"
              isLoading={isLoadingVisitorInterests}
              allSelectable
            />
          )}
          <FormSelect
            isMulti
            clearable
            searchable
            label={t('institutions.form.places')}
            error={get(errors, 'places')}
            onChange={onPlacesInputChange}
            options={places || []}
            control={control}
            name={'places'}
            defaultValue={initialValues.places || undefined}
            onMenuScrollToBottom={onPlacesMenuScrollToBottom}
            isLoading={isLoadingPlaces}
            onSearch={onPlacesSearch}
            onClickItemCallback={redirectToPlaceEdit}
            testId="InstitutionEdit#places"
          />
          <FormInput
            label={t('institutions.form.countryCode')}
            {...register('countryCode')}
            error={errors.countryCode}
            testId="InstitutionEdit#countryCode"
          />
          <FormTextarea
            label={t('institutions.form.taskCodes')}
            name={'taskCodes'}
            onChange={onTaskCodesChange}
            defaultValue={initialValues.taskCodes.join('\n')}
            testId="InstitutionEdit#taskCodes"
          />
          <FormTextarea
            label={t('institutions.form.description')}
            {...register('description')}
            error={errors.description}
            testId="InstitutionEdit#description"
          />
          <FormInput
            label={t('institutions.form.website')}
            {...register('website')}
            error={errors.website}
            testId="InstitutionEdit#website"
          />
          <FormInput
            label={t('institutions.form.reportEmail')}
            {...register('contactEmail')}
            error={errors.contactEmail}
            testId="InstitutionEdit#email"
          />
          <FormInput
            label={t('institutions.form.phone')}
            {...register('contactPhone')}
            error={errors.contactPhone}
            testId="InstitutionEdit#phone"
          />
          <PlaceSearchInput
            label={t('institutions.form.address')}
            error={get(errors, 'location.address')}
            defaultValue={
              initialValues.location
                ? {
                    label: initialValues.location?.address,
                    value: initialValues.location?.address,
                  }
                : undefined
            }
            onValueChange={onLocationValueChange}
          />
          <FlexWrapper>
            <FormSwitcherWithLabel
              isVertical
              testId="InstitutionEdit#isChatEnabled"
              label={t('institutions.form.isChatEnabled')}
              initialValue={!!initialValues.isChatEnabled}
              onChange={onIsChatEnabledChange}
            />
            <FormSwitcherWithLabel
              isVertical
              testId="InstitutionEdit#isRecommended"
              label={t('institutions.form.isRecommended')}
              initialValue={!!initialValues.isRecommended}
              onChange={onIsRecommendedChange}
            />
          </FlexWrapper>
          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={`${t('places.edit.avatar')}\n${t('places.edit.avatarDescription')}`}
          />
          <FormUpload
            control={control}
            name="branding.avatar"
            t={t}
            dropzoneLabel={t('places.edit.dropAvatar')}
            testId="InstitutionEdit#imageAvatar"
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />
          <FormSelect
            label={t('institutions.form.language')}
            options={getLangOptions()}
            control={control}
            name={'lang'}
            defaultValue={initialValues.lang}
            testId="InstitutionEdit#languageSlect"
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
              testId="InstitutionEdit#submit"
            >
              {institution ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default InstitutionEdit;
