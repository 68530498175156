import { FC } from 'react';
import styled from 'styled-components';
import {
  Button,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormUpload,
  TSelectItems,
  Wrapper,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { TPreferenceCards } from '../../types/TPreferenceCards';

const PageWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
`;

type Props = {
  onSubmit: (arg: TPreferenceCards) => Promise<void>;
  cardIds: TSelectItems | undefined;
};

const PreferenceCards: FC<Props> = ({ onSubmit, cardIds }) => {
  const { t } = useTranslation();

  const methods = useForm<TPreferenceCards>({
    defaultValues: { title: '', type: '', content: '', cardsImage: null },
  });
  const { handleSubmit, formState, register } = methods;
  const { isSubmitting } = formState;

  return (
    <PageWrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect label={t('card.edit.id')} name={'card'} options={cardIds || []} searchable />
          <FormInput label={t('card.edit.title')} {...register('title')} />
          <FormInput label={t('card.edit.type')} {...register('type')} />
          <FormInput label={t('card.edit.content')} {...register('content')} />
          <FormSectionHeader description={t('card.edit.logo')} />
          <FormUpload
            name="cardsImage"
            t={t}
            dropzoneLabel={t('card.edit.dropLogo')}
            fileTypeErrorLabel={t('common.validation.png')}
          />
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('common.save')}
          </Button>
        </FormProvider>
      </FormWrapper>
    </PageWrapper>
  );
};

export default PreferenceCards;
