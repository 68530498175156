import { FormWrapper } from '@sim-admin-frontends/ui-shared';
import styled from 'styled-components';

import { Wrapper } from '../Formstyles';

export const StyledFormWrapper = styled(FormWrapper)`
  max-width: 960px;
  min-width: 700px;
`;

export const Container = styled(Wrapper)`
  display: block;
`;

export const SubmitContainer = styled(Wrapper)`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  display: flex;
  align-items: center;
`;
