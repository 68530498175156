import { useSaRunScrapeTaskByCodeMutation } from '@sim-admin-frontends/data-access';
import {
  useSaDeleteScrapeEventsByCodeMutation,
  useSaDeleteScrapeNewsByCodeMutation,
  useSaDeleteTaskMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  ConfirmModal,
  EditIcon,
  FlushIcon,
  loadingToast,
  PlayIcon,
  SearchIcon,
  TableIconProps,
  toast,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TTaskModalActions } from '../../types/TTask';

type Props = {
  code?: string;
  refetch: () => void;
  isEditPage?: boolean;
  scraperType?: string;
  websiteTemplate?: string | null;
  dataType?: string;
};

export const useGenerateTaskActions = ({
  code,
  refetch,
  isEditPage,
  scraperType,
  websiteTemplate,
  dataType,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate: runTaskMutation } = useSaRunScrapeTaskByCodeMutation({
    onMutate: () => {
      loadingToast(t('tasks.runToast.loading'), {
        toastId: TTaskModalActions.RUN,
      });
    },
    onError: () => {
      updateToast(TTaskModalActions.RUN, t('tasks.runToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TTaskModalActions.RUN, t('tasks.runToast.success'), TToastType.SUCCESS);
    },
  });

  const runTask = () => {
    runTaskMutation({
      code,
    });
  };

  const { mutate: deleteTaskMutation } = useSaDeleteTaskMutation({
    onMutate: () => {
      loadingToast(t('common.loading'), {
        toastId: TTaskModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const { mutate: deleteNewsByCodeMutation } = useSaDeleteScrapeNewsByCodeMutation({
    onMutate: () => {
      loadingToast(t('common.loading'), {
        toastId: TTaskModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.flushToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.flushToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const { mutate: deleteEventsByCodeMutation } = useSaDeleteScrapeEventsByCodeMutation({
    onMutate: () => {
      loadingToast(t('common.loading'), {
        toastId: TTaskModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.flushToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TTaskModalActions.DELETE, t('tasks.flushToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const deleteTask = async () => {
    await deleteTaskMutation({
      code: code || '',
    });
  };

  const handleConfirmClick = () => {
    deleteTask();
    setIsModalVisible(false);
    history.push(ROUTES.tasks.path);
    refetch();
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = () => {
    if (code?.endsWith('twitter')) {
      history.push(generatePath(ROUTES.taskEditTwitter.path, { code }));
    } else if (code?.endsWith('google')) {
      history.push(generatePath(ROUTES.taskEditGoogle.path, { code }));
    } else if (code?.endsWith('yelp')) {
      history.push(generatePath(ROUTES.taskEditYelp.path, { code }));
    } else {
      history.push(generatePath(ROUTES.taskEdit.path, { code }));
    }
  };

  const handleFlushClick = async () => {
    if (code?.endsWith('news') || code?.endsWith('twitter') || code?.endsWith('yelp')) {
      await deleteNewsByCodeMutation({ code: code || '' });
      return;
    }
    if (code?.endsWith('events') || code?.endsWith('google')) {
      await deleteEventsByCodeMutation({ code });
      return;
    }
    toast(t('tasks.flushToast.impossible'), TToastType.ERROR);
  };

  const goToSelector = () => {
    if (websiteTemplate) {
      if (dataType === 'NEWS') {
        history.push(generatePath(ROUTES.selectorEdit.path, { code: `${websiteTemplate}News` }));
      }
      if (dataType === 'EVENTS') {
        history.push(generatePath(ROUTES.selectorEdit.path, { code: `${websiteTemplate}Events` }));
      }
    } else {
      history.push(generatePath(ROUTES.selectorEdit.path, { code }));
    }
  };

  const generateItems = (): TableIconProps[] => {
    if (!code) {
      return [];
    }
    const actions = [
      {
        label: t('table.dropdown.run'),
        action: runTask,
        testId: 'TasksTableMenu#run',
        icon: <PlayIcon />,
      },
      {
        label: t('table.dropdown.flush'),
        action: handleFlushClick,
        testId: 'TasksTableMenu#flush',
        icon: <FlushIcon />,
      },
      {
        label: t('table.dropdown.edit'),
        action: handleEditClick,
        hidden: isEditPage,
        testId: 'TasksTableMenu#edit',
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'TasksTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];
    if (scraperType?.includes('WEBSITE')) {
      actions.push({
        label: t('table.dropdown.goToSelector'),
        action: goToSelector,
        testId: 'TasksTableMenu#goToSelector',
        icon: <SearchIcon />,
      });
    }
    return actions;
  };

  const renderModal = () => (
    <ConfirmModal
      title={t('tasks.deleteModal.title')}
      content={t('tasks.deleteModal.description')}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('tasks.deleteModal.cancel')}
      confirmButtonText={t('tasks.deleteModal.confirm')}
    />
  );

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
