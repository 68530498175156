import { FC } from 'react';
import { PageHeader, Wrapper } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import PreferenceCardsContainer from '../../components/cards/PreferenceCards.container';

const PreferenceCardsPage: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <PageWrapper>
        <PageHeader
          title={t('imageUpload.title')}
          caption={t('imageUpload.description')}
          testId="ImageUpload#PageHeader"
        />
        <PreferenceCardsContainer />
      </PageWrapper>
    </Wrapper>
  );
};

export default PreferenceCardsPage;
