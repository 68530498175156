import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

import { URL_REGEX } from './PlaceFeatureEdit';
import { TReminderTypeOption, TReminderVisualizationOption } from '../../../types/TReminders';

export const typeValidation = (t: TFunction) =>
  Yup.mixed().test(
    'isRequired',
    t('common.validation.required'),
    (value?: TReminderTypeOption) => !!value?.value,
  );

export const reminderSettingsValidation = (t: TFunction) =>
  Yup.object().shape({
    type: typeValidation(t),
    visualization: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value?: TReminderVisualizationOption) => !!value?.value,
    ),
    institution: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value?: TSelectItem) => !!value?.value,
    ),
    parserSettings: Yup.object({
      apiUrl: Yup.string()
        .matches(URL_REGEX, t('form.urlFormat'))
        .required(t('form.fieldRequired')),
    }),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.mixed().test(
            'isRequired',
            t('common.validation.required'),
            (value?: TSelectItem) => !!value?.value,
          ),
        }),
      )
      .compact()
      .min(1, t('common.validation.required')),
  });
