import { useRemoveLandmarkMutation } from '@sim-admin-frontends/data-access-admin-be';
import {
  ConfirmModal,
  EditIcon,
  loadingToast,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TLandmarkModalActions } from '../../types/TLandmark';

type Props = {
  id?: string;
  placeId: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateLandmarkActions = ({ refetch, id, placeId, isEditPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { mutate: deleteLandmarkMutation } = useRemoveLandmarkMutation({
    onMutate: () => {
      loadingToast(t('places.deleteToast.loading'), {
        toastId: TLandmarkModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TLandmarkModalActions.DELETE, t('places.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(
        TLandmarkModalActions.DELETE,
        t('institutions.deleteToast.success'),
        TToastType.SUCCESS,
      );
      refetch();
      if (!isEditPage) {
        return;
      }
      history.push(
        generatePath(ROUTES.landmarks.path, {
          placeId,
        }),
      );
    },
  });

  const deleteLandark = async () => {
    await deleteLandmarkMutation({
      id: id || '',
    });
  };

  const handleConfirmClick = () => {
    deleteLandark();
    setIsModalVisible(false);
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = () => {
    history.push(generatePath(ROUTES.landmarkEdit.path, { id, placeId }));
  };

  const renderModal = () => (
    <ConfirmModal
      title={t('landmarks.deleteModal.title')}
      content={t('landmarks.deleteModal.description')}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('landmarks.deleteModal.cancel')}
      confirmButtonText={t('landmarks.deleteModal.confirm')}
      testId="Landmarkss#ConfirmModal"
    />
  );

  const listItems = [
    {
      label: t('table.dropdown.edit'),
      action: handleEditClick,
      hidden: isEditPage,
      testId: 'LandmarksTableMenu#edit',
      icon: <EditIcon />,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'LandmarksTableMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];
  const detailItems = listItems.filter((item) => !item.hidden);

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
