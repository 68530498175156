import styled, { css } from 'styled-components';

export const PageWrapper = styled.div<{ $smallTopPadding?: boolean }>`
  padding: 48px ${({ theme }) => theme.spaces.spacing32};
  ${({ $smallTopPadding }) =>
    $smallTopPadding &&
    css`
      padding-top: ${({ theme }) => theme.spaces.spacing16};
    `}
`;
