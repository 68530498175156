import React, { FC, useMemo } from 'react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { getLangOptionByVal, getLangOptions, isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormWrapper,
  FormSwitcherWithLabel,
  TSelectItem,
  FormSelect,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ScrapeContentType,
  ScrapeDataType,
  ScrapeType,
  TSATaskDetail,
} from '@sim-admin-frontends/data-access-admin-be';

import { TTaskEditFormValues } from '../../../types/TTask';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';

const regexTask = /\w+_(\w+-)?\w+_(\w+-)?(twitter)/;
const schema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .label(t('form.fieldRequired'))
      .matches(
        regexTask,
        t('tasks.form.codeMatch') + '{state}_{area}-{city or institution}_{identifier}-{twitter}',
      )
      .trim(),
    enabled: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    url: Yup.string().required(t('form.fieldRequired')).trim(),
    JSRender: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    proxy: Yup.boolean().oneOf([true, false]).required(t('form.fieldRequired')),
    contentType: Yup.string()
      .oneOf(Object.values(ScrapeContentType))
      .required(t('form.fieldRequired')),
    dataType: Yup.string().oneOf(Object.values(ScrapeDataType)).required(t('form.fieldRequired')),
    scraperType: Yup.string().oneOf(Object.values(ScrapeType)).required(t('form.fieldRequired')),
    websiteTemplate: Yup.string().nullable().trim(),
    timeZone: Yup.string().required(t('form.fieldRequired')).trim(),
    checkCodes: Yup.array().of(Yup.string().trim()),
  });
};

type TaskEditProps = {
  onSubmit: (values: TTaskEditFormValues) => Promise<void>;
  task: TSATaskDetail;
  institutions: TSelectItems;
  initialInstitution?: TSelectItem;
  onAllInstitutionsMenuScrollToBottom?: (e: Event) => void;
  onInstitutionsSearch?: (text: string) => void;
  isFetchingInstitutions?: boolean;
};

const TaskEditTwitter: FC<TaskEditProps> = ({
  onSubmit,
  task,
  institutions,
  initialInstitution,
  onAllInstitutionsMenuScrollToBottom,
  onInstitutionsSearch,
  isFetchingInstitutions,
}) => {
  const { t } = useTranslation();

  const initialValues: TTaskEditFormValues = {
    code: task?.code || '',
    enabled: task?.enabled || false,
    enabledStaging: task?.enabledStaging || false,
    enabledTesting: task?.enabledTesting || false,
    url: task?.url || '',
    JSRender: task?.JSRender || false,
    proxy: task?.proxy || false,
    contentType: task?.contentType || ScrapeContentType.Json,
    timeZone: task?.timeZone || 'UTC',
    checkCodes: [],
    lang: getLangOptionByVal(task?.lang),
    dataType: task?.dataType || ScrapeDataType.News,
    scraperType: task?.scraperType || ScrapeType.Twitter,
    websiteTemplate: task?.websiteTemplate || null,
    subpageContentType: task?.subpageContentType || ScrapeContentType.Json,
    institution: null,
  };

  const methods = useForm<TTaskEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, setValue, register, trigger, control } = methods;
  const { errors, isSubmitting } = formState;

  const onSwitcherChange = (value: boolean) => {
    setValue('enabled', value);
  };

  const scrapeTypeOptions = [
    {
      value: ScrapeType.Twitter,
      label: ScrapeType.Twitter,
    },
    {
      value: ScrapeType.TwitterOfficial,
      label: ScrapeType.TwitterOfficial,
    },
  ];

  const initalScrapeType = useMemo(() => {
    const opt = scrapeTypeOptions.find(
      (typeOption) => typeOption.value === initialValues?.scraperType,
    );
    if (opt) {
      return opt;
    }
    return {
      value: ScrapeType.Website,
      label: ScrapeType.Website,
    };
  }, []);

  const onScrapeTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = arg?.[0].value as ScrapeType;
    setValue('scraperType', value);
    trigger('scraperType');
  };

  const onInstitutionChange = (arg: readonly TSelectItem[] | null) => {
    if (!arg) {
      setValue('institution', null);
      trigger('institution');
      return;
    }
    setValue('institution', { name: arg?.[0].label, id: arg?.[0].value });
    trigger('institution');
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput label={t('tasks.form.code')} {...register('code')} error={errors.code} />
          <FormSwitcherWithLabel
            isVertical
            testId="TaskEditTwitter#enabled"
            label={t('tasks.form.enabled')}
            initialValue={!!initialValues.enabled}
            onChange={onSwitcherChange}
          />
          <FormSelect
            searchable
            clearable
            label={t('tasks.form.institution')}
            error={get(errors, 'institution')}
            options={institutions}
            control={control}
            name={'institution'}
            defaultValue={initialInstitution}
            testId="TasksEdit#institutions"
            onMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
            onSearch={onInstitutionsSearch}
            isLoading={isFetchingInstitutions}
            onChange={onInstitutionChange}
          />
          <FormInput label={t('tasks.form.url')} {...register('url')} error={errors.url} />
          <FormSelect
            label={t('tasks.form.lang')}
            name="lang"
            defaultValue={initialValues.lang}
            options={getLangOptions()}
          />
          <FormSelect
            name="scraperType"
            label={t('tasks.form.scrapeType')}
            options={scrapeTypeOptions}
            defaultValue={initalScrapeType}
            error={errors.scraperType}
            onChange={onScrapeTypeChange}
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {task ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default TaskEditTwitter;
