import { ReminderCategory } from '@sim-admin-frontends/data-access';
import { findGenericOption, isEmpty } from '@sim-admin-frontends/utils-shared';
import { FC } from 'react';
import { FormProvider, useForm, get } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  FormInput,
  FormSelect,
  FormUpload,
  FormWysiwyg,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_WEB_IMAGE_FORMATS,
} from '@sim-admin-frontends/ui-shared';

import { getReminderTypeOptions } from '../../../utils/reminderUtils';
import { TReminderCategoryValues } from '../../../types/TReminders';
import { StyledFormWrapper } from '../../common/featureFlags/FeatureFlagFormStyles';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { typeValidation } from '../../placeFeatures/edit/validation';
import { iconValidation } from '../../../utils/formUtils';

const schema = (t: TFunction) =>
  Yup.object().shape({
    title: Yup.string().required(t('form.fieldRequired')),
    shortTitle: Yup.string().required(t('form.fieldRequired')),
    subtitle: Yup.string().required(t('form.fieldRequired')),
    code: Yup.string().required(t('form.fieldRequired')),
    color: Yup.string().required(t('form.fieldRequired')),
    content: Yup.string().required(t('form.fieldRequired')),
    icon: iconValidation(t),
    reminderTypeOption: typeValidation(t),
  });

type ReminderCategoriesEditProps = {
  onSubmit: (values: TReminderCategoryValues) => Promise<void>;
  reminderCategory?: ReminderCategory;
};

const ReminderCategoriesEdit: FC<ReminderCategoriesEditProps> = ({
  onSubmit,
  reminderCategory,
}) => {
  const { t } = useTranslation();
  const reminderTypeOptions = getReminderTypeOptions(t);

  const initialValues: TReminderCategoryValues = {
    code: reminderCategory?.code || '',
    title: reminderCategory?.title || '',
    shortTitle: reminderCategory?.shortTitle || '',
    subtitle: reminderCategory?.subtitle || '',
    icon: reminderCategory?.icon ? [reminderCategory?.icon] : null,
    color: reminderCategory?.color || '',
    reminderTypeOption: findGenericOption(reminderTypeOptions, reminderCategory?.reminderType),
    content: reminderCategory?.content || '',
  };

  const submit = async (values: TReminderCategoryValues) => {
    if (onSubmit) {
      return await onSubmit(values);
    }
    return null;
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'onSubmit',
  });

  const { handleSubmit, formState, control, register, getValues } = methods;
  const { errors, isSubmitting } = formState;

  const initialContent = () => {
    const content = getValues('content');

    try {
      return JSON.parse(content || SERIALIZED_WYSIWYG_EMPTY_VALUE);
    } catch (e) {
      return [
        {
          type: 'paragraph',
          children: [{ text: content, type: 'text' }],
        },
      ];
    }
  };

  return (
    <Wrapper>
      <StyledFormWrapper>
        <FormProvider {...methods}>
          <FormInput
            testId="ReminderCategoriesEdit#Title"
            label={t('places.features.form.title')}
            {...register('title')}
            defaultValue={initialValues.title}
            error={errors?.title}
          />
          <FormInput
            testId="ReminderCategoriesEdit#ShortTitle"
            label={t('reminderCategories.edit.shortTitle')}
            {...register('shortTitle')}
            defaultValue={initialValues.shortTitle}
            error={errors?.shortTitle}
          />
          <FormInput
            testId="ReminderCategoriesEdit#Subtitle"
            label={t('reminderCategories.edit.subtitle')}
            {...register('subtitle')}
            defaultValue={initialValues.subtitle}
            error={errors?.subtitle}
          />
          <FormInput
            testId="ReminderCategoriesEdit#Code"
            label={t('reminderCategories.edit.code')}
            {...register('code')}
            defaultValue={initialValues.code}
            error={errors?.code}
          />
          <FormUpload
            dropzoneLabel={t('reminderCategories.edit.icon')}
            testId="ReminderCategoriesEdit#Icon"
            name="icon"
            t={t}
            control={control}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.png')}
          />
          <FormInput
            testId="ReminderCategoriesEdit#Color"
            label={t('reminderCategories.edit.color')}
            {...register('color')}
            defaultValue={initialValues.color}
            error={errors.color}
          />
          <FormSelect
            label={t('reminderCategories.edit.reminderType')}
            options={reminderTypeOptions}
            control={control}
            error={get(errors, 'reminderTypeOption')}
            name="reminderTypeOption"
            defaultValue={initialValues.reminderTypeOption}
            testId="ReminderCategoriesEdit#ReminderType"
          />
          <FormWysiwyg
            control={control}
            testId="ReminderCategoriesEdit#Content"
            label={t('reminderCategories.edit.content')}
            name="content"
            initialValue={initialContent()}
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
              testId="FeatureFlagEdit#CreateFlag"
            >
              {reminderCategory
                ? t('reminderCategories.edit.update')
                : t('reminderCategories.edit.create')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </StyledFormWrapper>
    </Wrapper>
  );
};

export default ReminderCategoriesEdit;
