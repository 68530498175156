import {
  BusinessNotificationCountsFilterInput,
  NotificationStatus,
  useBusinessActiveUsersCountsQuery,
  useSaBusinessNotificationsQuery,
} from '@sim-admin-frontends/data-access';
import { Error, TSelectItem, Wrapper } from '@sim-admin-frontends/ui-shared';
import { ISO_DATE_FORMAT, NOTIFICATIONS_DATE_FORMAT } from '@sim-admin-frontends/utils-shared';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { mapDashboardData, threeDaysAgo, yesterday } from '../../utils/dashboardUtils';
import { DashboardView } from './DashboardView';
import { createTableColumns } from './helper';

const DashboardViewContainer: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = createTableColumns(t, theme);
  const [currentProcessingStatus, setCurrentProcessingStatus] = useState<NotificationStatus>(
    NotificationStatus.Sent,
  );

  const period = `${threeDaysAgo(ISO_DATE_FORMAT)} -> ${yesterday(ISO_DATE_FORMAT)}`;

  const filter: BusinessNotificationCountsFilterInput = useMemo(
    () => ({
      status: currentProcessingStatus,
      startDate: threeDaysAgo(NOTIFICATIONS_DATE_FORMAT),
      endDate: yesterday(NOTIFICATIONS_DATE_FORMAT),
    }),
    [currentProcessingStatus],
  );

  const {
    data: notificationsData,
    isError: isNotificationsError,
    isLoading: isNotificationsLoading,
    refetch: notificationsRefetch,
  } = useSaBusinessNotificationsQuery({ filter });

  const {
    data: usersData,
    isError: isUsersError,
    isLoading: isUsersLoading,
    refetch: refetchUsers,
  } = useBusinessActiveUsersCountsQuery();

  const isError = isNotificationsError || isUsersError;
  const isLoading = isNotificationsLoading || isUsersLoading;
  const refetch = () => {
    notificationsRefetch();
    refetchUsers();
  };

  const notifications = notificationsData?.businessNotificationCounts.places || [];
  const users = usersData?.businessActiveUsersCounts.places || [];

  const mappedData = useMemo(() => mapDashboardData(notifications, users), [notifications, users]);

  const onStatusFilterChange = useCallback((option: readonly TSelectItem[] | null) => {
    setCurrentProcessingStatus(
      (option?.[0].value ?? NotificationStatus.Sent) as NotificationStatus,
    );
  }, []);

  if (isError) {
    return (
      <Wrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </Wrapper>
    );
  }

  return (
    <DashboardView
      data={mappedData}
      columns={columns}
      itemsCount={mappedData.length}
      onStatusFilterChange={onStatusFilterChange}
      period={period}
      isLoading={isLoading}
    />
  );
};

export { DashboardViewContainer };
