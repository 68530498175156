import {
  useInfiniteSAAllInstitutionsQuery,
  useInfiniteSAInstitutionsBySearchQuery,
} from '@sim-admin-frontends/data-access';
import { useCallback, useState, useMemo } from 'react';

import { MIN_NUMBER_OF_SEARCH_CHARS } from '../constants/Constants';

const PAGE_SIZE = 100;

export const useSearchCombinedInstitutions = () => {
  const [searchTermInstitutions, setSearchTermInstitutions] = useState('');

  const onInstitutionsSearch = useCallback(
    (text: string) => {
      if (text.length < MIN_NUMBER_OF_SEARCH_CHARS) {
        return;
      }
      setSearchTermInstitutions(text);
    },
    [searchTermInstitutions],
  );

  const {
    data: allInstitutionsData,
    isLoading: isLoadingAllInstitutions,
    isError: isErrorAllInstitutions,
    fetchNextPage: fetchNextPageAllInstitutions,
    hasNextPage: hasNextPageAllInstitutions,
    refetch: refetchAllInstitutions,
    isFetchingNextPage: isFetchingNextPageAllInstitutions,
  } = useInfiniteSAAllInstitutionsQuery(PAGE_SIZE);

  const {
    data: searchData,
    isError: isErrorSearch,
    isFetching: isSearchFetching,
    fetchNextPage: fetchNextPageSearchInstitutions,
    hasNextPage: hasNextPageSearchInstitutions,
  } = useInfiniteSAInstitutionsBySearchQuery(
    { text: searchTermInstitutions },
    !!searchTermInstitutions,
  );

  const institutionsBySearch = useMemo(
    () => searchData?.pages?.flatMap((page) => page.institutionsBySearch.institutions),
    [searchData, searchTermInstitutions],
  );

  const allInstitutions = useMemo(
    () => allInstitutionsData?.pages?.flatMap((page) => page.allInstitutions.institutions),
    [allInstitutionsData],
  );

  const institutions = searchTermInstitutions ? institutionsBySearch : allInstitutions;
  const fetchNextPageInstitutions = searchTermInstitutions
    ? fetchNextPageSearchInstitutions
    : fetchNextPageAllInstitutions;
  const hasNextPageInstitutions = searchTermInstitutions
    ? hasNextPageSearchInstitutions
    : hasNextPageAllInstitutions;
  const isFetchingInstitutions =
    isLoadingAllInstitutions || isFetchingNextPageAllInstitutions || isSearchFetching;
  const isErrorInsitutions = isErrorAllInstitutions || isErrorSearch;

  return {
    institutions,
    isLoadingInstitutions: isLoadingAllInstitutions,
    isFetchingInstitutions,
    isErrorInsitutions,
    refetchInstitutions: refetchAllInstitutions,
    fetchNextPageInstitutions,
    hasNextPageInstitutions,
    onInstitutionsSearch,
  };
};
