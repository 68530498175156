import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: [
      'en',
      'uk',
      'sk',
      'es',
      'ru',
      'fil',
      'fr',
      'ar',
      'ur',
      'ko',
      'bn',
      'pl',
      'zh',
      'zh-TW',
      'ht',
    ],
    debug: true,
    supportedLngs: [
      'en',
      'uk',
      'sk',
      'es',
      'ru',
      'fil',
      'fr',
      'ar',
      'ur',
      'ko',
      'bn',
      'pl',
      'zh',
      'zh-TW',
      'ht',
    ],
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

export default i18n;
