import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { FormSelect } from '@sim-admin-frontends/ui-shared';
import { ChatGptKnowledgeBase, ChatGptTopic } from '@sim-admin-frontends/data-access-admin-be';

import { TChatBotSettingsValues, TPlaceFeatureFlagValues } from '../../../types/TFeatureFlags';

type Props = {
  control: Control<TPlaceFeatureFlagValues>;
  initialChatbotSettings: TChatBotSettingsValues | undefined;
};

const PlaceFeatureChatbotSection: FC<Props> = ({ control, initialChatbotSettings }) => {
  const { t } = useTranslation();

  const topicOptions = useMemo(() => {
    return Object.values(ChatGptTopic)
      .filter((topic) => topic !== ChatGptTopic.Info)
      .map((value) => ({ value, label: value }));
  }, []);

  const knowledgeBaseOptions = useMemo(() => {
    return Object.values(ChatGptKnowledgeBase).map((value) => ({ value, label: value }));
  }, []);

  return (
    <>
      <FormSelect
        control={control}
        clearable
        isMulti
        name="chatBotSettings.topics"
        label={t('institutions.features.form.topic')}
        options={topicOptions}
        defaultValue={initialChatbotSettings?.topics}
        testId="FeatureFlagEdit#topic"
      />
      <FormSelect
        control={control}
        clearable
        name="chatBotSettings.knowledgeBase"
        label={t('institutions.features.form.knowledgeBase')}
        options={knowledgeBaseOptions}
        defaultValue={initialChatbotSettings?.knowledgeBase}
        testId="FeatureFlagEdit#knowledgeBase"
      />
    </>
  );
};

export default PlaceFeatureChatbotSection;
