import { TSATaskListItem, TSATaskListItems } from '@sim-admin-frontends/data-access-admin-be';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

export interface TasksViewProps {
  data: TSATaskListItems;
  columns: TExtendedColumn<TSATaskListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
}

const TasksView: FC<TasksViewProps> = ({
  data,
  columns,
  onNextPress,
  hasNextPage,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
}) => {
  return (
    <Table
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      hasNextPage={hasNextPage}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      testId="TasksView#Table"
      responsive
    />
  );
};

export { TasksView };
