import { useCallback, useState } from 'react';

export const useSearchTerm = (minNumOfChars = 0) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearch = useCallback(
    (text: string) => {
      if (text === searchTerm || text.length < minNumOfChars) {
        return;
      }
      setSearchTerm(text);
    },
    [searchTerm],
  );

  return { searchTerm, onSearch };
};
