import { EditIcon, TableIconProps } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import ROUTES from '../../routing/routes';

type Props = {
  id: string;
  isEditPage?: boolean;
};

export const useGeneratePaymentActions = ({ id, isEditPage }: Props) => {
  const { t } = useTranslation();
  const editPath = generatePath(ROUTES.paymentsEdit.path, { id });

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      hidden: isEditPage,
      testId: 'PaymentsTableMenu#edit',
      icon: <EditIcon />,
    },
  ];

  const listItems = generateItems();
  const detailItems = generateItems().filter((i) => !i.hidden);

  return {
    listActions: listItems,
    detailActions: detailItems,
  };
};
