import { TPaymentTransaction } from '@sim-admin-frontends/data-access';
import { FormInput } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  paymentTransaction: TPaymentTransaction;
};

const PaymentEditTicketSection: FC<Props> = ({ paymentTransaction }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        label={t('payments.citationNumber')}
        value={paymentTransaction.citationNumber}
        readOnly
        disabled
        testId="PaymentEdit#citationNumber"
      />
      <FormInput
        label={t('payments.licenseNumber')}
        value={paymentTransaction.licenseNumber}
        readOnly
        disabled
        testId="PaymentEdit#licenseNumber"
      />
      {!!paymentTransaction.stateCode && (
        <FormInput
          label={t('payments.stateCode')}
          value={paymentTransaction.stateCode}
          readOnly
          disabled
          testId="PaymentEdit#statusCode"
        />
      )}
    </>
  );
};

export default PaymentEditTicketSection;
