import styled from 'styled-components';

const AVATAR_HEIGHT = 95;

const Avatar = styled.img<{ isCircular: boolean }>`
  height: ${AVATAR_HEIGHT}px;
  aspect-ratio: 1;
  border-radius: ${({ theme, isCircular }) =>
    isCircular ? theme.borderRadius.radiusMax : theme.borderRadius.radius4};
`;

type Props = {
  name: string;
  imageSrc: string;
  isCircular?: boolean;
};

const InfluencerAvatar = ({ name, imageSrc, isCircular = false }: Props) => (
  <Avatar src={imageSrc} alt={name} isCircular={isCircular} />
);

export default InfluencerAvatar;
