import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TLandmarkListItem } from '@sim-admin-frontends/data-access-admin-be';

export interface LandmarksViewProps {
  data: TLandmarkListItem[];
  columns: TExtendedColumn<TLandmarkListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  loading: boolean;
  hasNextPage: boolean;
  tablePageSize: number;
}

const LandmarksView = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  loading,
  hasNextPage,
  tablePageSize,
}: LandmarksViewProps) => {
  return (
    <Table
      tablePageSize={tablePageSize}
      loading={loading}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      onNextPress={onNextPress}
      hasNextPage={hasNextPage}
      onPreviousPress={onPreviousPress}
      testId="LandmarksView#Table"
    />
  );
};

export { LandmarksView };
