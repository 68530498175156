import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';

import { useGenerateLandmarkActions } from '../../../hooks/actionButtons/useGenerateLandmarkActions';

type LandmarksViewTableMenuProps = TBaseProps & {
  placeId: string;
  id: string;
  refetch: () => void;
};

const LandmarksViewTableMenu = ({ id, placeId, refetch, testId }: LandmarksViewTableMenuProps) => {
  const { listActions: items, renderModal } = useGenerateLandmarkActions({ id, placeId, refetch });
  return (
    <TableIcons items={items} testId={testId}>
      {renderModal()}
    </TableIcons>
  );
};

export { LandmarksViewTableMenu };
