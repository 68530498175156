import { useSaPlaceQuery } from '@sim-admin-frontends/data-access-admin-be';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { PageWrapper } from '../../components/layout/PageLayout';
import PlaceItinerariesTabs from '../../components/placeItineraries/PlaceItinerariesTabs';

type Props = {
  placeId: string;
};

const WrappedB = styled.b`
  white-space: pre-wrap;
`;

const PlaceItinerariesPage = ({ match }: RouteComponentProps<Props>) => {
  const { t } = useTranslation();
  const placeId = match.params.placeId;
  const { data } = useSaPlaceQuery({ id: placeId });

  return (
    <PageWrapper>
      <PageHeader
        title={t('places.itineraries.title')}
        caption={
          <Trans
            t={t}
            i18nKey={'places.itineraries.description'}
            values={{ cityName: data?.place?.name || '' }}
            components={{ b: <WrappedB /> }}
          />
        }
        testId="PlacesItineraries#PageHeader"
      />
      <PlaceItinerariesTabs placeId={placeId} />
    </PageWrapper>
  );
};

export default PlaceItinerariesPage;
