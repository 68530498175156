import { TPaymentTransaction } from '@sim-admin-frontends/data-access';
import { FormInput } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  paymentTransaction: TPaymentTransaction;
};

const PaymentEditDonationSection: FC<Props> = ({ paymentTransaction }) => {
  const { t } = useTranslation();

  return (
    <FormInput
      label={t('payments.postId')}
      value={paymentTransaction.postId}
      readOnly
      disabled
      testId="PaymentEdit#postId"
    />
  );
};

export default PaymentEditDonationSection;
