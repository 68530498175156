import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useSaInstitutionQuery } from '@sim-admin-frontends/data-access';

import { PageWrapper } from '../../components/layout/PageLayout';
import { UsersViewContainer } from '../../components/users/view/UsersView.container';

type UsersListPageProps = {
  institutionId?: string;
  placeId?: string;
};

const UserListPage: FC<RouteComponentProps<UsersListPageProps>> = ({ match }) => {
  const { t } = useTranslation();
  const institutionId = match?.params?.institutionId;
  const placeId = match?.params?.placeId;
  const { data } = useSaInstitutionQuery({ id: institutionId || '' }, { enabled: !!institutionId });

  const institutionName = data?.institution?.name ?? '';

  return (
    <PageWrapper>
      <PageHeader
        title={`${t('users.pageTitle')} ${
          institutionId ? `${t('common.in')} ${institutionName}` : ''
        }`}
        caption={t('users.pageCaption')}
        testId="UsersTabs#PageHeader"
      />
      <UsersViewContainer institutionId={institutionId} placeId={placeId} />
    </PageWrapper>
  );
};

export default UserListPage;
