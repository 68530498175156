import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useSaPlaceQuery } from '@sim-admin-frontends/data-access-admin-be';

import { PageWrapper } from '../../components/layout/PageLayout';
import { LandmarksViewContainer } from '../../components/landmarks/view/LandmarksView.container';
import { WarningText } from '../../components/common/Texts/Texts';

type LandmaksListPageProps = {
  placeId: string;
};

const LandmaksListPage = ({ match }: RouteComponentProps<LandmaksListPageProps>) => {
  const { t } = useTranslation();
  const placeId = match?.params?.placeId;
  const { data } = useSaPlaceQuery({ id: placeId || '' });

  const placeName = data?.place?.name ?? '';

  return (
    <PageWrapper $smallTopPadding>
      <WarningText>{t('common.warningText')}</WarningText>
      <PageHeader
        title={`${t('landmarks.pageTitle')} ${placeId ? `${t('common.in')} ${placeName}` : ''}`}
        caption={t('landmarks.pageCaption')}
        testId="LandmarksTabs#PageHeader"
      />
      <LandmarksViewContainer placeId={placeId} />
    </PageWrapper>
  );
};

export default LandmaksListPage;
