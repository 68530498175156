import React from 'react';
import { FieldPath, FormProvider, get, useForm } from 'react-hook-form';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormInput,
  FormSelect,
  FormWrapper,
  TSelectItem,
  FormSwitcherWithLabel,
  FormMultipleInputs,
  FormSectionHeader,
  FormUpload,
  SUPPORTED_WEB_IMAGE_FORMATS,
  FormTextarea,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LandmarkAdditionalType,
  TLandmarkDetail,
  TPlaceDetail,
} from '@sim-admin-frontends/data-access-admin-be';

import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { TLandmarkEditFormValues } from '../../../types/TLandmark';

const schema = (t: TFunction) => {
  return Yup.object().shape({
    title: Yup.string().required(t('form.fieldRequired')),
    gpsLocation: Yup.object().shape({
      lat: Yup.number(),
      lon: Yup.number(),
    }),
    initials: Yup.object().shape({
      question: Yup.string().required(t('form.fieldRequired')),
      answer: Yup.string().required(t('form.fieldRequired')),
      followups: Yup.array()
        .of(Yup.string().required(t('form.fieldRequired')))
        .required(t('form.fieldRequired')),
    }),
    rating: Yup.number(),
    userRatingCount: Yup.number(),
  });
};

type LandmarkEditProps = {
  onSubmit: (values: TLandmarkEditFormValues) => Promise<void>;
  landmark: TLandmarkDetail;
  place?: TPlaceDetail | null;
};

const LandmarkEdit = ({ onSubmit, landmark, place }: LandmarkEditProps) => {
  const { t } = useTranslation();
  const initialValues: TLandmarkEditFormValues = {
    id: landmark?.id || '',
    title: landmark?.title || '',
    initials: landmark?.initials || {
      question: '',
      answer: '',
      followups: [],
    },
    additionalType: landmark?.additionalType || [],
    googlePlacesId: landmark?.googlePlacesId || '',
    gpsLocation: landmark?.gpsLocation || {
      lat: 0,
      lon: 0,
    },
    imageObjects: landmark?.imageObjects || [],
    isHiddenGem: landmark?.isHiddenGem || false,
    landmarkName: landmark?.landmarkName || '',
    primaryType: landmark?.primaryType || '',
    rating: landmark?.rating || 0,
    types: landmark?.types || [],
    userRatingCount: landmark?.userRatingCount || 0,
    description: landmark?.description || '',
  };
  const submit = async (values: TLandmarkEditFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TLandmarkEditFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
  });

  const { handleSubmit, formState, register, setValue, trigger, control } = methods;
  const { errors, isSubmitting } = formState;

  const onAdditionalTypeChange = (arg: readonly TSelectItem[] | null) => {
    const value = (arg ? arg.map((item) => item.value) : []) as LandmarkAdditionalType[];
    setValue('additionalType', value);
    trigger('additionalType');
  };

  const onSwitcherChange = (field: FieldPath<TLandmarkEditFormValues>, value: boolean) => {
    setValue(field, value);
    trigger(field);
  };

  const toCapitalizedSnakeCase = (str: string): string => {
    return str
      .split(/\s+/)
      .map((word) => word.toUpperCase())
      .join('_');
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('title', event.target.value);
    trigger('title');
    if (!landmark?.landmarkName) {
      setValue('landmarkName', event.target.value);
      trigger('landmarkName');
    }

    if (!landmark?.id) {
      setValue(
        'id',
        event.target.value !== ''
          ? toCapitalizedSnakeCase(`${place?.name} ${event.target.value}`)
          : '',
      );
      trigger('id');
    }
  };

  const onOptionsChange = (values: string[]) => {
    setValue('types', values);
    if (values.length > 1) {
      trigger('types');
    }
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('landmarks.form.id')}
            {...register('id')}
            error={errors.id}
            disabled={true}
            testId="LandmarksEdit#id"
          />

          <FormInput
            label={t('landmarks.form.title')}
            {...register('title')}
            error={errors.title}
            testId="LandmarksEdit#title"
            onChange={onTitleChange}
          />
          <FormInput
            label={t('landmarks.form.landmarkName')}
            disabled={initialValues.title ? true : false}
            {...register('landmarkName')}
            error={errors.landmarkName}
            testId="LandmarksEdit#landmarkName"
          />
          <FormTextarea
            label={t('landmarks.form.description')}
            {...register('description')}
            error={errors.description}
            testId="LandmarksEdit#description"
          />
          <FormInput
            label={t('landmarks.form.rating')}
            {...register('rating')}
            type="number"
            error={errors.rating}
            testId="LandmarksEdit#rating"
          />
          <FormInput
            label={t('landmarks.form.userRatingCount')}
            {...register('userRatingCount')}
            type="number"
            error={errors.userRatingCount}
            testId="LandmarksEdit#userRatingCount"
          />
          <FormInput
            label={t('landmarks.form.googlePlacesId')}
            {...register('googlePlacesId')}
            error={errors.googlePlacesId}
            testId="LandmarksEdit#googlePlacesId"
          />
          <FormSectionHeader
            title={t('landmarks.edit.setImages')}
            description={t('landmarks.edit.format')}
          />
          <FormUpload
            multiple
            testId="PlaceEdit#imageLogo"
            control={control}
            name="imageObjects"
            t={t}
            dropzoneLabel={t('landmarks.edit.dropLogo')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.png')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="LandmarksEdit#isHiddenGem"
            onChange={(value: boolean) => onSwitcherChange('isHiddenGem', value)}
            label={t('landmarks.form.isHiddenGem')}
            initialValue={initialValues.isHiddenGem || false}
          />
          <FormInput
            label={t('landmarks.form.primaryType')}
            {...register('primaryType')}
            error={errors.primaryType}
            testId="LandmarksEdit#primaryType"
          />
          <FormSelect
            isMulti
            clearable
            searchable
            label={t('landmarks.additionalType')}
            name={'additionalType'}
            error={get(errors, 'additionalType')}
            options={Object.values(LandmarkAdditionalType).map((value) => ({
              label: value,
              value,
            }))}
            defaultValue={initialValues.additionalType?.map((value) => ({ label: value, value }))}
            testId="LandmarksEdit#additionalType"
            onChange={onAdditionalTypeChange}
          />
          <FormSectionHeader title={t('landmarks.edit.setTypes')} />
          <FormMultipleInputs
            label={t('landmarks.types.label')}
            placeholder={t('landmarks.typesPlaceholder')}
            buttonLabel={t('landmarks.types.addMore')}
            onChange={onOptionsChange}
            testId={'LandmarksEdit#types'}
            values={initialValues.types || []}
            error={get(errors, 'types')}
          />
          <FormSectionHeader title={t('landmarks.edit.location')} />
          <FormInput
            label={t('landmarks.form.gpsLat')}
            {...register('gpsLocation.lat')}
            type="number"
            error={errors.gpsLocation?.lat}
            testId="LandmarksEdit#gpsLocation.lat"
          />
          <FormInput
            label={t('landmarks.form.gpsLon')}
            {...register('gpsLocation.lon')}
            type="number"
            error={errors.gpsLocation?.lon}
            testId="LandmarksEdit#gpsLocation.lon"
          />
          <FormSectionHeader title={t('landmarks.edit.initials')} />
          <FormInput
            label={t('landmarks.form.question')}
            {...register('initials.question')}
            error={errors.initials?.question}
            testId="LandmarksEdit#initials.question"
          />
          <FormInput
            label={t('landmarks.form.answer')}
            {...register('initials.answer')}
            error={errors.initials?.answer}
            testId="LandmarksEdit#initials.answer"
          />
          <FormMultipleInputs
            label={t('landmarks.followups.label')}
            placeholder={t('landmarks.followupsPlaceholder')}
            buttonLabel={t('landmarks.followups.addMore')}
            onChange={(values) => setValue('initials.followups', values)}
            testId={'LandmarksEdit#initials.followups'}
            values={initialValues.initials.followups || []}
            error={get(errors, 'initials.followups')}
          />

          <ButtonsWrapper>
            <Button
              testId="LandmarksEdit#submit"
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {landmark ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default LandmarkEdit;
