import {
  PaymentTransactionsByProcessingStatusFilterInput,
  PaymentTransactionsByProcessingStatusOrderByInput,
  PaymentTransactionsBySortBy,
  ProcessingStatus,
  SaPaymentTransactionsQuery,
  SortOrder,
  useInfiniteGraphQLQuery,
  useSaPaymentTransactionsQuery,
} from '../../index';

export const useInfiniteSAPaymentTransactionsQuery = (
  processingStatus: ProcessingStatus,
  filter: PaymentTransactionsByProcessingStatusFilterInput,
  pageSize: number,
) => {
  const getNextPageParam = (lastPage: SaPaymentTransactionsQuery) =>
    lastPage.paymentTransactionsByProcessingStatus.nextToken;

  const orderBy: PaymentTransactionsByProcessingStatusOrderByInput = {
    order: SortOrder.Desc,
    by: PaymentTransactionsBySortBy.CreatedAt,
  };

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
    orderBy,
    processingStatus,
    pageSize,
  });

  return useInfiniteGraphQLQuery(useSaPaymentTransactionsQuery, getVariables, {
    getNextPageParam,
  });
};
