import { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import ROUTES from './routes';
import Layout from '../components/layout/Layout';
import { useAuthInfo } from '../contexts/userContext';

const PrivateRoute: FC<{
  component: FC<any>;
  path: string;
  exact?: boolean;
}> = ({ component: Component, ...rest }) => {
  const { user } = useAuthInfo();

  if (!user) {
    return <Redirect to={ROUTES.login.path} />;
  }

  return (
    <Route
      {...rest}
      render={({ match }) => {
        return (
          <Layout>
            <Component {...rest} match={match} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
