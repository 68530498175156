import { getLoginErrorMessage, toast, TToastType } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Login from './Login';
import { TLoggedUser, TLoginFormValues } from '../../types/TUser';
import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import CompleteRegistrationContainer from '../completeRegistration/CompleteRegistration.container';

const SUPERADMIN_GROUP_ID = 'SuperAdmin';

type LoginResponse = TLoggedUser & {
  challengeName?: string;
};

const LoginContainer: FC = () => {
  const { setUser } = useAuthInfo();
  const [userWithoutNewPassword, setUserWithoutNewPassword] = useState<CognitoUser | null>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const onChangePasswordSuccess = () => {
    setUserWithoutNewPassword(null);
  };

  const onLogin = async (values: TLoginFormValues) => {
    const { password, username } = values;

    try {
      const user: LoginResponse = await Auth.signIn(username, password);
      if (user.getSignInUserSession()) {
        const userGroup = user.getSignInUserSession()?.getIdToken().payload['cognito:groups'];
        if (!userGroup.includes(SUPERADMIN_GROUP_ID)) {
          toast(t('error.loginError'), TToastType.ERROR);
        } else {
          setUser(user);
          const userSub = user.attributes?.sub;
          if (!userSub) {
            Sentry.captureMessage('Failed to send user sub to analytics. User sub is undefined.', {
              level: Sentry.Severity.Warning,
            });
          }
          history.push(ROUTES.home.path);
        }
      }
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        toast(t('completeRegistration.toast'), TToastType.INFO, {
          autoClose: false,
        });
        setUserWithoutNewPassword(user);
      }
    } catch (err) {
      toast(getLoginErrorMessage(err, t), TToastType.ERROR);
    }
  };

  return userWithoutNewPassword ? (
    <CompleteRegistrationContainer
      user={userWithoutNewPassword}
      onChangePasswordSuccess={onChangePasswordSuccess}
    />
  ) : (
    <Login onLogin={onLogin} />
  );
};

export default LoginContainer;
