import { TableIcons, CloudwatchIcon } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import isUrl from 'is-url';

type Props = {
  cloudWatchLogUrl?: string | null;
};

const PlaceItinerariesTableMenu = ({ cloudWatchLogUrl }: Props) => {
  const { t } = useTranslation();
  if (!cloudWatchLogUrl || !isUrl(cloudWatchLogUrl)) {
    return null;
  }

  const openCloudwatch = () => {
    if (!isUrl(cloudWatchLogUrl)) {
      return;
    }
    window.open(cloudWatchLogUrl, '_blank', 'noopener,noreferrer');
  };

  const items = [
    {
      label: t('table.dropdown.showItineraries'),
      action: openCloudwatch,
      icon: <CloudwatchIcon width={20} height={20} />,
      testId: 'PlacesTableMenu#itineraries',
    },
  ];

  return <TableIcons items={items} />;
};

export default PlaceItinerariesTableMenu;
