import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import InstitutionEditContainer from '../../components/institutions/edit/InstitutionEdit.container';
import { PageWrapper } from '../../components/layout/PageLayout';

type InstitutionsEditPageProps = {
  id?: string;
  placeId?: string;
};

const InstitutionsEditPage: FC<RouteComponentProps<InstitutionsEditPageProps>> = ({ match }) => {
  const id = match.params.id;
  const placeId = match.params.placeId;

  return (
    <PageWrapper>
      <InstitutionEditContainer id={id} placeId={placeId} />
    </PageWrapper>
  );
};

export default InstitutionsEditPage;
