import { useTranslation } from 'react-i18next';
import { PageHeader } from '@sim-admin-frontends/ui-shared';

import { PageWrapper } from '../../components/layout/PageLayout';
import InfluencersViewContainer from '../../components/influencers/view/InfluencersView.container';
import { WarningText } from '../../components/common/Texts/Texts';

const InfluencersListPage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper $smallTopPadding>
      <WarningText>{t('common.warningText')}</WarningText>
      <PageHeader
        title={t('influencers.pageTitle')}
        caption={t('influencers.pageCaption')}
        testId="InfluencersTabs#PageHeader"
      />
      <InfluencersViewContainer />
    </PageWrapper>
  );
};

export default InfluencersListPage;
