import { captureException } from '@sentry/react';
import { useSaAllUsersQuery, useSaInstitutionsByQuery } from '@sim-admin-frontends/data-access';
import {
  useInfiniteSaPlacesQuery,
  useSaAddPlaceMutation,
  useSaPlaceQuery,
  useSaUpdatePlaceMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useUploadImage, useSearchTerm } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useGeneratePlaceActions } from '../../../hooks/actionButtons/useGeneratePlaceActions';
import { prepareCreationData, prepareUpdateData, transformPlaces } from '../../../utils/placeUtils';
import NotFound from '../../../routing/NotFound';
import ROUTES from '../../../routing/routes';
import { TAddPlaceFormValues } from '../../../types/TPlace';
import PlaceEdit from './PlaceEdit';
import { MAX_PLACES_PAGE_SIZE } from '../../../constants/Constants';
import { WarningText } from '../../common/Texts/Texts';

const SHARED_TOAST_ID = 'placesToast';

type PlaceEditContainerProps = {
  id?: string;
};

const PlaceEditContainer: FC<PlaceEditContainerProps> = ({ id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { searchTerm, onSearch } = useSearchTerm();
  const { uploadFormImages } = useUploadImage();
  const { mutateAsync: addPlace } = useSaAddPlaceMutation();
  const { mutateAsync: updatePlace } = useSaUpdatePlaceMutation();

  const pageTitle = id ? t('places.editPageTitle') : t('places.createPageTitle');
  const pageCaption = id ? t('places.editPageDescription') : t('places.createPageDescription');

  const { data, isLoading, isError, refetch } = useSaPlaceQuery(
    {
      id: id || '',
    },
    {
      enabled: !!id,
    },
  );

  const {
    data: allPlaces,
    isLoading: allPlacesLoading,
    isError: allPlacesError,
    refetch: allPlacesRefetch,
    hasNextPage: hasNextPagePlaces,
    fetchNextPage: fetchNextPagePlaces,
  } = useInfiniteSaPlacesQuery({
    includeDistricts: true,
    prefix: searchTerm,
    pageSize: MAX_PLACES_PAGE_SIZE,
  });

  const places = useMemo(
    () => allPlaces?.pages?.flatMap((page) => page.places.places) || [],
    [allPlaces?.pages],
  );

  const onPlacesMenuScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const {
    data: filteredInstitutions,
    isLoading: institutionsLoading,
    isError: institutionsError,
    refetch: refetchInstitutions,
  } = useSaInstitutionsByQuery(
    {
      filter: { placeUuid: data?.place?.id },
    },
    { enabled: !!id },
  );
  const institutions = filteredInstitutions?.institutionsBy.institutions;

  const {
    data: filteredUsers,
    isLoading: usersLoading,
    isError: usersError,
    refetch: usersRefetch,
  } = useSaAllUsersQuery({ filter: { placeUuid: data?.place?.id } }, { enabled: !!id });
  const users = filteredUsers?.adminUsers.users;

  const transformedPlaces = useMemo(() => {
    if (places) {
      return transformPlaces(places);
    } else {
      return places;
    }
  }, [places]);

  const isDataLoading = isLoading || institutionsLoading || usersLoading;
  const isFetchError = isError || allPlacesError || institutionsError || usersError;

  const refetchData = () => {
    refetch();
    allPlacesRefetch();
    refetchInstitutions();
    usersRefetch();
  };

  const onSubmit = async (values: TAddPlaceFormValues) => {
    try {
      loadingToast(t('places.createToast.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const creationData = prepareCreationData(values);
      if (id) {
        const updateData = await prepareUpdateData(creationData, values, id, uploadFormImages);
        await updatePlace({
          id,
          place: updateData,
        });
      } else {
        // add places without images
        const result = await addPlace({
          place: creationData,
        });
        const newId = result.addPlace.id;

        // once we acquired placeUuid upload images with id and update place with branding links
        const updateData = await prepareUpdateData(creationData, values, newId, uploadFormImages);
        await updatePlace({ id: newId, place: updateData });
      }

      updateToast(
        SHARED_TOAST_ID,
        id ? t('places.editToast.success') : t('places.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(ROUTES.places.path);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGeneratePlaceActions({
    refetch,
    placeId: id,
    isEditPage: true,
  });

  if (isDataLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isFetchError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetchData} />
      </SpinnerWrapper>
    );

  if (id && !data?.place)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="PlacesEdit#PageHeader" />
      {!!id && <WarningText>{t('common.warningText')}</WarningText>}
      <PlaceEdit
        onSubmit={onSubmit}
        place={data?.place}
        places={transformedPlaces}
        institutions={institutions}
        users={users}
        onPlacesMenuScrollToBottom={onPlacesMenuScrollToBottom}
        isLoadingPlaces={allPlacesLoading}
        onPlacesSearch={onSearch}
      />
      {renderModal()}
    </>
  );
};

export default PlaceEditContainer;
