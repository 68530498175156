import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { captureException } from '@sentry/react';
import {
  ActionButtons,
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TSelectItems,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  ScrapeTaskInput,
  useSaAddTaskMutation,
  useSaTaskByCodeQuery,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  SaInstitutionDocument,
  SaInstitutionQuery,
  SaInstitutionQueryVariables,
  fetcher,
  useSaInstitutionsByQuery,
  useSaUpdateInstitutionMutation,
} from '@sim-admin-frontends/data-access';

import TaskEditYelp from './TaskEditYelp';
import { TTaskEditFormValues } from '../../../types/TTask';
import ROUTES from '../../../routing/routes';
import NotFound from '../../../routing/NotFound';
import { getYelpUrl, transformFormValues } from '../../../utils/tasksUtils';
import { useGenerateTaskActions } from '../../../hooks/actionButtons/useGenerateTaskActions';
import { useSearchCombinedInstitutions } from '../../../hooks/useSearchCombinedInstitutions';
import { transformInstitutions } from '../../../utils/institutionUtils';

const SHARED_TOAST_ID = 'tasksToast';

type TaskEditContainerProps = {
  code?: string;
};

const TaskEditYelpContainer: FC<TaskEditContainerProps> = ({ code }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const pageTitle = code ? t('tasks.editPageTitle') : t('tasks.createPageTitle');
  const pageCaption = code ? t('tasks.editPageCaption') : t('tasks.createPageCaption');

  const { mutateAsync: addTask } = useSaAddTaskMutation();
  const { mutateAsync: updateInstitution } = useSaUpdateInstitutionMutation();

  const { data, isLoading, isError, refetch } = useSaTaskByCodeQuery(
    {
      code: code || '',
    },
    {
      enabled: !!code,
    },
  );

  const {
    isLoading: institutionLoading,
    error: institutionError,
    data: institutionForTaskCode,
  } = useSaInstitutionsByQuery(
    {
      filter: {
        taskCode: code || '',
      },
    },
    {
      enabled: true,
    },
  );

  const institution = institutionForTaskCode?.institutionsBy.institutions[0];

  const {
    institutions,
    isLoadingInstitutions,
    isFetchingInstitutions,
    isErrorInsitutions,
    fetchNextPageInstitutions,
    hasNextPageInstitutions,
    onInstitutionsSearch,
  } = useSearchCombinedInstitutions();

  const onAllInstitutionsMenuScrollToBottom = () => {
    if (hasNextPageInstitutions) {
      fetchNextPageInstitutions();
    }
  };

  const initialInstitution = useMemo(
    () =>
      institution
        ? {
            label: institution.name,
            value: institution.id,
          }
        : undefined,
    [institution],
  );

  const institutionOptions: TSelectItems = transformInstitutions(institutions ?? []);

  const onSubmit = async (values: TTaskEditFormValues) => {
    try {
      loadingToast(t('tasks.createToast.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const mutationVariables: ScrapeTaskInput = transformFormValues(values);
      mutationVariables.url = getYelpUrl(values.url);
      await addTask({
        task: mutationVariables,
      });
      const institutionChanged = institution?.id !== values.institution?.id;
      if (institutionChanged && institution) {
        const newCodes = institution?.taskCodes?.filter((taskCode) => taskCode !== code);
        await updateInstitution({
          id: institution.id,
          institution: {
            taskCodes: newCodes,
            name: institution.name,
            categoryUuid: institution.category.id,
            places:
              institution.places?.map((place) => ({
                placeId: place.id,
                role: institution.inPlaces ? institution.inPlaces[0].role : null,
              })) || [],
          },
        });
      }

      if (values.institution && code) {
        let res = null;
        try {
          const fetch = await fetcher<SaInstitutionQuery, SaInstitutionQueryVariables>(
            SaInstitutionDocument,
            { id: values.institution.id },
          );
          res = await fetch();
        } catch (err) {
          updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
          captureException(err);
        }
        if (!res || !res.institution) {
          return;
        }
        const newInstitution = res.institution;
        if (newInstitution) {
          const newCodes = newInstitution?.taskCodes ? [...newInstitution.taskCodes, code] : [code];
          await updateInstitution({
            id: values.institution.id,
            institution: {
              taskCodes: newCodes,
              name: newInstitution.name,
              categoryUuid: newInstitution.category.id,
              places:
                newInstitution.places?.map((place) => ({
                  placeId: place.id,
                  role: newInstitution.inPlaces ? newInstitution.inPlaces[0].role : null,
                })) || [],
            },
          });
        }
      }

      updateToast(
        SHARED_TOAST_ID,
        code ? t('tasks.editToast.success') : t('tasks.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(ROUTES.tasks.path);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGenerateTaskActions({
    code: code || '',
    refetch,
    isEditPage: true,
    websiteTemplate: 'Yelp',
  });

  if (isLoading || institutionLoading || isLoadingInstitutions) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError || institutionError || isErrorInsitutions) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (code && !data?.scrapeTaskByCode) {
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} />
      <TaskEditYelp
        onSubmit={onSubmit}
        task={data?.scrapeTaskByCode}
        institutions={institutionOptions}
        initialInstitution={initialInstitution}
        onAllInstitutionsMenuScrollToBottom={onAllInstitutionsMenuScrollToBottom}
        isFetchingInstitutions={isFetchingInstitutions}
        onInstitutionsSearch={onInstitutionsSearch}
      />
      {renderModal()}
    </>
  );
};

export default TaskEditYelpContainer;
