import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import styled, { css } from 'styled-components';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';
import {
  FormError,
  FormItemLabel,
  FormItemWrapper,
  MapPin,
  SearchIcon,
  SelectStyles,
  TSelectItem,
  TSelectOption,
} from '@sim-admin-frontends/ui-shared';
import { getFinalPlaceData } from '@sim-admin-frontends/utils-shared';

import { TAddPlaceFormValues } from '../../types/TPlace';

type LocationInputValue = {
  name: string;
  gps?: {
    lat: number;
    lon: number;
  };
};

const Wrapper = styled(SelectStyles)<{ isError: boolean }>`
  & .react-select__placeholder,
  .react-select__menu-notice {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.lineHeights.caption1};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: Manrope, serif;
    color: ${({ theme }) => theme.colors.lightText};
  }
  & .react-select__control {
    padding-left: 40px;
    border: 1px solid;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red60 : theme.colors.coolGray20};
  }

  & .react-select__control:hover {
    border: 1px solid;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red60 : theme.colors.coolGray20};
  }
`;

const Search = styled(SearchIcon)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spaces.spacing8};
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionIconWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionWrapper = styled.div<{ hasIcon: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacing8};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: Manrope, serif;
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: 10px ${({ theme }) => theme.spaces.spacing8};
  ${(props) => (props.hasIcon ? 'padding-left: 40px' : '')};
  :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const CustomOption: FC<any> = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    optionIcon,
  } = props;
  return (
    <OptionWrapper
      hasIcon={!!optionIcon}
      ref={innerRef}
      className={cx(
        css(getStyles('option', props)),
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      {...innerProps}
    >
      <OptionIconWrapper>{optionIcon}</OptionIconWrapper>
      {children}
    </OptionWrapper>
  );
};

CustomOption.displayName = 'LocationOption';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <div></div>
    </components.DropdownIndicator>
  );
};

export interface PlaceSearchProps {
  onValueChange: (value?: TAddPlaceFormValues | null) => void;
  error?: FieldError;
  defaultValue?: TSelectItem;
  label?: string;
  fullPlaceSearch: true;
}

export interface LocationSearchProps {
  onValueChange: (value?: LocationInputValue | null) => void;
  error?: FieldError;
  defaultValue?: TSelectItem;
  label?: string;
  fullPlaceSearch?: false;
}

type PlaceSearchInputProps = PlaceSearchProps | LocationSearchProps;

type TLocationSelectOption = TSelectOption<{ place_id: string }> | null;

const PlaceSearchInput = (props: PlaceSearchInputProps) => {
  const { defaultValue, label, error } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const fetchFullPlace = async (item: TLocationSelectOption) => {
    const apiKey = process.env.NX_GOOGLE_MAPS_SA_API_KEY || '';
    if (!item) {
      return;
    }
    const placeData = await getFinalPlaceData(item.value.place_id, apiKey);
    if (props.fullPlaceSearch) {
      const { onValueChange } = props;
      onValueChange(placeData);
    }
  };

  const fetchLocation = async (item: TLocationSelectOption) => {
    if (!item) {
      return;
    }
    const result = await geocodeByPlaceId(item.value.place_id);
    const location = {
      name: item.label,
      address: item.label,
      gps: {
        lat: result[0].geometry.location?.lat(),
        lon: result[0].geometry.location?.lng(),
      },
    };
    if (!props.fullPlaceSearch) {
      const { onValueChange } = props;
      onValueChange(location);
    }
  };

  const onItemSelected = async (item: TLocationSelectOption) => {
    if (!item) {
      props.onValueChange(null);
    } else {
      if (props.fullPlaceSearch) await fetchFullPlace(item);
      else await fetchLocation(item);
    }
  };

  return (
    <FormItemWrapper>
      <FormItemLabel>{label || t('places.findPlace')}</FormItemLabel>
      <Wrapper isError={!!error}>
        <Search />
        <GooglePlacesAutocomplete
          apiOptions={{
            language,
          }}
          apiKey={process.env.NX_GOOGLE_MAPS_SA_API_KEY}
          selectProps={{
            isClearable: true,
            backspaceRemovesValue: true,
            className: 'react-select-container location-select',
            classNamePrefix: 'react-select',
            defaultValue: defaultValue,
            placeholder: t('places.findPlace'),
            onChange: onItemSelected,
            components: {
              DropdownIndicator,
              // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
              Option: function Option(optionProps: OptionProps) {
                return <CustomOption optionIcon={<MapPin />} {...optionProps} />;
              },
            },
          }}
        />
      </Wrapper>
      {error && <FormError error={error} />}
    </FormItemWrapper>
  );
};

export { PlaceSearchInput, LocationInputValue };
