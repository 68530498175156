import { ReminderType, ReminderVisualization } from '@sim-admin-frontends/data-access-admin-be';
import { TFunction } from 'react-i18next';

import { TReminderTypeOption, TReminderVisualizationOption } from '../types/TReminders';

export const getReminderTypeOptions = (t: TFunction): TReminderTypeOption[] => [
  { label: t('places.features.form.wasteManagement'), value: ReminderType.WasteManagement },
  { label: t('places.features.form.streetSweeping'), value: ReminderType.StreetSweeping },
];

export const getReminderVisualizationOptions = (t: TFunction): TReminderVisualizationOption[] => [
  {
    label: t('places.features.form.visualization.standard'),
    value: ReminderVisualization.Standard,
  },
  // TODO: uncomment when product says to not only use calendar reminders
  // { label: t('places.features.form.visualization.weekDays'), value: ReminderVisualization.WeekDays },
];
