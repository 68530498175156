import {
  ConfirmModal,
  EditIcon,
  TableIconProps,
  TrashCanIcon,
  FeatureFlagIcon,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TInfluencerModalActions } from '../../types/TInfluencer';

type Props = {
  id?: string;
  refetch: () => void;
  editPath: string;
  listPath: string;
  isEditPage?: boolean;
  deleteMutation: ({ id }: { id: string }) => void;
  influencerId?: string;
};

export const useGenerateInfluencerActions = ({
  id,
  refetch,
  editPath,
  listPath,
  isEditPage,
  influencerId,
  deleteMutation,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(TInfluencerModalActions.DELETE);

  const deleteInfluencerItem = async () => {
    await deleteMutation({
      id: id || '',
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === TInfluencerModalActions.DELETE) {
      deleteInfluencerItem();
      setIsModalVisible(false);
      if (influencerId) {
        history.push(generatePath(listPath, { influencerId }));
      } else {
        history.push(listPath);
      }
      refetch();
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setModalDescription(t('influencers.deleteModal.description'));
    setModalClickAction(TInfluencerModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handleEditClick = () => {
    if (influencerId) {
      history.push(generatePath(editPath, { id, influencerId }));
      return;
    }
    history.push(generatePath(editPath, { id }));
  };

  const generateItems = (): TableIconProps[] => {
    if (!id) {
      return [];
    }

    const tableIcons: TableIconProps[] = [
      {
        label: t('table.dropdown.edit'),
        action: handleEditClick,
        hidden: isEditPage,
        testId: 'InfluencersTableMenu#edit',
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'InfluencersTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];

    if (!influencerId) {
      const influencerSpotsPath = generatePath(ROUTES.influencerSpots.path, { influencerId: id });

      tableIcons.splice(0, 0, {
        label: t('influencers.spots.title'),
        path: influencerSpotsPath,
        testId: 'InfluencersTableMenu#influencerSpots',
        icon: <FeatureFlagIcon />,
      });
    }

    return tableIcons;
  };

  const renderModal = () => (
    <ConfirmModal
      title={t('institutions.deleteModal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('institutions.deleteModal.cancel')}
      confirmButtonText={t('institutions.deleteModal.confirm')}
      testId="Influencers#ConfirmModal"
    />
  );

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
