import { InstitutionFeatureFlag } from '@sim-admin-frontends/data-access';
import { Button, Table, TableActionHeader } from '@sim-admin-frontends/ui-shared';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { Container, SubmitContainer } from '../../common/featureFlags/FeatureFlagFormStyles';
import { createInstitutionFeaturesTableColumns } from './helper';

type Props = {
  onSubmit: (values: InstitutionFeatureFlag[]) => Promise<void>;
  initialValues: InstitutionFeatureFlag[];
  isLoading: boolean;
  institutionId: string;
};

const InstitutionFeatures: FC<Props> = ({ onSubmit, initialValues, isLoading, institutionId }) => {
  const { t } = useTranslation();
  const [dataToTable, setDataToTable] = useState(initialValues);

  useEffect(() => {
    setDataToTable(initialValues);
  }, [initialValues]);

  const onDelete = (index: number) => {
    const newData = dataToTable.filter((_, i) => index !== i);
    setDataToTable(newData);
  };

  const tableColumns = createInstitutionFeaturesTableColumns(onDelete, institutionId);

  const onSubmitClick = async () => {
    await onSubmit(dataToTable);
  };

  return (
    <Container>
      <TableActionHeader>
        <Link to={generatePath(ROUTES.institutionFeaturesEdit.path, { institutionId })}>
          <Button testId="InstitutionsTabs#CreateFlag">{t('institutions.features.create')}</Button>
        </Link>
      </TableActionHeader>
      <Table
        loading={isLoading}
        itemsCount={dataToTable.length}
        pageIndex={0}
        data={dataToTable}
        columns={tableColumns}
        hasPagination={false}
        onNextPress={() => undefined}
        onPreviousPress={() => undefined}
        testId="InstitutionFeatures#Table"
      />
      <SubmitContainer>
        <Button onClick={onSubmitClick} testId="InstitutionsTabs#SaveChange">
          {t('places.features.saveChanges')}
        </Button>
      </SubmitContainer>
    </Container>
  );
};

export default InstitutionFeatures;
