import { FC } from 'react';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import InstitutionFeatureEditContainer from '../../components/institutionFeatures/edit/InstitutionFeatureEdit.container';

type Props = {
  institutionId: string;
  featureIndex: string;
};

const InstitutionFeaturesEditPage: FC<RouteComponentProps<Props>> = ({ match }) => {
  const { t } = useTranslation();
  const { institutionId, featureIndex } = match.params;
  return (
    <PageWrapper>
      <PageHeader
        title={
          featureIndex
            ? t('institutions.features.updateFeatureFlagTitle')
            : t('institutions.features.createFeatureFlagTitle')
        }
        caption={
          featureIndex
            ? t('institutions.features.updateFeatureFlagCaption')
            : t('institutions.features.createFeatureFlagCaption')
        }
        testId="InstitutionFlagEdit#PageHeader"
      />
      <InstitutionFeatureEditContainer institutionId={institutionId} featureIndex={featureIndex} />
    </PageWrapper>
  );
};

export default InstitutionFeaturesEditPage;
