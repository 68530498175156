import { Trans, useTranslation } from 'react-i18next';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { RouteComponentProps } from 'react-router-dom';
import { useSaInfluencerQuery } from '@sim-admin-frontends/data-access';
import styled from 'styled-components';

import { PageWrapper } from '../../components/layout/PageLayout';
import InfluencerSpotsViewContainer from '../../components/influencerSpots/view/InfluencerSpotsView.container';
import { WarningText } from '../../components/common/Texts/Texts';

const WrappedB = styled.b`
  white-space: pre-wrap;
`;

type Props = {
  influencerId?: string;
};

const InfluencerSpotsListPage = ({ match }: RouteComponentProps<Props>) => {
  const influencerId = match.params.influencerId;
  const { t } = useTranslation();

  const { data } = useSaInfluencerQuery({ id: influencerId || '' }, { enabled: !!influencerId });

  return (
    <PageWrapper $smallTopPadding>
      <WarningText>{t('common.warningText')}</WarningText>
      <PageHeader
        title={t('influencerSpots.pageTitle')}
        caption={
          <Trans
            t={t}
            i18nKey={'influencerSpots.pageCaption'}
            values={{ influencerName: data?.influencer?.name || '' }}
            components={{ b: <WrappedB /> }}
          />
        }
        testId="InfluencerSpotsTabs#PageHeader"
      />
      <InfluencerSpotsViewContainer influencerId={influencerId || ''} />
    </PageWrapper>
  );
};

export default InfluencerSpotsListPage;
