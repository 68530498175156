import { TFunction } from 'i18next';
import {
  ClickableCopyText,
  TExtendedColumn,
  TableHeaderTitle,
} from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import { TInfluencerSpotListItem } from '@sim-admin-frontends/data-access';

import InfluencersViewTableMenu from './../../influencers/view/InfluencersViewTableMenu';
import { TInfluencerSpottViewValues } from '../../../types/TInfluencer';
import ROUTES from '../../../routing/routes';
import InfluencerAvatar from './../../influencers/view/InfluencerAvatar';

export const createTableColumns = ({
  t,
  refetch,
  deleteMutation,
  influencerId,
}: {
  t: TFunction;
  refetch: () => void;
  deleteMutation: ({ id }: { id: string }) => void;
  influencerId: string;
}) => {
  const columns: TExtendedColumn<TInfluencerSpottViewValues>[] = [
    {
      Header: `${t('influencers.form.avatar')}`,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TInfluencerSpottViewValues>) => {
        const src = row.original.image?.links?.self;
        const name = row.original.title;

        if (!src) {
          return null;
        }

        return <InfluencerAvatar imageSrc={src} name={name} />;
      },
    },
    {
      Header: `${t('influencerSpots.form.title')}`,
      accessor: 'title',
      Cell: ({ value }) =>
        value ? <TableHeaderTitle item={value || ''} maxTextLength={100} /> : null,
      disableSortBy: true,
    },
    {
      Header: `${t('influencerSpots.form.placeId')}`,
      accessor: 'placeUuid',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('institutions.columns.uuid')}`,
      accessor: 'id',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TInfluencerSpotListItem>) => {
        return (
          <InfluencersViewTableMenu
            testId={`InfluencerSpotsView#TableMenu${row.index}`}
            refetch={refetch}
            id={row.original.id}
            editPath={ROUTES.influencerSpotsEdit.path}
            listPath={ROUTES.influencerSpots.path}
            deleteMutation={deleteMutation}
            influencerId={influencerId}
          />
        );
      },
    },
  ];
  return columns;
};
