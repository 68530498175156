import { RouteComponentProps } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import InfluencerSpotEditContainer from '../../components/influencerSpots/edit/InfluencerSpotEdit.container';

type Props = {
  id?: string;
  influencerId: string;
};

const InfluencerSpotsEditPage = ({ match }: RouteComponentProps<Props>) => {
  const id = match.params.id;
  const influencerId = match.params.influencerId;

  return (
    <PageWrapper>
      <InfluencerSpotEditContainer id={id} influencerId={influencerId} />
    </PageWrapper>
  );
};

export default InfluencerSpotsEditPage;
