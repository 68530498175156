import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  Wrapper,
  TSelectItem,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, get, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { isEmpty } from '@sim-admin-frontends/utils-shared';

import { TItineraryNotificationsEditValues } from '../../../types/TNotifications';

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
  align-self: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const schema = (t: TFunction) => {
  return Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    body: Yup.string(),
    city: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
    deeplink: Yup.string().url(t('common.validation.url')),
  });
};

type Props = {
  places: TSelectItems;
  onPlacesSearch: (arg: string) => void;
  onPlacesScrollToBottom: () => void;
  onPlaceSelect: (arg: readonly TSelectItem[] | null) => void;
  isFetchingPlaces: boolean;
  onSubmit: (values: TItineraryNotificationsEditValues) => void;
};

const ItineraryNotificationsEdit: FC<Props> = ({
  places,
  onPlacesSearch,
  onPlacesScrollToBottom,
  onPlaceSelect,
  isFetchingPlaces,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const methods = useForm<TItineraryNotificationsEditValues>({
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });
  const { handleSubmit, formState, control, register } = methods;
  const { errors, isSubmitting } = formState;
  const [shouldDisableSendButton, setShouldDisableSendButton] = useState(false);

  const submit = async (values: TItineraryNotificationsEditValues) => {
    await onSubmit(values);
    setShouldDisableSendButton(true);
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            searchable
            clearable
            label={t('notifications.city')}
            error={get(errors, 'city')}
            options={places}
            control={control}
            name={'city'}
            onMenuScrollToBottom={onPlacesScrollToBottom}
            isLoading={isFetchingPlaces}
            onSearch={onPlacesSearch}
            onChange={onPlaceSelect}
            testId="NotificationEdit#city"
          />
          <FormInput
            label={t('notifications.title')}
            {...register('title')}
            error={errors.title}
            testId="NotificationEdit#title"
          />
          <FormTextarea
            label={t('notifications.body')}
            {...register('body')}
            error={errors.body}
            testId="NotificationEdit#body"
          />
          <FormInput
            label={t('notifications.deeplink')}
            {...register('deeplink')}
            error={errors.deeplink}
            testId="NotificationEdit#deeplink"
          />
          <ButtonsWrapper>
            <Button
              testId="NotificationEdit#submit"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting && !shouldDisableSendButton}
              disabled={isSubmitting || !isEmpty(errors) || shouldDisableSendButton}
            >
              {t('common.send')}
            </Button>
            {shouldDisableSendButton && (
              <Button
                type="submit"
                onClick={handleSubmit(submit)}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                {t('common.sendAgain')}
              </Button>
            )}
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};
export default ItineraryNotificationsEdit;
