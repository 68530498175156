import { FC } from 'react';
import styled from 'styled-components';
import {
  FormInput,
  SmallText,
  Button,
  CheckboxWithLabel,
  useShowPassword,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as Logo } from '../../assets/img/simplicity_logo-full.svg';
import { TLoginFormValues } from '../../types/TUser';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadlineText = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spaces.spacing32}; ;
`;

const FormWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    username: Yup.string().required(t('common.validation.required')),
    password: Yup.string().required(t('common.validation.required')),
  });

type Props = {
  onLogin: (values: TLoginFormValues) => void;
};

const Login: FC<Props> = ({ onLogin }) => {
  const { passwordFieldType, showPassword, onShowPasswordChange, loginCleanup } = useShowPassword();
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useForm<TLoginFormValues>({
    resolver: yupResolver(schema(t)),
  });
  const { errors, isSubmitting } = formState;
  const onSubmit = async (values: TLoginFormValues) => {
    if (showPassword) {
      loginCleanup();
    }

    return onLogin(values);
  };

  const handleKeyPress = (keyCode: string) => {
    if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Logo />
        <HeadlineText>{t('login.headline')}</HeadlineText>
        <FormInput
          label={t('login.username')}
          {...register('username', { required: true })}
          error={errors.username}
          onKeyDown={(event) => handleKeyPress(event.code)}
          testId="Login#username"
        />
        <FormInput
          label={t('login.password')}
          {...register('password', { required: true })}
          type={passwordFieldType}
          error={errors.password}
          onKeyDown={(event) => handleKeyPress(event.code)}
          testId="Login#password"
        />
        <CheckboxWithLabel
          label={t('common.showPassword')}
          value={showPassword}
          onValueChange={onShowPasswordChange}
          testId={'Login#showpassword'}
        />
        <SubmitButton
          size="larger"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          testId="Login#submit"
        >
          {t('login.submit')}
        </SubmitButton>
      </FormWrapper>
    </Wrapper>
  );
};

export default Login;
