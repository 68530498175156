import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Button, Error, SpinnerWrapper, TableActionHeader } from '@sim-admin-frontends/ui-shared';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { PlacesView } from './PlacesView';
import { getIsPlaceEnabled } from '../../../utils/placeUtils';

const PlacesViewContainer: FC = () => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTerm });

  const columns = createTableColumns(t, refetch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const itemsCount = data?.pages?.[0].places.totalCount ?? 0;
  const places = data?.pages?.[currentPageIndex]?.places.places;
  const filteredAndSortedPlaces = useMemo(
    () =>
      places
        ?.filter((place) => getIsPlaceEnabled(place))
        .sort((firstPlace, secondPlace) => firstPlace.name.localeCompare(secondPlace.name)),
    [places],
  );

  if (isError) {
    return (
      <>
        <TableActionHeader>
          <Link to={generatePath(ROUTES.placeEdit.path)}>
            <Button>{t('places.addPlace')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }

  return (
    <PlacesView
      onSearchTermChange={setSearchTerm}
      loading={isLoading || isFetchingNextPage}
      itemsCount={itemsCount}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={filteredAndSortedPlaces || []}
    />
  );
};

export { PlacesViewContainer };
