import { useState } from 'react';
import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  Wrapper,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import { FormProvider, get, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { PushNotificationType, PushNotificationPriority } from '@sim-admin-frontends/data-access';

import { TChatBot, TChatBotNotificationsEditValues } from '../../../types/TNotifications';

const TYPE_OPTIONS = Object.values(PushNotificationType).map((value) => ({
  label: value,
  value,
}));

const PRIORITY_OPTIONS = Object.values(PushNotificationPriority).map((value) => ({
  label: value,
  value,
}));

const CHAT_BOT_OPTIONS: TChatBot[] = [
  {
    label: 'Charlie',
    value: 'Charlie',
  },
];

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
  align-self: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const schema = (t: TFunction) => {
  return Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    subtitle: Yup.string(),
    body: Yup.string(),
    email: Yup.string().email(t('common.validation.email')).max(255),
    type: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
  });
};

type Props = {
  onSubmit: (values: TChatBotNotificationsEditValues) => void;
  onChatBotChange: (newChatBot: readonly TSelectItem[] | null) => void;
  isFetchingInstitutions: boolean;
};

const ChatBotNotificationsEdit = ({ onSubmit, onChatBotChange, isFetchingInstitutions }: Props) => {
  const { t } = useTranslation();
  const initialValues = {
    type: TYPE_OPTIONS[0],
    chatBot: CHAT_BOT_OPTIONS[0],
  };
  const methods = useForm<TChatBotNotificationsEditValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });
  const { handleSubmit, formState, control, register } = methods;
  const { errors, isSubmitting } = formState;
  const [shouldDisableSendButton, setShouldDisableSendButton] = useState(false);

  const submit = async (values: TChatBotNotificationsEditValues) => {
    await onSubmit(values);
    setShouldDisableSendButton(true);
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('users.form.email')}
            {...register('email')}
            error={errors.email}
            testId="NotificationEdit#email"
          />
          <FormInput
            label={t('notifications.title')}
            {...register('title')}
            error={errors.title}
            testId="NotificationEdit#title"
          />
          <FormInput
            label={t('notifications.subtitle')}
            {...register('subtitle')}
            error={errors.subtitle}
            testId="NotificationEdit#subtitle"
          />
          <FormTextarea
            label={t('notifications.body')}
            {...register('body')}
            error={errors.body}
            testId="NotificationEdit#body"
          />
          <FormSelect
            label={t('notifications.type')}
            error={get(errors, 'type')}
            options={TYPE_OPTIONS}
            control={control}
            name={'type'}
            testId="NotificationEdit#type"
            defaultValue={initialValues.type}
          />
          <FormSelect
            clearable
            label={t('notifications.priority')}
            error={get(errors, 'priority')}
            options={PRIORITY_OPTIONS}
            control={control}
            name={'priority'}
            testId="NotificationEdit#priority"
          />
          <FormSelect
            clearable
            label={t('notifications.chatBot')}
            error={get(errors, 'chatBot')}
            options={CHAT_BOT_OPTIONS}
            control={control}
            onChange={onChatBotChange}
            name={'chatBot'}
            isLoading={isFetchingInstitutions}
            testId="NotificationEdit#chatBot"
            defaultValue={initialValues.chatBot}
          />
          <ButtonsWrapper>
            <Button
              testId="NotificationEdit#submit"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting && !shouldDisableSendButton}
              disabled={isSubmitting || !isEmpty(errors) || shouldDisableSendButton}
            >
              {t('common.send')}
            </Button>
            {shouldDisableSendButton && (
              <Button
                type="submit"
                onClick={handleSubmit(submit)}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                {t('common.sendAgain')}
              </Button>
            )}
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default ChatBotNotificationsEdit;
