import { UseInfiniteQueryOptions } from 'react-query';

import {
  useSaInfluencersQuery,
  useInfiniteGraphQLQuery,
  SaInfluencersQuery,
  InfluencersByFilterInput,
  InfluencerSpotsByFilter,
  SaInfluencerSpotsQuery,
  useSaInfluencerSpotsQuery,
} from '../..';

export const useInfiniteInfluencersQuery = (
  filter: InfluencersByFilterInput,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaInfluencersQuery) => lastPage.influencersBy.nextToken;
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useSaInfluencersQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled ?? true,
  });
};

export const useInfiniteInfluencerSpotsQuery = (
  filter: InfluencerSpotsByFilter,
  options?: UseInfiniteQueryOptions,
) => {
  const getNextPageParam = (lastPage: SaInfluencerSpotsQuery) =>
    lastPage.influencerSpotsBy.nextToken;
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useSaInfluencerSpotsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled: options?.enabled ?? true,
  });
};
