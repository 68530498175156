import {
  useSaInfluencerQuery,
  useSaAddInfluencerMutation,
  useSaDeleteInfluencerMutation,
  useSaUpdateInfluencerMutation,
  UploadType,
} from '@sim-admin-frontends/data-access';
import {
  Spinner,
  SpinnerWrapper,
  TToastType,
  loadingToast,
  updateToast,
  Error,
  ActionButtons,
  PageHeader,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import { useUploadImage } from '@sim-admin-frontends/utils-shared';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { useGenerateInfluencerActions } from '../../../hooks/actionButtons/useGenerateInfluencerActions';
import ROUTES from '../../../routing/routes';
import { TInfluencerFormValues } from '../../../types/TInfluencer';
import InfluencerEdit from './InfluencerEdit';
import { transformFormValues } from '../../../utils/influencerUtils';
import { INFLUENCER_TOAST_ID } from '../../../constants/Constants';
import useSearchablePlaceOptions from '../../../hooks/useSearchablePlaceOptions';

type Props = {
  id?: string;
};

const InfluencerEditContainer = ({ id }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: influencerData,
    isLoading: isLoadingInfluencer,
    isError: isErrorInfluencer,
    refetch: refetchInfluencer,
  } = useSaInfluencerQuery({ id: id || '' }, { enabled: !!id });

  const {
    placesOptions,
    onPlacesMenuScrollToBottom,
    onPlacesSearch,
    isErrorPlaces,
    isLoadingPlaces,
    refetchPlaces,
  } = useSearchablePlaceOptions(true);

  const pageTitle = id ? t('influencers.editPageTitle') : t('influencers.createPageTitle');
  const pageCaption = id ? t('influencers.editPageCaption') : t('influencers.createPageCaption');

  const { uploadFormImages } = useUploadImage();
  const { mutateAsync: addInfluencer } = useSaAddInfluencerMutation();
  const { mutateAsync: updateInfluencer } = useSaUpdateInfluencerMutation();
  const { mutate: deleteMutation } = useSaDeleteInfluencerMutation({
    onMutate: () => {
      loadingToast(t('institutions.deleteToast.loading'), {
        toastId: INFLUENCER_TOAST_ID,
      });
    },
    onError: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const refetch = () => {
    refetchPlaces();
    refetchInfluencer();
  };

  const onSubmit = async (formValues: TInfluencerFormValues) => {
    try {
      loadingToast(t('institutions.createToast.loading'), {
        toastId: INFLUENCER_TOAST_ID,
      });

      const avatarImages = await uploadFormImages(UploadType.Influencer, formValues.avatar, {
        placeId: formValues.places[0].value,
      });
      const backgroundImages = await uploadFormImages(
        UploadType.Influencer,
        formValues.background,
        { placeId: formValues.places[0].value },
      );

      const influencer = transformFormValues(formValues, avatarImages, backgroundImages);

      if (id) {
        await updateInfluencer({ id, influencer });
      } else {
        await addInfluencer({ influencer });
      }

      updateToast(
        INFLUENCER_TOAST_ID,
        id ? t('influencers.editToast.success') : t('influencers.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(generatePath(ROUTES.influencers.path));
    } catch (e) {
      updateToast(INFLUENCER_TOAST_ID, getErrorMessage(e), TToastType.ERROR);
      captureException(e);
    }
  };

  const { detailActions, renderModal } = useGenerateInfluencerActions({
    id,
    refetch,
    editPath: ROUTES.influencersEdit.path,
    listPath: ROUTES.influencers.path,
    isEditPage: true,
    deleteMutation,
  });

  if (isLoadingInfluencer)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const isError = isErrorInfluencer || isErrorPlaces || (id && !influencerData);

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  const influencer = influencerData?.influencer;

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="InfluencersEdit#PageHeader" />
      <InfluencerEdit
        influencer={influencer}
        onSubmit={onSubmit}
        places={placesOptions}
        onPlacesMenuScrollToBottom={onPlacesMenuScrollToBottom}
        isLoadingPlaces={isLoadingPlaces}
        onPlacesSearch={onPlacesSearch}
      />
      {renderModal()}
    </>
  );
};

export default InfluencerEditContainer;
