import { TReminderCategory } from '@sim-admin-frontends/data-access';
import { TExtendedColumn, ClickableCopyText } from '@sim-admin-frontends/ui-shared';
import { TFunction } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';

import ReminderCategoriesViewTableMenu from './ReminderCategoriesViewTableMenu';

const Image = styled.img`
  aspect-ratio: 1;
  width: 25px;
`;

export const createTableColumns = (t: TFunction, refetch: () => void) => {
  const columns: TExtendedColumn<TReminderCategory>[] = [
    {
      Header: 'Icon',
      accessor: 'icon',
      disableSortBy: true,
      Cell: ({ cell }: CellProps<TReminderCategory>) =>
        cell?.value?.links?.self ? <Image src={cell?.value?.links?.self} alt={'icon'} /> : null,
    },
    {
      Header: `${t('reminderCategories.columns.title')}`,
      accessor: 'title',
      disableSortBy: true,
    },
    {
      Header: `${t('reminderCategories.columns.shortTitle')}`,
      accessor: 'shortTitle',
      disableSortBy: true,
    },
    {
      Header: `${t('reminderCategories.columns.code')}`,
      accessor: 'code',
      Cell: ({ value }) => (value ? <ClickableCopyText value={value} /> : null),
      disableSortBy: true,
    },
    {
      Header: `${t('reminderCategories.columns.subtitle')}`,
      accessor: 'subtitle',
      disableSortBy: true,
    },
    {
      Header: `${t('reminderCategories.columns.reminderType')}`,
      accessor: 'reminderType',
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      minWidth: 15,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TReminderCategory>) => {
        return (
          <ReminderCategoriesViewTableMenu
            refetch={refetch}
            placeId={row.original.placeUuid}
            code={row.original.code}
            testId={`ReminderCategories#TableMenu${row.index}-${row.original.code}`}
          />
        );
      },
    },
  ];
  return columns;
};
