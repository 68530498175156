import { FC } from 'react';
import {
  Button,
  Error,
  SpinnerWrapper,
  TableActionHeader,
  useSearchFilter,
} from '@sim-admin-frontends/ui-shared';
import { useInfiniteSAAllSelectorsQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { createTableColumns } from './helper';
import { SelectorsView } from './SelectorsView';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';

const SelectorsViewContainer: FC = () => {
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'SelectorsView#Search',
    minNumOfChars: MIN_NUMBER_OF_SEARCH_CHARS,
  });
  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useInfiniteSAAllSelectorsQuery({ affix: filter });

  const columns = createTableColumns(t, refetch);

  const { currentPageIndex, onPreviousPress, onNextPress } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  if (isError) {
    return (
      <>
        <TableActionHeader prependItem={renderFilterComponent()}>
          <Link to={generatePath(ROUTES.selectorEdit.path)}>
            <Button>{t('selectors.addSelector')}</Button>
          </Link>
        </TableActionHeader>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }
  const itemsCount = data?.pages[0].allScrapeSelectors.selectors.length;
  const selectors = data?.pages[currentPageIndex].allScrapeSelectors.selectors;
  return (
    <>
      <TableActionHeader prependItem={renderFilterComponent()}>
        <Link to={generatePath(ROUTES.selectorEdit.path)}>
          <Button testId="SelectorsTab#AddSelector">{t('selectors.addSelector')}</Button>
        </Link>
      </TableActionHeader>
      <SelectorsView
        loading={isLoading || isFetchingNextPage}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        hasNextPage={hasNextPage || false}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={selectors || []}
      />
    </>
  );
};

export { SelectorsViewContainer };
