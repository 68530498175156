import { TFunction } from 'i18next';
import { ScrapeSingleSelector, ScrapeVariable } from '@sim-admin-frontends/data-access';
import { TSASelectorListItem } from '@sim-admin-frontends/data-access-admin-be';
import { TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';

import { SelectorsViewTableMenu } from './SelectorsViewTableMenu';

const printScrapeSelectors = (value: ScrapeSingleSelector[]) => {
  if (value) {
    return value.map(
      (selector, index) => `${selector.collect}${index !== value.length - 1 ? ',' : ''} `,
    );
  }
  return null;
};

const printList = (value: string[]) => {
  if (value) {
    return value.map((selector, index) => `${selector}${index !== value.length - 1 ? ',' : ''} `);
  }
  return null;
};

const printScrapeVariable = (value: ScrapeVariable[]) => {
  if (value) {
    return value.map(
      (variable, index) => `${variable.key}${index !== value.length - 1 ? ',' : ''} `,
    );
  }
  return null;
};

export const createTableColumns = (t: TFunction, refetch: () => void) => {
  const columns: TExtendedColumn<TSASelectorListItem>[] = [
    {
      Header: `${t('selectors.columns.code')}`,
      accessor: 'code',
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.nodeSelector')}`,
      accessor: 'nodeSelector',
      Cell: ({ value }) => printList(value),
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.subpageSelector')}`,
      accessor: 'subpageSelector',
      Cell: ({ value }) => printScrapeSelectors(value),
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.nextPageSelector')}`,
      accessor: 'nextPageSelector',
      Cell: ({ value }) => printScrapeSelectors(value),
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.maxPages')}`,
      accessor: 'maxPages',
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.imports')}`,
      accessor: 'imports',
      Cell: ({ value }) => printList(value),
      disableSortBy: true,
    },
    {
      Header: `${t('selectors.columns.variables')}`,
      accessor: 'variables',
      Cell: ({ value }) => printScrapeVariable(value),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TSASelectorListItem>) => {
        return <SelectorsViewTableMenu refetch={refetch} code={row.original.code} />;
      },
    },
  ];
  return columns;
};
