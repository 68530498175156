import { InstitutionFeatureFlag, InstitutionFeIconEnum } from '@sim-admin-frontends/data-access';
import {
  BinIcon,
  CarIcon,
  EarthIcon,
  FormIcon,
  GpsIcon,
  PaperPlaneIcon,
  PaymentIcon,
  PhoneIcon,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import styled from 'styled-components';

import InstitutionFeaturesTableMenu from './InstitutionFeaturesTableMenu';

const Image = styled.img`
  aspect-ratio: 1;
  width: 25px;
`;

export const getFeIcon = (icon: InstitutionFeatureFlag['feIcon']) => {
  switch (icon) {
    case InstitutionFeIconEnum.Gps:
      return <GpsIcon />;
    case InstitutionFeIconEnum.Earth:
      return <EarthIcon />;
    case InstitutionFeIconEnum.Phone:
      return <PhoneIcon />;
    case InstitutionFeIconEnum.PaperPlane:
      return <PaperPlaneIcon />;
    case InstitutionFeIconEnum.Car:
      return <CarIcon />;
    case InstitutionFeIconEnum.Payment:
      return <PaymentIcon />;
    case InstitutionFeIconEnum.Bin:
      return <BinIcon />;
    case InstitutionFeIconEnum.Form:
      return <FormIcon />;
    default:
      return null;
  }
};

export const createInstitutionFeaturesTableColumns = (
  onDeleteClick: (i: number) => void,
  institutionId: string,
) => {
  const columns: TExtendedColumn<InstitutionFeatureFlag>[] = [
    {
      Header: 'Icon',
      accessor: 'icon',
      disableSortBy: true,
      Cell: ({ row, cell }: CellProps<InstitutionFeatureFlag>) => {
        const src = cell?.value?.links?.self;
        const feIcon = getFeIcon(row.original.feIcon);

        if (feIcon) {
          return feIcon;
        }
        return src ? <Image src={src} alt={'icon'} /> : null;
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
    },
    {
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      testId: 'InstitutionFeaturesTable#dots',
      disableSortBy: true,
      Cell: ({ row }: CellProps<InstitutionFeatureFlag>) => {
        return (
          <InstitutionFeaturesTableMenu
            institutionId={institutionId}
            onDeleteClick={onDeleteClick}
            featureIndex={row.index}
            testId={`InstitutionFeatures#TableMenu${row.index}-${row.original.value}`}
          />
        );
      },
    },
  ];
  return columns;
};
