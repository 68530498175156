import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { TUserViewValues } from '../../../types/TUser';

export interface UsersViewProps {
  data: TUserViewValues[];
  columns: TExtendedColumn<TUserViewValues>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage: boolean;
  tablePageSize: number;
}

const UsersView: FC<UsersViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  hasNextPage,
  tablePageSize,
}) => {
  return (
    <Table
      tablePageSize={tablePageSize}
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      onNextPress={onNextPress}
      hasNextPage={hasNextPage}
      onPreviousPress={onPreviousPress}
      testId="UsersView#Table"
    />
  );
};

export { UsersView };
