import { CognitoUser } from '@aws-amplify/auth';
import { TAdminUserListItem } from '@sim-admin-frontends/data-access';
import { TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';

export type TLoginFormValues = {
  username: string;
  password: string;
};

export type TChangePasswordValues = {
  password: string;
  passwordConfirm: string;
};

export interface TLoggedUser extends CognitoUser {
  attributes?: {
    email?: string;
    sub?: string;
    username?: string;
  };
  signInUserSession?: {
    accessToken?: {
      jwtToken: string;
    };
  };
  username: string;
}

export type TUserEditFormValues = {
  username: string;
  email: string;
  institutions?: TSelectItems;
  place?: TSelectItem;
};

export type TAdminUserInfo = {
  username: string;
  email: string;
  tmpPassword?: string | null;
};

export type TUserViewValues = TAdminUserListItem & { institutions: TSelectItems };

export enum TUserModalActions {
  DELETE = 'DELETE',
}
