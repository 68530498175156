import {
  FailedPlaceItinerariesQuery,
  PlaceItinerariesQuery,
} from '@sim-admin-frontends/data-access-admin-be';

export enum TPlaceItinerariesTabTypes {
  ALL = 'all',
  FAILED = 'failed',
}

export type TPlaceItinerariesListItem =
  PlaceItinerariesQuery['placeItineraries']['itineraries'][number];

export type TFailedPlaceItinerariesListItem =
  FailedPlaceItinerariesQuery['failedPlaceItineraries']['itineraries'][number];

export type TItinerariesListItem = TPlaceItinerariesListItem | TFailedPlaceItinerariesListItem;

export type TItinerariesListItems = TItinerariesListItem[];
