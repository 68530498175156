import { TPaymentTransaction } from '@sim-admin-frontends/data-access';
import {
  FormInput,
  FormSectionHeader,
  SwitchableFormSection,
} from '@sim-admin-frontends/ui-shared';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PaymentEditTuiProduct from './PaymentEditTuiProduct';

const SwitchableFormSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

type Props = {
  paymentTransaction: TPaymentTransaction;
};

const PaymentEditTuiSection = ({ paymentTransaction }: Props) => {
  const { t } = useTranslation();
  const [showProducts, setShowProducts] = useState(false);
  const { metadata, marketPaymentDetails } = paymentTransaction;
  const orderUuid = marketPaymentDetails?.order?.uuid || paymentTransaction.orderUuid;
  const orderIdetifier = marketPaymentDetails?.order?.identifier;
  const merchantPrice = `${marketPaymentDetails?.merchantPrice} ${paymentTransaction.currency}`;
  const totalPrice = `${marketPaymentDetails?.totalPrice} ${paymentTransaction.currency}`;
  const products = marketPaymentDetails?.products;
  const parsedMetadata = useMemo(() => {
    if (!metadata) {
      return {};
    }
    return JSON.parse(metadata);
  }, [metadata]);

  const { email } = parsedMetadata;

  return (
    <>
      <FormInput
        label={t('payments.email')}
        value={email}
        readOnly
        disabled
        testId="PaymentEdit#email"
      />
      <FormSectionHeader title={t('paymentEdit.tuiMarketPayment')} />
      <FormInput
        label={t('payments.retailPrice')}
        value={totalPrice}
        readOnly
        disabled
        testId="PaymentEdit#retailPrice"
      />
      <FormInput
        label={t('payments.merchantPrice')}
        value={merchantPrice}
        readOnly
        disabled
        testId="PaymentEdit#merchantPrice"
      />
      <FormInput
        label={t('payments.attractionName')}
        value={paymentTransaction.marketItem?.title}
        readOnly
        disabled
        testId="PaymentEdit#attractionName"
      />
      <FormInput
        label={t('payments.orderId')}
        value={orderUuid}
        readOnly
        disabled
        testId="PaymentEdit#orderUuid"
      />
      <FormInput
        label={t('payments.orderIdentifier')}
        value={orderIdetifier}
        readOnly
        disabled
        testId="PaymentEdit#orderIdentifier"
      />
      <SwitchableFormSectionWrapper>
        <SwitchableFormSection
          title={t('payments.products')}
          description={t('payments.productsDescription')}
          onVisibilityChanged={setShowProducts}
          initiallyOpened={showProducts}
          disabled={!products?.length}
          disabledText={t('payments.productsDisabled')}
          testId={'PaymentEdit#switch'}
        >
          {products?.map((product) => (
            <PaymentEditTuiProduct product={product} key={product.id} />
          ))}
        </SwitchableFormSection>
      </SwitchableFormSectionWrapper>
    </>
  );
};

export default PaymentEditTuiSection;
