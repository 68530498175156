import { TExtendedColumn, Table } from '@sim-admin-frontends/ui-shared';
import { ITINERARIES_PAGE_SIZE } from '@sim-admin-frontends/data-access-admin-be';

import { TItinerariesListItems, TItinerariesListItem } from '../../types/TPlaceItineraries';

type Props = {
  data: TItinerariesListItems;
  columns: TExtendedColumn<TItinerariesListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  loading: boolean;
};

const PlaceItineraries = ({
  data,
  columns,
  onNextPress,
  onPreviousPress,
  pageIndex,
  loading,
}: Props) => (
  <Table
    loading={loading}
    tablePageSize={ITINERARIES_PAGE_SIZE}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    testId="PlacesItineraries#Table"
    responsive
  />
);

export default PlaceItineraries;
