import { TFunction } from 'i18next';
import { TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';
import { TAdminUserListItem } from '@sim-admin-frontends/data-access';

import { UsersViewTableMenu } from './UsersViewTableMenu';
import { TUserViewValues } from '../../../types/TUser';

export const createTableColumns = (t: TFunction, refetch: () => void) => {
  const columns: TExtendedColumn<TUserViewValues>[] = [
    {
      Header: `${t('users.form.username')}`,
      accessor: 'username',
      disableSortBy: true,
    },
    {
      Header: `${t('users.form.email')}`,
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: `${t('users.form.institutions')}`,
      accessor: 'institutions',
      Cell: ({ value }) =>
        value
          ? value
              .reduce<string[]>((acc, curr) => (curr.label ? [...acc, curr.label] : [...acc]), [])
              .join(', ')
          : null,
      disableSortBy: true,
    },
    {
      Header: `${t('users.form.place')}`,
      accessor: 'place',
      Cell: ({ value }) => (value ? value.name : null),
      disableSortBy: true,
    },
    {
      Header: '',
      id: '0',
      disableSortBy: true,
      Cell: ({ row }: CellProps<TAdminUserListItem>) => {
        return (
          <UsersViewTableMenu
            testId={`UsersView#TableMenu${row.index}`}
            refetch={refetch}
            username={row.original.username}
          />
        );
      },
    },
  ];
  return columns;
};
