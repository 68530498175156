import { FC } from 'react';
import {
  Component,
  ComponentState,
  useSystemStatusQuery,
  useUpdateSystemStatusMutation,
  TSystemSettings,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Error,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import SystemSettings from './SystemSettings';
import { getInitialSystemStatusValues } from '../../utils/systemSettingsUtils';

const SHARED_TOAST_ID = 'systemSettingsToast';

const SystemSettingsContainer: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const systemSettingsQueryKey = useSystemStatusQuery.getKey();
  const { isLoading, isError, data, refetch } = useSystemStatusQuery();
  const { mutateAsync: updateSystemStatus } = useUpdateSystemStatusMutation();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const onSubmit = async (values: TSystemSettings) => {
    try {
      loadingToast(t('common.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      await updateSystemStatus({
        componentActions: [
          {
            component: Component.Notifications,
            componentState: values.notificationsEnabled
              ? ComponentState.TurnOn
              : ComponentState.TurnOff,
          },
          {
            component: Component.Posts,
            componentState: values.postsEnabled ? ComponentState.TurnOn : ComponentState.TurnOff,
          },
        ],
      });
      await queryClient.invalidateQueries(systemSettingsQueryKey);
      updateToast(SHARED_TOAST_ID, t('systemSettings.successToast'), TToastType.SUCCESS);
    } catch (e) {
      updateToast(SHARED_TOAST_ID, e.message, TToastType.ERROR);
    }
  };

  return <SystemSettings onSubmit={onSubmit} initialValues={getInitialSystemStatusValues(data)} />;
};

export default SystemSettingsContainer;
