import { FC, useMemo, useState } from 'react';
import { useInfiniteSaPlacesQuery } from '@sim-admin-frontends/data-access-admin-be';
import { useSAnotifyUsersItitneraryMutation } from '@sim-admin-frontends/data-access';
import { useSearchTerm } from '@sim-admin-frontends/utils-shared';
import {
  getErrorMessage,
  loadingToast,
  TSelectItem,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { TItineraryNotificationsEditValues } from '../../../types/TNotifications';
import { transformPlaces } from '../../../utils/placeUtils';
import { MIN_NUMBER_OF_SEARCH_CHARS } from '../../../constants/Constants';
import ItineraryNotificationsEdit from './ItineraryNotificationsEdit';
import { SHARED_TOAST_ID } from '../view/NotificationsTabs';

const ItineraryNotificationsEditContainer: FC = () => {
  const { t } = useTranslation();
  const [selectedPlaceId, setSelectedPlaceId] = useState('');
  const { onSearch: onPlacesSearch, searchTerm: searchTermPlaces } = useSearchTerm(
    MIN_NUMBER_OF_SEARCH_CHARS,
  );

  const {
    data: placesData,
    fetchNextPage: fetchNextPagePlaces,
    hasNextPage: hasNextPagePlaces,
    isLoading: isLoadingPlaces,
  } = useInfiniteSaPlacesQuery({ includeDistricts: true, prefix: searchTermPlaces });

  const { mutateAsync: sendNotification } = useSAnotifyUsersItitneraryMutation();

  const places = useMemo(
    () => placesData?.pages.flatMap((page) => transformPlaces(page.places.places)) ?? [],
    [placesData],
  );

  const onPlacesScrollToBottom = () => {
    if (hasNextPagePlaces) {
      fetchNextPagePlaces();
    }
  };

  const onPlaceSelect = (selectedItem: readonly TSelectItem[] | null) => {
    if (!selectedItem) {
      return;
    }
    setSelectedPlaceId(selectedItem[0].value);
  };

  const onSubmit = async (values: TItineraryNotificationsEditValues) => {
    loadingToast(t('notifications.toastSubmitting'), {
      toastId: SHARED_TOAST_ID,
    });
    try {
      const res = await sendNotification({
        notificationDetails: {
          title: values.title,
          body: values.body,
          deepLink: values.deeplink,
        },
        placeUuid: selectedPlaceId,
      });
      const success = res.notifyUsersItitnerary.success;
      if (!success) {
        updateToast(
          SHARED_TOAST_ID,
          `${t('notifications.itinerary.toastFail')} `,
          TToastType.ERROR,
        );
        return;
      }
      const count = res.notifyUsersItitnerary.count;
      updateToast(
        SHARED_TOAST_ID,
        `${t('notifications.itinerary.toastSuccess')} ${count}`,
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  return (
    <ItineraryNotificationsEdit
      onPlacesSearch={onPlacesSearch}
      onPlacesScrollToBottom={onPlacesScrollToBottom}
      isFetchingPlaces={isLoadingPlaces}
      onPlaceSelect={onPlaceSelect}
      places={places}
      onSubmit={onSubmit}
    />
  );
};

export default ItineraryNotificationsEditContainer;
