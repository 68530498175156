import { ImageInput, Institution, Place } from '@sim-admin-frontends/data-access';
import { MAX_UPLOAD_SIZE, SUPPORTED_WEB_IMAGE_FORMATS } from '@sim-admin-frontends/ui-shared';
import { isFile } from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

type TPlaceBranding = Place['branding'];
type TPlaceSectionImages = Place['sectionImages'];
type TInstitutionBranding = Institution['branding'];

export const getInitialBrandingValues = (value: TInstitutionBranding) => {
  if (!value) {
    return null;
  }
  return !value.avatar
    ? null
    : {
        avatar: [value.avatar],
      };
};

export const getInitialPlaceSectionImages = (value?: TPlaceSectionImages) => {
  if (!value) {
    return undefined;
  }
  return {
    attractions: value.attractions ? [value.attractions] : [],
    museums: value.museums ? [value.museums] : [],
    theatre: value.theatre ? [value.theatre] : [],
    concerts: value.concerts ? [value.concerts] : [],
    sports: value.sports ? [value.sports] : [],
    cell: value.cell ? [value.cell] : [],
  };
};

export const getInitialPlaceBrandingValues = (value?: TPlaceBranding) => {
  if (!value) {
    return undefined;
  }
  return {
    logo: value.logo ? [value.logo] : [],
    avatar: value.avatar ? [value.avatar] : [],
    background: value.background ? [value.background] : [],
    topTenBackground: value.topTenBackground ? [value.topTenBackground] : [],
  };
};

export const getParentPlaceUuidFromString = (placeName?: string | null) => {
  if (!placeName || placeName === 'place#') {
    return undefined;
  }
  if (placeName.includes('#')) {
    return placeName.split('#')[1];
  }
  return placeName;
};

export const iconValidation = (t: TFunction, isRequired = true) =>
  Yup.array()
    .nullable()
    .test('required', t('form.fieldRequired'), (value) => (isRequired ? !!value : true))
    .of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test('fileType', t('common.validation.png'), (file: File | ImageInput) =>
          file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
        ),
    );

export const isNumberSet = (value?: number | null) => value === 0 || !!value;
