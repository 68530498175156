import { FC, useCallback, useMemo } from 'react';
import {
  TBaseProps,
  GpsIcon,
  EarthIcon,
  PhoneIcon,
  PaperPlaneIcon,
  CarIcon,
  PaymentIcon,
  BinIcon,
  FormIcon,
  FormItemLabel,
} from '@sim-admin-frontends/ui-shared';
import { InstitutionFeIconEnum } from '@sim-admin-frontends/data-access';
import styled from 'styled-components';

import { TInstitutionFeatureFlagValues } from '../../types/TFeatureFlags';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spaces.spacing32};
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

const Row = styled.div`
  display: flex;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spaces.spacing12};
`;

const IconWrapper = styled.div<{ $isActive: boolean; $isFirst?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => theme.spaces.spacing2} solid
    ${({ theme, $isActive }) => ($isActive ? theme.colors.blue50 : 'none')};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  width: ${({ theme }) => theme.spaces.spacing32};
  height: ${({ theme }) => theme.spaces.spacing32};
  margin: ${({ theme, $isActive }) => ($isActive ? theme.spaces.spacing2 : theme.spaces.spacing4)};
  cursor: pointer;
`;

interface Props extends TBaseProps {
  pickedIcon?: TInstitutionFeatureFlagValues['feIcon'];
  onIconClick: (value: TInstitutionFeatureFlagValues['feIcon']) => void;
  label?: string;
}

type TFeatureIcons = Array<{
  icon: React.ReactElement;
  value: InstitutionFeIconEnum;
}>;

const IconPicker: FC<Props> = ({ pickedIcon, onIconClick, testId, label }) => {
  const icons: TFeatureIcons = useMemo(
    () => [
      { icon: <GpsIcon />, value: InstitutionFeIconEnum.Gps },
      { icon: <EarthIcon />, value: InstitutionFeIconEnum.Earth },
      { icon: <PhoneIcon />, value: InstitutionFeIconEnum.Phone },
      { icon: <PaperPlaneIcon />, value: InstitutionFeIconEnum.PaperPlane },
      { icon: <CarIcon />, value: InstitutionFeIconEnum.Car },
      { icon: <PaymentIcon />, value: InstitutionFeIconEnum.Payment },
      { icon: <BinIcon />, value: InstitutionFeIconEnum.Bin },
      { icon: <FormIcon />, value: InstitutionFeIconEnum.Form },
    ],
    [],
  );

  const iconsHalf = useMemo(() => Math.ceil(icons.length / 2), [icons]);

  const onClick = useCallback(
    (value: InstitutionFeIconEnum) => () => {
      if (pickedIcon === value) {
        onIconClick(null);
      } else {
        onIconClick(value);
      }
    },
    [onIconClick, pickedIcon],
  );

  const iconsFirstHalf = icons.slice(0, iconsHalf);
  const iconsSecondHalf = icons.slice(iconsHalf);

  const renderIcons = useCallback(
    (iconArray: TFeatureIcons) =>
      iconArray.map(({ icon, value }, index) => (
        <IconWrapper
          $isFirst={index === 0}
          $isActive={value === pickedIcon}
          key={value}
          onClick={onClick(value)}
          data-testid={`${testId}-row1-${value}`}
        >
          {icon}
        </IconWrapper>
      )),
    [onClick, pickedIcon, testId],
  );

  return (
    <Wrapper>
      {label && <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>}
      <IconsWrapper>
        <Row>{renderIcons(iconsFirstHalf)}</Row>
        <Row>{renderIcons(iconsSecondHalf)}</Row>
      </IconsWrapper>
    </Wrapper>
  );
};

export default IconPicker;
