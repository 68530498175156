import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacing4};
`;

export const HeaderItem = styled.div`
  flex: 1;
`;

export const StyledTippy = styled(Tippy)`
  &.tippy-box {
    > .tippy-content {
      padding: ${({ theme }) => theme.spaces.spacing8};
    }
  }
`;
