import { useMemo } from 'react';
import { captureException } from '@sentry/react';
import {
  CategoryType,
  UploadType,
  useCategoriesQuery,
  useSaAddInfluencerSpotMutation,
  useSaDeleteInfluencerSpotMutation,
  useSaInfluencerSpotPlaceQuery,
  useSaInfluencerSpotQuery,
  useSaUpdateInfluencerSpotMutation,
} from '@sim-admin-frontends/data-access';
import {
  TToastType,
  loadingToast,
  updateToast,
  Error,
  SpinnerWrapper,
  Spinner,
  ActionButtons,
  PageHeader,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import { filterCategories, useUploadImage } from '@sim-admin-frontends/utils-shared';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { INFLUENCER_TOAST_ID } from '../../../constants/Constants';
import { useGenerateInfluencerActions } from '../../../hooks/actionButtons/useGenerateInfluencerActions';
import ROUTES from '../../../routing/routes';
import InfluencerSpotEdit from './InfluencerSpotEdit';
import { TInfluencerSpotFormValues } from '../../../types/TInfluencer';
import useSearchablePlaceOptions from '../../../hooks/useSearchablePlaceOptions';
import { getCategoriesOptions } from '../../../utils/categoriesUtils';
import useSearchableMarketItemsOptions from '../../../hooks/useSearchableMarketItemsOptions';
import { transformInfluencerSpotFormValues } from '../../../utils/influencerUtils';

type Props = {
  id?: string;
  influencerId: string;
};

const InfluencerSpotEditContainer = ({ id, influencerId }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: influencerSpotData,
    isLoading: isLoadingInfluencerSpot,
    isError: isErrorInfluencerSpot,
    refetch: refetchInfluencerSpot,
  } = useSaInfluencerSpotQuery({ id: id || '' }, { enabled: !!id });

  const initialPlaceId = influencerSpotData?.influencerSpot.placeUuid;

  const {
    data: initialPlaceData,
    isLoading: isLoadingInitialPlaceData,
    isError: isErrorInitialPlaceData,
    refetch: refetchnitialPlaceData,
  } = useSaInfluencerSpotPlaceQuery({ id: initialPlaceId || '' }, { enabled: !!initialPlaceId });

  const initialPlace = initialPlaceData?.place
    ? { value: initialPlaceData.place.id, label: initialPlaceData.place.name }
    : undefined;

  const {
    placesOptions,
    onPlacesMenuScrollToBottom,
    onPlacesSearch,
    isErrorPlaces,
    isLoadingPlaces,
    refetchPlaces,
  } = useSearchablePlaceOptions(true);

  const {
    setPlaceUuid,
    marketItems,
    onMarketItemsMenuScrollToBottom,
    onMarketItemsSearch,
    isErrorMarketItems,
    isLoadingMarketItems,
    refetchMarketItems,
  } = useSearchableMarketItemsOptions(initialPlaceId || '');

  const {
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    data: categoriesData,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Influencer,
    },
  });

  const categories = useMemo(() => {
    return getCategoriesOptions(
      filterCategories(categoriesData?.categoriesBy.categories || [], {
        filterLegacy: true,
      }),
    );
  }, [categoriesData]);

  const refetch = () => {
    refetchInfluencerSpot();
    refetchPlaces();
    refetchnitialPlaceData();
    refetchMarketItems();
  };

  const pageTitle = id ? t('influencerSpots.editPageTitle') : t('influencerSpots.createPageTitle');
  const pageCaption = id
    ? t('influencerSpots.editPageCaption')
    : t('influencerSpots.createPageCaption');

  const { uploadFormImages } = useUploadImage();
  const { mutateAsync: addInfluencerSpot } = useSaAddInfluencerSpotMutation();
  const { mutateAsync: updateInfluencerSpot } = useSaUpdateInfluencerSpotMutation();
  const { mutate: deleteMutation } = useSaDeleteInfluencerSpotMutation({
    onMutate: () => {
      loadingToast(t('institutions.deleteToast.loading'), {
        toastId: INFLUENCER_TOAST_ID,
      });
    },
    onError: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(INFLUENCER_TOAST_ID, t('institutions.deleteToast.success'), TToastType.SUCCESS);
      refetchInfluencerSpot();
    },
  });

  const onSubmit = async (formValues: TInfluencerSpotFormValues) => {
    try {
      loadingToast(t('institutions.createToast.loading'), {
        toastId: INFLUENCER_TOAST_ID,
      });
      const images = await uploadFormImages(UploadType.Influencer, formValues.image, {
        placeId: formValues.place?.value,
      });
      const influencerSpot = transformInfluencerSpotFormValues(formValues, images, influencerId);
      if (id) {
        await updateInfluencerSpot({ id, influencerSpot });
      } else {
        await addInfluencerSpot({ influencerSpot });
      }
      updateToast(
        INFLUENCER_TOAST_ID,
        id ? t('influencers.editToast.success') : t('influencers.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(generatePath(ROUTES.influencerSpots.path, { influencerId }));
    } catch (e) {
      updateToast(INFLUENCER_TOAST_ID, getErrorMessage(e), TToastType.ERROR);
      captureException(e);
    }
  };

  const { detailActions, renderModal } = useGenerateInfluencerActions({
    id,
    refetch,
    editPath: ROUTES.influencerSpotsEdit.path,
    listPath: ROUTES.influencerSpots.path,
    isEditPage: true,
    deleteMutation,
    influencerId,
  });

  const isLoading = isLoadingInfluencerSpot || isLoadingCategories || isLoadingInitialPlaceData;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const isError =
    isErrorInfluencerSpot ||
    isErrorCategories ||
    isErrorPlaces ||
    isErrorInitialPlaceData ||
    isErrorMarketItems ||
    (id && !influencerSpotData && !initialPlace);

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  const influencerSpot = influencerSpotData?.influencerSpot;

  return (
    <>
      <ActionButtons actionButtons={detailActions} />
      <PageHeader title={pageTitle} caption={pageCaption} testId="InfluencerSpotsEdit#PageHeader" />
      <InfluencerSpotEdit
        influencerSpot={influencerSpot}
        onSubmit={onSubmit}
        places={placesOptions}
        onPlacesSearch={onPlacesSearch}
        isLoadingPlaces={isLoadingPlaces}
        onPlacesMenuScrollToBottom={onPlacesMenuScrollToBottom}
        categories={categories}
        initialPlace={initialPlace}
        setPlaceUuid={setPlaceUuid}
        marketItems={marketItems}
        isLoadingMarketItems={isLoadingMarketItems}
        onMarketItemsSearch={onMarketItemsSearch}
        onMarketItemsMenuScrollToBottom={onMarketItemsMenuScrollToBottom}
      />
      {renderModal()}
    </>
  );
};

export default InfluencerSpotEditContainer;
