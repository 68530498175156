import { TSelectItems } from '@sim-admin-frontends/ui-shared';

import { TInstitutionEnablingStatus } from '../types/TInstitution';

export const transformArrayToSelectItems = <T extends { [key: string]: any }>(
  array: T[] | undefined,
  labelKey: string,
  valueKey: string,
  includeDisabled = false,
): TSelectItems => {
  if (!array || !labelKey || !valueKey) {
    return [];
  }
  return array.map((i) => ({
    label: i[labelKey],
    value: i[valueKey],
    ...(includeDisabled ? { disabled: i.enabled === TInstitutionEnablingStatus.DISABLED } : {}),
  }));
};
