import {
  TInfluencerListItem,
  TInfluencerSpotListItem,
  SaInfluencerQuery,
  SocialType,
  PropertyType,
  ImageInput,
  SaInfluencerSpotQuery,
  LocationInput,
  Currency,
} from '@sim-admin-frontends/data-access';
import { TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';

export type TInfluencerDetail = SaInfluencerQuery['influencer'];
export type TInfluencerSpotDetail = SaInfluencerSpotQuery['influencerSpot'];

export type TInfluencerViewValues = TInfluencerListItem;
export type TInfluencerSpottViewValues = TInfluencerSpotListItem;

export enum TInfluencerModalActions {
  DELETE = 'DELETE',
}

export type TSocialSelectItem = {
  value: SocialType;
  label: string;
};

export type TPropertySelectItem = {
  value: PropertyType;
  label: string;
};

export type TInfluencerFormValues = {
  avatar?: (File | ImageInput)[] | null;
  background?: (File | ImageInput)[] | null;
  name: string;
  description: string;
  socials: {
    type: TSocialSelectItem;
    url: string;
  }[];
  properties: {
    name: TPropertySelectItem;
    value: string;
  }[];
  places: TSelectItems;
};

export type TCurrencySelectItem = {
  label: string;
  value: Currency;
};

export type TInfluencerStartEndFormValues = {
  startTime?: string;
  endTime?: string;
  startTimePeriod?: string;
  endTimePeriod?: string;
};

export type TInfluencerSpotFormValues = TInfluencerStartEndFormValues & {
  place?: TSelectItem;
  title: string;
  content: string;
  image?: (File | ImageInput)[] | null;
  location?: LocationInput | null;
  duration?: number | null;
  marketItem?: TSelectItem;
  price?: number | null;
  currency?: TCurrencySelectItem;
  category?: TSelectItem;
  favourite: boolean;
};
