import { InstitutionFeatureFlag } from '@sim-admin-frontends/data-access';
import { PlaceFeatureFlag, TitleLocalization } from '@sim-admin-frontends/data-access-admin-be';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import AskMayorQuestionImage from '../assets/img/customFeatureFlags/ask_mayor_a_question.png';
import TransportImage from '../assets/img/customFeatureFlags/busIcon.png';
import CommunityImage from '../assets/img/customFeatureFlags/comunity_fund.png';
import CouponsImage from '../assets/img/customFeatureFlags/coupons.png';
import GarbageImage from '../assets/img/customFeatureFlags/garbage.png';
import MessagingImage from '../assets/img/customFeatureFlags/messaging.png';
import PayParkingTicketImage from '../assets/img/customFeatureFlags/pay_parking_ticket.png';
import PetsImage from '../assets/img/customFeatureFlags/pets.png';
import SimplicityIDImage from '../assets/img/customFeatureFlags/simplicity-id.png';
import ChatbotImage from '../assets/img/customFeatureFlags/chatbot.png';
import { FeatureFlagsMenuItem, TTitleLocalizations } from '../types/TFeatureFlags';

const StyledImage = styled.img`
  width: ${({ theme }) => theme.spaces.spacing32};
`;

export const cloneFlagWithoutIcon = <T extends InstitutionFeatureFlag | PlaceFeatureFlag>(
  flag: T,
) => {
  const newValue = cloneDeep(flag);
  delete newValue.icon?.links;
  return newValue;
};

export const getFeatureFlagPlaceholderImage = (featureFlag: PlaceFeatureFlag) => {
  if (featureFlag.url?.toLowerCase().includes('amq')) {
    return <StyledImage src={AskMayorQuestionImage} alt="AskMayorQuestionImage" />;
  }
  switch (featureFlag.url) {
    case FeatureFlagsMenuItem.SIMPLICITY_ID:
      return <StyledImage src={SimplicityIDImage} alt="SimplicityIDImage" />;
    case FeatureFlagsMenuItem.COMMUNITY_FUND:
      return <StyledImage src={CommunityImage} alt="CommunityImage" />;
    case FeatureFlagsMenuItem.REPORT_PROBLEM:
    case FeatureFlagsMenuItem.MESSAGING:
      return <StyledImage src={MessagingImage} alt="MessagingImage" />;
    case FeatureFlagsMenuItem.PET_DIRECTORY:
      return <StyledImage src={PetsImage} alt="PetsImage" />;
    case FeatureFlagsMenuItem.ASK_MAYOR_QUESTION:
      return <StyledImage src={AskMayorQuestionImage} alt="AskMayorQuestionImage" />;
    case FeatureFlagsMenuItem.COUPON:
      return <StyledImage src={CouponsImage} alt="CouponsImage" />;
    case FeatureFlagsMenuItem.ANY_PAYMENTS:
      return <StyledImage src={PayParkingTicketImage} alt="PayParkingTicketImage" />;
    case FeatureFlagsMenuItem.TRANSPORT:
      return <StyledImage src={TransportImage} alt="TransportImage" />;
    case FeatureFlagsMenuItem.GARBAGE:
      return <StyledImage src={GarbageImage} alt="GarbageImage" />;
    case FeatureFlagsMenuItem.CHATBOT:
      return <StyledImage src={ChatbotImage} alt="ChatbotImage" />;
    default:
      return null;
  }
};

export const getInitialTitleLocalizations = (titleLocalizations?: TitleLocalization[]) => {
  if (!titleLocalizations || !titleLocalizations.length) {
    return undefined;
  }

  const titleLocalizationsObject: TTitleLocalizations = {};

  titleLocalizations?.forEach((titleLocalization) => {
    titleLocalizationsObject[titleLocalization.language as keyof TTitleLocalizations] =
      titleLocalization.title;
  });

  return titleLocalizationsObject;
};

export const transformTitleLocalizationsForBE = (titleLocalizations: TTitleLocalizations) =>
  Object.entries(titleLocalizations).reduce<TitleLocalization[]>((acc, [language, title]) => {
    if (!title) {
      return acc;
    }
    return [
      ...acc,
      {
        language,
        title,
      },
    ];
  }, []);
