import {
  Component,
  ComponentState,
  SystemStatusQuery,
} from '@sim-admin-frontends/data-access-admin-be';

export const getInitialSystemStatusValues = (data?: SystemStatusQuery) => {
  let notificationsEnabled = false;
  let postsEnabled = false;
  data?.systemStatus.componentActions.forEach((action) => {
    if (action.component === Component.Notifications) {
      notificationsEnabled = action.componentState === ComponentState.TurnOn;
    }
    if (action.component === Component.Posts) {
      postsEnabled = action.componentState === ComponentState.TurnOn;
    }
  });
  return { notificationsEnabled, postsEnabled };
};
