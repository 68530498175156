import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { captureException } from '@sentry/react';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TSelectItems,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  UploadType,
  useInfiniteSAReminderCategoriesByQuery,
  useInstitutionNameQuery,
} from '@sim-admin-frontends/data-access';
import {
  useInfiniteReportProblemCategoriesQuery,
  useMessagingCategoryQuery,
  useSaPlaceQuery,
  useSaUpdatePlaceMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import { useUploadImage } from '@sim-admin-frontends/utils-shared';

import NotFound from '../../../routing/NotFound';
import PlaceFeatureEdit from './PlaceFeatureEdit';
import ROUTES from '../../../routing/routes';
import {
  transformFeatureFlagsValues,
  compareFlagOrder,
} from '../../../utils/placeFeatureFlagsUtils';
import { TPlaceFeatureFlagValues } from '../../../types/TFeatureFlags';
import { useSearchCombinedInstitutions } from '../../../hooks/useSearchCombinedInstitutions';

const SHARED_TOAST_ID = 'placesToast';

type PlaceFeatureEditContainerProps = {
  placeId: string;
  featureIndex?: string;
};

const PlaceFeatureEditContainer: FC<PlaceFeatureEditContainerProps> = ({
  placeId,
  featureIndex,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { uploadFormImages } = useUploadImage();

  const {
    data,
    isLoading: isLoadingPlace,
    isError: isErrorPlace,
    refetch: refetchPlace,
  } = useSaPlaceQuery({ id: placeId });
  const { mutateAsync: updatePlace } = useSaUpdatePlaceMutation();
  const place = data?.place;

  const featureFlags = useMemo(
    () => (place?.featureFlags ? place?.featureFlags.sort(compareFlagOrder) : []),
    [place?.featureFlags],
  );

  const featureFlag = featureIndex ? featureFlags[Number(featureIndex)] : undefined;
  const order = featureFlag?.order ? featureFlag.order : data?.place?.featureFlags?.length ?? 0;

  const {
    data: reminderCategoriesData,
    isError: isReminderCategoriesError,
    isLoading: isReminderCategoriesLoading,
    refetch: refetchReminderCategories,
    fetchNextPage: fetchNextPageReminderCategories,
    hasNextPage: hasNextPageReminderCategories,
    isFetchingNextPage: isFetchingNextPageReminderCategories,
  } = useInfiniteSAReminderCategoriesByQuery(placeId);

  const isFetchingReminderCategories =
    isReminderCategoriesLoading || isFetchingNextPageReminderCategories;

  const onReminderCategoriesScrollToBottom = () => {
    if (hasNextPageReminderCategories) {
      fetchNextPageReminderCategories();
    }
  };

  const reminderCategoriesOptions: TSelectItems = useMemo(
    () =>
      reminderCategoriesData?.pages?.flatMap((page) =>
        page.reminderCategoriesBy.reminderCategories.map((cat) => ({
          value: cat.code,
          label: cat.title,
        })),
      ) || [],
    [reminderCategoriesData],
  );

  const {
    institutions,
    isLoadingInstitutions,
    isFetchingInstitutions,
    isErrorInsitutions,
    refetchInstitutions,
    fetchNextPageInstitutions,
    hasNextPageInstitutions,
    onInstitutionsSearch,
  } = useSearchCombinedInstitutions();

  const onInstitutionsScrollToBottom = () => {
    if (hasNextPageInstitutions) {
      fetchNextPageInstitutions();
    }
  };

  const institutionOptions = institutions
    ? institutions.map((institution) => ({ label: institution.name, value: institution.id }))
    : [];

  const isInstitutionNameEnabled =
    !!featureFlag?.reminderSettings?.institutionUuid ||
    !!featureFlag?.ticketmasterSettings?.institutionUuid;

  const {
    data: institution,
    isLoading: isInstitutionLoading,
    isError: isInstitutionError,
    refetch: institutionRefetch,
  } = useInstitutionNameQuery(
    {
      id:
        featureFlag?.reminderSettings?.institutionUuid ??
        featureFlag?.ticketmasterSettings?.institutionUuid ??
        '',
    },
    { enabled: isInstitutionNameEnabled },
  );

  const institutionName = institution?.institution?.name;

  const potholeCategoryId = featureFlag?.reportedProblemsSettings?.potholeCategoryUuid || '';

  const {
    data: potholeCategoryData,
    isError: isPotholeCategoryError,
    isLoading: isPotholeCategoryLoading,
    refetch: potholeCategoryRefetch,
  } = useMessagingCategoryQuery({ id: potholeCategoryId }, { enabled: !!potholeCategoryId });

  const potholeCategory = potholeCategoryData?.messagingCategory || undefined;

  const {
    data: reportProblemCategories,
    isLoading: reportProblemCategoriesIsLoading,
    isError: reportProblemCategoriesIsLoadingIsError,
    refetch: reportProblemRefetch,
    hasNextPage: reportProblemHasNextPage,
    fetchNextPage: reportProblemFetchNextPage,
  } = useInfiniteReportProblemCategoriesQuery({ placeUuid: placeId });

  const reportProblemCategoriesOptions = useMemo(
    () =>
      (reportProblemCategories?.pages || [])
        .flatMap((page) => page.messagingCategoriesBy?.messagingCategories || [])
        .map((category) => ({
          label: category.name || '',
          value: category.id || '',
        })),
    [reportProblemCategories],
  );

  const onReportProblemCategoriesScrollBottom = () => {
    if (reportProblemHasNextPage) {
      reportProblemFetchNextPage();
    }
  };

  const defaultLanguageSettings = useMemo(
    () => ({
      countryCode: place?.countryCode,
      language: place?.defaultLanguage || undefined,
    }),
    [place],
  );

  const isError =
    isErrorPlace ||
    isErrorInsitutions ||
    isInstitutionError ||
    reportProblemCategoriesIsLoadingIsError ||
    isPotholeCategoryError;

  const isLoading =
    isLoadingPlace || isLoadingInstitutions || isInstitutionLoading || isPotholeCategoryLoading;

  const refetch = () => {
    refetchPlace();
    refetchReminderCategories();
    refetchInstitutions();
    institutionRefetch();
    if (reportProblemCategoriesIsLoadingIsError) {
      reportProblemRefetch();
    }
    if (isPotholeCategoryError) {
      potholeCategoryRefetch();
    }
  };

  const onSubmit = async (values: TPlaceFeatureFlagValues) => {
    if (!place) {
      return;
    }
    try {
      loadingToast(t('places.createToast.loading'), {
        toastId: SHARED_TOAST_ID,
      });
      const iconImage = await uploadFormImages(UploadType.FeatureFlagIcons, values?.icon, {
        placeId,
      });
      const { newFlag, prevFlags, placeInfo } = transformFeatureFlagsValues(
        place,
        featureFlags,
        values,
        iconImage,
        featureIndex,
      );

      await updatePlace({
        id: placeId,
        place: {
          ...placeInfo,
          featureFlags: newFlag ? [...prevFlags, newFlag] : [...prevFlags],
        },
      });
      updateToast(
        SHARED_TOAST_ID,
        placeId ? t('places.editToast.success') : t('places.createToast.success'),
        TToastType.SUCCESS,
      );
      history.push(generatePath(ROUTES.placeFeatures.path, { id: placeId }));
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  if (isError || isReminderCategoriesError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const isNotFound =
    (placeId && !data) || (featureIndex && !featureFlag) || (featureIndex && !featureFlags);

  if (isNotFound) {
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  }

  return (
    <PlaceFeatureEdit
      featureFlag={featureFlag}
      onSubmit={onSubmit}
      order={order}
      reminderCategoriesOptions={reminderCategoriesOptions}
      isFetchingReminderCategories={isFetchingReminderCategories}
      onReminderCategoriesScrollToBottom={onReminderCategoriesScrollToBottom}
      institutionName={institutionName}
      institutionOptions={institutionOptions}
      isFetchingInstitutions={isFetchingInstitutions}
      onInstitutionsSearch={onInstitutionsSearch}
      onInstitutionsScrollToBottom={onInstitutionsScrollToBottom}
      defaultLanguageSettings={defaultLanguageSettings}
      defaultPotholeCategory={potholeCategory}
      reportProblemCategoriesOptions={reportProblemCategoriesOptions}
      onReportProblemCategoriesScrollBottom={onReportProblemCategoriesScrollBottom}
      reportProblemCategoriesIsLoading={reportProblemCategoriesIsLoading}
    />
  );
};

export default PlaceFeatureEditContainer;
