import { useSaDeleteAdminUserMutation } from '@sim-admin-frontends/data-access';
import {
  ConfirmModal,
  EditIcon,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TUserModalActions } from '../../types/TUser';

type Props = {
  username?: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateUserActions = ({ refetch, username, isEditPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(TUserModalActions.DELETE);

  const { mutate: deleteAdminUserMutation } = useSaDeleteAdminUserMutation({
    onMutate: () => {
      loadingToast(t('places.deleteToast.loading'), {
        toastId: TUserModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TUserModalActions.DELETE, t('places.deleteToast.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(TUserModalActions.DELETE, t('places.deleteToast.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const deleteUser = async () => {
    await deleteAdminUserMutation({
      username: username || '',
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === TUserModalActions.DELETE) {
      deleteUser();
      setIsModalVisible(false);
      history.push(ROUTES.users.path);
      refetch();
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setModalDescription(t('users.deleteModal.description'));
    setModalClickAction(TUserModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handleEditClick = () => {
    history.push(generatePath(ROUTES.userEdit.path, { username }));
  };

  const generateItems = (): TableIconProps[] => {
    if (!username) {
      return [];
    }

    return [
      {
        label: t('table.dropdown.edit'),
        action: handleEditClick,
        hidden: isEditPage,
        testId: 'UsersTableMenu#edit',
        icon: <EditIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'UsersTableMenu#delete',
        icon: <TrashCanIcon />,
      },
    ];
  };

  const renderModal = () => (
    <ConfirmModal
      title={t('users.deleteModal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('users.deleteModal.cancel')}
      confirmButtonText={t('users.deleteModal.confirm')}
      testId="Users#ConfirmModal"
    />
  );

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
