import {
  EditIcon,
  TableIconProps,
  TableIcons,
  TBaseProps,
  TrashCanIcon,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import ROUTES from '../../../routing/routes';

interface PlaceFeaturesTableMenuProps extends TBaseProps {
  placeId: string;
  onDeleteClick: (i: number) => void;
  featureIndex: number;
}

const PlaceFeaturesTableMenu: FC<PlaceFeaturesTableMenuProps> = ({
  placeId,
  onDeleteClick,
  featureIndex,
  testId,
}) => {
  const { t } = useTranslation();

  const editPath = generatePath(ROUTES.placeFeaturesEdit.path, { placeId, featureIndex });

  const handleDeleteClick = () => {
    onDeleteClick(featureIndex);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'PlacesFeatureMenu#edit',
      icon: <EditIcon />,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'PlacesFeatureMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  const items = generateItems();

  return <TableIcons testId={testId} items={items} />;
};

export default PlaceFeaturesTableMenu;
